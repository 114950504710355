import _ from "lodash";
import Select from "react-dropdown-select";
import AsyncSelect from "react-select/async";
import React, { Component } from 'react';
import { Layout } from './Layout';
import jQuery from 'jquery';
import $ from 'jquery';
import MaterialTable from "material-table";
import { Link, NavLink } from 'react-router-dom';
import 'rc-datepicker/lib/style.css';
import Api from '../serviceCall';
import axios from 'axios';
import { isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
export class CaseDetails extends Component {
    static displayName = CaseDetails.name;
    constructor(props) {
        super(props);
        this.state = {
            DownloadImages: "",
            loading: true, PFN: "", PLN: "", PEA: "", PON: "", PDOB: "", PGender: "", PSSN: "", PPCM: "", PPhn1: "", PExt1: "", PPhn2: "", PExt2: "", PPhn3: "", PExt3: "", PFax: "", PAddr: "", PCity: "", PState: "", PZip: "",
            AFN: "", ALN: "", AEA: "", AWP: "", AExt: "", ACP: "", AFax: "", AAI: "", AdFN: "", AdLN: "", AdEA: "", AdWP: "", AdExt: "", AdCP: "", AdFax: "", AdAI: "", PolicyNum: "", AccName: "", Orginator: "", CarrierName: "",
            DocList: [], OrderId: "", contactName: "", contactEmail: "", PrevPage: '', BackText: "", UploadDocId: "", OrderFacilityId: "", OrderDiscussionFacilityId: "",
            contactPhone: "", isUploadModalOpen: false, FileName: '', FileType: '', FileString: '', Files: [], Base64: '', isViewModalopen: false, DocFileName: "", PolicyAmount: "",
            POtherField1: "", POtherField2: "", POtherField3: "", POtherField4: "", PDateOfIncident: "", HideLifeDiv: false, HideLegalDiv: false, isDiscussionModalOpen: false,
            OrderDiscussionTopic: "", OrderDiscussionType: "", OrderDiscussionStatus: "", OrderDiscussionComment: "", DateAuthor: "", Selected: "", isFirst: true,
            //added code
            CMId: "",
            CMFN: "",
            CMLN: "",
            CMEmailAddress: "",
            CMWorkPhone: "",
            CMExt: "",
            CMCellPhone: "",
            CMFax: "",

            lblOtherField1: "",
            lblOtherField2: "",
            lblOtherField3: "",
            lblOtherField4: "",
            lblPolicyAmount: "",
            lblPolicyNum: "",
            OtherField1Hide: false,
            OtherField2Hide: false,
            OtherField3Hide: false,
            OtherField4Hide: false,
            PolicyAmountHide: false,
            PolicyNumHide: false,

            lblAgentFN: "",
            lblAgentLN: "",
            lblAgentEmail: "",
            lblAgentPhone: "",
            lblAgentExt: "",
            lblAgentMobile: "",
            lblAgentFax: "",
            lblAgentId: "",
            AgentFNHide: false,
            AgentLNHide: false,
            AgentEmailHide: false,
            AgentPhoneHide: false,
            AgentExtHide: false,
            AgentMobileHide: false,
            AgentFaxHide: false,
            AgentIdHide: false,

            lblAdjusterFN: "",
            lblAdjusterLN: "",
            lblAdjusterEmail: "",
            lblAdjusterPhone: "",
            lblAdjusterMobile: "",
            lblAdjusterExt: "",
            lblAdjusterFax: "",
            lblAdjusterId: "",
            AdjusterFNHide: false,
            AdjusterLNHide: false,
            AdjusterEmailHide: false,
            AdjusterPhoneHide: false,
            AdjusterMobileHide: false,
            AdjusterExtHide: false,
            AdjusterFaxHide: false,
            AdjusterIdHide: false,

            lblCMFN: "",
            lblCMLN: "",
            lblCMEmailAddress: "",
            lblCMWorkPhone: "",
            lblCMCellPhone: "",
            lblCMExt: "",
            lblCMFax: "",
            CMFNHide: false,
            CMLNHide: false,
            CMEmailAddressHide: false,
            CMWorkPhoneHide: false,
            CMCellPhoneHide: false,
            CMExtHide: false,
            CMFaxHide: false,

            lblOtherNames: "",
            lblSSN: "",
            lblFirstName: "",
            lblLastName: "",
            lblEmail: "",
            lblPCAddress: "",
            lblPCAddress2: "",
            lblPCCity: "",
            lblPCZipCode: "",
            lblPhone1: "",
            lblExt1: "",
            lblPhone2: "",
            lblExt2: "",
            lblPhone3: "",
            lblExt3: "",
            lblFax: "",
            lblPreferredContact: "",
            lblDOB: "",
            lblGender: "",
            lblStateId: "",
            lblDateOfIncident: "",

            OtherNamesHide: false,
            SSNHide: false,
            FirstNameHide: false,
            LastNameHide: false,
            EmailHide: false,
            PCAddressHide: false,
            PCAddress2Hide: false,
            PCCityHide: false,
            PCZipCodeHide: false,
            Phone1Hide: false,
            Ext1Hide: false,
            Phone2Hide: false,
            Ext2Hide: false,
            Phone3Hide: false,
            Ext3Hide: false,
            FaxHide: false,
            PreferredContactHide: false,
            DOBHide: false,
            GenderHide: false,
            StateIdHide: false,
            DateOfIncidentHide: false,

            lblDrAddress: "",
            lblDrEmailAddress: "",
            lblDoctorCity: "",
            lblDrStateId: "",
            lblDrZipCode: "",
            lblPhone: "",
            lblFacilityName: "",
            lblDrFax: "",
            DrAddressHide: false,
            DrEmailAddressHide: false,
            DoctorCityHide: false,
            DrStateIdHide: false,
            DrZipCodeHide: false,
            PhoneHide: false,
            FacilityNameHide: false,
            DrFaxHide: false,
            isDownload: false,
            isViewNote: false,
            isViewAuth: false,

            RequiredFieldsList: [],
            Description: [],

            repopupShow: false,
            ReopenHide: false,
            ReopenComents: "",
            IsReopenText: false,
            ReopenOrderId: "",
            AuthDocIndex: 0,
            IsRedacted: false,
            SupportedFileTypes: [],
            AllFileTypes: [],
            SupportedFileSize: "",
            showSSN: false,
            cancelpopupShow: false,
            CancelHide: false,
            CancelComents: "",
            IsCancelText: false,
            CSRRequestType: "",
            eNoahOrderId: "",
            messagepopupShow: false,
            IsMessageText: false,
            MessageComents: "",
            AllowCSRMessaging: false,
            toasterCla: false,
            toaster: '',
            OrderFacilityNo: "",
            ProductType: "",
            ProductTypeShow: true,
            APSReasonShow: true,

            isAPSproduct: false,
            ProductCode: "",
            UWId: "",
            UWFN: "",
            UWLN: "",
            UWEmailAddress: "",
            UWWorkPhone: "",
            UWExt: "",
            UWCellPhone: "",
            UWFax: "",
            UnderwriterFNHide: false,
            UnderwriterLNHide: false,
            UnderwriterEmailAddressHide: false,
            UnderwriterWorkPhoneHide: false,
            UnderwriterCellPhoneHide: false,
            UnderwriterExtHide: false,
            UnderwriterFaxHide: false,
            AllowReopenOrAdditional: false,
            orderOfficeId: "",
            Agents: [],
            AgentOptions: [],
            PopAgentId: "",
            PopAgentName: "",
            dopopAgentname: false,
            IsAgentId: "",
            removeAgentAlert: false,
            InitialAgentList: [],
            InitialAgentValues: [],
            SelectedAgent: "",
            SelectedAdjuster: "",
            AgentList: [],
            isModalopen: false,
            SupportedSummaryFileSize: "",
            AuthFileSizeInGB: "",
            SummaryFileSizeInGB: "",
            PAddr2: ""

        };
        this.handleGoBack = this.handleGoBack.bind(this);
        this.GetRequiredFields_CaseDetails = this.GetRequiredFields_CaseDetails.bind(this);
        this.handleexpandAll = this.handleexpandAll.bind(this);
        this.handlecollapseAll = this.handlecollapseAll.bind(this);
        this.redirect = this.redirect.bind(this);
        this.viewDoc = this.viewDoc.bind(this);
        this.viewAuthDoc = this.viewAuthDoc.bind(this);
        this.UploadDoc = this.UploadDoc.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.handleUploadAuthFile = this.handleUploadAuthFile.bind(this);
        this.handleAddOrderDiscussion = this.handleAddOrderDiscussion.bind(this);
        this.openUploadModal = this.openUploadModal.bind(this);
        this.DocChangeHandler = this.DocChangeHandler.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.onClickclosePopup = this.onClickclosePopup.bind(this);
        this.handleOrderDiscussionTopicChange = this.handleOrderDiscussionTopicChange.bind(this);
        this.handleOrderDiscussionCommentChange = this.handleOrderDiscussionCommentChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleOrderDiscussionTypeChange = this.handleOrderDiscussionTypeChange.bind(this);
        this.handleOrderDiscussionStatusChange = this.handleOrderDiscussionStatusChange.bind(this);
        this.handleReOpenClose = this.handleReOpenClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReopenComentsChange = this.handleReopenComentsChange.bind(this);
        this.handleCancelMessageClose = this.handleCancelMessageClose.bind(this);
        this.handleCancelMessageSubmit = this.handleCancelMessageSubmit.bind(this);
        this.handleCancelComentsChange = this.handleCancelComentsChange.bind(this);
        this.handleMessageComentsChange = this.handleMessageComentsChange.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.GetOrderDetails = this.GetOrderDetails.bind(this);
        this.onReassignClick = this.onReassignClick.bind(this);
        this.onClickAgentClosePopup = this.onClickAgentClosePopup.bind(this);
        this.handlePopAgentNameChange = this.handlePopAgentNameChange.bind(this);
        this.getAgentOptions = _.debounce(this.getAgentOptions.bind(this), 500);
        this.handleReassign = this.handleReassign.bind(this);
        this.getSSNValue = this.getSSNValue.bind(this);
    }
    redirect(e) {
        this.setState({ isFirst: false });
        this.props.history.push('/place-order?id=' + this.state.DocList[0].doctor.mapId);
    }
    viewDoc(e, ext, facilityid, isAuthDoc, docTitle, authDocId) {
        let currentComponent = this;
        if (currentComponent.state.isDownload || (!currentComponent.state.isDownload && docTitle != 13 && docTitle != 17)) {
            if (ext.toLowerCase() != "tif" && ext.toLowerCase() != "tiff") {
                currentComponent.setState({ url: "" });
                Api.Post('api/placeorder/GetDocURL', { "CompanyCode": e }, currentComponent).then(function (result) {
                    currentComponent.setState({ loading: true });
                    if (result != null && result.length != 0) {
                        let type = "";
                        if (ext.toLowerCase() == "pdf") {
                            type = "application/pdf";
                        }
                        else if (ext.toLowerCase() == "jpg" || ext.toLowerCase() == "jpeg") {
                            type = "image/jpg";
                        }
                        else if (ext.toLowerCase() == "png") {
                            type = "image/png";
                        }
                        currentComponent.setState({
                            isViewModalopen: true, loading: false, url: result
                        });

                        if (!isAuthDoc && facilityid > 0) {
                            Api.Post('api/placeorder/UpdateDocumentsHistoryByFacilityId', { "FacilityId": facilityid, "AuthDocId": authDocId }, currentComponent).then(function (result) {
                            }).catch(function (error) {
                                currentComponent.setState({ Loading: false });
                                if (error == "TypeError: Failed to fetch") {
                                    currentComponent.toasterredfun2("Check your internet connection.");
                                }
                                else {
                                    currentComponent.toasterredfun2(error);
                                }
                            });

                        }
                    }
                    else {
                        currentComponent.toasterredfun2("Document not uploaded.");
                        currentComponent.setState({ url: "", loading: false });
                    }
                });
            }
            else {
                currentComponent.toasterredfun("Your browser doesn't support to view tif images.");
            }
        }
        else {
            currentComponent.toasterredfun("You have no access to download. Contact Administrator.");
        }
    }

    viewAuthDoc(e, ext, facilityid, isAuthDoc, docTitle, authDocId) {
        let currentComponent = this;
        if (isAuthDoc) {
            if (ext.toLowerCase() != "tif" && ext.toLowerCase() != "tiff") {
                currentComponent.setState({ url: "" });
                Api.Post('api/placeorder/GetDocURL', { "CompanyCode": e }, currentComponent).then(function (result) {
                    currentComponent.setState({ loading: true });
                    if (result != null && result.length != 0) {
                        let type = "";
                        if (ext.toLowerCase() == "pdf") {
                            type = "application/pdf";
                        }
                        else if (ext.toLowerCase() == "jpg" || ext.toLowerCase() == "jpeg") {
                            type = "image/jpg";
                        }
                        else if (ext.toLowerCase() == "png") {
                            type = "image/png";
                        }
                        currentComponent.setState({
                            isViewModalopen: true, loading: false, url: result
                        });

                        if (!isAuthDoc && facilityid > 0) {
                            Api.Post('api/placeorder/UpdateDocumentsHistoryByFacilityId', { "FacilityId": facilityid, "AuthDocId": authDocId }, currentComponent).then(function (result) {
                            }).catch(function (error) {
                                currentComponent.setState({ Loading: false });
                                if (error == "TypeError: Failed to fetch") {
                                    currentComponent.toasterredfun2("Check your internet connection.");
                                }
                                else {
                                    currentComponent.toasterredfun2(error);
                                }
                            });

                        }
                    }
                    else {
                        currentComponent.toasterredfun2("Document not uploaded.");
                        currentComponent.setState({ url: "", loading: false });
                    }
                });
            }
            else {
                currentComponent.toasterredfun("Your browser doesn't support to view tif images.");
            }
        }
        else {
            currentComponent.toasterredfun("You have no access to view. Contact Administrator.");
        }        
    }

    onClickclosePopup = (e) => {
        this.setState({
            isViewModalopen: false, url: "",
            isDiscussionModalOpen: false
        });
    }
    openUploadModal = (docId, orderFacilityId, index)=> {
        this.setState({ isFirst: false });
        this.setState({ UploadDocId: docId, OrderFacilityId: orderFacilityId, AuthDocIndex: index});
        this.setState({
            isUploadModalOpen: true
        });
    }
    handleCloseModal = () => {
        this.setState({ isFirst: false });
        if (this.state.Files.length != 0) {
            let docURL = this.state.Files[0].DownloadString;
            let currentComponent = this;
            Api.Post("api/placeorder/DeleteDoc", { "FileName": docURL }, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({ Files: [] });
                }
                currentComponent.setState({
                    loading: false
                })
            });
        }
        document.getElementById("customFile1").value = "";
        this.setState({
            isUploadModalOpen: false,
        })
    }
    handlecollapseAll() {
        this.setState({ isFirst: false });
        $('.panel-collapse').removeClass('in').removeClass('show');
    }
    handleexpandAll() {
        this.setState({ isFirst: false });
        $('.panel-collapse').addClass('in').addClass('show');
    }

    handleCheck(val) {
        return this.state.SupportedFileTypes.some(item => val.toLowerCase() === item);
    }

    UploadDoc(event) {
        this.setState({ isFirst: false });
        let currentComp = this;
        currentComp.state.CurrentDocItem = null;
        let extName = event.target.files[0].name.split('.').pop();
        let doubleext = event.target.files[0].name.split('.').slice(1, -1);
        let isdoubleext = false;

        doubleext.map((ext) => {

            if (this.state.AllFileTypes.some(item => ext.toLowerCase() === item)) {
                isdoubleext = true;
            }
        });

        if (isdoubleext) {
            currentComp.setState({ isUploadModalOpen : false });
            currentComp.toasterredfun2("Multiple extension files are not supported");

            return;
        }
        if (!this.handleCheck(extName)) {
            currentComp.setState({ isUploadModalOpen: false });
            currentComp.toasterredfun2("eNoah supports PDF, TIFF, TIF, JPG and JPEG formats for authorizations and medical records");

            return;
        }
        if (event.target.files[0].size > this.state.SupportedFileSize) {
            currentComp.setState({ isUploadModalOpen: false });
            currentComp.toasterredfun2("Maximum file size to upload is " + this.state.FileSizeInGB + "GB");

        }
        else {
            this.setState({ loading: true });
            localStorage.setItem('IsFileuploadInprogress', 'true');
            let f = new FormData();
            f.append("CompanyCode", localStorage.getItem("Companycode"));
            f.append("File", event.target.files[0]);
            f.append("IsFromPlaceOrderPage", false);

            currentComp.state.CurrentDocItem = null;
            let docName = event.target.files[0].name;
            let split = docName.split('.');
            let extension = "pdf";
            let docNameWithExtension = split[0].replace(/[&/#,+()$~%'":;*?<>{} !@^`=]+/g, "") + "." + extension;
            currentComp.setState({ loading: true });
            localStorage.setItem('IsFileuploadInprogress', 'true');
            let euid = localStorage.getItem("Euid");
            axios.post("api/placeorder/UploadDoc", f, {
                headers: { 'Content-Type': 'multipart/form-data', "Companycode": localStorage.getItem("Companycode"), "Euid": euid }
            }).then((result) => {
                currentComp.setState({ loading: true });
                if (result.data.status == 401) {
                    currentComp.props.history.push('/');
                }
                else if (result.data.statusMessage == "Success") {
                    currentComp.setState({ Files: [...currentComp.state.Files, { DownloadString: result.data.filePath, FileName: docNameWithExtension, MimeType: extension, id: 2 }] });
                }
                else {
                    currentComp.toasterredfun2(result.data.statusMessage);
                }
                currentComp.setState({ loading: false });
                localStorage.setItem('IsFileuploadInprogress', 'false');
                currentComp.setState({ DocName: '' });
                currentComp.setState({ CurrentDocName: '' });
            }).catch((ex) => {
                currentComp.setState({ loading: false });
                localStorage.setItem('IsFileuploadInprogress', 'false');
                if (ex && ex.response && ex.response.status === 403) {
                    currentComp.toasterredfun2("File upload was terminated due to a potentially harmful file");
                } else {
                    currentComp.toasterredfun2("Failed uploading, please check your internet connectivity and try again");
                }
                document.getElementById("customFile1").value = "";
                currentComp.setState({ DocName: '' });
                currentComp.setState({ CurrentDocName: '' });
            });
        }
    }
    DocChangeHandler = (event) => {
        this.setState({ isFirst: false });
        let currentComp = this;
        currentComp.setState({ loading: true });
        if (this.state.Files.length != 0) {
            let docURL = this.state.Files[0].DownloadString;
            let currentComponent = this;
            Api.Post("api/placeorder/DeleteDoc", { "FileName": docURL }, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({ Files: [] });
                    currentComp.UploadDoc(event);
                }
            });
        }
        else {
            currentComp.UploadDoc(event);
        }

    }
    handleUploadAuthFile(event) {
        this.setState({ isFirst: false });
        if (this.state.Files.length != 0) {
            let currentComponent = this;
            this.setState({ loading: true });
            let companyCode = localStorage.getItem('Companycode');
            Api.Post('api/placeorder/AddDocumentCaseDetails', { "OrderFacilityId": this.state.OrderFacilityId, "AuthDocs": this.state.Files, "CompanyCode": companyCode }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    currentComponent.setState({ isUploadModalOpen: false });
                    currentComponent.setState({
                        DocList: result.facility, Files: []
                    });
                    document.getElementById("customFile1").value = "";
                }
                else {
                    currentComponent.toasterredfun2("Document not uploaded.");
                    this.setState({ CurrentDocName: '', Files: [] });
                }
                currentComponent.setState({ loading: false });
            });
        }
    }
    handleAddOrderDiscussion(event) {
        this.setState({ isFirst: false });
        let currentComponent = this;
        this.setState({ loading: true });
        let companyCode = localStorage.getItem('Companycode');
        Api.Post('api/placeorder/AddOrderDiscussion',
            {
                "OrderFacilityId": this.state.OrderDiscussionFacilityId, "Topic": this.state.OrderDiscussionTopic, "Comment": this.state.OrderDiscussionComment,
                "CompanyCode": companyCode, "DiscussionType": this.state.OrderDiscussionType, "DiscussionStatus": this.state.OrderDiscussionStatus
            },
            currentComponent).then(function (result) {
                currentComponent.setState({ loading: true });
                if (result.statusCode === 100) {
                    currentComponent.setState({ loading: false });
                    currentComponent.setState({ isDiscussionModalOpen: false });
                }
                else if (result.statusCode === 400) {
                    currentComponent.toasterredfun2(result.StatusMessage);
                    currentComponent.setState({ loading: false });
                }
                else {
                    currentComponent.toasterredfun2("Something went wrong!");
                    currentComponent.setState({ loading: false });
                }
            });
    }
    handleDateChange(e) {
        this.setState({ isFirst: false });
        this.setState({ pdob: false });
        if (e instanceof Date) {
            this.setState({ dateAuthor: e });
        }
        else {
            this.setState({ dateAuthor: e });
        }
    }
    openDiscussionModal = (orderFacilityId) => {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionFacilityId: orderFacilityId });
        this.setState({
            isDiscussionModalOpen: true
        })
    }
    handleOrderDiscussionTopicChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionTopic: e.target.value });
    }
    handleOrderDiscussionCommentChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionComment: e.target.value });
    }
    handleOrderDiscussionTypeChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionType: e.target.value });
    }
    handleOrderDiscussionStatusChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionStatus: e.target.value });
    }
    handleDOBChange(e) {
        this.setState({ isFirst: false });
        this.setState({ pdob: false });
        if (e instanceof Date) {
            let tdate = e;
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            today = mm + '/' + dd + '/' + yyyy;
            if (tdate >= this.state.maxDate && tdate <= this.state.minDate) {
                this.setState({ pdob: true });
                document.getElementById("dobDate").value = "";
                this.setState({ DOB: "" });
            }
            else {
                this.setState({ DOB: e });
            }

        }
        else {
            this.setState({ pdob: true });
            this.setState({ DOB: e });
        }
    }
    handleDOBBlur(e) {
        this.setState({ isFirst: false });
        if (this.state.DOB == "Invalid date") {
            this.setState({ pdob: true });
            document.getElementById("dobDate").value = "";
            this.setState({ DOB: "" });
        }
    }
    onKeyPressValue(event) {
        this.setState({ isFirst: false });
        let length = event.target.value.length;
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d/]/g, '');
        if (length > 11) {
            event.stopPropagation();
        }
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                if (this.state.PrevPage == "search") {
                    localStorage.setItem('IsFromCaseDetails', 'true');
                    this.props.history.push('/search');
                }
                else if (this.state.PrevPage == "dashboard") {
                    localStorage.setItem('IsFromCaseDetailsToDashboard', 'true');
                    this.props.history.push('/dashboard');
                }
                else if (this.state.PrevPage == "result") {
                    localStorage.setItem('IsFromCaseDetailsToResults', 'true');
                    this.props.history.push('/result');
                }
                else {
                    this.props.history.push('/search');
                }
            }.bind(this), 5000);
    }
    toasterredfun(e) {
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this),
            5000
        );
    }
    handleEvent = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ showSSN: true });
                $('#eye-ssn').toggleClass('fa fa-eye');
            } else {
                this.setState({ showSSN: false });
                $('#eye-ssn').toggleClass('fa fa-eye-slash');
            }
        }
    }
    handleSSNMouseLeave = (event) => {
        this.setState({ showSSN: false });
        $('#eye-ssn').toggleClass('fa fa-eye-slash');
    }
    handleDOBMouseLeave = (event) => {
        this.setState({ showDOB: false });
        $('#eye-dob').toggleClass('fa fa-eye-slash');
    }
    handleDOBEvent = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ showDOB: true });
                $('#eye-dob').toggleClass('fa fa-eye');
            } else {
                this.setState({ showDOB: false });
                $('#eye-dob').toggleClass('fa fa-eye-slash');
            }
        }
    }

    GetRequiredFields_CaseDetails() {
        let CompanyId = 1;
        let currentComponent = this;
        Api.Post('api/placeorder/GetRequiredFields_CaseDetails', { "CompanyId": CompanyId }, currentComponent).then(function (result) {

            currentComponent.setState({ RequiredFieldsList: result.requiredFields });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (const field of currentComponent.state.RequiredFieldsList) {
                    currentComponent.setState({
                        [field.className + 'Hide']: field.isActive,
                    });
                    if (field.customFieldName === "") {
                        currentComponent.setState({
                            ['lbl' + field.className]: field.originalFieldName,
                        });
                    } else {
                        currentComponent.setState({
                            ['lbl' + field.className]: field.customFieldName,
                        });
                    }
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    async componentWillMount() {
        let companyType = localStorage.getItem("CompanyType");
        let DownloadResults = localStorage.getItem("ViewResults");
        this.setState({ DownloadImages: DownloadResults });
        if (companyType == "LEGAL") {
            this.setState({ HideLegalDiv: true })
        }
        else {
            this.setState({ HideLifeDiv: true })
        }
        let currentComponent = this;
        fetch('labelConfigs/CaseDetails_Labels.json').then(function (response) {
            return response.json();
        }).then(function (response) {
            currentComponent.setState({
                clientDetailsPolicyNoLabel: response[companyType].Client_Details.Policy_No,
                agentInfoHeaderLabel: response[companyType].Agent_Info.Header,
                agentInfoAgentIdLabel: response[companyType].Agent_Info.Agent_Id,
                individualInfoHeaderLabel: response[companyType].Individual_Info.Header,
                locationDetailsFacilityNameLabel: response[companyType].Location_Details.Facility_Name,
            });
        });

        this.GetRequiredFields_CaseDetails();

        await Api.Get('api/placeorder/GetAuthDocumentRecordTitle', currentComponent).then(function (result) {

            if (result.statusMessage == "Success") {
                currentComponent.setState({ Description: result.authDocumentRecordTitles });
            }
        });

        await Api.Get('api/placeorder/GetFileTypes', currentComponent).then(function (result) {
            if (result.statusMessage == "Success") {
                currentComponent.setState({ SupportedFileTypes: result.supportedFileFormats, AllFileTypes: result.allFileFormats, SupportedFileSize: result.fileSize, SupportedSummaryFileSize: result.summaryFileSize, SummaryFileSizeInGB: result.summaryFileSizeInGB, AuthFileSizeInGB: result.fileSizeInGB });
            }
        });

        this.GetOrderDetails();
      
    }
    componentDidMount() {
        let prevPage = localStorage.getItem('PrevPage');
        let backText = "";

        if (prevPage === "search") {
            backText = "Back to Search List";
        } else if (prevPage === "dashboard") {
            backText = "Back to Dashboard";
        } else if (prevPage === "result") {
            backText = "Back to Results List";
        } else if (prevPage === "summaryedit") {
            backText = "Back to Summary Review";
        }

        this.setState({
            PrevPage: prevPage || "",  // Set default if prevPage is null
            BackText: backText || "Back"  // Default button text
        });
    }
    GetOrderDetails() {
        let companyType = localStorage.getItem("CompanyType");
        let currentComponent = this;
        let windowlocation = window.location.href;
        let split = windowlocation.split('?id=');
        if (split.length < 2) {
            this.toasterredfun2("Wrong URL. Check the search page and try again.");
        }
        let getid = split[1];
        this.setState({ OrderId: getid });
        const fetchConfigData = () => {
            Api.Get('api/placeorder/GetConfigData', currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    if (currentComponent.state.ProductCode == 1) {
                        currentComponent.setState({ contactName: result.contactName, contactEmail: result.contactEmail, contactPhone: result.contactPhone });
                    }

                    else {
                        currentComponent.setState({ contactName: result.summaryCSR[0].contactName, contactEmail: result.summaryCSR[0].contactEmail, contactPhone: result.summaryCSR[0].contactPhone });
                    }
                }
            });
        };
        Api.Post('api/placeorder/GetOrderDetails', { "Id": getid }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({
                    PFN: Api.decryptData(result.patient.patientFN), PLN: Api.decryptData(result.patient.patientLN), PEA: Api.decryptData(result.patient.email), PON: Api.decryptData(result.patient.otherName), PDOB: Api.decryptData(result.patient.dob).replace("-", "/").replace("-", "/"),
                    PGender: Api.decryptData(result.patient.genderStr), PSSN: Api.decryptData(result.patient.ssn), PPCM: Api.decryptData(result.patient.preferredContact), PPhn1: Api.decryptData(result.patient.phone1), PExt1: Api.decryptData(result.patient.ext1),
                    PPhn3: Api.decryptData(result.patient.phone3), PExt3: Api.decryptData(result.patient.ext3), PPhn2: Api.decryptData(result.patient.phone2), PExt2: Api.decryptData(result.patient.ext2), PFax: Api.decryptData(result.patient.fax),
                    PAddr: Api.decryptData(result.patient.address), PCity: Api.decryptData(result.patient.city), PState: Api.decryptData(result.patient.state), PZip: Api.decryptData(result.patient.zip), PolicyNum: Api.decryptData(result.patient.policy),
                    PolicyAmount: Api.decryptData(result.patient.policyAmount), POtherField1: Api.decryptData(result.patient.otherField1), POtherField2: Api.decryptData(result.patient.otherField2), POtherField3: Api.decryptData(result.patient.otherField3),
                    POtherField4: Api.decryptData(result.patient.otherField4), PDateOfIncident: Api.decryptData(result.patient.dateOfIncident).replace("-", "/").replace("-", "/"), Orginator: result.createdBy, CarrierName: result.carrierName, AccName: result.accountName,
                    DocList: result.facility, loading: false, Selected: result.selected, isDownload: result.isDownload, isViewNote: result.isViewNote, isViewAuth: result.isViewAuth, AllowCSRMessaging: result.allowCSRMessaging,
                    orderFacilityId: result.orderFacilityId, ProductType: result.productType ?? "", ProductCode: result.productCode, AllowReopenOrAdditional: result.allowReopenOrAdditional, orderOfficeId: result.orderOfficeId, PAddr2: Api.decryptData(result.patient.address2)
                }, fetchConfigData);
                if (result.productCode == 1) {
                    currentComponent.setState({ isAPSproduct: true });
                }
                if (isNullOrUndefined(result.productType) || result.productType == '') {
                    currentComponent.setState({ ProductTypeShow: false });
                }
                if (result.facility) {
                    let redactedOrder = result.facility.find((a) => a.doctor.isRedacted);
                    if (redactedOrder && redactedOrder.doctor) {
                        currentComponent.setState({ IsRedacted: redactedOrder.doctor.isRedacted });
                    }
                }

                if (result.agent) {
                    currentComponent.setState({
                        AFN: result.agent.agentFN, ALN: result.agent.agentLN, AEA: result.agent.agentEmail, AWP: result.agent.agentPhone, AFax: result.agent.agentFax, AExt: result.agent.agentExt, ACP: result.agent.agentCell, AAI: result.agent.agentId
                    });
                }
                if (result.underwriter) {
                    currentComponent.setState({
                        UWId: result.underwriter.id,
                        UWFN: result.underwriter.firstName,
                        UWLN: result.underwriter.lastName,
                        UWEmailAddress: result.underwriter.emailAddress,
                        UWWorkPhone: result.underwriter.workPhone,
                        UWExt: result.underwriter.ext,
                        UWCellPhone: result.underwriter.cellPhone,
                        UWFax: result.underwriter.fax,
                    });
                }
                if (result.carrierManager) {
                    currentComponent.setState({
                        CMId: result.carrierManager.id,
                        CMFN: result.carrierManager.firstName,
                        CMLN: result.carrierManager.lastName,
                        CMEmailAddress: result.carrierManager.emailAddress,
                        CMWorkPhone: result.carrierManager.workPhone,
                        CMExt: result.carrierManager.ext,
                        CMCellPhone: result.carrierManager.cellPhone,
                        CMFax: result.carrierManager.fax,
                    });
                }

                if (companyType == "LEGAL" && result.adjuster) {
                    currentComponent.setState({
                        AdFN: result.adjuster.agentFN, AdLN: result.adjuster.agentLN, AdEA: result.adjuster.agentEmail, AdWP: result.adjuster.agentPhone, AdFax: result.adjuster.agentFax, AdExt: result.adjuster.agentExt, AdCP: result.adjuster.agentCell, AdAI: result.adjuster.agentId
                    });
                }
            } else {
                currentComponent.setState({ loading: false });
                currentComponent.toasterredfun2("You don't have permission to access this operation, please try again later.");
           }            
        }).catch(function (error) {
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    handleGoBack(e) {
        this.setState({ isFirst: false });
        if (this.state.PrevPage == "search") {
            this.props.history.push('/search');
            localStorage.setItem('IsFromCaseDetailsToSearch', 'true');
        }
        else if (this.state.PrevPage == "dashboard") {
            localStorage.setItem('IsFromCaseDetailsToDashboard', 'true');
            this.props.history.push('/dashboard');
        }
        else if (this.state.PrevPage == "result") {
            localStorage.setItem('IsFromCaseDetailsToResults', 'true');
            this.props.history.push('/result');
        }
        else if (this.state.PrevPage == "summaryedit") {
            localStorage.setItem('IsFromCaseDetailsToSummaryEdit', 'true');
            this.props.history.push('/summaryedit');
        }
        else {
            this.props.history.push('/search');
        }
    }
    getBase64(file, cb) {
        let currentComp = this;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            currentComp.setState({ Base64: reader.result });
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }
   
    handleReOpenClose(e) {

        let currentComponent = this;
        if (e.target.value == "Close") {
            currentComponent.setState({ repopupShow: false, ReopenOrderId: "" })
        }
        else {
            let tValue = e.target.value;
            let validation = tValue.split("@PoP@");
            if (validation.length > 1) {
                if (validation[1].toLowerCase() == "true") {
                    currentComponent.setState({ repopupShow: true, ReopenOrderId: validation[0] })
                    $("html, body").animate({ scrollTop: 0 }, "slow");
                }
                else {
                    alert("You are not authorized to reopen this order");
                }
            }
            else {
                alert("You are not authorized to reopen this order");
            }
        }
        currentComponent.setState({ ReopenComents: "", IsReopenText: false });
        localStorage.setItem("ReopenComments", "");
    }

    handleSubmit(e) {
        let currentComponent = this;
        if (currentComponent.state.ReopenComents.trim() != "" && currentComponent.state.ReopenOrderId != "") {
            localStorage.setItem("ReopenComments", currentComponent.state.ReopenComents.trim());
            currentComponent.setState({ IsReopenText: false });
            currentComponent.props.history.push("/place-order?orderreopenid=" + currentComponent.state.ReopenOrderId);
        }
        else {
            currentComponent.setState({ IsReopenText: true });
        }
    }

    handleReopenComentsChange(e) {
        let currentComponent = this;
        currentComponent.state.IsReopenText = false;
        currentComponent.setState({ ReopenComents: e.target.value });
    }
    handleCancelMessageClose(e, enoahOrderId, orderFacilityNo) {
        let currentComponent = this;
        if (e.target.value == "MessageRequest") {
            currentComponent.setState({ messagepopupShow: true, CSRRequestType: e.target.value, eNoahOrderId: enoahOrderId, OrderFacilityNo: orderFacilityNo })
            $("html, body").animate({ scrollTop: 0 }, "slow");
        }
        else if (e.target.value == "CancelRequest") {
            currentComponent.setState({ cancelpopupShow: true, CSRRequestType: e.target.value, eNoahOrderId: enoahOrderId, OrderFacilityNo: orderFacilityNo })
            $("html, body").animate({ scrollTop: 0 }, "slow");
        }
        else {
            currentComponent.setState({ cancelpopupShow: false, messagepopupShow: false, OrderId: "" })
        }
        currentComponent.setState({ CancelComents: "", MessageComents: "", IsCancelText: false, IsMessageText: false });
    }

    handleCancelMessageSubmit(e) {
        let currentComponent = this;
        let isvalid = true;
        if ((currentComponent.state.CancelComents.trim() != "" || currentComponent.state.MessageComents.trim() != "")) {
            currentComponent.setState({ IsCancelText: false, IsMessageText: false });
        }
        else {
            currentComponent.setState({ IsCancelText: true, IsMessageText: true });
            isvalid = false;
        }
        if (isvalid) {
            this.setState({ loading: true, cancelpopupShow: false, messagepopupShow: false, IsCancelText: false, IsMessageText: false });
            let data = {
                "RequestType": currentComponent.state.CSRRequestType,
                "Note": (currentComponent.state.CSRRequestType == "CancelRequest" ? currentComponent.state.CancelComents : currentComponent.state.MessageComents),
                "OrderFacilityId": currentComponent.state.OrderFacilityNo
            };
            Api.Post('api/placeorder/AddCSRRequestNotifications', data, currentComponent).then(function (result) {
                currentComponent.setState({ loading: false });
                if (result.statusMessage == "Success") {
                    currentComponent.state.CSRRequestType = "";
                    currentComponent.state.CancelComents = "";
                    currentComponent.state.MessageComents = "";
                    currentComponent.state.OrderFacilityNo = "";
                    currentComponent.GetOrderDetails();
                    currentComponent.toasterfun2(" Your request has been submitted");
                }
                else if (result.status === 401 || result == null) {
                    currentComponent.props.history.push('/');
                }
                else {
                    currentComponent.toasterredfun(result.statusMessage);
                }
            })
                .catch(function (error) {
                    currentComponent.setState({ loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun(error);
                    }
                });
        }
    }
    handleCancelComentsChange(e) {
        let currentComponent = this;
        currentComponent.state.IsCancelText = false;
        currentComponent.setState({ CancelComents: e.target.value });
    }
    handleMessageComentsChange(e) {
        let currentComponent = this;
        currentComponent.state.IsMessageText = false;
        currentComponent.setState({ MessageComents: e.target.value });
    }
    onReassignClick(OrderFacilityId, orderOfficeId, assignedTo) {
        let currentComp = this;
        currentComp.setState({ isModalopen: true, OrderFacilityId: OrderFacilityId, orderOfficeId: orderOfficeId, assignedTo: assignedTo, loading: true });
        this.GetInitial(orderOfficeId, assignedTo);
        this.renderAgentList();
    }
    async GetInitial(OrderOfficeId, AssignedTo) {
        let currentComponent = this;
        currentComponent.setState({ loading: true, ClickedAssignedTo: AssignedTo });
        await Api.Post(
            "api/placeorder/GetInitialAgentsLists",
            { "OrderingOfficeAccess": OrderOfficeId },
            currentComponent
        )
            .then(function (result) {
                if (result.isAccessible) {
                    currentComponent.setState({ Agents: result.agentList, loading: false });
                    currentComponent.setState({ InitialAgentList: result.agentList });
                    let userId = Api.decryptData(result.userId);
                    currentComponent.setState({ UserId: userId });

                    currentComponent.state.Agents.forEach((doc) => {
                        if (doc.combinedName !== AssignedTo) {
                            currentComponent.setState({
                                AgentOptions: [
                                    ...currentComponent.state.AgentOptions,
                                    { label: doc.combinedName, value: "" + doc.id, assignedTo: doc.userId },
                                ],
                            });
                        }
                    });
                    currentComponent.setState({ loading: false });
                }
            })
            .catch(function (error) {
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2(
                        "Check your internet connection."
                    );
                } else {
                    currentComponent.toasterredfun2(error);
                }
                if (!window.location.href.includes("=")) {
                    currentComponent.setState({ loading: false });
                }
            });
    }
    renderAgentList() {
        if (this.state.AgentList && this.state.AgentList.length > 0) {
            return (
                <div className="dropdown-menu">
                    {this.state.AgentList.map(listitem => (
                        <a className="dropdown-item" onClick={() => this.onSelectAgent(listitem)} key={listitem.id}>
                            {listitem.agentFN} {listitem.agentLN}
                        </a>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="dropdown-menu">
                    <a className="dropdown-item">No Results</a>
                </div>
            );
        }
    }
    onSelectAgent = (agent) => {
        this.setState({ IsAgentId: agent.id });
        this.setState({
            AgentFN: agent.agentFN,
            AgentLN: agent.agentLN,
            AgentId: agent.agentId,
            AgId: agent.id,
            assignedTo: agent.userId,
        });
    }
    renderAgentSearch() {
        return (
            <AsyncSelect
                cacheOptions
                value={this.state.SelectedAgent}
                defaultOptions={this.state.AgentOptions}
                loadOptions={this.getAgentOptions}
                placeholder="Select User (Min 3 Chars)"
                onChange={this.handlePopAgentNameChange}
                noOptionsMessage={() => "No Users found"}
            />
        );
    }
    handlePopAgentNameChange(e) {
        if (e != null) {
            this.setState(
                {
                    SelectedAgent: e,
                    PopAgentName: e.label,
                    PopAgentId: e.value,
                    assignedTo: e.assignedTo,
                },
                () => {
                }
            );
        }
    }

    getAgentOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        if (inputValue.length > 2) {
            let currentComponent = this;
            Api.Post(
                "api/login/AgentSearchList",
                { apstool: inputValue, OrderingOfficeId: this.state.orderOfficeId },
                currentComponent
            ).then(function (result) {
                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                }
                else {
                    currentComponent.setState({
                        Agents: result.agentList,
                        AgentOptions: [],
                        AgentList: result.agentList,
                    });
                    let filteredAgents = currentComponent.state.Agents.filter(doc => doc.combinedName !== currentComponent.state.ClickedAssignedTo);
                    let agentOptions = filteredAgents.map(doc => ({
                        label: doc.combinedName,
                        value: "" + doc.id,
                        assignedTo: doc.userId,
                    }));
                    currentComponent.setState({
                        AgentOptions: agentOptions
                    });
                    callback(agentOptions);
                }
            });
        }
    };
    onClickAgentClosePopup() {
        this.setState({
            isModalopen: false, SelectedAgent: "", SelectedAdjuster: "", Agents: [], gentOptions: [], PopAgentId: "",
            PopAgentName: "",
            assignedTo: "",
            ClickedAssignedTo: ""
        });

    }
    handleReassign(e) {
        let currentComponent = this;
        let SelectedAgent = this.state.SelectedAgent;
        currentComponent.setState({ OrderFacilityId: this.state.OrderFacilityId, assignedTo: this.state.assignedTo, loading: true });
        if (SelectedAgent != "") {
            Api.Post('api/placeorder/UpdateAssignemntHistory', { "FacilityId": this.state.OrderFacilityId, "AssignedTo": this.state.assignedTo }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    currentComponent.setState({
                        loading: false, isModalopen: false, OrderFacilityId: "", assignedTo: "", PopAgentId: "", PopAgentName: "", Agents: [], AgentOptions: [], AgentList: [], SelectedAgent: "", ClickedAssignedTo: ""
                    });
                    currentComponent.GetOrderDetails();
                }
                else {
                    currentComponent.setState({ loading: false });
                }
            });
        }
        else {
            currentComponent.setState({ loading: false, dopopAgentname: true });
            currentComponent.toasterredfun2("Please select the User to Reassign");
        }
    }
    onClickReview(Id) {
        let currentComponent = this;
        currentComponent.setState({ OrderFacilityId: Id, loading: true });
        Api.Post('api/placeorder/UpdateReviewHistory', { "OrderFacilityId": Id }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({
                    loading: false, OrderFacilityId: ""
                });
                window.open("https://eapsuat.enoahisolution.com/", "_blank");
            }
            else {
                currentComponent.setState({ loading: false });
            }
        });

    }
    getSSNValue() {
        if (this.state.PSSN !== "") {
            return this.state.showSSN
                ? 'xxx-xxx-' + this.state.PSSN.slice(-4)
                : 'xxx-xxx-xxx';
        }
        return "";
    }
    render() {
        let landing = 'img/ems-logo.gif';
        let companyType = localStorage.getItem("CompanyType");
        return (
            <Layout>
                <TimeoutCounter />
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className='hgtDiv'>
                    <div className="bg-gray-no">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="bg-white rounded my-2 p-1">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h1 className="mb-1 pl-2 case-title">Case Details - {(this.state.isAPSproduct ? "APS Retrieval Order" : "Summary Order")}</h1>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 hvr-bounce-to-left" onClick={this.handleGoBack}>{this.state.BackText}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <h3 className="suborder-cla-x mb-2"> Order Details </h3>
                        <div className="bgwhitee mt-0">

                            <div className="row no-gutterss">
                                <div className={" " + (companyType == "LEGAL" ? "col-md-4 px-1 pl-2" : "col-md-4 px-1 pl-2")}>

                                    <div className="bg-white rounded custom-height">
                                        <div className="card mb-1 placeholder-div max-div-xs ">
                                            <div className="card-header mt-0">
                                                Client Details
                                            </div>
                                            <div className="card-body">
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className="col-md-4 pull-left nopadding">
                                                                    <label className="small mb-1" htmlFor="inputCarrierFirstName">Client Name</label>
                                                                </div>
                                                                <div className="col-md-8 pull-left nopadding">
                                                                    <input className="form-control" id="inputCarrierFirstName" type="text" autoComplete="off" value={this.state.CarrierName} onChange={() => { }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className="col-md-4 pull-left nopadding">
                                                                    <label className="small mb-1" htmlFor="inputOfficeName">Office Name</label>
                                                                </div>
                                                                <div className="col-md-8 pull-left nopadding">
                                                                    <input className="form-control" id="inputOfficeName" type="text" autoComplete="off" value={this.state.AccName} onChange={() => { }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className="col-md-4 pull-left nopadding">
                                                                    <label className="small mb-1" htmlFor="inputOriginator">Originator</label>
                                                                </div>
                                                                <div className="col-md-8 pull-left nopadding">
                                                                    <input className="form-control" id="inputOriginator" type="text" autoComplete="off" value={this.state.Orginator} onChange={() => { }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className={"col-md-4 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                                    <label className="small mb-1" htmlFor="inputPolicyNum">{this.state.lblPolicyNum}</label>
                                                                </div>
                                                                <div className={"col-md-8 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                                    <input className="form-control" id="inputPolicyNum" type="text" autoComplete="off" value={this.state.PolicyNum} onChange={() => { }} />
                                                                </div>
                                                                <div className={this.state.HideLifeDiv ? "show" : "hide"}>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.PolicyAmountHide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPolicyAmount">{this.state.lblPolicyAmount}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.PolicyAmountHide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPolicyAmount" type="text" autoComplete="off" value={this.state.PolicyAmount} onChange={() => { }} />
                                                                    </div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.ProductTypeShow ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputProductType">Product Type</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.ProductTypeShow ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputProductType" type="text" autoComplete="off" value={this.state.ProductType} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField1">{this.state.lblOtherField1}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField1" type="text" autoComplete="off" value={this.state.POtherField1} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField2">{this.state.lblOtherField2}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField2" type="text" autoComplete="off" value={this.state.POtherField2} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField3">{this.state.lblOtherField3}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField3" type="text" autoComplete="off" value={this.state.POtherField3} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField4">{this.state.lblOtherField4}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField4" type="text" autoComplete="off" value={this.state.POtherField4} onChange={() => { }} />
                                                                    </div>

                                                                </div>
                                                                <div className={this.state.HideLegalDiv ? "show" : "hide"}>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.DateOfIncidentHide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputDOI">{this.state.lblDateOfIncident}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.DateOfIncidentHide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputDOI" type="text" autoComplete="off" value={this.state.PDateOfIncident} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField1">{this.state.lblOtherField1}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField1" type="text" autoComplete="off" value={this.state.POtherField1} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField2">{this.state.lblOtherField2}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField2" type="text" autoComplete="off" value={this.state.POtherField2} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField3">{this.state.lblOtherField3}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField3" type="text" autoComplete="off" value={this.state.POtherField3} onChange={() => { }} />
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField4">{this.state.lblOtherField4}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField4" type="text" autoComplete="off" value={this.state.POtherField4} onChange={() => { }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={companyType == "LEGAL" ? "col-md-4 px-1 pl-2" : "col-md-4 px-1 pl-2"}>
                                    <div className="card placeholder-div mb-1 max-div-xs bg-white rounded custom-height">
                                        <div className="card-header mt-0">
                                            {this.state.agentInfoHeaderLabel}
                                        </div>
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AgentFNHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputAgentFirstName">{this.state.lblAgentFN}</label>
                                                        </div>
                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.AgentFNHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAgentFirstName" type="text" autoComplete="off" value={this.state.AFN} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AgentLNHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputAgentLastName">{this.state.lblAgentLN}</label>
                                                        </div>
                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.AgentLNHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAgentLastName" type="text" autoComplete="off" value={this.state.ALN} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AgentEmailHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputAgentEmail">{this.state.lblAgentEmail}</label>
                                                        </div>
                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.AgentEmailHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAgentEmail" type="text" autoComplete="off" value={this.state.AEA} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AgentPhoneHide ? "show" : "hide")}>
                                                            <label className="small mdb-1" htmlFor="inputAgentPhone">{this.state.lblAgentPhone}</label>

                                                        </div>
                                                        <div className={"col-md-6 pull-left nopadding " + (this.state.AgentPhoneHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAgentPhone" type="text" autoComplete="off" value={this.state.AWP} onChange={() => { }} />
                                                        </div>
                                                        <div className={"col-md-1 pull-left nopadding " + (this.state.AgentExtHide ? "show" : "hide")}>
                                                            <label className="small mdb-1" htmlFor="inputAgentExt">{this.state.lblAgentExt}</label>

                                                        </div>
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.AgentExtHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAgentExt" type="text" autoComplete="off" value={this.state.AExt} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.AgentMobileHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputAgentMobile">{this.state.lblAgentMobile}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.AgentMobileHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputAgentMobile" type="text" autoComplete="off" value={this.state.ACP} onChange={() => { }} />
                                                </div>

                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.AgentFaxHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputAgentFax">{this.state.lblAgentFax}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.AgentFaxHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputAgentFax" type="text" autoComplete="off" value={this.state.AFax} onChange={() => { }} />
                                                </div>

                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.AgentIdHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputAgentId">{this.state.lblAgentId}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.AgentIdHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputAgentId" type="text" autoComplete="off" value={this.state.AAI} onChange={() => { }} />
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-4 px-1 pl-2 " + (companyType == "LEGAL" ? "show" : "hide")}>
                                    <div className="card placeholder-div mb-1 max-div-xs bg-white rounded custom-height">
                                        <div className="card-header mt-0">
                                            Adjuster Info
                                        </div>
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterFNHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputAdjFirstName">{this.state.lblAdjusterFN}</label>
                                                        </div>
                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterFNHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAdjFirstName" type="text" autoComplete="off" value={this.state.AdFN} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputAdjLastName">{this.state.lblAdjusterLN}</label>
                                                        </div>
                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAdjLastName" type="text" autoComplete="off" value={this.state.AdLN} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterEmailHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputAdjEmail">{this.state.lblAdjusterEmail}</label>
                                                        </div>
                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterEmailHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAdjEmail" type="text" autoComplete="off" value={this.state.AdEA} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row-xm">
                                                    <div className="form-group">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterPhoneHide ? "show" : "hide")}>
                                                            <label className="small mdb-1" htmlFor="inputAdjPhone">{this.state.lblAdjusterPhone}</label>

                                                        </div>
                                                        <div className={"col-md-6 pull-left nopadding " + (this.state.AdjusterPhoneHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAdjPhone" type="text" autoComplete="off" value={this.state.AdWP} onChange={() => { }} />
                                                        </div>
                                                        <div className={"col-md-1 pull-left nopadding " + (this.state.AdjusterExtHide ? "show" : "hide")}>
                                                            <label className="small mdb-1" htmlFor="inputAdjExt">{this.state.lblAdjusterExt}</label>

                                                        </div>
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterExtHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputAdjExt" type="text" autoComplete="off" value={this.state.AdExt} onChange={() => { }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterMobileHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputAdjMobile">{this.state.lblAdjusterMobile}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterMobileHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputAdjMobile" type="text" autoComplete="off" value={this.state.AdCP} onChange={() => { }} />
                                                </div>

                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterFaxHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputAdjFax">{this.state.lblAdjusterFax}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterFaxHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputAdjFax" type="text" autoComplete="off" value={this.state.AdFax} onChange={() => { }} />
                                                </div>

                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterIdHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputAdjId">{this.state.lblAdjusterId}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterIdHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputAdjId" type="text" autoComplete="off" value={this.state.AdAI} onChange={() => { }} />
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 px-1 pl-2">
                                    <div className="card mb-1 placeholder-div max-div-xs bg-white rounded custom-height">
                                        <div className="card-header mt-0">

                                            {(this.state.isAPSproduct ? "APS CUSTOMER SERVICE CONTACT" : "UNDERWRITING CUSTOMER SERVICE CONTACT")}

                                        </div>
                                        <div className="card-body">

                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputContactName">Name</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className="form-control" type="text" id="inputContactName" autoComplete="off" value={this.state.contactName} onChange={() => { }} />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputContactEmail">Email Address</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className="form-control" type="text" id="inputContactEmail" autoComplete="off" value={this.state.contactEmail} onChange={() => { }} />
                                                </div>

                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="small mdb-1" htmlFor="inputWorkPhone">Work Phone</label>

                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className="form-control" type="text" id="inputWorkPhone" autoComplete="off" value={this.state.contactPhone} onChange={() => { }} />
                                                </div>
                                            </div>


                                        </div>
                                        <div className="card-header mt-0">
                                            {(this.state.isAPSproduct ? "CLIENT MANAGER" : "UNDERWRITER")}
                                        </div>
                                        <div className="card-body">

                                            <div className="form-row-xm">
                                                <div className="form-group">

                                                    <div className="row no-gutters">
                                                        <div className={"col-md-3 " + (this.state.CMFNHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="configManagerFirstName"
                                                                style={{ position: "relative" }}
                                                            >
                                                                {(this.state.isAPSproduct ? (this.state.lblCMFN) : (this.state.lblUnderwriterFN))}

                                                            </label>
                                                        </div>
                                                        <div className={"col-md-3 " + (this.state.CMFNHide ? "show" : "hide")}>
                                                            <input
                                                                className="form-control : blackborder"
                                                                id="configManagerFirstName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.isAPSproduct ? this.state.CMFN : this.state.UWFN}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                        <div className={"col-md-3 " + (this.state.CMLNHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="configMangerLastName"
                                                            >
                                                                {(this.state.isAPSproduct ? (this.state.lblCMLN) : (this.state.lblUnderwriterLN))}
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-3 " + (this.state.CMLNHide ? "show" : "hide")}>
                                                            <input
                                                                className="form-control : blackborder"
                                                                id="configMangerLastName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.isAPSproduct ? this.state.CMLN : this.state.UWLN}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="form-row-xm mt-2">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.CMEmailAddressHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mb-1"
                                                            htmlFor="configManagerEmailAddress"
                                                        >
                                                            {(this.state.isAPSproduct ? (this.state.lblCMEmailAddress) : (this.state.lblUnderwriterEmailAddress))}
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.CMEmailAddressHide ? "show" : "hide")}>
                                                        <input
                                                            className="form-control : blackborder"
                                                            id="configManagerEmailAddress"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.isAPSproduct ? this.state.CMEmailAddress : this.state.UWEmailAddress}
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row-xm mt-2">
                                                <div className="row">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.CMWorkPhoneHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="configManagerWorkPhone"
                                                        >
                                                            {(this.state.isAPSproduct ? (this.state.lblCMWorkPhone) : (this.state.lblUnderwriterWorkPhone))}
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.CMWorkPhoneHide ? "show" : "hide")}>
                                                        <input
                                                            className="form-control : blackborder"
                                                            ref="Dphn"
                                                            id="configManagerWorkPhone"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.isAPSproduct ? this.state.CMWorkPhone : this.state.UWWorkPhone}
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.CMExtHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="configManagerExt"
                                                        >
                                                            {(this.state.isAPSproduct ? (this.state.lblCMExt) : (this.state.lblUnderwriterExt))}
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.CMExtHide ? "show" : "hide")}>
                                                        <input
                                                            className="form-control : blackborder"
                                                            ref="Dfax"
                                                            id="configManagerExt"
                                                            type="text"
                                                            placeholder="ext"
                                                            autoComplete="off"
                                                            value={this.state.isAPSproduct ? this.state.CMExt : this.state.UWExt}
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 nopadding">
                                                    <div className="row">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.CMCellPhoneHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="configManagerCellPhone"
                                                            >
                                                                {(this.state.isAPSproduct ? (this.state.lblCMCellPhone) : (this.state.lblUnderwriterCellPhone))}
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.CMCellPhoneHide ? "show" : "hide")}>
                                                            <input
                                                                className="form-control : blackborder"
                                                                ref="Dphn"
                                                                id="configManagerCellPhone"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.isAPSproduct ? this.state.CMCellPhone : this.state.UWCellPhone}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                        <div className={"col-md-1 pull-left nopadding " + (this.state.CMFaxHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="configManagerFax"
                                                            >
                                                                {(this.state.isAPSproduct ? (this.state.lblCMFax) : (this.state.lblUnderwriterFax))}
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.CMFaxHide ? "show" : "hide")}>
                                                            <input
                                                                className="form-control : blackborder"
                                                                ref="Dfax"
                                                                id="configManagerFax"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.isAPSproduct ? this.state.CMFax : this.state.UWFax}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <h3 className="suborder-cla-x mb-2"> Individual Information </h3>
                        <div className="bgwhitee mt-0">
                            <div className="row no-gutterss">
                                <div className="col-md-4 px-1 pl-2">
                                    <div className="card mb-1 max-div-xs placeholder-div bg-white rounded custom-height">
                                        <div className="card-header mt-0">
                                            {this.state.individualInfoHeaderLabel}
                                        </div>
                                        <div className="card-body">

                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputIndFirstName">{this.state.lblFirstName} </label>
                                                    </div>
                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputIndFirstName" type="text" autoComplete="off" value={this.state.PFN} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputIndLastName">{this.state.lblLastName} </label>
                                                    </div>
                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputIndLastName" type="text" autoComplete="off" value={this.state.PLN} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherNamesHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputIndOtherName">{this.state.lblOtherNames} </label>
                                                    </div>
                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherNamesHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputIndOtherName" type="text" autoComplete="off" value={this.state.PON} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.DOBHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputIndDOB">{this.state.lblDOB}</label>

                                                </div>
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.DOBHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputIndDOB" type="text" autoComplete="off" value={this.state.showDOB ? this.state.PDOB : "*********"} onChange={() => { }} />
                                                    <div className="view-dob">
                                                        <button className="eye-for-dob" onMouseLeave={this.handleDOBMouseLeave} onMouseDown={this.handleDOBEvent} onMouseUp={this.handleDOBEvent} ><i id="eye-dob" className="fa fa-eye-slash"></i></button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputIndGender">{this.state.lblGender} </label>
                                                    </div>
                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputIndGender" type="text" autoComplete="off" value={this.state.PGender} onChange={() => { }} />
                                                    </div>
                                                </div>


                                            </div>


                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-4 pull-left nopadding mt-2 " + (this.state.SSNHide ? "show" : "hide")}>
                                                    <label className="small mb-1" htmlFor="inputIndSSN">{this.state.lblSSN}</label>
                                                </div>
                                                <div className={"col-md-8 pull-left nopadding " + (this.state.SSNHide ? "show" : "hide")}>
                                                    <input
                                                        className="form-control"
                                                        id="inputIndSSN"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.getSSNValue()}
                                                        onChange={() => { }}
                                                    />
                                                    <div className="view-ssn ">
                                                        <button className="eye-for-ssn" onMouseDown={this.handleEvent} onMouseLeave={this.handleSSNMouseLeave} onMouseUp={this.handleEvent} ><i id="eye-ssn" className="fa fa-eye-slash"></i></button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-12 nopadding pull-left">
                                                <div className={"col-md-4 pull-left nopadding mt-2 " + (this.state.EmailHide ? "show" : "hide")}>
                                                    <label className="small mb-1" htmlFor="inputIndEmail">{this.state.lblEmail}</label>
                                                </div>
                                                <div className={"col-md-8 pull-left nopadding " + (this.state.EmailHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputIndEmail" type="text" autoComplete="off" value={this.state.PEA} onChange={() => { }} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="col-md-4 px-1">
                                    <div className="card mb-1 placeholder-div max-div-xs bg-white rounded custom-height">
                                        <div className="card-header mt-0">

                                            Phone/Fax
                                        </div>
                                        <div className="card-body">

                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Phone1Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputPhoneOne">{this.state.lblPhone1} </label>
                                                    </div>
                                                    <div className={"col-md-5 pull-left nopadding " + (this.state.Phone1Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputPhoneOne" type="text" autoComplete="off" value={this.state.PPhn1} onChange={() => { }} />
                                                    </div>
                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Ext1Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputExtOne">{this.state.lblExt1} </label>
                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Ext1Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputExtOne" type="text" autoComplete="off" value={this.state.PExt1} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Phone2Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputPhoneTwo">{this.state.lblPhone2} </label>
                                                    </div>
                                                    <div className={"col-md-5 pull-left nopadding " + (this.state.Phone2Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputPhoneTwo" type="text" autoComplete="off" value={this.state.PPhn2} onChange={() => { }} />
                                                    </div>
                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Ext2Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputExtTwo">{this.state.lblExt2} </label>
                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Ext2Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputExtTwo" type="text" autoComplete="off" value={this.state.PExt2} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Phone3Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputPhoneThree">{this.state.lblPhone3} </label>
                                                    </div>
                                                    <div className={"col-md-5 pull-left nopadding " + (this.state.Phone3Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputPhoneThree" type="text" autoComplete="off" value={this.state.PPhn3} onChange={() => { }} />
                                                    </div>
                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Ext3Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputExtThree">{this.state.lblExt3} </label>
                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Ext3Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputExtThree" type="text" autoComplete="off" value={this.state.PExt3} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.FaxHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputFax">{this.state.lblFax} </label>
                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.FaxHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputFax" type="text" autoComplete="off" value={this.state.PFax} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row-xm">
                                                <div className="form-group">
                                                    <div className={"col-md-5 pull-left nopadding mt-2 " + (this.state.PreferredContactHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputIndPrefContact">{this.state.lblPreferredContact}</label>
                                                    </div>
                                                    <div className={"col-md-7 pull-left nopadding " + (this.state.PreferredContactHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputIndPrefContact" type="text" autoComplete="off" value={this.state.PPCM} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>



                                </div>
                                <div className="col-md-4 px-1 pr-2">
                                    <div className="card mb-1 placeholder-div max-div-xs bg-white rounded custom-height">

                                        <div className="card-header mt-0">

                                            Address
                                        </div>
                                        <div className="card-body">
                                            <div className="form-row-xm mt-1">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.PCAddressHide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputPCAddress">{this.state.lblPCAddress}</label>
                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.PCAddressHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputPCAddress" type="text" autoComplete="off" value={this.state.PAddr} onChange={() => { }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row-xm mt-1">
                                                <div className="form-group">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.PCAddress2Hide ? "show" : "hide")}>
                                                        <label className="small mb-1" htmlFor="inputPCAddress2">{this.state.lblPCAddress2}</label>
                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.PCAddress2Hide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputPCAddress2" type="text" autoComplete="off" value={this.state.PAddr2} onChange={() => { }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-3 pull-left nopadding " + (this.state.PCCityHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputPCCity">{this.state.lblPCCity}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.PCCityHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputPCCity" type="text" autoComplete="off" value={this.state.PCity} onChange={() => { }}/>
                                                </div>

                                                <div className={"col-md-3 pull-left nopadding " + (this.state.StateIdHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputPCST">{this.state.lblStateId} </label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.StateIdHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputPCST" type="text" autoComplete="off" value={this.state.PState} onChange={() => { }}/>
                                                </div>

                                                <div className={"col-md-3 pull-left nopadding " + (this.state.PCZipCodeHide ? "show" : "hide")}>
                                                    <label className="small mdb-1" htmlFor="inputPCZip">{this.state.lblPCZipCode}</label>

                                                </div>
                                                <div className={"col-md-9 pull-left nopadding " + (this.state.PCZipCodeHide ? "show" : "hide")}>
                                                    <input className="form-control" id="inputPCZip" type="text" autoComplete="off" value={this.state.PZip} onChange={() => { }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="suborder-cla-x"> Custodian/Facility Details </h3>
                        <div className="row bgwhite rounded ml-1">
                            <div className="col-md-12 mt-1">

                                <button className="btn btn-blue-btnnm btn-type pull-left mt-1 expandAll mr-4" onClick={this.handleexpandAll}>Expand All  </button>
                                <button className="btn btn-blue-btnnm btn-type pull-left mt-1 collAll mb-1 mr-1" onClick={this.handlecollapseAll}>Collapse All</button>

                                <span><NavLink tag={Link} activeClassName="main-nav-active" className="main-nav" to={"/place-order?orderid=" + this.state.OrderId}><button data-for='tooltipOrder' data-place="left" data-effect="solid" className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 " + (!this.state.AllowReopenOrAdditional || this.state.IsRedacted ? "hide" : "show")} data-tip='Allows you to request records for the same patient but from other facilities.'>Order Additional Facility</button></NavLink></span>
                                <ReactTooltip id='tooltipOrder' />
                            </div>
                        </div>
                    </div>
                    {this.state.DocList.map((doc, index) => {
                        let name = "", facilityName = "", facname = "", docname = "", isfac = false, isdoc = false, expand = false, isCompleted = false, AllowCSRMessaging = this.state.AllowCSRMessaging;
                        if (doc.doctor.drFacilityName != null && doc.doctor.drFacilityName.length != 0) {
                            facname = doc.doctor.drFacilityName;
                            isfac = true;
                        }
                        if (doc.doctor.drFirstName != null && doc.doctor.drFirstName.length != 0) {
                            docname = "Dr " + doc.doctor.drFirstName + " " + doc.doctor.drLastName;
                            isdoc = true;
                        }
                        if (isfac && isdoc) {
                            name = facname + " - " + docname;
                        }
                        else if (!isfac && isdoc) {
                            name = docname;
                        }
                        else if (isfac && !isdoc) {
                            name = facname;
                        }
                        if (this.state.isFirst && doc.doctor.mapId == this.state.Selected) {
                            expand = true;
                        }
                        if (doc.doctor.status == "Completed" || doc.doctor.status == "Cancelled" || doc.doctor.status == "Cancel") {
                            isCompleted = true;
                        }
                        if (companyType == "LEGAL" && doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 && doc.doctor.facilityRequests[0].requestType) {
                            facilityName = name + " - " + doc.doctor.facilityRequests[0].requestType.toUpperCase();
                        } else {
                            facilityName = name;
                        }
                        let SPIn = "";
                        if (companyType == "LEGAL") {
                            SPIn = doc.doctor.facilityRequests ? doc.doctor.facilityRequests[0].specialInstruction : "";
                        } else {
                            SPIn = doc.doctor.specialInstruction;
                        }
                        let _lastReopenDateTime = "";
                        if (!isNullOrUndefined(doc.lastReopenDateTime)) {
                            _lastReopenDateTime = doc.lastReopenDateTime.replace("-", "/").replace("-", "/");
                        }

                        return (
                            <div className="row">
                                <div className="col-md-12 accordLevel">
                                    <div className="panel-group" id="accordion">
                                        <div className="panel panel-default">
                                            <div className="toggle-content rounded">

                                                <div className="panel-heading">
                                                    <h4 className="panel-title w-25">
                                                        <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index}>{facilityName}</a>
                                                    </h4>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <button className={"btn btn-blue-btnnm btn-type pull-left expandAll mr-1 " + (doc.reviewStatus ? "show" : "hide")} onClick={(e) => this.onReassignClick(doc.orderFacilityNo, this.state.orderOfficeId, doc.assignedTo)}>Reassign</button>
                                                            <button className={"btn btn-blue-btnnm btn-type pull-left collAll mb-1 mr-1 " + (doc.reviewStatus ? "show" : "hide")} onClick={(e) => this.onClickReview(doc.orderFacilityNo)}>Review</button>
                                                        </div>
                                                        <button data-for='tooltipReopen' data-place="left" data-effect="solid" className={"btn btn-blue-btnnm hvr-bounce-to-right btn-type pull-left mt-1 collAll mb-1 mr-1 ml-auto " + (!this.state.AllowReopenOrAdditional || doc.isOrderReopen || doc.doctor.isRedacted ? "hide" : "show")} onClick={this.handleReOpenClose} value={doc.reopenOrderId} data-tip='Allows you to reopen this case for additional handling.'>Reopen Case</button>
                                                        <ReactTooltip id='tooltipReopen' />
                                                        <div>
                                                            <button type="button" className={"btn btn-blue-btnnm hvr-bounce-to-right btn-type pull-left mt-1 mb-1 " + (doc.orderStatus ? "hide" : "show")} onClick={event => this.handleCancelMessageClose(event, doc.reopenOrderNo, doc.orderFacilityNo)} value="CancelRequest">Cancel Order </button>
                                                            <button type="button" className={"btn btn-blue-btnnm hvr-bounce-to-right btn-type pull-right mt-1 mb-1 mr-1 ml-2 " + ((doc.orderStatus || !AllowCSRMessaging) ? "hide" : "show")} onClick={event => this.handleCancelMessageClose(event, doc.reopenOrderNo, doc.orderFacilityNo)} value="MessageRequest">Message CSR</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id={"collapse" + index} className={"panel-collapse collapse in " + (expand ? "show" : "")}>
                                                    <div className="panel-body d-block">
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <div className="bgwhite rounded">
                                                                    <div className="card placeholder-div max-div-xs mb-1">
                                                                        <div className="card-header">
                                                                            <img className="mr-2" src="img/id-icon.png" width="40" />
                                                                            Location Details
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mdb-1" htmlFor="inputReceivedDTTime">Received Date/Time </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="form-group mat-div-xd">
                                                                                        <span> {doc.doctor.orderDate.replace("-", "/").replace("-", "/")}  </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={"form-row-xm " + (!isNullOrUndefined(doc.lastReopenDateTime) ? "show" : "hide")}>
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mdb-1" htmlFor="inputReceivedDTTime">LastReopenDate/Time </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="form-group mat-div-xd">
                                                                                        <span> {_lastReopenDateTime} </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mb-1" htmlFor="inputeNoahOrderId">eNoah Order #</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 pull-left nopadding">
                                                                                        <input className="form-control" id="inputeNoahOrderId" type="text" autoComplete="off" value={doc.doctor.eNoahOrderId} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mb-1" htmlFor="inputReqType">Request Type </label>
                                                                                    </div>
                                                                                    <div className="col-md-9 pull-left nopadding">
                                                                                        <input className="form-control" id="inputReqType" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? doc.doctor.facilityRequests[0].requestType : ""} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mb-1" htmlFor="inputOrderType">Order Type </label>
                                                                                    </div>
                                                                                    <div className="col-md-9 pull-left nopadding">
                                                                                        <input className="form-control" id="inputOrderType" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? (doc.doctor.facilityRequests[0].rushRequest ? "Rush" : "Standard") : ""} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mb-1" htmlFor="inputCR">Certified Request </label>
                                                                                    </div>
                                                                                    <div className="col-md-9 pull-left nopadding">
                                                                                        <input className="form-control" id="inputCR" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? (doc.doctor.facilityRequests[0].certifiedRequest ? "Yes" : "No") : ""} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                <div className="form-group">
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <label className="small mb-1" htmlFor="inputAR">Affidavit Request </label>
                                                                                    </div>
                                                                                    <div className="col-md-9 pull-left nopadding">
                                                                                        <input className="form-control" id="inputAR" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? (doc.doctor.facilityRequests[0].affidavitRequest ? "Yes" : "No") : ""} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.FacilityNameHide ? "show" : "hide")}>
                                                                                        <label className="small mb-1" htmlFor="inputFacName">{this.state.lblFacilityName} </label>
                                                                                    </div>
                                                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.FacilityNameHide ? "show" : "hide")}>
                                                                                        <input className="form-control" id="inputFacName" type="text" autoComplete="off" value={name} onChange={() => { }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.DrEmailAddressHide ? "show" : "hide")}>
                                                                                        <label className="small mb-1" htmlFor="inputDrEmail">{this.state.lblDrEmailAddress}</label>
                                                                                    </div>
                                                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.DrEmailAddressHide ? "show" : "hide")}>
                                                                                        <input className="form-control" id="inputDrEmail" value={doc.doctor.drEmailAddress} type="text" autoComplete="off" onChange={() => { }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.DrAddressHide ? "show" : "hide")}>
                                                                                        <label className="small mb-1" htmlFor="inputDrAddress">{this.state.lblDrAddress}</label>
                                                                                    </div>
                                                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.DrAddressHide ? "show" : "hide")}>
                                                                                        <input className="form-control" id="inputDrAddress" value={doc.doctor.drAddress} type="text" autoComplete="off" onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12 nopadding">
                                                                                <div className={"col-md-3 pull-left nopadding " + (this.state.DoctorCityHide ? "show" : "hide")}>
                                                                                    <label className="small mdb-1" htmlFor="inputDrCity">{this.state.lblDoctorCity}</label>

                                                                                </div>
                                                                                <div className={"col-md-3 pull-left nopadding " + (this.state.DoctorCityHide ? "show" : "hide")}>
                                                                                    <input className="form-control" id="inputDrCity" type="text" autoComplete="off" value={doc.doctor.drCity} onChange={() => { }}/>
                                                                                </div>
                                                                                <div className={"col-md-1 pull-left nopadding " + (this.state.DrStateIdHide ? "show" : "hide")}>
                                                                                    <label className="small mdb-1" htmlFor="inputDrState">{this.state.lblDrStateId} </label>
                                                                                </div>
                                                                                <div className={"col-md-2 pull-left nopadding " + (this.state.DrStateIdHide ? "show" : "hide")}>
                                                                                    <input className="form-control" id="inputDrState" type="text" autoComplete="off" value={doc.doctor.drState} onChange={() => { }}/>
                                                                                </div>
                                                                                <div className={"col-md-1 pull-left nopadding " + (this.state.DrZipCodeHide ? "show" : "hide")}>
                                                                                    <label className="small mdb-1" htmlFor="inputDrZip">{this.state.lblDrZipCode} </label>
                                                                                </div>
                                                                                <div className={"col-md-2 pull-left nopadding " + (this.state.DrZipCodeHide ? "show" : "hide")}>
                                                                                    <input className="form-control" id="inputDrZip" type="text" autoComplete="off" value={doc.doctor.drZipCode} onChange={() => { }}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.PhoneHide ? "show" : "hide")}>
                                                                                        <label className="small mb-1" htmlFor="inputPhone">{this.state.lblPhone} </label>
                                                                                    </div>
                                                                                    <div className={"col-md-5 pull-left nopadding " + (this.state.PhoneHide ? "show" : "hide")}>
                                                                                        <input className="form-control" id="inputPhone" type="text" autoComplete="off" value={doc.doctor.drPhone} onChange={() => { }}/>
                                                                                    </div>
                                                                                    <div className="col-md-1 pull-left nopadding">
                                                                                        <label className="small mb-1" htmlFor="inputEXT">EXT: </label>
                                                                                    </div>
                                                                                    <div className="col-md-3 pull-left nopadding">
                                                                                        <input className="form-control" id="inputEXT" type="text" autoComplete="off" value={doc.doctor.drExt} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm">
                                                                                <div className="form-group">
                                                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.DrFaxHide ? "show" : "hide")}>
                                                                                        <label className="small mb-1" htmlFor="inputDrFax">{this.state.lblDrFax} </label>
                                                                                    </div>
                                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.DrFaxHide ? "show" : "hide")}>
                                                                                        <input className="form-control" id="inputDrFax" type="text" autoComplete="off" value={doc.doctor.drFax} onChange={() => { }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row-xm mt-20">
                                                                                <div className="form-group">
                                                                                    <div className={"col-md-3 pull-left nopadding"}>
                                                                                        <label className="small mb-1" htmlFor="inputCPF">Customer Provided Facility </label>
                                                                                    </div>
                                                                                    <div className={"col-md-8 pull-left nopadding"}>
                                                                                        <textarea id="inputCPF" className="form-control textareax" placeholder="Customer Provided Facility" value={!isNullOrUndefined(doc.doctor.customerProvidedFacility) ? doc.doctor.customerProvidedFacility.replace(/[\r\n]+/gm, "") : ""} onChange={() => { }}></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"bgwhite rounded  " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                    <div className="card placeholder-div mb-1">
                                                                        <div className="card-header">
                                                                            <img className="mr-2" src="img/case-sp-icon.png" width="40" />
                                                                            Special Instructions
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="form-row mb-3">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <textarea className="form-control textareax" placeholder="Instructions" value={SPIn} onChange={() => { }}>  </textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-5">
                                                                <div className="card max-div-xs mb-1">
                                                                    <div className="card-header">
                                                                        Results Uploaded
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="form-row">
                                                                            {doc.customerDocs.map((document, i) => {
                                                                                let filename = "", ext = document.fileName.split('.')[document.fileName.split('.').length - 1];
                                                                                let authDocId = document.id;
                                                                                let mapId = doc.doctor ? doc.doctor.mapId : 0

                                                                                switch (document.title) {
                                                                                    case "3":
                                                                                        filename = "Invoice";
                                                                                        break;
                                                                                    case "13":
                                                                                    case "17":
                                                                                        filename = "Records";
                                                                                        break;
                                                                                    default:
                                                                                        if (ext.toLowerCase() == "pdf") {
                                                                                            filename = "Records";
                                                                                        }
                                                                                        else {
                                                                                            filename = ext.toLowerCase();
                                                                                        }
                                                                                }

                                                                                return (<div className="col-md-2">
                                                                                    <div className="form-group mat-div-xd" onClick={() => this.viewDoc(document.path, ext, mapId, false, document.title, authDocId)}>
                                                                                        <div className="text-center">
                                                                                            <i className='fas fa-envelope-open-text'></i>
                                                                                        </div>
                                                                                        <div className="text-center icon-tx-x"> {filename}</div>
                                                                                        {!isNullOrUndefined(document.createdOnDateTime) ? (
                                                                                            <div className="text-center icon-tx-x"> {Moment(document.createdOnDateTime).format('MM/DD/yyyy')}</div>
                                                                                        ) : (
                                                                                            <div className="text-center icon-tx-x"> </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>)
                                                                            })
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className={"card max-div-xs mb-1 " + (this.state.isAPSproduct?"show":"hide")}>
                                                                    <div className="card-header">
                                                                        Auth Documents
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="form-row">
                                                                            {doc.doctor.authDocs.map((document, i) => {
                                                                                let filename = "", ext = document.fileName.split('.')[document.fileName.split('.').length - 1];
                                                                                let authDocId = document.id;
                                                                                if (!isNullOrUndefined(document.title)) {

                                                                                    let item = this.state.Description.find(item => item.recordTitle === parseInt(document.title));
                                                                                    if (!isNullOrUndefined(item)) {
                                                                                        filename = item.description;
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    filename = "Auth";
                                                                                }
                                                                                return (<div className="col-md-2">
                                                                                    <div className="form-group mat-div-xd" onClick={() => this.viewAuthDoc(document.path, ext, 0, this.state.isViewAuth, document.title, authDocId)}>
                                                                                        <div className="text-center">
                                                                                            <i className='fas fa-envelope-open-text'></i>
                                                                                        </div>
                                                                                        <div className="text-center icon-tx-x"> {filename}</div>
                                                                                        {!isNullOrUndefined(document.createdOnDateTime) ? (
                                                                                            <div className="text-center icon-tx-x"> {Moment(document.createdOnDateTime).format('MM/DD/yyyy')}</div>
                                                                                        ) : (
                                                                                            <div className="text-center icon-tx-x"> </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>)
                                                                            })
                                                                            }
                                                                        </div>
                                                                        <button type="button" id={index} className={"btn btn-blue-btnnm hvr-bounce-to-right btn-type pull-right " + ((isCompleted || doc.doctor.isRedacted) ? "hide" : "show")} onClick={(e) => this.openUploadModal(doc.doctor.id, doc.doctor.mapId, index)} style={{ marginTop: "-30px" }}>Other Documents</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-7">
                                                                <div className={"bgwhite " + (this.state.HideLifeDiv ? "show" : "hide")}>
                                                                    <div className="card placeholder-div mb-1">
                                                                        <div className="card-header">
                                                                            <img className="mr-2" src="img/case-sp-icon.png" width="40" />
                                                                            Special Instructions
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="form-row mb-3">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <textarea className="form-control textareax" placeholder="Instructions" value={doc.doctor.specialInstruction} onChange={() => { }}>  </textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-5">
                                                                <div className={"bgwhite " + ((Array.isArray(doc.apsReason) && doc.apsReason.length > 0 && doc.apsReason.map(item => item.apsReason) != null && doc.apsReason.map(item => item.apsReason) != '' && this.state.HideLifeDiv) ? "show" : "hide")}>
                                                                    <div className="card placeholder-div mb-1">
                                                                        <div className="card-header">
                                                                            <i className="fa fa-file" aria-hidden="true"></i>
                                                                            APS Reason
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="form-row mb-3">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <textarea className="form-control textareax" placeholder="APS Reason" value={Array.isArray(doc.apsReason) ? doc.apsReason.map(item => item.apsReason).join('\n') : ''} maxLength={1000}
                                                                                            onChange={() => { }}>  </textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-12 mt-2 clearboth hide">
                                                                <div className="card max-div-xs mb-1">
                                                                    <div className="card-header">
                                                                        Order Discussion
                                                                        <button type="button" className="btn btn-blue-btnnm btn-type pull-right" onClick={() => this.openDiscussionModal(doc.doctor.mapId)}>Start New Discussion</button>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="table-responsive dataTable unsubmitted posrel casedetails">
                                                                            <MaterialTable
                                                                                title=""
                                                                                columns={[
                                                                                    {
                                                                                        title: 'Date/Author', field: 'createdDateTime'
                                                                                    },
                                                                                    { title: 'Topic', field: 'topic' },
                                                                                    { title: 'Last Post', field: 'comment' },
                                                                                    { title: 'Discussion Type', field: 'discussionType' },
                                                                                    { title: 'Discussion Status', field: 'discussionStatus' },
                                                                                ]}
                                                                                data={doc.orderDiscussions}
                                                                                options={{
                                                                                    pageSize: doc.orderDiscussions.length,
                                                                                    search: false,
                                                                                    toolbar: false
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 mt-2 clearboth">
                                                                <div className="card max-div-xs mb-1">
                                                                    <div className="card-header">
                                                                        Status History
                                                                    </div>
                                                                    <div className="card-body dataTable">
                                                                        <div className="table-responsive dataTable unsubmitted posrel postion-right">
                                                                            <div className="left-0">
                                                                                <label className="small mb-1">Number of entries: </label>
                                                                                <span><label className="small mb-1"> {" " + doc.statuses.length} </label></span>
                                                                            </div>
                                                                            <div className="table-responsive dataTable posrel">
                                                                                <div className="col-md-2 searchoff nopadding">
                                                                                    <span className="show-title">Show</span>
                                                                                </div>
                                                                                <div className="col-md-2 entriesoff nopadding">
                                                                                    <span className="show-entries">entries</span>
                                                                                </div>
                                                                                <div className="searchoff-xc">
                                                                                    <span className="show-title-x">Search</span>
                                                                                </div>
                                                                                <MaterialTable
                                                                                    title=""
                                                                                    className="dataTable"
                                                                                    class="mat-paginator-sticky"
                                                                                    columns={[
                                                                                        {
                                                                                            title: 'Date/Time', field: 'dateTime', type: 'datetime', width: '14%'
                                                                                        },
                                                                                        { title: 'Status', field: 'status', width: '15%' },
                                                                                        { title: 'Status Event', field: 'eventDisplayName', width: '27%' },
                                                                                        { title: 'Details', field: 'details', width: '45%', hidden: !this.state.isViewNote },
                                                                                    ]}
                                                                                    data={doc.statuses}
                                                                                    options={{
                                                                                        paginationType: "stepped",
                                                                                        search: true,
                                                                                        labelRowsPerPage: true,
                                                                                        sorting: true
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={"modal " + (this.state.isViewModalopen ? "show" : "hide")} id="editDoc">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content h-96">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.state.DocFileName}
                                </h4>
                                <button type="button" className="close" onClick={this.onClickclosePopup} data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body document-modal">
                                <div className="holds-the-iframe">
                                    <iframe src={this.state.url} scrolling="yes" title={this.state.pdftitle} className="iframewidth">
                                        <p>Your browser does not support iframes.</p>
                                    </iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div
                    className={"modal " + (this.state.isUploadModalOpen ? "show" : "hide")}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="d-flex justify-content-between w-100">
                                    <h4 className="modal-title">Upload Document</h4>
                                </div>
                                <button type="button" className="close" onClick={this.handleCloseModal} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-4">
                                <div className="form-group">
                                    <div className="col-md-3 pull-left nopadding">
                                        <label className="small mb-1">Choose File</label>
                                    </div>
                                    <div className="col-md-8 pull-left nopadding">
                                        <input className="form-control" type="file" name="file" id="customFile1" accept=".pdf,.jpg,.jpeg,.tif,.tiff" title="Please choose a file" onChange={(event) => { this.DocChangeHandler(event) }} onClick={(event) => { event.target.value = null }} />
                                    </div>
                                </div>
                                <ul className="btn-alignment-x mt-5">
                                    <li><button className="btn btn-blue-btnn btn-type" onClick={this.handleCloseModal}>Cancel</button> </li>
                                    <li><button className="btn btn-blue-btnn btn-type" onClick={this.handleUploadAuthFile}>Upload</button> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"modal " + (this.state.isDiscussionModalOpen ? "show" : "hide")}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">New Discussion</h4>
                                <button type="button" className="close" onClick={this.onClickclosePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-4">

                                <div className="form-group">
                                    <div className="col-md-3 pull-left nopadding">
                                        <label className="small mb-1">Topic:</label>
                                    </div>
                                    <div className="col-md-9 pull-left nopadding">
                                        <input className="form-control" onChange={this.handleOrderDiscussionTopicChange} maxLength="30" value={this.state.OrderDiscussionTopic} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-md-3 pull-left nopadding">
                                        <label className="small mb-1">Post:</label>
                                    </div>
                                    <div className="col-md-9 pull-left nopadding">
                                        <input className="form-control" onChange={this.handleOrderDiscussionCommentChange} maxLength="100" value={this.state.OrderDiscussionComment} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-md-3 pull-left nopadding">
                                        <label className="small mb-1">Discussion Type:</label>
                                    </div>
                                    <div className="col-md-9 pull-left nopadding">
                                        <select className="form-control "
                                            value={this.state.OrderDiscussionType} onChange={this.handleOrderDiscussionTypeChange} >
                                            <option key="0" value="Select">Select</option>
                                            <option key="1" value="Type01">Type01</option>
                                            <option key="2" value="Type02">Type02</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-md-3 pull-left nopadding">
                                        <label className="small mb-1">Discussion Status:</label>
                                    </div>
                                    <div className="col-md-9 pull-left nopadding">
                                        <select className="form-control "
                                            value={this.state.OrderDiscussionStatus} onChange={this.handleOrderDiscussionStatusChange} >
                                            <option key="0" value="0">Select</option>
                                            <option key="1" value="1">Status01</option>
                                            <option key="2" value="2">Status02</option>
                                        </select>
                                    </div>
                                </div>
                                <ul className="btn-alignment-x mt-5">
                                    <li><button className="btn btn-blue-btnn btn-type" onClick={this.handleCloseOrderDiscussionModal}>Cancel</button> </li>
                                    <li><button className="btn btn-blue-btnn btn-type" onClick={this.handleAddOrderDiscussion}>Add</button> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"removeAlert-divbrowser " + (this.state.repopupShow ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <div className="text-center-div m-0">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter Reopen Reason</h4>
                            </div>
                            <textarea id="sub" className={"form-control textareax " + (this.state.IsReopenText ? "redborder" : "blackborder")}
                                placeholder="Reopen Reason" value={this.state.ReopenComents} maxLength={1000}
                                onChange={this.handleReopenComentsChange}
                            ></textarea>
                            <button className="bttn popupButton m-0" onClick={this.handleSubmit}> Submit </button>
                            <button className="bttn popupButton m-2" onClick={this.handleReOpenClose} value="Close" > Cancel </button>
                        </div>
                    </div>
                </div>

                <div className={"removeAlert-divbrowser " + (this.state.cancelpopupShow ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <div className="text-center-div m-0">
                            <div className="modal-header">
                                <h4 className="modal-title">You are about to request this order to be cancelled. The CSR will review the order to ensure it can be cancelled. Not all cases can be cancelled, and medical fees may still apply if they have been made to the facility. Please allow 24 hours for a response.</h4>
                            </div>
                            <textarea id="sub" className={"form-control textareax " + (this.state.IsCancelText ? "redborder" : "blackborder")}
                                placeholder="Cancel Note" value={this.state.CancelComents} maxLength={1000}
                                onChange={this.handleCancelComentsChange}
                            ></textarea>
                            <button className="bttn popupButton m-0" onClick={this.handleCancelMessageSubmit}> Cancel Order </button>
                            <button className="bttn popupButton m-2" onClick={event => this.handleCancelMessageClose(event, this.state.reopenOrderNo, this.state.orderFacilityNo)} value="Close" > Do not Cancel </button>
                        </div>
                    </div>
                </div>

                <div className={"removeAlert-divbrowser " + (this.state.messagepopupShow ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <div className="text-center-div m-0">
                            <div className="modal-header">
                                <h4 className="modal-title">Please enter the message you would like to send to your Customer Service Representative. Please allow 24 hours for a response.</h4>
                            </div>
                            <textarea id="sub" className={"form-control textareax " + (this.state.IsMessageText ? "redborder" : "blackborder")}
                                placeholder="Message Note" value={this.state.MessageComents} maxLength={1000}
                                onChange={this.handleMessageComentsChange}
                            ></textarea>
                            <button className="bttn popupButton m-0" onClick={this.handleCancelMessageSubmit}> Send Message </button>
                            <button className="bttn popupButton m-2" onClick={event => this.handleCancelMessageClose(event, this.state.reopenOrderNo, this.state.orderFacilityNo)} value="Close" > Do not message </button>
                        </div>
                    </div>
                </div>
                <div
                    className={"modal " + (this.state.isModalopen ? "show" : "hide")}
                    id="myModal3"
                >
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">User Search</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickAgentClosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left mb-2">
                                        <div className="form-group posrelative-xx fadocsel">
                                            <Select
                                                className={
                                                    "hide form-control popAgent " +
                                                    (this.state.dopopAgentname
                                                        ? "redborder"
                                                        : "blackborder")
                                                }
                                                value={this.state.PopAgentId}
                                                options={this.state.AgentOptions}
                                                onChange={this.handlePopAgentNameChange}
                                                placeholder=""
                                                Clearable
                                                Loading
                                            />

                                            {this.renderAgentSearch()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 "
                                    onClick={this.handleReassign}
                                >
                                    Add{" "}
                                </button>
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1"
                                    onClick={this.onClickAgentClosePopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
