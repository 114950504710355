import React, { Component } from 'react';
export class LoginErrorSSO extends Component {

    render() {
        return (
            <div className="container">
                <div className="container h-100 d-flex">
                    <div className="jumbotron my-auto">
                        <h1 className="display-6">Alert!</h1>
                        <p className="lead">Direct Access to Portal is not allowed.</p>
                    </div>
                </div>
            </div>
        );
    }
}

