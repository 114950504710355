import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import $ from 'jquery';
import Api from '../serviceCall';
import 'jspdf-autotable';
import { isNullOrUndefined } from 'util';
import "./Result.css";
import { TimeoutCounter } from './TimeoutCounter';
import BootstrapSwitchButton from "bootstrap-switch-button-react";

export class EventType extends Component {
    static displayName = EventType.name;
    constructor(props) {
        super(props);
        this.state = {
            EventId: "",
            EventValue: "",
            EventDisplayName: "",
            StatusEventCode: "",
            StatusEventValue: "",
            IsActive: 0,
            boolIsActive: false,
            eventList: [],
            toasterCla: false,
            toasterredCla: false,
            toaster: "",
            toaster2: "",
            isEventTypeopen: false,
            removeAlertx: false,
        };
        this.handleEventIdChange = this.handleEventIdChange.bind(this);
        this.handleEventValueChange = this.handleEventValueChange.bind(this);
        this.handleEventDisplayNameChange = this.handleEventDisplayNameChange.bind(this);
        this.handleStatusEventCodeChange = this.handleStatusEventCodeChange.bind(this);
        this.handleStatusEventValueChange = this.handleStatusEventValueChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.testAlphawithSpaceForName = this.testAlphawithSpaceForName.bind(this);
        this.handleUpdateEvent = this.handleUpdateEvent.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.handleEditEvent = this.handleEditEvent.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handlePopupUpdateEvent = this.handlePopupUpdateEvent.bind(this);
        this.closeUpdatePopup = this.closeUpdatePopup.bind(this);        
    }

    componentWillMount() {
        let currentComponent = this;
        currentComponent.setState({ eventList: [], Loading: true });
        Api.Get('services/api/GetEventList', {
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                if (!isNullOrUndefined(result.result)) {
                    currentComponent.setState({ eventList: result.result, Loading: false });
                }
                else {
                    currentComponent.setState({ eventList: [], Loading: false });
                }
            }
            else {
                currentComponent.setState({ eventList: [], Loading: false });
            }
        });
    }

    handlePopupUpdateEvent(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeAlertx: true });
    }

    handleUpdateEvent(e) {
        this.UpdateEventType();
    }

    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }

    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }

    UpdateEventType() {
        let currentComponent = this;
        let EID = currentComponent.state.EventId, EV = currentComponent.state.EventValue, EDN = currentComponent.state.EventDisplayName,
            SEC = currentComponent.state.StatusEventCode, SEV = currentComponent.state.StatusEventValue, IA = 0,
            valid = true;
        if (EID == null || EID.length === 0) {
            valid = false;
        }
        if (isNullOrUndefined(EV)) {
            EV = "";
        }
        if (isNullOrUndefined(EDN)) {
            EDN = "";
        }
        if (isNullOrUndefined(SEC)) {
            SEC = "";
        }
        if (isNullOrUndefined(SEV)) {
            SEV = "";
        }
        if (currentComponent.state.boolIsActive) {
            IA = 1;
        }
       
        if (valid) {
            currentComponent.setState({ loading: true });
            Api.Post('services/api/UpdateEventTypeList', {
                "Id": EID,
                "EventValue": EV,
                "EventDisplayName": EDN,
                "StatusEventCode": SEC,
                "StatusEventValue": SEV,
                "IsActive": IA
            }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    if (!isNullOrUndefined(result.result)) {
                        currentComponent.toasterfun2("Event Type Updated Successfully.");
                        currentComponent.setState({ eventList: result.result, Loading: false, EventId: "", EventValue: "", EventDisplayName: "", StatusEventCode: "", StatusEventValue: "", IsActive: false, isEventTypeopen: false, boolIsActive: false, removeAlertx: false });
                    }
                    else {
                        currentComponent.setState({ eventList: [], Loading: false });
                    }
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
        else {
            currentComponent.toasterredfun2("Event Id is Invalid");
        }
    }

    testAlphawithSpaceForName(e) {
        return /^[._a-zA-Z0-9&'-\s]+$/g.test(e) && !/\s{2,}/g.test(e);
    }

    handleEditEvent(eventID) {
        let Eventcodevalue = this.state.eventList.find(x => x.id == eventID);
        this.setState(prevState => ({
            EventId: Eventcodevalue.id,
            EventValue: Eventcodevalue.eventValue,
            EventDisplayName: Eventcodevalue.eventDisplayName,
            StatusEventCode: Eventcodevalue.statusEventCode,
            StatusEventValue: Eventcodevalue.statusEventValue,
            boolIsActive: Eventcodevalue.isActive === "YES",
        }));
        this.setState({ isEventTypeopen: true });
    }

    handleEventIdChange(e) {

        this.setState({ EventId: e.target.value });
    }

    handleEventValueChange(e) {
        this.setState({ EventValue: e.target.value });
    }

    handleEventDisplayNameChange(e) {

        this.setState({ EventDisplayName: e.target.value });
    }

    handleStatusEventCodeChange(e) {
        if (e.target.value.length !== 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                this.setState({ StatusEventCode: e.target.value });
            }
        } else {
            this.setState({ StatusEventCode: e.target.value });
        };
    }

    handleStatusEventValueChange(e) {
        this.setState({ StatusEventValue: e.target.value });
        if (e.target.value.length !== 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                this.setState({ StatusEventValue: e.target.value });
            }
        } else {
            this.setState({ StatusEventValue: e.target.value });
        };
    }

    handleIsActiveChange(e) {
        this.setState(prevState => ({
            boolIsActive: !prevState.boolIsActive
        }));
    }


    closePopup(e) {
        this.setState({ isEventTypeopen: false });
        this.setState = ({
            EventId: "",
            EventValue: "",
            EventDisplayName: "",
            StatusEventCode: "",
            StatusEventValue: "",
            IsActive: 0,
            eventList: [],
            toasterCla: false,
            toasterredCla: false,
            toaster: "",
            toaster2: "",
            isEventTypeopen: false,
            boolIsActive: false,
            removeAlertx: false,
        });
    }

    closeUpdatePopup(e) {
        this.setState({ removeAlertx: false });
    }

    render() {
        let landing = 'img/ems-logo.gif';        
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="process_img"/> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster}
                </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster}
                </div>
                <div className="bgwhite">
                    <div className="card mb-2 placeholder-div">       
                        <div className="card-body mt-2">                                
                            <div className='hgtDiv bg-white mt-2 p-3'>
                                <div className="container-fluid nopadding">
                                    <h1 className="mt-1 mb-3 withset">Event Type List</h1>
                                    <div className=" mb-4 normal-data ">
                                        <div className="card-body posrelative">
                                            <div className="table-responsive dataTable unsubmitted posrel">
                                                <div className="col-md-2 searchoff nopadding">
                                                    <span className="show-title">Show</span>
                                                </div>
                                                <div className="col-md-2 entriesoff nopadding">
                                                    <span className="show-entries">entries</span>
                                                </div>
                                                <div className="searchoff-x">
                                                    <span className="show-title-x">Search :</span>
                                                </div>
                                                <MaterialTable
                                                    title=""
                                                    className="dataTable"
                                                    columns={[
                                                        { title: ' Event ID ', field: 'id', type: 'int' },
                                                        { title: ' Event Value ', field: 'eventValue'},
                                                        { title: ' Event Display Name ', field: 'eventDisplayName'},
                                                        { title: ' Status Event Code ', field: 'statusEventCode'},
                                                        { title: ' Status Event Value ', field: 'statusEventValue'},
                                                        { title: ' Is Active ', field: 'isActive'},
                                                        {
                                                            title: ' Action ', field: 'action',
                                                            render: rowData => <span style={{ "width": "150px", "display": "block" }}>
                                                                <span className="edit-btnm" onClick={() => this.handleEditEvent(rowData.id)}
                                                                    onKeyDown={(event) => {
                                                                        let key = event.key || event.keyCode;
                                                                        if (key === "13" || key === 13) {
                                                                            this.handleEditEvent(rowData.id);
                                                                        }
                                                                    }}
                                                                    tabIndex={0}                                                                 >
                                                                    <img src="img/edit-icon.png" alt="edit_img" /> Edit </span>
                                                            </span>
                                                        },
                                                    ]}
                                                    data={this.state.eventList}
                                                    class="mat-paginator-sticky"                                                    
                                                    options={{
                                                        pageSizeOptions: [50, 100, 500],
                                                        pageSize: 50,
                                                        filtering: true,
                                                        exportButton: true,
                                                        exportFileName: "Event Type",
                                                        isLoading: true,
                                                        paginationType: "stepped",
                                                        exportAllData: true
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>                                  
                            </div>
                        </div>
                    </div>

                    <div className={"modal " + (this.state.isEventTypeopen ? "show" : "hide")}>
                        <span className="alert-bg-div-x"></span>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Event Information</h4>
                                    <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body p-3 placeholder-div">
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Event Id : {" "}</label>                                            
                                        </div>
                                        <div className="col-md-7">
                                            <input className="form-control" value={this.state.EventId} onChange={this.handleEventIdChange} disabled={true} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Event Value : {" "}</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className="form-control" maxLength={100} value={this.state.EventValue} onChange={this.handleEventValueChange} disabled={true} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Event Display Name : {" "}</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className="form-control" maxLength={100} value={this.state.EventDisplayName} onChange={this.handleEventDisplayNameChange} disabled={true} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Status Event Code : {" "}</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className="form-control" maxLength={50} value={this.state.StatusEventCode} onChange={this.handleStatusEventCodeChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Status Event Value : {" "}</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className="form-control" maxLength={250} value={this.state.StatusEventValue} onChange={this.handleStatusEventValueChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Is Active : {"   "}</label>
                                        </div>
                                        <div className="col-md-0">
                                            <label className="checkbox-inline borderone">
                                                <BootstrapSwitchButton
                                                checked={this.state.boolIsActive}
                                                    onlabel="On"
                                                    offlabel="Off"
                                                value={this.state.boolIsActive}
                                                    onChange={this.handleIsActiveChange}
                                                    />
                                            </label>
                                        </div>
                                    </div>                                        
                                </div>
                                <div className={"removeAlert-div " + (this.state.removeAlertx ? "show" : "hide")}>
                                    <span className="alert-bg-div"></span>
                                    <div className="alert-pos-x">
                                        {" "}
                                        <i className="fa fa-bell" aria-hidden="true"></i> Do you want update the record?
                                        <div className="text-center-div">
                                            <div className="modal-footer">                                            
                                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateEvent}>OK </button>
                                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.closeUpdatePopup}>Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">                                        
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handlePopupUpdateEvent}>Update </button>
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
            </Layout>
        );
    }
}


