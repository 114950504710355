import React, { Component} from 'react';
import { Layout } from './Layout';
import jQuery from 'jquery';
import Api from '../serviceCall';
import { TimeoutCounter } from './TimeoutCounter';
import Switch from "react-bootstrap-switch";
import $ from 'jquery';
import { Prompt } from 'react-router-dom';

export class Account extends Component {
    static displayName = Account.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            phone: "",
            mobile: "",
            email: "",
            ext: "",
            zip:"",
            AccessLevel: false,
            EnableSendNotification: false,
            EnableActionRequired: false,
            EnableResultsAvailable: false,
            EnableCancelled: false,
            MyOrders: false,
            AllOrders: false,
            toasterCla: false,
            toasterredCla: false,
            toaster: '',
            SendUpdatesOn: "myOrders",
            saveAlert: false,
            SendNotificationEnabled: false,
            ActionRequiredEnabled: false,
            ResultsAvailableEnabled: false,
            CancelledEnabled: false,
            SendUpdatesOnEnabled: "myOrders", RequestMissingClas: false,
            MyOrdersEnabled: false,
            AllOrdersEnabled: false,
            showConfirmModal: false,
            confirm: true,
            isSendNotificationChanged: false,
            isResultsAvailableChanged: false,
            isCancelledChanged: false,
            isSendUpdatesOnChanged: false,
            isActionRequiredChanged:false
        };
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.handleEnableSendNotificationSwitch = this.handleEnableSendNotificationSwitch.bind(this);
        this.handleEnableActionRequiredSwitch = this.handleEnableActionRequiredSwitch.bind(this);
        this.handleEnableResultsAvailableSwitch = this.handleEnableResultsAvailableSwitch.bind(this);
        this.handleEnableCancelledSwitch = this.handleEnableCancelledSwitch.bind(this);
        this.handleMyOrdersRadioChange = this.handleMyOrdersRadioChange.bind(this);
        this.handleAllOrdersRadioChange = this.handleAllOrdersRadioChange.bind(this);
        this.handleSaveUserDetails = this.handleSaveUserDetails.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    componentWillMount() {
        this.getUserDetails();
    }

    toasterredfun(e) {
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    handleEnableSendNotificationSwitch(elem, state) {
        this.setState({
            EnableSendNotification: state
        });
        if (!state) {
            this.setState({
                MyOrders: true,
                AllOrders: false,
                EnableActionRequired: true,
                EnableResultsAvailable: true,
                EnableCancelled: true
            });
        }
        if (state !== this.state.SendNotificationEnabled) {
            this.setState({
                isSendNotificationChanged: true
            });
        }
        else {
            this.setState({
                isSendNotificationChanged: false
            });
        }
    }
    handleEnableActionRequiredSwitch(elem, state) {
        this.setState({
            EnableActionRequired: state
        });
        this.setState({ RequestMissingClas: false });
        if (state !== this.state.ActionRequiredEnabled) {
            this.setState({
                isActionRequiredChanged: true
            });
        }
        else {
            this.setState({
                isActionRequiredChanged: false
            });
        }
    }
    handleEnableResultsAvailableSwitch(elem, state) {
        this.setState({
            EnableResultsAvailable: state
        });
        this.setState({ RequestMissingClas: false });
        if (state !== this.state.ResultsAvailableEnabled) {
            this.setState({
                isResultsAvailableChanged: true
            });
        }
        else {
            this.setState({
                isResultsAvailableChanged: false
            });
        }
    }
    handleEnableCancelledSwitch(elem, state) {
        this.setState({
            EnableCancelled: state
        });
        this.setState({ RequestMissingClas: false });
        if (state !== this.state.CancelledEnabled) {
            this.setState({
                isCancelledChanged: true
            });
        }
        else {
            this.setState({
                isCancelledChanged: false
            });
        }
    }
    handleMyOrdersRadioChange = (e) => {
        this.setState({
            MyOrders: e.target.checked,
            AllOrders: !e.target.checked,
        });
        if (e.target.checked !== this.state.MyOrdersEnabled) {
            this.setState({
                isSendUpdatesOnChanged: true
            });
        }
        else {
            this.setState({
                isSendUpdatesOnChanged: false
            });
        }
    };
    handleAllOrdersRadioChange = (e) => {
        this.setState({
            MyOrders: !e.target.checked,
            AllOrders: e.target.checked,
        });
        if (e.target.checked !== this.state.AllOrdersEnabled) {
            this.setState({
                isSendUpdatesOnChanged: true
            });
        }
        else {
            this.setState({
                isSendUpdatesOnChanged: false
            });
        }
    };
    handleSaveUserDetails = (event) => {
        let currentComponent = this;
        let isvalid = true;
        if (this.state.EnableSendNotification) {
            if (!this.state.EnableActionRequired && !this.state.EnableResultsAvailable && !this.state.EnableCancelled) {
                isvalid = false;
                this.setState({ RequestMissingClas: true });
                jQuery("html, body").animate({ scrollTop: 0 }, "slow");
            }
        }
        if (isvalid) {
            this.setState({ loading: true });
            let data = {
                "NotifyCaseUpdates": currentComponent.state.EnableSendNotification,
                "MyOrdersOnly": currentComponent.state.MyOrders,
                "AllOfficeOrders": currentComponent.state.AllOrders,
                "NotifyActionRequired": currentComponent.state.EnableActionRequired,
                "ResultsUploaded": currentComponent.state.EnableResultsAvailable,
                "NotifyCancelled": currentComponent.state.EnableCancelled
            };
            Api.Post('api/placeorder/UpdateRequestNotifications', data, currentComponent).then(function (result) {
                currentComponent.setState({ loading: false, isSendNotificationChanged: false, isActionRequiredChanged: false, isResultsAvailableChanged: false, isCancelledChanged: false, isSendUpdatesOnChanged: false});
                if (result.statusMessage === "Success") {
                    currentComponent.state.EnableSendNotification = "";
                    currentComponent.state.MyOrders = "";
                    currentComponent.state.AllOrders = "";
                    currentComponent.state.EnableActionRequired = "";
                    currentComponent.state.EnableResultsAvailable = "";
                    currentComponent.state.EnableCancelled = "";
                    currentComponent.getUserDetails();
                    currentComponent.toasterfun2(" Your Preferences are Saved Successfully");
                }
                else if (result.status === 401 || result == null) {
                    currentComponent.props.history.push('/');
                }
                else {
                    currentComponent.toasterredfun(result.statusMessage);
                }
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun(error);
                }
            });
        }
    }

    getUserDetails = () => {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        this.setState({ RequestMissingClas: false });
        Api.Get('api/placeorder/GetUserDetails', currentComponent).then(function (result) {
            currentComponent.setState({ loading: false });
            if (result.statusCode === 100) {
                let sendUpdatesOnValue = '';
                if (result.myOrdersOnly) {
                    sendUpdatesOnValue = 'myOrders';
                } else if (result.allOfficeOrders) {
                    sendUpdatesOnValue = 'allOfficeOrders';
                }
                currentComponent.setState({
                    firstName: result.firstName ?? "",
                    lastName: result.lastName ?? "",
                    address: result.address ?? "",
                    city: result.city ?? "",
                    state: result.state ?? "",
                    phone: result.phone ?? "",
                    mobile: result.mobile ?? "",
                    ext: result.ext ?? "",
                    email: result.email ?? "",
                    zip: result.zip??"",
                    AccessLevel: result.accessLevel ?? "",
                    EnableSendNotification: result.notifyCaseUpdates ?? "",
                    MyOrders: result.myOrdersOnly,
                    AllOrders: result.allOfficeOrders,
                    SendUpdatesOn: sendUpdatesOnValue,
                    EnableActionRequired: result.notifyActionRequired,
                    EnableResultsAvailable: result.resultsUploaded,
                    EnableCancelled: result.notifyCancelled,
                    SendNotificationEnabled: result.notifyCaseUpdates ?? "",
                    SendUpdatesOnEnabled: sendUpdatesOnValue,
                    ActionRequiredEnabled: result.notifyActionRequired,
                    ResultsAvailableEnabled: result.resultsUploaded,
                    CancelledEnabled: result.notifyCancelled,
                    MyOrdersEnabled: result.myOrdersOnly,
                    AllOrdersEnabled: result.allOfficeOrders,
                });
            }
            else if (result.status === 401 || result == null) {
                currentComponent.props.history.push('/');
            }
            else {
                currentComponent.toasterredfun(result.statusMessage);
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun(error);
            }
        });
    }

    render() {
        let landing = 'img/ems-logo.gif';
        return (
            <Layout>
                <div>
                    {(this.state.isSendNotificationChanged || this.state.isActionRequiredChanged || this.state.isResultsAvailableChanged || this.state.isCancelledChanged || this.state.isSendUpdatesOnChanged) && (
                        <Prompt message={() => 'Changes are Unsaved. Do you want to continue?'} />
                    )}
                </div>
                <TimeoutCounter />
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="Loading"  /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.showConfirmModal ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> You have unsaved changes. Leaving this page will result in losing any changes made.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" >
                                CONTINUE
                            </div>
                        </div>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" >
                                CANCEL
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv mt-2 p-3 bg-white rounded max-width-95 custom-margin '>

                    <div className="row">
                        <div className="col-xl-12  card border-0">
                            <div className={"red mt-2 error-div " + (this.state.RequestMissingClas ? "show" : "hide")}>*Please correct missing information.</div>
                            <div className="card placeholder-div">
                                <div className="card-header font-style">
                                    <img src="img/account-user.png" className="mr-2" width="25" alt="Account" /> USER ACCOUNT DETAILS
                                </div>
                                <div className="card-body">
                                    <div className="px-4">
                                        <div className="col-lg-12 nopadding mt-2">
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputFirstName">First Name
                                                    <span className="red">*</span>
                                                </label>
                                            </div>
                                            <div className="col-lg-2 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputFirstName" type="text" autoComplete="off" value={this.state.firstName} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputLastName">Last Name
                                                    <span className="red">*</span>
                                                </label>
                                            </div>
                                            <div className="col-lg-2 pull-left nopadding mb-2">
                                                <input className="form-control val w-75" id="inputLastName" type="text" autoComplete="off" value={this.state.lastName} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputEmail">Email Address</label>
                                            </div>
                                            <div className="col-lg-5 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputEmail" type="text" autoComplete="off" value={this.state.email} onChange={() => { }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 nopadding">
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputPhone">Work Phone</label>
                                            </div>
                                            <div className="col-lg-2 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputPhone" type="text" autoComplete="off" value={this.state.phone} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputExtension">EXT</label>
                                            </div>
                                            <div className="col-lg-2 pull-left nopadding mb-2">
                                                <input className="form-control val w-50" id="inputExtension" type="text" autoComplete="off" value={this.state.ext} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputMobile">Cell Phone</label>
                                            </div>
                                            <div className="col-lg-5 pull-left nopadding mb-2">
                                                <input className="form-control val w-50" id="inputMobile" type="text" autoComplete="off" value={this.state.mobile} onChange={() => { }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 nopadding">
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mb-1 lbl" htmlFor="inputAddress">Address</label>
                                            </div>
                                            <div className="col-lg-5 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputAddress" type="text" autoComplete="off" value={this.state.address} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputCity">City</label>
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputCity" type="text" autoComplete="off" value={this.state.city} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl" htmlFor="inputState">ST</label>
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputState" type="text" autoComplete="off" value={this.state.state} onChange={() => { }} />
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <label className="small mdb-1 lbl " htmlFor="inputZipcode">Zip</label>
                                            </div>
                                            <div className="col-lg-1 pull-left nopadding mb-2">
                                                <input className="form-control val" id="inputZipcode" type="text" autoComplete="off" value={this.state.zip} onChange={() => { }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 card border-0">
                            <div className="card mb-4 placeholder-divX req-border">
                                <div className="custom-border">
                                    <div className="card-header bg-transparent border-0">
                                        REQUEST NOTIFICATIONS
                                    </div>

                                    <div className="card-body p-0 pb-3">
                                        <div className="col-md-12 nopadding mt-2 ml-4">
                                            <div className="row no-gutters">
                                                <span className={"red " + (this.state.RequestMissingClas ? "show" : "hide")}>*</span>
                                                <label className="small mdb-1 lbl">Would you like notifications on case status updates?</label>
                                                <div className="col-md-4 pull-left nopadding ml-3"> {/* Added ml-2 for spacing */}
                                                    <Switch
                                                        defaultValue={false}
                                                        bsSize="mini"
                                                        onText="Yes"
                                                        offText="No"
                                                        value={this.state.EnableSendNotification}
                                                        onChange={(el, state) => this.handleEnableSendNotificationSwitch(el, state)}
                                                        name="enableSendNotification"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.EnableSendNotification && (
                                    <div>
                                        <div className="row mt-3 offset-1">



                                            <div className="col-md-9 ml-4 d-flex justify-content-between posrel">
                                                <label className="span-red col-mt-6 col-md-6"> <span className="red">*</span> Send updates on:</label>
                                                <label className="col-md-5 text-left">
                                                    <input className="col-md-2"
                                                        type="radio"
                                                        id="myOrders"
                                                        value="myOrders"
                                                        checked={this.state.MyOrders === true}
                                                        onChange={(e) => this.handleMyOrdersRadioChange(e)}
                                                    />
                                                    My Orders Only
                                                </label>
                                                <label className="col-md-5 text-left">
                                                    <input className="col-md-2"
                                                        type="radio"
                                                        id="allOfficeOrders"
                                                        value="allOfficeOrders"
                                                        checked={this.state.AllOrders === true}
                                                        onChange={(e) => this.handleAllOrdersRadioChange(e)}
                                                        disabled={this.state.AccessLevel === false}
                                                    />
                                                    All Office Orders
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mt-2 ml-4">
                                            <span className="red">*</span>
                                            <label className={"small mdb-2 ml-1" + (this.state.RequestMissingClas ? " red show" : " black show")}>Request Type Notifications (Must select at least 1)</label>
                                            <div className="col-md-12 mt-2">
                                                <div className="row no-gutters">

                                                    <div className="col-md-9 nopadding ml-5 text-right">
                                                        <label className="small mr-2 float-none">Action Required</label>
                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.EnableActionRequired}
                                                            onChange={(el, state) => this.handleEnableActionRequiredSwitch(el, state)}
                                                            name="enableActionRequiredSwitch"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row no-gutters">

                                                    <div className="col-md-9 nopadding ml-5 text-right">
                                                        <label className="small float-none mr-2">Results Available</label>
                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.EnableResultsAvailable}
                                                            onChange={(el, state) => this.handleEnableResultsAvailableSwitch(el, state)}
                                                            name="enableResultsAvailableSwitch"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row no-gutters toggle">

                                                    <div className="col-md-9 nopadding ml-5 text-right">
                                                        <label className="small float-none mr-2">Cancelled</label>
                                                        <Switch
                                                            defaultValue={false}
                                                            bsSize="mini"
                                                            onText="Yes"
                                                            offText="No"
                                                            value={this.state.EnableCancelled}
                                                            onChange={(el, state) => this.handleEnableCancelledSwitch(el, state)}
                                                            name="enableCancelledSwitch"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12 mt-3 text-right">
                            <button className="btn btn-blue-btnnm btn-type hvr-shutter-out-horizontal mr-2 " onClick={this.handleSaveUserDetails}>Save</button>
                            <button className="btn btn-blue-btnnm btn-type hvr-shutter-out-horizontal mr-2 " onClick={this.getUserDetails}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
