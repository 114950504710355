import React, { Component } from 'react';
import { Container} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {isNullOrUndefined } from 'util';

import Api from '../serviceCall';
import './NavMenu.css';
import {deviceDetect } from "react-device-detect";

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        this.logoutuser = this.logoutuser.bind(this);
        this.Admin = this.Admin.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true, userType: 'Client Admin', placeHolder: 'no', carrier: 'no', create: "no", showAdminMenu: true, performanceMetrics: 'no', HideHomeDashboardMenu: true, searchpagesso: 'no', UserId: "", carrierName: "", allowSummary: 'no'
        };
    }

    

    toggleNavbar() {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    }
    logoutuser(e) {
        let IsSSOUser = localStorage.getItem("IsSSOUser");
        if (IsSSOUser === 'true') {
            this.SSOUserLogoutUrl()
        }
        else {
            this.UserLogoutTime();
        }
    }
    SSOUserLogoutUrl() {
       let CompanyCode = localStorage.getItem("Companycode");
        let euid = localStorage.getItem("Euid");

        fetch('api/login/SSOUserLogout', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Euid": euid,
                "CompanyCode": CompanyCode,
            },
            body: JSON.stringify({
                "CompanyCode": CompanyCode,
                "BrowserType": deviceDetect().browserName,
                "BrowserVersion": deviceDetect().browserFullVersion
            })
        }).then(response => response.json())
        .then(function(res) {
            localStorage.clear();
            if (!isNullOrUndefined(res.ssoLogOutURL)) {
                window.location.href = res.ssoLogOutURL;
            }
            else {
                window.location.href = "/LogoutSSO";
            }
        })
    }
    UserLogoutTime() {
        let CompanyCode = localStorage.getItem("Companycode");
        this.setState({ loading: true });
        let euid = localStorage.getItem("Euid");
        fetch('api/login/UserLogout', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Euid": euid,
                    "CompanyCode": CompanyCode,
                },
                body: JSON.stringify({
                    "CompanyCode": CompanyCode,
                    "BrowserType": deviceDetect().browserName,
                    "BrowserVersion": deviceDetect().browserFullVersion
                })
            }).then(function (response) {
                    localStorage.clear();
                    window.location.href = "/"; 
            });
        }
    Admin(e) {
        this.props.history.push('/Carrier');
    }
    IdiomaticReactList(users, etype) {
        return (
            <ul className="menu-header">
                {users.map((user) => {
                    if (user.type === etype && user.btype === 'yes') {
                        return <li className="hvr-bounce-to-bottom" key={user.id}><NavLink className="main-nav" activeClassName="main-nav-active" to={"/" + user.link}>{user.name} </NavLink> </li>
                    }
                })}
            </ul>
        );
    }
    async componentWillMount() {
        let IsSSOUser = localStorage.getItem("IsSSOUser");
        let CompanyCode = localStorage.getItem("Companycode");       
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.allowExport);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                localStorage.setItem('AllowSummary', result.allowSummary);
                currentComponent.setState({ UserId: Api.decryptData(result.userId) });
                currentComponent.setState({ carrierName: result.carrierName });
            }

        });             
       
        if (!isNullOrUndefined(CompanyCode) && CompanyCode.toLowerCase() === 'primerica' && IsSSOUser === 'true') {
            this.setState({ HideHomeDashboardMenu: false });
        }

        let userType = localStorage.getItem("UserType");
        let ctype = localStorage.getItem("PlaceOrder");
        
        if (ctype === 'true') {
            this.setState({ placeHolder: 'yes' });
        } else {
            this.setState({ placeHolder: 'no' });
        }
        let allowSummary = localStorage.getItem("AllowSummary");
        if (allowSummary === 'true') {
            this.setState({ allowSummary: 'yes' });
        } else {
            this.setState({ allowSummary: 'no' });
        }
        let pmtype = localStorage.getItem("PerformanceMetrics");
        if (pmtype === 'true') {
            this.setState({ performanceMetrics: 'yes' });
        } else {
            this.setState({ performanceMetrics: 'no' });
        }
        this.setState({ userType: userType });
        if (!isNullOrUndefined(CompanyCode) && CompanyCode.toLowerCase() == 'primerica' && IsSSOUser == 'true') {
            this.setState({ carrier: false });
        }
        else if (userType === 'Client Admin' || userType === 'eNoah Admin' || userType === 'Client Home Office' || userType === 'eNoah System Admin') {
            this.setState({ carrier: localStorage.getItem("ShowAdminMenu") === "true"});
        } else {
            this.setState({ carrier: false });

        }        
        let AgentID = localStorage.getItem("Agentid");
        this.setState({ userType: userType });
        if (!isNullOrUndefined(AgentID))
            {
            if (!isNullOrUndefined(Api.decryptData(AgentID)) && Api.decryptData(AgentID).length > 0) {
                this.setState({ searchpagesso: 'yes' });
            }
            else { this.setState({ searchpagesso: 'no' }); }
        }
    }

    render() {
        let CompanyCode = localStorage.getItem("Companycode");
        let IsSSOUser = localStorage.getItem("IsSSOUser");
        let users;
        let userssso = [{
            name: 'Search',
            link: 'search',
            type: 'Client Admin',
            btype: 'no'
        }];
        users = userssso;
        if (!isNullOrUndefined(CompanyCode)) {
            if (CompanyCode.toLowerCase() === 'primerica' && IsSSOUser === 'true') {
                let userssso = [{
                    name: 'Search',
                    link: 'searchsso',
                    type: 'eNoah System Admin',
                    btype: this.state.searchpagesso
                }];
                users = userssso;
            }

            else {
                let normalusers = [
                    {
                        name: 'Inbound Orders',
                        link: 'inbound-orders',
                        type: 'Client Admin',
                        btype: 'no'
                    },
                    {
                        name: 'Place Order',
                        link: 'place-order',
                        type: 'Agent',
                        btype: this.state.placeHolder
                    },
                    {
                        name: 'Unsubmitted Orders',
                        link: 'unsubmitted-order',
                        type: 'Agent',
                        btype: this.state.placeHolder
                    },
                    {
                        name: 'Place Order',
                        link: 'place-order',
                        type: 'Client Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Unsubmitted Orders',
                        link: 'unsubmitted-order',
                        type: 'Client Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Search',
                        link: 'search',
                        type: 'Client Admin',
                        btype: 'yes'
                    }, 
                    {
                        name: 'Search',
                        link: 'search',
                        type: 'Agent',
                        btype: 'yes'
                    },                    
                    {
                        name: 'Results',
                        link: 'result',
                        type: 'Client Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Performance Metrics',
                        link: 'performance',
                        type: 'Client Admin',
                        btype: this.state.performanceMetrics
                    },
                    {
                        name: 'Account Preferences',
                        link: 'account',
                        type: 'Client Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Summary Review',
                        link: 'summaryedit',
                        type: 'Agent',
                        btype: this.state.allowSummary

                    },
                    {
                        name: 'Results',
                        link: 'result',
                        type: 'Agent',
                        btype: 'yes'

                    },
                    {
                        name: 'Performance Metrics',
                        link: 'performance',
                        type: 'Agent',
                        btype: this.state.performanceMetrics
                    },
                    {
                        name: 'Account Preferences',
                        link: 'account',
                        type: 'Agent',
                        btype: 'yes'
                    },
                    {
                        name: 'Inbound Orders',
                        link: 'inbound-orders',
                        type: 'eNoah Admin',
                        btype: 'no'
                    },
                    {
                        name: 'Place Order',
                        link: 'place-order',
                        type: 'eNoah Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Unsubmitted Orders',
                        link: 'unsubmitted-order',
                        type: 'eNoah Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Search',
                        link: 'search',
                        type: 'eNoah Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Summary Review',
                        link: 'summaryedit',
                        type: 'eNoah Admin',
                        btype: this.state.allowSummary

                    },
                    {
                        name: 'Results',
                        link: 'result',
                        type: 'eNoah Admin',
                        btype: 'yes'

                    },
                    {
                        name: 'Performance Metrics',
                        link: 'performance',
                        type: 'eNoah Admin',
                        btype: this.state.performanceMetrics
                    },
                    {
                        name: 'Account Preferences',
                        link: 'account',
                        type: 'eNoah Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Inbound Orders',
                        link: 'inbound-orders',
                        type: 'Client Home Office',
                        btype: 'no'
                    },
                    {
                        name: 'Place Order',
                        link: 'place-order',
                        type: 'Client Home Office',
                        btype: this.state.placeHolder
                    },
                    {
                        name: 'Unsubmitted Orders',
                        link: 'unsubmitted-order',
                        type: 'Client Home Office',
                        btype: this.state.placeHolder
                    },
                    {
                        name: 'Search',
                        link: 'search',
                        type: 'Client Home Office',
                        btype: 'yes'
                    },
                    {
                        name: 'Results',
                        link: 'result',
                        type: 'Client Home Office',
                        btype: 'yes'
                    },
                    {
                        name: 'Performance Metrics',
                        link: 'performance',
                        type: 'Client Home Office',
                        btype: this.state.performanceMetrics
                    },
                    {
                        name: 'Account Preferences',
                        link: 'account',
                        type: 'Client Home Office',
                        btype: 'yes'
                    },
                    {
                        name: 'Inbound Orders',
                        link: 'inbound-orders',
                        type: 'eNoah System Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Place Order',
                        link: 'place-order',
                        type: 'eNoah System Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Unsubmitted Orders',
                        link: 'unsubmitted-order',
                        type: 'eNoah System Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Search',
                        link: 'search',
                        type: 'eNoah System Admin',
                        btype: 'yes'
                    },
                    {
                        name: 'Summary Review',
                        link: 'summaryedit',
                        type: 'eNoah System Admin',
                        btype: this.state.allowSummary
                    },
                    {
                        name: 'Results',
                        link: 'result',
                        type: 'eNoah System Admin',
                        btype: 'yes'

                    },
            {
                name: 'Performance Metrics',
                link: 'performance',
                type: 'eNoah System Admin',
                btype: this.state.performanceMetrics
            },
            {
                name: 'Account Preferences',
                link: 'account',
                type: 'eNoah System Admin',
                btype: 'yes'

            },
            {
                name: 'Inbound Orders',
                link: 'inbound-orders',
                type: 'Agent',
                btype: 'no'
            },
        ];
                users = normalusers;
            }
        }
        
        return (
            <header className="container-fluid rounded">
                <nav className="sb-topnav navbar navbar-dark bg-dark rounded" style={{ paddingRight: "0px" }}>
                    <Container fluid style={{ paddingLeft: "0.5rem" ,paddingRight:"0.5rem"}}>
                        <NavLink className={"navbar-brand hvr-buzz-out " + (!isNullOrUndefined(CompanyCode) && this.state.HideHomeDashboardMenu ? "" : "hide")} style={{ padding: "0px", marginRight: "10px" }} to={"/dashboard"}><img src="img/eSubmission-logo.png" /></NavLink>
                        <a className={"navbar-brand hvr-buzz-out " + (this.state.HideHomeDashboardMenu ? "hide" : "")} style={{ padding: "0px", marginRight: "10px" }}><img src="img/eSubmission-logo.png" /></a>
                        <div className='logosub-title-parent'>
                            <span className='logosub-title'>Online Portal</span>
                            <span className='logosub-title-company-name'>{this.state.carrierName}</span>
                        </div>
                        {this.IdiomaticReactList(users, this.state.userType)}
                        <ul className="navbar-nav" style={{ flexDirection: "row" }}>
                            <li className={"nav-item hvr-float-shadow homeIcon borderRight " + (this.state.HideHomeDashboardMenu ? "show" : "hide")}>
                                <NavLink to="/Dashboard"> <img src="img/home.png" alt="Home" /> </NavLink>
                            </li>
                            <li className="nav-item hvr-float-shadow dropdown ovaldiv  ">
                                <a className="nav-link dropdown-toggle text-center" style={{ marginRight: "0px" }} id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                        <NavLink  className={"logout-div-x " + (this.state.carrier ? "show" : "hide")} activeClassName="main-nav-active" to="/carrier">Admin</NavLink>
                                        {/*<NavLink tag={Link} className="logout-div-x " activeclassname="main-nav-active" to="/b2bconfiguration">B2B Configuration</NavLink>*/}
                                        <NavLink  className={"logout-div-x " + (this.state.carrier ? "show" : "hide")} activeClassName="main-nav-active" to="/order-report">Order Report</NavLink>
                                        <NavLink  className={"logout-div-x " + ((isNullOrUndefined(IsSSOUser) || IsSSOUser === "false") ? "show" : "hide")} activeClassName="main-nav-active" to="/reset">Reset Password</NavLink>
                                        <a href="false" onClick={this.logoutuser} className="logout-div-x logout-link">Logout</a>
                                    </div>
                                </li>
                            </ul>
                    </Container>
                </nav>
            </header>
        );
    }
}
