import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Api from '../serviceCall';
import { isNullOrUndefined } from 'util';


export class Forgot extends Component {
    static displayName = Forgot.name;
    constructor(props) {
        super(props);
        this.state = {
            Username: '', sererror: false, usererror: false, loading: false, validUserName: '', isPasswordField: false, isUserNameField: true, input: {},
            errors: {}, Version: "", Env: "", Date: "", isValidMessage: false, companycodeerror: false, cnminlengtherror: false, cnaplphanumericerror: false,
            Companycode: "", CopyRightYear: new Date().getFullYear(), ssousererror: false, IsLocked: false, PrivacyPolicyURL: "", GenericErrMessageForgotpassword: false,
            Source: "", OrderFacilityId: "", EncryptedUrlParams: ""
        };
        this.signInSubmit = this.signInSubmit.bind(this);
        this.handleUNChange = this.handleUNChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCNChange = this.handleCNChange.bind(this);
        this.handleKeyDownUN = this.handleKeyDownUN.bind(this);
        this.handleKeyDownPass = this.handleKeyDownPass.bind(this);
        this.handleKeyDownCN = this.handleKeyDownCN.bind(this);
        this.changePasswordInSubmit = this.changePasswordInSubmit.bind(this);
    }
    componentWillMount() {
        localStorage.clear();
        let currentComponent = this;
        let windowlocation = window.location.href;
        const urlParts = windowlocation.split('?');
        let queryString = "";      
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {
            debugger;
            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
            }
        });
        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }
            else {
                currentComponent.setState({ PrivacyPolicyURL: "https://enoahisolution.com/privacy-policy/" });
            }
        });

        if (urlParts.length > 1) {
            queryString = urlParts[1];
            if (!isNullOrUndefined(queryString) && queryString.trim().length > 0) {
                fetch('api/Login/DecryptUrlString', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "UrlString": queryString })
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                    if (result.statusMessage === "Success") {
                        currentComponent.setState({
                            Source: result.source, OrderFacilityId: result.orderFacilityId, EncryptedUrlParams: queryString
                        })
                    }
                }).catch(function (error) {
                    this.setState({ Loading: false });
                    if (error === "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });
            }
        }
        else {
            currentComponent.setState({
                Source: '0'
            })
        }
    }
    handleChange(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input
        });
    }
    handleCNChange = (event) => {
        if (!this.state.cnaplphanumericerror) {
            this.setState({ companycodeerror: false, sererror: false, GenericErrMessageForgotpassword: false, Companycode: event.target.value });
        }
    }
    signInSubmit(e) {
        e.preventDefault();
        this.Signin();
    }
    changePasswordInSubmit(e) {
        e.preventDefault();

        let input = this.state.input;
        let errors = {};
        let isValid = true;
        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";            
        }
        if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {

            if (input["password"] !== input["confirm_password"]) {
                isValid = false;
                errors["match"] = "Passwords don't match.";
            }
        }
        this.setState({
            errors: errors
        });

        if (isValid) {
            let input = {};
            input["password"] = "";
            input["confirm_password"] = "";
            this.setState({ input: input });
            let currentComponent = this;
            fetch('api/PlaceOrder/ForgotPassword', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "UserName": this.state.Username,
                    "NewPassword": this.state.input.password
                })
            }).then(function (response) {
                return response.json();
            }).then(function (resul) {
                currentComponent.setState({ loading: false });
                if (resul.statusMessage !== "Success") {
                    currentComponent.setState({ loading: false, sererror: false, GenericErrMessageForgotpassword: true });
                }
                else {
                    currentComponent.props.history.push('/');
                    currentComponent.setState({ loading: false });
                }
            }).catch(function (error) {
                currentComponent.setState({ loading: false, sererror: false, GenericErrMessageForgotpassword: true });                
            });
        }
    }    
    handleUNChange(e) {
        if (e.target.value.length !== 0) {
            if (this.checkUN(e.target.value.trim())) {
                this.setState({ sererror: false, GenericErrMessageForgotpassword: false });
                this.setState({ Username: e.target.value.trim() });
            }
        }
        else {
            this.setState({ sererror: false, GenericErrMessageForgotpassword: false });
            this.setState({ Username: e.target.value });
        }
    }
    checkUN(e) {
        return !(e.length > 1 && e.split('.').length > 2) && /^[a-zA-Z0-9@.]*$/g.test(e);
    }
    handleKeyDown(event) {
        this.setState({ usererror: false, sererror: false, GenericErrMessageForgotpassword: false });
        let key = event.which || event.key;
        if (key === 13) {
            if (this.state.Username.length !== 0) {
                this.Signin();
            }
        }
    }
    handleKeyDownUN(event) {
        this.setState({ usererror: false, GenericErrMessageForgotpassword: false });
        let key = event.which || event.key;
        if (key === 13) {
            if (this.state.Username.length != 0 && this.state.Companycode.length !== 0) {
                this.Signin();
            }
        }
    }
    handleKeyDownPass(e) {
        this.setState({ passerror: false });
        let key = e.which || e.key;
        if (key === 13) {
            if (this.state.Username.length !== 0 && this.state.Companycode.length !== 0) {
                this.Signin();
            }
        }
    }
    handleKeyDownCN(e) {
        this.setState({ companycodeerror: false, sererror: false, GenericErrMessageForgotpassword: false });
        if (/^[A-Za-z0-9]+$/g.test(e.key)) {
            let key = e.which || e.key;
            this.setState({ cnaplphanumericerror: false });
            if (key === 13) {
                this.Signin();
            }
        } else {
            this.setState({ cnaplphanumericerror: true });
        }
    }
    Signin() {
        let antiToken = Cookies.get('XSRF-TOKEN');
        let currentComponent = this;
        let UN = this.state.Username, valid = true, Companycode = this.state.Companycode;
        
        if (UN === null || UN.length === 0) {
            this.setState({ usererror: true });
            valid = false;
        }
        if (Companycode === null || Companycode.length === 0) {
            this.setState({ companycodeerror: true });
            valid = false;
        }
        if (Companycode !== null && Companycode.length < 1 && valid) {
            this.setState({ cnminlengtherror: true });
            valid = false;
        }
        let emailParams = "";
        if (currentComponent.state.Source == "1") {
            emailParams = currentComponent.state.EncryptedUrlParams;
        }
        if (valid) {
            this.setState({ loading: true });
            fetch('api/login/CheckUserName', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Companycode': Companycode,
                    'X-XSRF-Token': antiToken
                },
                body: JSON.stringify({
                    "UserName": Api.encryptData(currentComponent.state.Username),
                    "CompCode": Api.encryptData(currentComponent.state.Companycode),
                    "EmailParams": emailParams
                })
            }).then(function (response) {
                return response.json();
            }).then(function (resul) {             
                currentComponent.setState({ loading: false,sererror: false, ssousererror: false, IsLocked: false, GenericErrMessageForgotpassword: false, isValidMessage: true});
            }).catch(function (error) {
                currentComponent.setState({ loading: false, sererror: false, ssousererror: false, IsLocked: false, GenericErrMessageForgotpassword: false, isValidMessage:true });
                
            });
        }
    }
    render() {
        let landing = 'img/ems-logo.gif';        
        return (
            <div>

                <div className="container-div">                           

                    <div className="con-div-xd lefeside-panel">


                    </div>

                    <div className="con-div-x top25">
                        <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="Loading" /> </span>
                        <div className="login-page">
                            <div className="logo">
                                <img src="img/eSubmission-logo.png" alt="Logo" />
                                <h2>eNoah Online Portal </h2>
                            </div>
                            <div className="form">
                                <h5> Change Password </h5>
                                <form className="login-form">
                                    <div className={"form-group " + (this.state.isUserNameField ? "show" : "hide")}>
                                        <label className="small">Username  </label>

                                        <input type="text" onKeyDown={this.handleKeyDown} maxLength={250} value={this.state.Username} autoComplete="username" onChange={this.handleUNChange} placeholder="Enter Username" />
                                        {/*<span className={"error " + (this.state.sererror ? "show" : "hide")}>User name Invalid </span>*/}
                                        <label className="small">Company Code</label>
                                        <input type="text" placeholder="Company Code" autoComplete="company" onKeyDown={this.handleKeyDownCN} maxLength={20} value={this.state.Companycode} onChange={this.handleCNChange} />
                                        <span className={"text-success " + (this.state.isValidMessage ? "show" : "hide")}>An email has been sent to your registered email</span>
                                        <span className={"error " + (this.state.companycodeerror ? "show" : "hide")}>Enter company code</span>
                                        <span className={"error " + (this.state.cnalphanumericerror ? "show" : "hide")}>Company code should only be in alphabets and numerics.</span>
                                        <span className={"error " + (this.state.cnminlengtherror ? "show" : "hide")}>Company code should contains min one characters.</span>
                                        <span className={"error " + (this.state.ssousererror ? "show" : "hide")}>SSO user cant reset their password.</span>
                                        <span className={"error " + (this.state.IsLocked ? "show" : "hide")}>Your account has been locked due to inactivity. Please contact your account Administrator for assistance.</span>
                                        <span className={"error " + (this.state.sererror ? "show" : "hide")}>User name or Company code is Invalid </span>
                                        <span className={"error " + (this.state.GenericErrMessageForgotpassword ? "show" : "hide")}>Your entered username or company code is invalid, or your account may have been locked. Please contact your account Administrator for assistance.</span>

                                        <button className="onLogin mt-4" onClick={this.signInSubmit}>Submit</button>
                                    </div>
                                    <div className={"form-group " + (this.state.isPasswordField ? "show" : "hide")}>
                                        <label htmlFor="password">Password:</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={this.state.input.password}
                                            onChange={this.handleChange}
                                            className="form-control"
                                            placeholder="Enter password"
                                            id="password" />
                                        <div className="text-danger">{this.state.errors.password}</div>
                                        <label htmlFor="password">Confirm Password:</label>
                                        <input
                                            type="password"
                                            name="confirm_password"
                                            value={this.state.input.confirm_password}
                                            onChange={this.handleChange}
                                            className="form-control"
                                            placeholder="Enter confirm password"
                                            id="confirm_password" />
                                        <div className="text-danger">{this.state.errors.confirm_password}</div>
                                        <div className="text-danger">{this.state.errors.match}</div>
                                        <button className="onLogin mt-4" onClick={this.changePasswordInSubmit}>Change Password</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="footer-text text-center mb-2">Build: <span className="text-dark mr-2">{(this.state.Date === "N/A" ? "N/A" : (this.state.Date + ": v" + this.state.Version))}</span> Environment: <span className="text-dark">{this.state.Env}</span></div>
                        <div className="footer-text text-center mb-2">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                        <div className="footer-text text-center">
                            <a href={this.state.PrivacyPolicyURL} rel="noopener noreferrer" target="_blank"  > Privacy Policy</a>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}
