import React, { Component } from 'react';
import { Layout } from './Layout';
import $ from 'jquery';
import Api from '../serviceCall';
import * as pbi from 'powerbi-client';
import { TimeoutCounter } from './TimeoutCounter';
import { isNullOrUndefined } from 'util';

const { models } = pbi;
const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
);

export class Performance extends Component {
    static displayName = Performance.name;
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            Loading: true,
            barChartPendingOrder: [],
            yearlyBarChartOrder: [],
            myOrderSelectedValue: true,
            allOrderSelectedValue: false,
            businessDaysSelectedValue: false,
            byOrderDateSelectedValue: false,
            WRecordsSelectedValue: false,
            CalendarDaysSelectedValue: false,
            ByClosedDateCheckBoxChange: false,
            WOutRecordsSelectedValue: false,
            EteDaysSelectedValue: false,
            AllSelectedValue: false,
            selectedOrderType: "MyOrders",
            selectedRecordType: "",
            selectedFilterType: "",
            toasterredCla: false,
            toaster: "",
            isOwn: false,
            powerBiEmbedUrl: "",
            config : {}
        };
        this.toasterredfun2 = this.toasterredfun2.bind(this);
    }
    componentWillMount() {        
        let currentComponent = this;
        let ctype = localStorage.getItem("PerformanceMetrics");        
        if (!isNullOrUndefined(ctype) && ctype == "false") {
            currentComponent.toasterredfun2("You have no access to view. Contact Administrator.");
            setTimeout(function () { currentComponent.props.history.push('/dashboard'); }, 3000);
        }
        
        this.setState({ Loading: false });
        
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this),
            5000
        );
    }
    componentDidMount() {
        this.getReport();       
    }

    getReport = e => {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        
        let data = {
            "CompanyCode": localStorage.getItem("Companycode")            
        };
        Api.Post('api/placeorder/GetReport', data,
            currentComponent).then(function (result) {
                currentComponent.setState({ Loading: false });
                if (result.statusCode !== 415) {
                    if (result.statusCode === 100) {
                        let config = {
                            type: 'report',
                            tokenType: models.TokenType.Embed,
                            accessToken: result.embedToken,
                            embedUrl: result.embedUrl,
                            id: result.id,
                            permissions: models.Permissions.All,
                            pageView: "fitToWidth",
                            settings: {
                                panes: {
                                    filters: {
                                        visible: true
                                    },
                                    pageNavigation: {
                                        visible: true
                                    }
                                }
                            }
                        };
                        currentComponent.setState({
                            config: config
                        });
                        powerbi.reset(currentComponent.myRef.current);
                        powerbi.embed(currentComponent.myRef.current, config);
                    }
                    else {
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
            });
    }
    render() {
        let landing = "img/ems-logo.gif";
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} alt="process_img" />{" "}
                </span>
                <div
                    className={
                        "toasterred-div " + (this.state.toasterredCla ? "show" : "hide")
                    }
                >
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                    {this.state.toaster}{" "}
                </div>
                <div className="iframeDivHeight"></div>
                <div className="iframeHeight" ref={this.myRef}></div>
               
            </Layout>
        );
    }
}
