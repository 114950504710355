import React, { Component } from 'react';
import { Layout } from "./Layout";
import MaterialTable from "material-table";
import TablePagination from '@material-ui/core/TablePagination';
import 'rc-datepicker/lib/style.css';
import { Link, NavLink } from 'react-router-dom';
import $ from 'jquery';
import Api from '../serviceCall';
import { number } from '@amcharts/amcharts4/core';
import { TimeoutCounter } from './TimeoutCounter';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

export class InboundOrders extends Component {
    static displayName = InboundOrders.name;
    constructor(props) {
        super(props);
        this.state = {
            SeachValue: "",
            sortOrder: 'desc',
            sortColumn: '',
            clickCount: 0,
            currentPage: 0,
            offSet: 0,
            PageLimit: 20,
            totalRecord: 0,
            SelectedFileName: '',
            CompanyCode:'',
            ShowErrorRecordDiv: false,
            Loading: true,
            sourceData: [],
            targetData: [],
            inboundOrderRequests: [],
            totalOrdersReceived: number,
            totalSuccessfulOrder: number,
            totalFailedOrder: number,
            removeAlert: false, removehidden: '', removeDoc: '', toasterCla: false, toasterredCla: false, toaster: '',
            individualNameTableHeaderLabel: "", facilityNameTableHeaderLabel: "", providerNameTableHeaderLabel: "", fileNumberTableHeaderLabel: ""
        };
        this.GetInboundOrderRequests = this.GetInboundOrderRequests.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.handleInboundOrderSearch = this.handleInboundOrderSearch.bind(this);
        this.testName = this.testName.bind(this);
    }

    componentWillMount() {
        let currentComponent = this;
        currentComponent.GetInboundOrderRequests(0,20,'','');
    }
    GetInboundOrderRequests(offSet, dataLimit, fieldName, sortOrder) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        currentComponent.setState({ offSet: offSet, dataLimit: dataLimit, fieldName: fieldName, sortOrder: sortOrder });
        let data = {
            "dataOffSet": offSet, "dataLimit": dataLimit,"fieldName": fieldName, "sortOrder": sortOrder, "SearchValue": currentComponent.state.SeachValue
        };
        Api.Post('api/placeorder/GetInboundOrderRequests', data, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ inboundOrderRequests: result.inboundOrderRequestDetails, Loading: false, removeAlert: false, totalRecord: result.totalRowCount });
            }
            else if (result.statusCode === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                currentComponent.setState({ Loading: false });
            }else {
                currentComponent.setState({ inboundOrderRequests: result.inboundOrderRequestDetails, loading: false, removeAlert: false, totalRecord: 0 });
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ Loading: false });            
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    componentDidMount() {
        this.setState({ loading: false });
        
    }

    getDetailsById(id) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        Api.Get('api/placeorder/GetInboundOrderRequestDetails/' + id, currentComponent).then(function (result) {
            if (result.statusCode === 100) {               
                currentComponent.setState({ CompanyCode: result.companyCode,SelectedFileName: result.selectedFileName, sourceData: result.sourceData, targetData: result.targetData, totalOrdersReceived: result.totalOrdersReceived, totalSuccessfulOrder: result.totalSuccessfulOrder, totalFailedOrder: result.totalFailedOrder, loading: false, removeAlert: false, ShowErrorRecordDiv: true });
            } else {
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    toasterredfun2(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: event });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    
    handleColumnHeaderClick = (column) => {
        if (this.state.inboundOrderRequests.length !== 0) {
            let offSet = (this.state.currentPage) * this.state.PageLimit;

            this.setState(prevState => {
                let newSortOrder;
                if (prevState.sortColumn === column.field) {
                    newSortOrder = prevState.sortOrder === 'asc' ? 'desc' : 'asc';
                } else {
                    newSortOrder = 'asc';
                }


                this.setState({
                    sortOrder: newSortOrder,
                    sortColumn: column.field
                });
            }, () => {
                this.GetInboundOrderRequests(offSet, this.state.PageLimit, column.field, "asc");
            });
        }
    }
    testName(e) {
        return /^[._a-zA-Z0-9&'-\s]+$/g.test(e) && !/\s{2,}/g.test(e);

    }
    handleInboundOrderSearch() {
        let currentComponent = this;
        currentComponent.setState({ currentPage: 0 }, () => { currentComponent.GetInboundOrderRequests(0, this.state.dataLimit, this.state.fieldName, this.state.sortOrder) });
    }
    handleClearSearch() {
        let currentComponent = this;
        currentComponent.setState({ currentPage: 0, totalRecord: 0, SeachValue: "", offSet: 0, dataLimit: 20, fieldName: '', sortOrder: '' }, () => { currentComponent.GetInboundOrderRequests(0, this.state.dataLimit, this.state.fieldName, this.state.sortOrder) });
    }
    handleKeypress(event) {
        let key = event.which || event.key;
        if (key == 13) {
            this.handleInboundOrderSearch();
        }
    }
    handleSearchChange(e) {
        if (e.target.value.length !== 0) {
            if (this.testName(e.target.value)) {
                this.setState({ SeachValue: e.target.value });
            }
        }
        else {
            this.setState({ SeachValue: e.target.value });
        }
    }
    render() {
        let landing = "img/ems-logo.gif";
        let columns = [

            { title: 'File Name', field: 'fileName' },
            { title: 'Total Orders Received', field: 'totalOrdersReceived' },
            /*     { title: 'Total Successful Order', field: 'totalSuccessfulOrder' },*/
            { title: 'Total Failed Order', field: 'totalFailedOrder' },
            { title: 'Created On', field: 'createdOnDateTime' },
            {
                title: 'Action ', field: 'action', disableClick: true, disableSorting: true,
                render: rowData =>
                    <span style={{ "width": "150px", "display": "block" }}>
                        <button className="edit-btnm editActionE hvr-rectangle-out" onClick={() => this.getDetailsById(rowData.id)}><img src="img/edit-icon.png" alt="Loading" /> View</button>
                    </span>

            }

        ];
        let visibleColumns = columns.filter((column) => !column.hidden);
        return (
            <Layout>
                <TimeoutCounter />
                <span id="load" className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} alt="Loading"/>{" "}
                </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card placeholder-div">
                            <div className="card-header bg-white rounded p-1">
                                Requested Order Files
                            </div>
                            <div className="card-body dataTable">
                                <div className="table-responsive dataTable posrel">
                                    <div className="searchoff-x">
                                        <span className="show-title-x">Search</span>
                                    </div>
                                    <div className="searchinput-x">
                                        <input className="form-control" id="SearchOrders" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.SeachValue} onChange={this.handleSearchChange} maxLength={50} placeholder="Search" />

                                    </div>
                                    <div className="searchicon-x">
                                        <span className="search-icon" onClick={this.handleInboundOrderSearch} tabIndex={0} onKeyDown={(event) => {
                                            let key = event.keyCode;
                                            if (key == 13 || key == "13") {
                                                this.handleInboundOrderSearch();
                                            }
                                        }}>
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </div>

                                    <div className="clearsearch-x">
                                        <span
                                            className="clear-icon"
                                            onClick={this.handleClearSearch}
                                            role="button"
                                            tabIndex={0} onKeyDown={(event) => {
                                                let key = event.keyCode;
                                                if (key == 13 || key == "13") {
                                                    this.handleClearSearch();
                                                }
                                            }}>
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </div>
                                <MaterialTable
                                    className="dataTable"
                                    columns={columns}
                                    data={this.state.inboundOrderRequests}
                                    class="mat-paginator-sticky"
                                    title=""
                                    options={{
                                        search: false,
                                        exportButton: false,
                                        /*exportFileName: "Requested Orders",
                                        isLoading: true,
                                        exportAllData: true,*/
                                        paginationType: "stepped",
                                        paging: true,
                                        pageSize: 20,
                                    }}
                                    components={{
                                        Header: (props) => (
                                            <TableHead>
                                                <TableRow>
                                                    {visibleColumns.map((column, index) => (
                                                        <TableCell
                                                            key={column.field}
                                                        >
                                                            {column.disableSorting ? (<span>{column.title}</span>) : (
                                                                <TableSortLabel
                                                                    active={this.state.sortColumn === column.field}
                                                                    direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                    onClick={() => this.handleColumnHeaderClick(column)}
                                                                >
                                                                    {column.title}
                                                                </TableSortLabel>)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                        ),
                                        Pagination: props => (
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[5, 10, 20]}
                                                rowsPerPage={this.state.PageLimit}
                                                count={this.state.totalRecord}
                                                page={this.state.currentPage}
                                                onChangeRowsPerPage={event => {
                                                    props.onChangeRowsPerPage(event);
                                                    this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                    this.GetInboundOrderRequests(0, event.target.value, this.state.sortColumn, this.state.sortOrder)
                                                }}
                                                onChangePage={(event, page) => {
                                                    let ClickedButton = event.nativeEvent.target.innerText;
                                                    if (ClickedButton === "first_page") {
                                                        this.setState({ currentPage: 0 })
                                                        this.GetInboundOrderRequests(0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                    }
                                                    else if (ClickedButton === "chevron_left") {
                                                        this.setState(prevState => {
                                                            this.setState({ currentPage: prevState.currentPage - 1 })
                                                        });                                                        let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                        this.GetInboundOrderRequests(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                    }
                                                    else if (ClickedButton === "chevron_right") {
                                                        this.setState(prevState => {
                                                            this.setState({ currentPage: prevState.currentPage + 1 })
                                                        });                                                        let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                        this.GetInboundOrderRequests(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                    }
                                                    else if (ClickedButton == "last_page") {
                                                        let lp = this.state.totalRecord / this.state.PageLimit;
                                                        lp = Math.ceil(lp) - 1;
                                                        let offSet;
                                                        if (lp === 0) {
                                                            this.setState({ currentPage: 1 })
                                                            offSet = this.state.PageLimit
                                                        }
                                                        else {
                                                            this.setState({ currentPage: lp })
                                                            offSet = lp * this.state.PageLimit
                                                        }
                                                        this.GetInboundOrderRequests(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                    }
                                                    else {
                                                        let offSet;
                                                        if (ClickedButton > this.state.currentPage) {
                                                            this.setState(prevState => {
                                                                this.setState({ currentPage: prevState.currentPage + 1 })
                                                            });
                                                            offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                        }
                                                        else {
                                                            this.setState(prevState => {
                                                                this.setState({ currentPage: prevState.currentPage - 1 })
                                                            });
                                                            offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                        }
                                                        this.GetInboundOrderRequests(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                    }
                                                }}
                                            />
                                        ),
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={this.state.ShowErrorRecordDiv ? "bg-white my-2 p-3 show" : "hide"}>
                    <h3>Selected File Name: {this.state.SelectedFileName}</h3>
                    <div className="row">
                        {/*<div className="col-lg-6">
                            <div className="card placeholder-div">
                                <div className="card-header">
                                    Source Data (XML Received)
                                </div>
                                <div className="card-body dataTable">
                                    <MaterialTable
                                        title=""
                                        className="dataTable"
                                        columns={[

                                            { title: 'TransRefGUID', field: 'transRefGUID' },

                                            { title: 'TransType', field: 'transType' },
                                            { title: 'TransExeDate', field: 'transExeDate', type: 'date' },
                                            { title: 'TransMode ', field: 'transMode' },

                                            { title: 'TrackingID', field: 'trackingID' },
                                            { title: 'ReqCode', field: 'reqCode' },
                                            { title: 'ReqStatus', field: 'reqStatus' },

                                            { title: 'RequirementInfoUniqueID', field: 'requirementInfoUniqueID' },
                                            { title: 'UserLoginName', field: 'userLoginName' },
                                            { title: 'CompanyCode', field: 'companyCode' },

                                            { title: 'LineOfBusiness', field: 'lineOfBusiness' },
                                            { title: 'OrderingOfficeCode', field: 'orderingOfficeCode' },
                                            { title: 'Ssn', field: 'ssn' },

                                            { title: 'PFirstName', field: 'pFirstName' },
                                            { title: 'PLastName', field: 'pLastName' },
                                            { title: 'Gender', field: 'gender' },

                                            { title: 'PDOB', field: 'pDOB' },
                                            { title: 'PAddress', field: 'pAddress' },
                                            { title: 'PCity', field: 'pCity' },


                                            { title: 'State', field: 'state' },
                                            { title: 'PZip', field: 'pZip' },

                                            { title: 'Phone1Type', field: 'phone1Type' },
                                            //{ title: 'Phone1AreaCode', field: 'phone1AreaCode' },
                                            { title: 'Phone1', field: 'phone1' },

                                            { title: 'Phone2Type', field: 'phone2Type' },
                                            //{ title: 'Phone2AreaCode', field: 'phone2AreaCode' },
                                            { title: 'Phone2', field: 'phone2' },

                                            { title: 'Phone3Type', field: 'phone3Type' },
                                            //{ title: 'Phone3AreaCode', field: 'phone3AreaCode' },
                                            { title: 'Phone3', field: 'phone3' },


                                            { title: 'PEmail', field: 'pEmail' },
                                            { title: 'AgentFN', field: 'agentFN' },
                                            { title: 'AgentLN', field: 'agentLN' },

                                            //{ title: 'AgAreaCode', field: 'agAreaCode' },
                                            { title: 'AgPhone', field: 'agPhone' },
                                            { title: 'AgentId', field: 'agentId' },

                                            { title: 'AgentEmail', field: 'agentEmail' },
                                            { title: 'AgencyFullName', field: 'agencyFullName' },
                                            { title: 'AgencyAreaCode', field: 'agencyAreaCode' },
                                            { title: 'AgencyPhone', field: 'agencyPhone' },
                                            { title: 'AgencyEmail', field: 'agencyEmail' },

                                            { title: 'DrFN', field: 'drFN' },
                                            { title: 'DrLN', field: 'drLN' },
                                            { title: 'DrAddress', field: 'drAddress' },
                                            { title: 'DrCity', field: 'drCity' },

                                            { title: 'DrState', field: 'drState' },
                                            { title: 'DrZip', field: 'drZip' },
                                            //{ title: 'DrAreaCode', field: 'drAreaCode' },

                                            { title: 'DrPhone', field: 'drPhone' },
                                            { title: 'FacilityName', field: 'facilityName' },

                                            { title: 'Policy', field: 'policy' },
                                            { title: 'PolicyAmount', field: 'policyAmount' }



                                        ]}
                                        data={this.state.sourceData}
                                        class="mat-paginator-sticky"
                                        title=""
                                        options={{
                                            pageSizeOptions: [50, 100, 500],
                                            pageSize: 50,
                                            filtering: true,
                                            exportButton: true
                                        }}
                                        options={{
                                            exportButton: true,
                                            exportFileName: "SourceData",
                                            isLoading: true,
                                            paginationType: "stepped",
                                            exportAllData: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>*/}
                        <div className="col-lg-12">
                            <div className="card placeholder-div">
                                {/*<div className="card-header">
                                    Target Data (XML Processed)
                                </div>*/}
                                <div className="card-header">
                                    Unprocessed Data
                                </div>
                                <div className="card-body dataTable">
                                    <MaterialTable
                                        title=""
                                        className="dataTable"
                                        columns={[
                                            {
                                                title: 'Action ', field: 'action',
                                                render: rowData => {
                                                    if (rowData.isEditable) {
                                                        return <span style={{ "width": "150px", "display": "block" }}><NavLink tag={Link} activeClassName="main-nav-active" className="main-nav" to={"/place-order?id=" + rowData.id + "&isFromInboundOrder=true"}><span className="edit-btnm editActionE hvr-rectangle-out"><img src="img/edit-icon.png" /> Edit</span></NavLink></span>
                                                    }
                                                }
                                            },
                                            { title: 'TransRefGUID', field: 'transRefGUID' },
                                            { title: 'TransType', field: 'transType' },
                                            { title: 'TrackingID', field: 'trackingID' },
                                            { title: 'ReqCode', field: 'reqCode' },
                                            { title: 'RequirementInfoUniqueID', field: 'requirementInfoUniqueID' },

                                        ]}
                                        data={this.state.targetData}
                                        class="mat-paginator-sticky"
                                        options={{
                                            pageSizeOptions: [50, 100, 500],
                                            pageSize: 50,
                                            filtering: false,                                           
                                            exportButton: false,                                         
                                            paginationType: "stepped",
                                            paging: true,                                            
                                            rowStyle: x => {
                                                if (!x.isSuccess) {
                                                    return {
                                                        backgroundColor: x.colorCode
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-5">
                            <div className="form-row-xm">
                                <div className="col-md-4 pull-left nopadding">
                                    <label className="small mdb-1 pull-right">Records Received</label>
                                </div>
                                <div className="col-md-8 pull-left nopaddingleft">
                                    <input className="form-control" type="text" value={this.state.totalOrdersReceived} onChange={() => { }}/>
                                </div>
                            </div>
                            <div className="form-row-xm">
                                <div className="col-md-4 pull-left nopadding">
                                    <label className="small mdb-1 pull-right">Successfull Records</label>
                                </div>
                                <div className="col-md-8 pull-left nopaddingleft">
                                    <input className="form-control" type="text" value={this.state.totalSuccessfulOrder} onChange={() => { }}/>
                                </div>
                            </div>
                            <div className="form-row-xm">
                                <div className="col-md-4 pull-left nopadding">
                                    <label className="small mdb-1 pull-right">Records with Errors</label>
                                </div>
                                <div className="col-md-8 pull-left nopaddingleft">
                                    <input className="form-control" type="text" value={this.state.totalFailedOrder} onChange={() => { }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1">
                            <ul className="list-unstyled" style={{ fontSize: "14px" }}>
                                <li><span className="status-label label-violet"></span> Auth File Corrupted</li>
                                <li><span className="status-label label-indigo"></span> Error in Facility (Missing or Invalid Facility Info i.e., City | State | Phone)</li>
                                <li><span className="status-label label-blue"></span> Error in Patient Info (Missing or Invalid First Name | Last Name | DOB | SSN | Policy Amount and Number| State | City | Zip)</li>
                                <li><span className="status-label label-green"></span> Error in Facility i.e., Missing or Invalid Zip alone</li>
                                <li><span className="status-label label-yellow"></span> Error in Patient Info (Missing or Invalid Gender)</li>
                                <li><span className="status-label label-orange"></span> Error in Patient Info (Missing or Invalid Dail number | Area Code | Phone Type)</li>
                                <li><span className="status-label label-red"></span> Error in Multiple Combinations of above and Edit Option will not be available for the orders which is missing the GUID's, Error in Ordering Office Information etc.</li>
                                <li className={this.state.CompanyCode.toLowerCase() == 'koc' ? 'show' : 'hide'}><span className="status-label label-light-blue"></span>Order data is not at the expected length of 466 characters.</li>
                                <li className={this.state.CompanyCode.toLowerCase() == 'gwl' ? 'show' : 'hide'}><span className="status-label label-light-blue"></span>Order data is not at the expected length.</li>                               
                            </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

