import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import Checkbox from '@material-ui/core/Checkbox';
import $ from 'jquery';
import Api from '../serviceCall';
import Switch from "react-bootstrap-switch";
import { isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
export class Carrier extends Component {
    static displayName = Carrier.name;
    constructor(props) {
        super(props);
        this.zipRef = React.createRef();
        this.mcpRef = React.createRef();
        this.acpRef = React.createRef();
        this.ccpnRef = React.createRef();
        this.ocpRef = React.createRef();
        this.inputRef = React.createRef();
        this.phn2Ref = React.createRef();
        this.zipdRef = React.createRef();
        this.cmwpRef = React.createRef();
        this.cmcpRef = React.createRef();
        this.CMFaxRef = React.createRef();
        this.AfaxRef = React.createRef();
        this.uwwpRef = React.createRef();
        this.uwcpRef = React.createRef();
        this.uwFaxRef = React.createRef();
        this.state = {
            SelectAllOffices: false,
            selectedOffice: false,
            SelectedRows: [],
            ToDefaultOffice: [],
            selectedOfficeList: [],
            EnableOtherCheckBoxes: false,
            PushToOffices: false,
            PlaceOrderUsers: false,
            ViewStatusUsers: false,
            ViewNotesUsers: false,
            ViewAuthUsers: false,
            ViewResultsUsers: false,
            DisablePushOfficesandUsers: false,
            AssignedOfficeId: "",
            AssignedViewStatusValue: "",
            AssignedViewAuthValue: "",
            AssignedViewResultsValue: "",
            AssignedViewNotesValue: "",
            AssignedPlaceOrderValue: "",
            AssignedOfficeName: "",
            ShowOrderingOfficeListing: true,
            ShowCareerManagerListing: false,
            ShowUnderwriterListing: false,
            ShowUsersAndAgentsListing: false,
            IsAdmin: false,
            HideLegalDiv: true,
            rowList: [],
            AllowViewResults: "",
            AllowPlaceOrder: "",
            AllowViewNotes: "",
            AllowViewStatus: "",
            AllowViewAuth: "",
            AllowOverrideAccess: "",
            CarrierLevelVR: "",
            CarrierLevelPO: "",
            CarrierLevelVN: "",
            CarrierLevelVA: "",
            CarrierLevelVS: "",
            CarrierLevelAOA: "",
            CarrierLevelDCA: "",
            valueCarrierLevelVS: "",
            valueCarrierLevelPO: "",
            valueCarrierLevelVN: "",
            valueCarrierLevelVR: "",
            valueCarrierLevelVA: "",
            DocList: [],
            setPlaceOrder: false,
            setViewNotes: false,
            setViewAuth: false,
            setViewStatus: false,
            setAccessType: "",
            officeId: "",
            UserandOfficeList: [],
            IsHomeOffice: false,
            DisableAllowOverride: false,
            DisableViewResults: false,
            DisablePlaceOrder: false,
            DisableViewNotes: false,
            DisableViewStatus: false,
            DisableViewAuth: false,
            ShowAdminMenu: true,
            HideAdminMenuForCurrentUser: true,
            OfficeList: [],
            NoOfSelectedOffices: 0,
            RequiredFieldsList: [], hideEnoahAdmin: true, showFieldSetup: true, isFieldsEditModalopen: false, SectionId: "", isDefault: false, originalFieldName: "", customFieldName: "", orderIsActiveSelectedValue: "Yes", orderIsRequiredSelectedValue: "Yes", fieldId: "",
            isUserModalopen: false, isOfficeModalopen: false, isAgentModalopen: false, CropNumber: "", CarrierName: "", Carrier: "", Address: "", City: "", UCity: "", State: "", ZipCode: "", MainContactName: "", MainContactEmail: "", MainContactPhone: "",
            AlternateContactName: "", AlternateContactEmail: "", AlternateContactPhone: "", OrderingOfficeInformationList: [], AccessList: [], UserList: [], CMList: [], ObjectArray: [], SelectedValues: [],
            CustomerServiceContactName: "", CustomerServiceContactEmail: "", CustomerServiceContactPhone: "",
            OfficeName: "", OHB: "", OfficeAccountNumber: "", officeCode: "", NumberOfUsers: "", FirstName: "", LastName: "", AgentId: "", AgentEmail: "", Phone1: "", Ext1: "", Phone2: "", Fax: "", Ext2: "", AddressLine1: "", AddressLine2: "", ST: "", Zip: "",
            toasterCla: false, toasterredCla: false, toaster: '', loading: true, StatesList: '', deleteAlert: false, removehidden: '', typehidden: '',
            UserType: "", AccessType: "", PlaceOrder: "", DownloadAccess: "", StatusView: "", AuthView: "", POReadOnly: false, SVReadOnly: false, AVReadOnly: false, DAReadOnly: false, DOReadOnly: false, ATReadOnly: "", isviewModalopen: false,
            OfficeInformationId: "", orderselectValue: "Active", isOfficeEditModalopen: false, isUserandOfficeEditModalopen: false, isCMEditModalopen: false, isEdit: false, Style: "", OrderingOffices: "",
            rCropNumber: false, rCarrierName: false, rAddress: false, rCity: false, rState: false, rOHB: false, rZipCode: false, rMainContactName: false, rMainContactEmail: false, rMainContactPhone: false, rUCity: false,
            rAlternateContactName: false, rAlternateContactEmail: false, rAlternateContactPhone: false, rCustomerServiceContactName: false, rCustomerServiceContactEmail: false, rCustomerServiceContactPhone: false,
            rFirstName: false, rLastName: false, rAgentId: false, rAgentEmail: false, rPhone1: false, rExt1: false, rFax: false, rPhone2: false, rExt2: false, rAddressLine1: false, rAddressLine2: false, rST: false, rZip: false,
            rUserType: false, rAccessType: false, rPlaceOrder: false, rDownloadAccess: false, rStatusView: false, rAuthView: false, rSelectedValues: false, addvalid: false, ofcName: false, ofcAccount: false, AgentUserId: "",
            DefaultOffice: "", rDefaultOffice: false, ofcCode: false, Summarization: false, IpAddressList: [], UWList: [],
            OEmail: "", OPhone: "", rOEmail: false, rOPhone: false,
            CMId: "",
            CMFirstName: "", CMLastName: "", CMEmailAddress: "", CMWorkPhone: "", CMExt: "", CMCellPhone: "", CMFax: "",
            rCMFirstName: false,
            rCMLastName: false,
            rCMEmailAddress: false,
            rCMWorkPhone: false,
            rCMExt: false,
            rCMCellPhone: false,
            rCMFax: false,
            OHBRO: true,
            rAdminMenu: false,
            CarrierManagerFields: false,
            PatientInfoFields: true,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            lblCarrierManagerFields: "Client Manager Fields",
            lblPatientInfoFields: "Patient and Contact Info Fields",
            lblAttorneyInfoFields: "Attorney Info Fields",
            lblAdjusterInfoFields: "Adjuster Info Fields",
            lblFacilityInfoFields: "Facility Info Fields",
            lblAgentInfoFields: "Agent Info Fields",
            CompanyType: "",
            isDDLoaded: false,
            EnableSSOConnection: false,
            rIdentifier: false,
            Identifier: "",
            Identifiervalue: "",
            rIdentifiervalue: false,
            vIdentifiersso: false,
            rIdentifiersso: false,
            Identifiersso: "",
            isClientAdmin: false,
            SelectCustomizeAll: false,
            SelectResultsAll: false,
            SelectPlaceorderAll: false,
            SelectStatusAll: false,
            SelectNotessAll: false,
            SelectAuthAll: false,
            ObjArrayDetails: [],
            AgentListFilterKey: "",
            muiTableKey: 0,
            IsCustomize: false,
            setIsCustomize: false,
            mIdentifierSSO: false,
            hideCHOViewResults: true,
            hideCHOPlaceOrder: true,
            hideCHOViewStatus: true,
            hideCHOViewNote: true,
            hideCHOViewAuth: true,
            CHOViewResultsValue: "Yes",
            CHOPlaceOrderValue: "Yes",
            CHOViewStatusValue: "Yes",
            CHOViewNoteValue: "Yes",
            CHOViewAuthValue: "Yes",
            hideExportSearchResults: false,
            hidePerformanceMetrics: false,
            ExportSearchResultsValue: "Yes",
            PerformanceMetricsValue: "Yes",
            CarrierLevelESR: "",
            CarrierLevelPMA: "",
            valueCarrierLevelESR: "",
            valueCarrierLevelPMA: "",
            EnableMFA: false,
            MFATokenType: "",
            MFATokenExpiryDays: "",
            MFATokenExpiryMin: "",
            MFATokenGenType: "",
            MFAUserwithIP: "mfaipwithuser",
            iseNoahAdmin: false,
            MFATokenTypeList: '',
            MFATokenExpiryDaysList: '',
            MFATokenExpiryMinList: '',
            MFATokenGenTypeList: '',
            rMFATokenLength: false,
            accessLevel_AllOffice: "",
            RHB: "",
            rRHB: false,
            RHBRO: true,
            RetentionPeriodDays: 120,
            ProductionPurgeDays: 365,
            OfflineArchivePurgeDays: 730,
            iseNoahSystemAdmin: false,
            rRetentionPeriodDays: false,
            rProductionPurgeDays: false,
            rOfflineArchivePurgeDays: false,
            hideEnoahSystemAdmin: false,
            rpassUpdateDays: false,
            DaysForEnforcePass: 0,
            rRemPasswordCount: false,
            RememberPassCount: 0,
            rInactiveDays: false,
            InactiveDays: 0,
            IpAddressListing: false,
            ipAddressChange: false,
            rFromIpAddress: false,
            FromIpAddress: "",
            rToIpAddress: false,
            ToIpAddress: "",
            passNeverExpires: false,
            IsPassNeverExpires: false,
            DisInactive: false,
            isDelete: false,
            IsNeverInactive: false,
            DisCheckPassNeverExpires: false,
            DisPreviousPassword: false,
            IPAddressFiltering: false,
            DisCheckInactive: false,
            unlockalert: false,
            unlockid: "",
            inactivealert: false,
            inactiveid: "",
            SelectedRowsForIp: [],
            adminAlert: false,
            UserTypeChangeAlert: false,
            UserId: "",
            AllowCSRMessaging: false,
            APS: false,
            Summary: false,
            oSummary: false,
            oAPS: false,
            oAddAPS: false,
            oAddSummary: false,
            clientSummary: false,
            summaryofcAccount: "",
            SummaryOfficeAccountNumber: "",

            UnderwriterFields: false,
            orderSummaryIsRequiredSelectedValue: "Yes",
            CustomerServiceContactNameforSummary: "", rCustomerServiceContactNameforSummary: false, CustomerServiceContactEmailforSummary: "", rCustomerServiceContactEmailforSummary: false, rCustomerServiceContactPhoneforSummary: false, CustomerServiceContactPhoneforSummary: "", UWId: "",
            UWFirstName: "", UWLastName: "", UWEmailAddress: "", UWWorkPhone: "", UWExt: "", UWCellPhone: "", UWFax: "",
            rUWFirstName: false,
            rUWLastName: false,
            rUWEmailAddress: false,
            rUWWorkPhone: false,
            rUWExt: false,
            rUWCellPhone: false,
            rUWFax: false,
            EnableOfficebyAccountNum: false,
            SummaryCropNumber: "", rSummaryCropNumber: false
        }
        this.style1 = {
            searchBox: {
                "border-bottom": "1px solid black"
            }
        }; this.style2 = {
            searchBox: {
                "border-bottom": "1px solid red"
            }
        };
        this.setListValueintoLabels = this.setListValueintoLabels.bind(this);
        this.EnableDisableAccessPermissions = this.EnableDisableAccessPermissions.bind(this);
        this.handleallowViewResultsChange = this.handleallowViewResultsChange.bind(this);
        this.handleallowPlaceOrderChange = this.handleallowPlaceOrderChange.bind(this);
        this.handleallowViewNotesChange = this.handleallowViewNotesChange.bind(this);
        this.handleallowViewAuthChange = this.handleallowViewAuthChange.bind(this);
        this.handleallowViewStatusChange = this.handleallowViewStatusChange.bind(this);
        this.handleallowOverrideAccessChange = this.handleallowOverrideAccessChange.bind(this);
        this.handleallowCarrierLevelAOAChange = this.handleallowCarrierLevelAOAChange.bind(this);
        this.handleallowCarrierLevelPOChange = this.handleallowCarrierLevelPOChange.bind(this);
        this.handleallowCarrierLevelVNChange = this.handleallowCarrierLevelVNChange.bind(this);
        this.handleallowCarrierLevelVAChange = this.handleallowCarrierLevelVAChange.bind(this);
        this.handleallowCarrierLevelVSChange = this.handleallowCarrierLevelVSChange.bind(this);
        this.handleallowCarrierLevelVRChange = this.handleallowCarrierLevelVRChange.bind(this);
        this.handleallowCarrierLevelDCAChange = this.handleallowCarrierLevelDCAChange.bind(this);
        this.state.Style = this.style1;
        this.renderOfficeListinGrid = this.renderOfficeListinGrid.bind(this);
        this.handleCustomFieldNameChange = this.handleCustomFieldNameChange.bind(this);
        this.onClickopenFieldEditPopup = this.onClickopenFieldEditPopup.bind(this);
        this.onClickopenOfficeForUserEditPopup = this.onClickopenOfficeForUserEditPopup.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.handleIsRequiredChange = this.handleIsRequiredChange.bind(this);
        this.handleUpdateFieldInformation = this.handleUpdateFieldInformation.bind(this);
        this.handleUpdateOfficesForUser_New = this.handleUpdateOfficesForUser_New.bind(this);
        this.handleAddOfficeInformation = this.handleAddOfficeInformation.bind(this);
        this.handleUpdateOfficeInformation = this.handleUpdateOfficeInformation.bind(this);
        this.handleOfficeNameChange = this.handleOfficeNameChange.bind(this);
        this.handleAssignedOfficeNameChange = this.handleAssignedOfficeNameChange.bind(this);
        this.handleOfficeAccountChange = this.handleOfficeAccountChange.bind(this);
        this.handleNumberOfUsersChange = this.handleNumberOfUsersChange.bind(this);
        this.handleAddAgentUser = this.handleAddAgentUser.bind(this);
        this.handleSubmitCompanyDetails = this.handleSubmitCompanyDetails.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleAgentEmailChange = this.handleAgentEmailChange.bind(this);
        this.handlerFaxhange = this.handlerFaxhange.bind(this);
        this.handlePhone1Change = this.handlePhone1Change.bind(this);
        this.handleExt1Change = this.handleExt1Change.bind(this);
        this.handlePhone2Change = this.handlePhone2Change.bind(this);
        this.handleExt2Change = this.handleExt2Change.bind(this);
        this.handleOHBChange = this.handleOHBChange.bind(this);
        this.handleAddressLine1Change = this.handleAddressLine1Change.bind(this);
        this.handleAddressLine2Change = this.handleAddressLine2Change.bind(this);
        this.handleUCityChange = this.handleUCityChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleSTChange = this.handleSTChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleCropNumberChange = this.handleCropNumberChange.bind(this);
        this.handleCarrierNameChange = this.handleCarrierNameChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
        this.handleMainContactNameChange = this.handleMainContactNameChange.bind(this);
        this.handleMainContactEmailChange = this.handleMainContactEmailChange.bind(this);
        this.handleAlternateContactPhoneChange = this.handleAlternateContactPhoneChange.bind(this);
        this.handleAlternateContactNameChange = this.handleAlternateContactNameChange.bind(this);
        this.handleAlternateContactEmailChange = this.handleAlternateContactEmailChange.bind(this);
        this.handleCustomerServiceContactNameChange = this.handleCustomerServiceContactNameChange.bind(this);
        this.handleCustomerServiceContactEmailChange = this.handleCustomerServiceContactEmailChange.bind(this);
        this.handleCustomerServiceContactPhoneChange = this.handleCustomerServiceContactPhoneChange.bind(this);
        this.handleAIdChange = this.handleAIdChange.bind(this);
        this.handleStatusViewChange = this.handleStatusViewChange.bind(this);
        this.handleAuthViewChange = this.handleAuthViewChange.bind(this);
        this.handleDownloadAccessChange = this.handleDownloadAccessChange.bind(this);
        this.handleShowAdminMenuChange = this.handleShowAdminMenuChange.bind(this);
        this.handlePlaceOrderChange = this.handlePlaceOrderChange.bind(this);
        this.handleAccessTypeChange = this.handleAccessTypeChange.bind(this);
        this.handleUserTypeChange = this.handleUserTypeChange.bind(this);
        this.onClickopenUserPopup = this.onClickopenUserPopup.bind(this);
        this.onClickopenOfficePopup = this.onClickopenOfficePopup.bind(this);
        this.onClickopenAgentPopup = this.onClickopenAgentPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.closePopupUserOffice = this.closePopupUserOffice.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.toasterfun3 = this.toasterfun3.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.handleMainContactPhoneChange = this.handleMainContactPhoneChange.bind(this);
        this.handledeletebttn = this.handledeletebttn.bind(this);
        this.handleOpendeletebttn = this.handleOpendeletebttn.bind(this);
        this.openEditPopup = this.openEditPopup.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.handlersaveOpenbttn = this.handlersaveOpenbttn.bind(this);
        this.isviewModalopen = this.isviewModalopen.bind(this);
        this.onClickclosePopup = this.onClickclosePopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditAgentUser = this.handleEditAgentUser.bind(this);
        this.testAlphaNumeric = this.testAlphaNumeric.bind(this);
        this.testEXT = this.testEXT.bind(this);
        this.testEmail = this.testEmail.bind(this);
        this.testAlphaNumericwithSpaceForEmail = this.testAlphaNumericwithSpaceForEmail.bind(this);
        this.testPhone = this.testPhone.bind(this);
        this.testZip = this.testZip.bind(this);
        this.testZip5 = this.testZip5.bind(this);
        this.testAlphawithSpace = this.testAlphawithSpace.bind(this);
        this.testAlphawithSpaceforCity = this.testAlphawithSpaceforCity.bind(this);
        this.testAlphaNumericwithSpaceForName = this.testAlphaNumericwithSpaceForName.bind(this);
        this.testAlphaNumericwithSpaceForCarrier = this.testAlphaNumericwithSpaceForCarrier.bind(this);
        this.testAlphaNumericwithSpaceForEmail = this.testAlphaNumericwithSpaceForEmail.bind(this);
        this.testAlphaNumericwithSpaceForEmailNew = this.testAlphaNumericwithSpaceForEmailNew.bind(this);
        this.testEmailNew = this.testEmailNew.bind(this);
        this.handleOnRemove = this.handleOnRemove.bind(this);
        this.handleDOChange = this.handleDOChange.bind(this);
        this.UnlockUser = this.UnlockUser.bind(this);
        this.SendConfirmation = this.SendConfirmation.bind(this);
        this.handleOfficeCodeChange = this.handleOfficeCodeChange.bind(this);
        this.handlePushtoOfficesandUsers = this.handlePushtoOfficesandUsers.bind(this);
        this.handlePlaceOrderForUsers = this.handlePlaceOrderForUsers.bind(this);
        this.handleViewStatusForUsers = this.handleViewStatusForUsers.bind(this);
        this.handleViewNotesForUsers = this.handleViewNotesForUsers.bind(this);
        this.handleViewAuthForUsers = this.handleViewAuthForUsers.bind(this);
        this.handleViewResultsForUsers = this.handleViewResultsForUsers.bind(this);

        this.handlePlaceOrderForAllOffices = this.handlePlaceOrderForAllOffices.bind(this);
        this.handleViewStatusForAllOffices = this.handleViewStatusForAllOffices.bind(this);
        this.handleViewNotesForAllOffices = this.handleViewNotesForAllOffices.bind(this);
        this.handleViewAuthForAllOffices = this.handleViewAuthForAllOffices.bind(this);
        this.handleViewResultsForAllOffices = this.handleViewResultsForAllOffices.bind(this);
        this.handleCustomizeForAllOffices = this.handleCustomizeForAllOffices.bind(this);
        this.handleSelectAllForAllOffices = this.handleSelectAllForAllOffices.bind(this);

        this.handleCustomizeForUsers = this.handleCustomizeForUsers.bind(this);
        this.handleSelectAllForUsers = this.handleSelectAllForUsers.bind(this);
        this.handleOemailChange = this.handleOemailChange.bind(this);
        this.handleOPhnChange = this.handleOPhnChange.bind(this);
        this.handleCMFirstNameChange = this.handleCMFirstNameChange.bind(this);
        this.handleCMLastNameChange = this.handleCMLastNameChange.bind(this);
        this.handleCMEmailAddressChange = this.handleCMEmailAddressChange.bind(this);
        this.handleCMWorkPhoneChange = this.handleCMWorkPhoneChange.bind(this);
        this.handleCMExtChange = this.handleCMExtChange.bind(this);
        this.handleCMCellPhoneChange = this.handleCMCellPhoneChange.bind(this);
        this.handleCMFaxhange = this.handleCMFaxhange.bind(this);
        this.handleUpdateCMInformation = this.handleUpdateCMInformation.bind(this);
        this.handleOpenCMdeletebttn = this.handleOpenCMdeletebttn.bind(this);
        this.ShowCarrierManagerFields = this.ShowCarrierManagerFields.bind(this);
        this.ShowPatientInfoFields = this.ShowPatientInfoFields.bind(this);
        this.ShowAttorneyInfoFields = this.ShowAttorneyInfoFields.bind(this);
        this.ShowAdjusterInfoFields = this.ShowAdjusterInfoFields.bind(this);
        this.ShowFacilityInfoFields = this.ShowFacilityInfoFields.bind(this);
        this.ShowAgentInfoFields = this.ShowAgentInfoFields.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.HideeNoahAdminMenu = this.HideeNoahAdminMenu.bind(this);
        this.handleblurAgentEmailChange = this.handleblurAgentEmailChange.bind(this);
        this.handleblurOemailChange = this.handleblurOemailChange.bind(this);
        this.handleblurCMEmailAddressChange = this.handleblurCMEmailAddressChange.bind(this);
        this.handleblurMainContactEmailChange = this.handleblurMainContactEmailChange.bind(this);
        this.handleblurAlternateContactEmailChange = this.handleblurAlternateContactEmailChange.bind(this);
        this.handleblurCustomerServiceContactEmailChange = this.handleblurCustomerServiceContactEmailChange.bind(this);
        this.handleResetSelectOfc = this.handleResetSelectOfc.bind(this);
        this.handleEnableSSOConnectionSwitch = this.handleEnableSSOConnectionSwitch.bind(this);
        this.handlIdentifiervalueChange = this.handlIdentifiervalueChange.bind(this);
        this.handlIdentifierssovalueChange = this.handlIdentifierssovalueChange.bind(this);
        this.resetAccessLevel = this.resetAccessLevel.bind(this);
        this.AgentListFilterSearch = this.AgentListFilterSearch.bind(this);
        this.renderOfficeListinGridNonEditable = this.renderOfficeListinGridNonEditable.bind(this);
        this.handleCHOViewResultsChange = this.handleCHOViewResultsChange.bind(this);
        this.handleCHOPlaceOrderChange = this.handleCHOPlaceOrderChange.bind(this);
        this.handleCHOViewStatusChange = this.handleCHOViewStatusChange.bind(this);
        this.handleCHOViewNotesChange = this.handleCHOViewNotesChange.bind(this);
        this.handleCHOViewAuthChange = this.handleCHOViewAuthChange.bind(this);
        this.handleExportSearchResultsChange = this.handleExportSearchResultsChange.bind(this);
        this.handlePerformanceMetricsChange = this.handlePerformanceMetricsChange.bind(this);
        this.handleallowCarrierLevelPMAChange = this.handleallowCarrierLevelPMAChange.bind(this);
        this.handleallowCarrierLevelESRChange = this.handleallowCarrierLevelESRChange.bind(this);
        this.handleEnableMFASwitch = this.handleEnableMFASwitch.bind(this);
        this.handleMFATokenTypeChange = this.handleMFATokenTypeChange.bind(this);
        this.handleMFATokenExpiryDaysChange = this.handleMFATokenExpiryDaysChange.bind(this);
        this.handleMFATokenExpiryMinChange = this.handleMFATokenExpiryMinChange.bind(this);
        this.handleMFATokenGenTypeChange = this.handleMFATokenGenTypeChange.bind(this);
        this.handleMFATokenLengthChange = this.handleMFATokenLengthChange.bind(this);
        this.handleMFAIpWithUserChange = this.handleMFAIpWithUserChange.bind(this);
        this.handleAccessTypeChangeForAllOffice = this.handleAccessTypeChangeForAllOffice.bind(this);
        this.handleRHBChange = this.handleRHBChange.bind(this);
        this.handleRetentionPeriodDaysChange = this.handleRetentionPeriodDaysChange.bind(this);
        this.handleOfflineArchivePurgeDaysChange = this.handleOfflineArchivePurgeDaysChange.bind(this);
        this.handleProductionPurgeDaysChange = this.handleProductionPurgeDaysChange.bind(this);
        this.handlePassUpdateDaysChange = this.handlePassUpdateDaysChange.bind(this);
        this.handleRemPasswordDaysChange = this.handleRemPasswordDaysChange.bind(this);
        this.handleInactiveDaysChange = this.handleInactiveDaysChange.bind(this);
        this.handleIpAddressChange = this.handleIpAddressChange.bind(this);
        this.handleFromIpAddressChange = this.handleFromIpAddressChange.bind(this);
        this.handleToIpAddressChange = this.handleToIpAddressChange.bind(this);
        this.handleAddIpAddress = this.handleAddIpAddress.bind(this);
        this.handlePasswordNeverExpires = this.handlePasswordNeverExpires.bind(this);
        this.handleNeverInactive = this.handleNeverInactive.bind(this);
        this.handleDeleteIpAddress = this.handleDeleteIpAddress.bind(this);
        this.handleIPdeletebttn = this.handleIPdeletebttn.bind(this);
        this.UnlockInActiveUser = this.UnlockInActiveUser.bind(this);
        this.handleOpenUnlockAlert = this.handleOpenUnlockAlert.bind(this);
        this.handleOpenInactivityAlert = this.handleOpenInactivityAlert.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.Onclickisadminpopup = this.Onclickisadminpopup.bind(this);
        this.CheckForAdmin = this.CheckForAdmin.bind(this);
        this.onBlurAddressChange = this.onBlurAddressChange.bind(this);
        this.handleallowCarrierLeverCSRMessagingChange = this.handleallowCarrierLeverCSRMessagingChange.bind(this);
        this.handleSummaryCheck = this.handleSummaryCheck.bind(this);
        this.handleAPSCheck = this.handleAPSCheck.bind(this);
        this.handleOfficeAPSCheck = this.handleOfficeAPSCheck.bind(this);
        this.handleOfficeSummaryCheck = this.handleOfficeSummaryCheck.bind(this);
        this.handleSummaryOfficeAccountChange = this.handleSummaryOfficeAccountChange.bind(this);
        this.ShowUnderwriterFields = this.ShowUnderwriterFields.bind(this);
        this.handleSummaryIsRequiredChange = this.handleSummaryIsRequiredChange.bind(this);
        this.handleCustomerServiceContactNameChangeforSummary = this.handleCustomerServiceContactNameChangeforSummary.bind(this);
        this.handleCustomerServiceContactEmailChangeforSummary = this.handleCustomerServiceContactEmailChangeforSummary.bind(this);
        this.handleblurCustomerServiceContactEmailChangeforSummary = this.handleblurCustomerServiceContactEmailChangeforSummary.bind(this);
        this.handleCustomerServiceContactPhoneChangeforSummary = this.handleCustomerServiceContactPhoneChangeforSummary.bind(this);
        this.handleSummaryCropNumberChange = this.handleSummaryCropNumberChange.bind(this);
        this.handleblurUWEmailAddressChange = this.handleblurUWEmailAddressChange.bind(this);
        this.handleUWFirstNameChange = this.handleUWFirstNameChange.bind(this);
        this.handleUWLastNameChange = this.handleUWLastNameChange.bind(this);
        this.handleUWEmailAddressChange = this.handleUWEmailAddressChange.bind(this);
        this.handleUWWorkPhoneChange = this.handleUWWorkPhoneChange.bind(this);
        this.handleUWExtChange = this.handleUWExtChange.bind(this);
        this.handleUWCellPhoneChange = this.handleUWCellPhoneChange.bind(this);
        this.handleUWFaxChange = this.handleUWFaxChange.bind(this);
        this.handleUpdateUWInformation = this.handleUpdateUWInformation.bind(this);
        this.handleEnableOfficeAccountSwitch = this.handleEnableOfficeAccountSwitch.bind(this);

    };
    handleEnableOfficeAccountSwitch(elem, state) {
        this.setState({
            EnableOfficebyAccountNum: state
        });
    }
    handleSummaryCheck(e) {
        this.setState({ Summary: e.target.checked });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ Summary: true });
        }
        else {
            this.setState({ Summary: false });
        }
    }
    handleAPSCheck(e) {
        this.setState({ APS: e.target.checked });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ APS: true });
        }
        else {
            this.setState({ APS: false });
        }
    }
    handleOfficeSummaryCheck(e) {
        this.setState({ oSummary: e.target.checked });
    }
    handleOfficeAPSCheck(e) {
        this.setState({ oAPS: e.target.checked });
    }

    handleSummaryOfficeAccountChange(e) {
        this.setState({ summaryofcAccount: false });
        if (e.target.value.length === 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ SummaryOfficeAccountNumber: e.target.value });
        }
    }
    handleCloseAlert() {
        this.setState({
            unlockalert: false,
            unlockid: "",
            inactivealert: false,
            inactiveid: "",
        });
    }

    handleIpAddressChange(elem, state) {
        if (state === true) {
            this.setState({ IpAddressListing: true, ipAddressChange: true });
        }
        else {
            this.setState({ IpAddressListing: false, ipAddressChange: false });
        }
    }
    UnlockInActiveUser(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        let userType = localStorage.getItem('UserType');
        Api.Post('api/login/UnlockInActivity', { "AgentUserId": e, "UserType": userType }, currentComponent).then(function (result) {
            if (result.statusMessage === "Success") {
                currentComponent.setState({
                    UserList: result.userList
                });
                currentComponent.toasterfun2("InActive User has been unlocked successfully.");
            }
            else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                currentComponent.setState({ loading: false });
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                currentComponent.setState({ adminAlert: true });
            }
            else {
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ inactivealert: false, inactiveid: "" });
            currentComponent.setState({ loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

    }


    testNumericForIpAddressLength(e) {
        let regexNumericForIpAddressLength = false;
        if ((/[1-2]/, /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/.test(e))) {

            if (/^[.0-9]*$/.test(e)) {
                regexNumericForIpAddressLength = true;
            }
        }
        return regexNumericForIpAddressLength;
    }

    handleFromIpAddressChange(e) {
        if (this.ValidateIPaddress(e.target.value)) {
            this.setState({ FromIpAddress: e.target.value });
        }
        this.setState({ rFroIpAddress: false });

        if (e.target.value.length != 0) {
            if (this.testNumericForIpAddressLength(e.target.value)) {
                this.setState({ FromIpAddress: e.target.value });
            }
        } else {
            this.setState({ FromIpAddress: e.target.value });
        }
    }
    handleToIpAddressChange(e) {

        if (this.ValidateIPaddress(e.target.value)) {
            this.setState({ ToIpAddress: e.target.value });
        }
        this.setState({ rToIpAddress: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForIpAddressLength(e.target.value)) {
                this.setState({ ToIpAddress: e.target.value });
            }
        } else {
            this.setState({ ToIpAddress: e.target.value });
        }
    }

    handlePassUpdateDaysChange(e) {
        this.setState({ rpassUpdateDays: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForThreeDigitTokenLength(e.target.value)) {
                this.setState({ DaysForEnforcePass: e.target.value });
            }
        } else {
            this.setState({ DaysForEnforcePass: e.target.value });
        }
    }
    handleRemPasswordDaysChange(e) {
        this.setState({ rRemPasswordCount: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForTwoDigitTokenLength(e.target.value)) {
                this.setState({ RememberPassCount: e.target.value });
            }
        } else {
            this.setState({ RememberPassCount: e.target.value });
        }
    }
    testNumericForTwoDigitTokenLength(e) {
        let regexpassfortwodigittoken = false;
        if (/^\d{0,2}$/g.test(e)) {
            regexpassfortwodigittoken = true;
        }
        return regexpassfortwodigittoken;
    }
    testNumericForThreeDigitTokenLength(e) {
        let regexpassforthreedigittoken = false;
        if (/^\d{0,3}$/g.test(e)) {
            regexpassforthreedigittoken = true;
        }
        return regexpassforthreedigittoken;
    }
    handleInactiveDaysChange(e) {
        this.setState({ rInactiveDays: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForThreeDigitTokenLength(e.target.value)) {
                this.setState({ InactiveDays: e.target.value });
            }
        } else {
            this.setState({ InactiveDays: e.target.value });
        }
    }


    handleIpAddressChangeOnLoad(elem, state) {
        if (state === true) {
            this.setState({ IpAddressListing: true, ipAddressChange: true });
        }
        else {
            this.setState({ IpAddressListing: false, ipAddressChange: false });
        }
    }
    handleDeleteIpAddress(e) {
        let isvalid = true;
        let currentComponent = this;

        if (this.state.SelectedRowsForIp.length === 0) {
            isvalid = false;
        }
        if (isvalid) {

            let userType = localStorage.getItem('UserType');
            currentComponent.setState({ loading: true });
            Api.Post('api/placeorder/DeleteIpAddress', { "FacilityIdList": this.state.SelectedRowsForIp, "UserType": userType }, currentComponent).then(function (result) {

                if (result.statusCode === 100) {

                    currentComponent.toasterfun2("IP Addresses has been Deleted successfully.");
                    currentComponent.setState({ SelectedRowsForIp: [] });
                    currentComponent.GetCarrierDetails();

                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else if (result.statusCode === 413) {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ Loading: false });
            }).catch(function (error) {
                currentComponent.setState({ Loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
        else {
            currentComponent.toasterredfun2("Please select the Ip Address for deletion");
        }
    }
    ValidateIPaddress(e) {
        let Ipaddressvalidationpassed = false;
        if (/^(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(e)) {
            Ipaddressvalidationpassed = true;
        }
        return Ipaddressvalidationpassed;
    }

    handleAddIpAddress(e) {
        let isvalid = true;
        if (this.state.FromIpAddress !== "" || this.state.FromIpAddress.length !== 0) {
            let FromIP = this.ValidateIPaddress(this.state.FromIpAddress);
            if (!FromIP) {
                isvalid = false;
                this.setState({ rFroIpAddress: true });
            }
        }
        if (this.state.ToIpAddress === "" || this.state.ToIpAddress.length === 0) {
            isvalid = false;
            this.setState({ rToIpAddress: true });
        }

        if (this.state.ToIpAddress !== "") {
            let ToIP = this.ValidateIPaddress(this.state.ToIpAddress);
            if (!ToIP) {
                isvalid = false;
                this.setState({ rToIpAddress: true });
            }
        }
        if (isvalid) {
            let currentComponent = this;
            let userType = localStorage.getItem('UserType');
            let data = {
                "FromIpAddress": currentComponent.state.FromIpAddress, "ToIpAddress": currentComponent.state.ToIpAddress, "IsEnableIP": currentComponent.state.ipAddressChange, "UserType": userType
            };
            currentComponent.setState({ loading: true });
            Api.Post('api/placeorder/AddIpAddress', data, currentComponent).then(function (result) {
                if (result.statusMessage === "Success") {
                    currentComponent.GetCarrierDetails();
                    currentComponent.state.FromIpAddress = "";
                    currentComponent.state.ToIpAddress = "";
                    currentComponent.toasterfun2("IP Address have been added successfully");
                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });

                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
    }
    handleNeverInactive(e) {
        this.setState({
            IsNeverInactive: e.target.checked,
        });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ DisInactive: true })
        }
        else {
            this.setState({ DisInactive: false })
        }
    }
    handlePasswordNeverExpires(e) {
        this.setState({
            IsPassNeverExpires: e.target.checked,
        });
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ passNeverExpires: true })
        }
        else {
            this.setState({ passNeverExpires: false })
        }
    }
    setListValueintoLabels() {
        let currentComponent = this;
        let val = this.state.SelectedValues.length;
        currentComponent.setState({ OfficeList: this.state.SelectedValues, NoofSelectedOffices: val });
    }
    onClickopenOfficeForUserEditPopup = (rowData) => {
        this.setState({ isAgentModalopen: false, isOfficeForUserModalopen: true });
        this.setState({ AssignedOfficeId: rowData.officeId, AssignedOfficeName: rowData.officeName, AssignedPlaceOrderValue: (rowData.placeordervalue == "True" || rowData.placeordervalue == "true" || rowData.placeordervalue == "Yes" || rowData.placeordervalue === true ? "Yes" : "No"), AssignedViewStatusValue: (rowData.viewstatusvalue == "True" || rowData.viewstatusvalue == "true" || rowData.viewstatusvalue == "Yes" || rowData.viewstatusvalue === true ? "Yes" : "No"), AssignedViewResultsValue: (rowData.viewresultsvalue == "True" || rowData.viewresultsvalue == "true" || rowData.viewresultsvalue == "Yes" || rowData.viewresultsvalue === true ? "Yes" : "No"), AssignedViewNotesValue: (rowData.viewnotesvalue == "True" || rowData.viewnotesvalue == "true" || rowData.viewnotesvalue == "Yes" || rowData.viewnotesvalue === true ? "Yes" : "No"), AssignedViewAuthValue: (rowData.viewAuthvalue == "True" || rowData.viewAuthvalue == "true" || rowData.viewAuthvalue == "Yes" || rowData.viewAuthvalue === true ? "Yes" : "No") });
    }

    handleUpdateOfficesForUser_New() {
        let isvalid = true;
        let currentComponent = this;
        if (this.state.AssignedOfficeName === null || this.state.AssignedOfficeName.trim().length === 0) {
            this.setState({ pAssignedOfficeName: true });
            isvalid = false;
        }
        else {
            this.setState({ pAssignedOfficeName: false });
        }

        if (isvalid) {
            this.setState({ loading: true });
            let AssignedoffName = currentComponent.state.AssignedOfficeName;
            let FieldId = currentComponent.state.AssignedOfficeId;
            let OffplaceOrder = currentComponent.state.AssignedPlaceOrderValue;
            let OffviewNotes = currentComponent.state.AssignedViewNotesValue;
            let OffviewResults = currentComponent.state.AssignedViewResultsValue;
            let OffviewStatus = currentComponent.state.AssignedViewStatusValue;
            let OffviewAuth = currentComponent.state.AssignedViewAuthValue;
            for (const row of currentComponent.state.rowList) {
                if (row.officeId === FieldId) {
                    row.officeName = AssignedoffName;
                    row.placeordervalue = OffplaceOrder === "Yes";
                    row.viewstatusvalue = OffviewResults === "Yes";
                    row.viewnotesvalue = OffviewNotes === "Yes";
                    row.viewresultsvalue = OffviewStatus === "Yes";
                    row.viewauthvalue = OffviewAuth === "Yes";
                }
            }

            currentComponent.setState({ isOfficeForUserModalopen: false, isAgentModalopen: true });
            currentComponent.renderOfficeListinGrid();
            currentComponent.setState({ loading: false });
        }
    }
    //PE-476 Select all
    AgentListFilterSearch(e) {
        this.setState(prevState => {
            let selectalloffice = prevState.SelectAllOffices;
            if (e === '') {
                selectalloffice = false;
            }

            let data = [...prevState.ObjectArray];
            let SelectedRows = prevState.SelectedRows;

            if (e && SelectedRows.length) {
                data = data.filter(function (item) {
                    return item.key.toUpperCase().indexOf(e.toUpperCase()) > -1;
                });

                selectalloffice = data.every(function (item) {
                    return SelectedRows.indexOf(item) > -1;
                });
            }

            return {
                AgentListFilterKey: e,
                SelectAllOffices: selectalloffice
            };
        });
    }
    renderOfficeListinGrid() {
        return (
            <div className="table-responsive dataTable unsubmitted  eport-ml selectOption">
                <div className="searchoff-x-sea">
                    <span className="show-title-x">Search</span>
                </div>
                <MaterialTable
                    key={this.state.muiTableKey}
                    title=""
                    className="table data-table dataTable table-bordered"
                    onSearchChange={(A, B, C) => this.AgentListFilterSearch(A, B, C)}

                    columns={[
                        {
                            title: <span> <input type="checkbox" checked={this.state.SelectAllOffices} onChange={(e) => this.handleSelectAllForAllOffices(e)} /> Select All </span>, sorting: false, field: 'IsSelectAll', render: rowData => (<div>
                                <Checkbox
                                    checked={rowData.selectedOfc === "True" || rowData.selectedOfc === "true" || rowData.selectedOfc === "Yes" || rowData.selectedOfc === true}
                                    name="isSelectAll"
                                    onChange={(e) => this.handleSelectAllForUsers(e, rowData)}
                                    value={rowData.SelectAll}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>)
                        },
                        {
                            title: 'Office Name', field: 'key'
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.DisableAllowOverride ? "" : "disabled")} checked={this.state.SelectCustomizeAll} onChange={(e) => this.handleCustomizeForAllOffices(e)} /> Customize </span>, sorting: false, field: 'IsCustomized', render: rowData => (<div>
                                <Checkbox
                                    checked={rowData.isCustomize === "True" || rowData.isCustomize === "true" || rowData.isCustomize === "Yes" || rowData.isCustomize === true}
                                    name="isCustomized"
                                    onChange={(e) => this.handleCustomizeForUsers(e, rowData)}
                                    value={this.state.setIsCustomize}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!this.state.DisableAllowOverride)}
                                />
                            </div>)
                        },
                        {
                            title: <span>
                                Access Type <br />
                                <div><input
                                    type="radio"
                                    value="Own"
                                    checked={this.state.accessLevel_AllOffice === "Own"}
                                    onChange={(e) => this.handleAccessTypeChangeForAllOffice(e)}
                                    disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")}
                                />Own Orders</div>
                                <div><input
                                    type="radio"
                                    value="All"
                                    checked={this.state.accessLevel_AllOffice === "All"}
                                    onChange={(e) => this.handleAccessTypeChangeForAllOffice(e)}
                                    disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")}
                                />All Orders</div>
                            </span>, sorting: false, field: 'IsCustomized', render: rowData => (<div>
                                <div><input
                                    type="radio"
                                    value="Own"
                                    checked={rowData.accessLevel === "Own" || isNullOrUndefined(rowData.accessLevel)}
                                    onChange={(e) => this.handleAccessTypeChange(e, rowData)}
                                />Own Orders</div>
                                <div><input
                                    type="radio"
                                    value="All"
                                    checked={rowData.accessLevel === "All"}
                                    onChange={(e) => this.handleAccessTypeChange(e, rowData)}
                                />All Orders</div>
                            </div>)
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} checked={this.state.SelectResultsAll} onChange={(e) => this.handleViewResultsForAllOffices(e)} /> View Results </span>, sorting: false, field: "vr", render: rowData => (
                                <Checkbox
                                    checked={rowData.vr === "True" || rowData.vr === "true" || rowData.vr === "Yes" || rowData.vr === true}
                                    name="isViewResultsForUsers"
                                    onChange={(e) => this.handleViewResultsForUsers(e, rowData)}
                                    value={this.state.setViewResults}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!rowData.isCustomize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} checked={this.state.SelectPlaceorderAll} onChange={(e) => this.handlePlaceOrderForAllOffices(e)} /> Place Order </span>, sorting: false, field: 'po', render: rowData => (
                                <Checkbox
                                    checked={rowData.po === "True" || rowData.po === "true" || rowData.po === "Yes" || rowData.po === true}
                                    name="isPlaceOrderForUsers"
                                    onChange={(e) => this.handlePlaceOrderForUsers(e, rowData)}
                                    value={this.state.setPlaceOrder}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!rowData.isCustomize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} checked={this.state.SelectStatusAll} onChange={(e) => this.handleViewStatusForAllOffices(e)} /> View Status </span>, sorting: false, field: 'vs', render: rowData => (
                                <Checkbox
                                    checked={rowData.vs === "True" || rowData.vs === "true" || rowData.vs === "Yes" || rowData.vs === true}
                                    name="isViewStatusForUsers"
                                    onChange={(e) => this.handleViewStatusForUsers(e, rowData)}
                                    value={this.state.setViewStatus}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!rowData.isCustomize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} checked={this.state.SelectNotessAll} onChange={(e) => this.handleViewNotesForAllOffices(e)} /> View Notes </span>, sorting: false, field: 'vn', render: rowData => (
                                <Checkbox
                                    id="summ"
                                    name="isViewNotesForUsers"
                                    onChange={(e) => this.handleViewNotesForUsers(e, rowData)}
                                    value={this.state.setViewNotes}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    checked={rowData.vn === "True" || rowData.vn === "true" || rowData.vn === "Yes" || rowData.vn === true}
                                    disabled={(!rowData.isCustomize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },

                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} checked={this.state.SelectAuthAll} onChange={(e) => this.handleViewAuthForAllOffices(e)} /> View Auth Docs </span>, sorting: false, field: 'va', render: rowData => (
                                <Checkbox
                                    id="summ"
                                    name="isViewAuthForUsers"
                                    onChange={(e) => this.handleViewAuthForUsers(e, rowData)}
                                    value={this.state.setViewAuth}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    checked={rowData.va === "True" || rowData.va === "true" || rowData.va === "Yes" || rowData.va === true}
                                    disabled={(!rowData.isCustomize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        { title: 'OfficeId', field: 'officeId', hidden: true },

                    ]}
                    data={this.state.ObjArrayDetails}
                    options={{
                        exportButton: false,
                        search: true,
                        paginationType: "stepped",
                        labelRowsPerPage: "Showing",
                        selection: false,
                    }}
                />
            </div>
        );

    }

    renderOfficeListinGridNonEditable() {
        return (
            <div className="table-responsive dataTable unsubmitted  eport-ml selectOption">
                <MaterialTable
                    key={this.state.muiTableKey}
                    title=""
                    className="table data-table dataTable table-bordered"
                    onSearchChange={(A, B, C) => this.AgentListFilterSearch(A, B, C)}

                    columns={[
                        {
                            title: <span> <input type="checkbox" checked={this.state.SelectAllOffices} disabled="disabled" style={{ visibility: "hidden" }} onChange={(e) => this.handleSelectAllForAllOffices(e)} /> Select All </span>, sorting: false, field: 'IsSelectAll', render: rowData => (<div>
                                <Checkbox
                                    checked={rowData.selectedOfc === "True" || rowData.selectedOfc === "true" || rowData.selectedOfc === "Yes" || rowData.selectedOfc === true}
                                    name="isSelectAll"
                                    onChange={(e) => this.handleSelectAllForUsers(e, rowData)}
                                    value={rowData.SelectAll}
                                    color="primary"
                                    disabled="true"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>)
                        },
                        {
                            title: 'Office Name', field: 'key'

                        },
                        {
                            title: <span> <input type="checkbox" disabled="disabled" style={{ visibility: "hidden" }} checked={this.state.SelectCustomizeAll} onChange={(e) => this.handleCustomizeForAllOffices(e)} /> Customize </span>, sorting: false, field: 'IsCustomized', render: rowData => (<div>
                                <Checkbox
                                    checked={rowData.isCustomize === "True" || rowData.isCustomize === "true" || rowData.isCustomize === "Yes" || rowData.isCustomize === true}
                                    name="isCustomize"
                                    onChange={(e) => this.handleCustomizeForUsers(e, rowData)}
                                    value={rowData.isCustomize}
                                    color="primary"
                                    disabled="true"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}

                                />
                            </div>)
                        },
                        {
                            title: <span>Access Type <br /></span>, sorting: false, field: 'IsCustomized', render: rowData => (<div>
                                <div><input
                                    type="radio"
                                    value="Own"
                                    checked={rowData.accessLevel === "Own" || isNullOrUndefined(rowData.accessLevel)}
                                    onChange={(e) => this.handleAccessTypeChange(e, rowData)}
                                    disabled={"disabled"}
                                />Own Orders</div>
                                <div><input
                                    type="radio"
                                    value="All"
                                    checked={rowData.accessLevel === "All"}
                                    onChange={(e) => this.handleAccessTypeChange(e, rowData)}
                                    disabled={"disabled"}
                                />All Orders</div>
                            </div>)
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} style={{ visibility: "hidden" }} checked={this.state.SelectResultsAll} onChange={(e) => this.handleViewResultsForAllOffices(e)} /> View Results </span>, sorting: false, field: "vr", render: rowData => (
                                <Checkbox
                                    checked={rowData.vr === "True" || rowData.vr === "true" || rowData.vr === "Yes" || rowData.vr === true}
                                    name="isPlaceOrderForUsers"
                                    onChange={(e) => this.handleViewResultsForUsers(e, rowData)}
                                    value={this.state.setViewResults}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!rowData.Customize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} style={{ visibility: "hidden" }} checked={this.state.SelectPlaceorderAll} onChange={(e) => this.handlePlaceOrderForAllOffices(e)} /> Place Order </span>, sorting: false, field: 'po', render: rowData => (
                                <Checkbox
                                    checked={rowData.po === "True" || rowData.po === "true" || rowData.po === "Yes" || rowData.po === true}
                                    name="isPlaceOrderForUsers"
                                    onChange={(e) => this.handlePlaceOrderForUsers(e, rowData)}
                                    value={this.state.setPlaceOrder}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!rowData.Customize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} style={{ visibility: "hidden" }} checked={this.state.SelectStatusAll} onChange={(e) => this.handleViewStatusForAllOffices(e)} /> View Status </span>, sorting: false, field: 'vs', render: rowData => (
                                <Checkbox
                                    checked={rowData.vs === "True" || rowData.vs === "true" || rowData.vs === "Yes" || rowData.vs === true}
                                    name="isViewStatusForUsers"
                                    onChange={(e) => this.handleViewStatusForUsers(e, rowData)}
                                    value={this.state.setViewStatus}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={(!rowData.Customize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} style={{ visibility: "hidden" }} checked={this.state.SelectNotessAll} onChange={(e) => this.handleViewNotesForAllOffices(e)} /> View Notes </span>, sorting: false, field: 'vn', render: rowData => (
                                <Checkbox
                                    id="summ"
                                    name="isViewNotesForUsers"
                                    onChange={(e) => this.handleViewNotesForUsers(e, rowData)}
                                    value={this.state.setViewNotes}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    checked={rowData.vn === "True" || rowData.vn === "true" || rowData.vn === "Yes" || rowData.vn === true}
                                    disabled={(!rowData.Customize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },
                        {
                            title: <span> <input type="checkbox" disabled={(this.state.EnableOtherCheckBoxes ? "" : "disabled")} style={{ visibility: "hidden" }} checked={this.state.SelectAuthAll} onChange={(e) => this.handleViewAuthForAllOffices(e)} /> View Auth Docs </span>, sorting: false, field: 'va', render: rowData => (
                                <Checkbox
                                    id="summ"
                                    name="isViewAuthForUsers"
                                    onChange={(e) => this.handleViewAuthForUsers(e, rowData)}
                                    value={this.state.setViewAuth}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    checked={rowData.va === "True" || rowData.va === "true" || rowData.va === "Yes" || rowData.va === true}
                                    disabled={(!rowData.Customize || !this.state.DisableAllowOverride)}
                                />
                            )
                        },

                        { title: 'OfficeId', field: 'officeId', hidden: true },
                    ]}
                    data={this.state.ObjArrayDetails}
                    options={{
                        exportButton: false,
                        search: true,
                        paginationType: "stepped",
                        labelRowsPerPage: "Showing",
                        selection: false,
                    }}
                />
            </div>
        );

    }
    handleallowViewResultsChange(e) {
        this.setState({ AssignedViewResultsValue: e.target.value, AllowViewResults: e.target.value });
    }

    handleallowOverrideAccessChange(e) {
        this.setState({ AllowOverrideAccess: e.target.value });
    }

    handleallowPlaceOrderChange(e) {
        this.setState({ AssignedPlaceOrderValue: e.target.value, AllowPlaceOrder: e.target.value });
    }

    handleallowViewNotesChange(e) {
        this.setState({ AssignedViewNotesValue: e.target.value, AllowViewNotes: e.target.value });
    }

    handleallowViewStatusChange(e) {
        this.setState({ AssignedViewStatusValue: e.target.value, AllowViewStatus: e.target.value });
    }

    handleallowViewAuthChange(e) {
        this.setState({ AssignedViewAuthValue: e.target.value, AllowViewAuth: e.target.value });
    }

    /* For Carrier Level Permissions Change*/
    handleallowCarrierLevelVRChange(e) {
        this.setState({ CarrierLevelVR: e.target.value });
    }

    handleallowCarrierLevelAOAChange(e) {
        this.setState({ CarrierLevelAOA: e.target.value });
        if (e.target.value == "Yes") {
            this.setState({ DisablePushOfficesandUsers: true, PushToOffices: false });
        }
        else {
            this.setState({ DisablePushOfficesandUsers: false, PushToOffices: true });
        }
    }

    handleallowCarrierLevelDCAChange(e) {
        this.setState({ CarrierLevelDCA: e.target.value, DefaultOffice: "" });
    }

    handleallowCarrierLevelPOChange(e) {
        this.setState({ CarrierLevelPO: e.target.value });
    }

    handleallowCarrierLevelVNChange(e) {
        this.setState({ CarrierLevelVN: e.target.value });
    }

    handleallowCarrierLevelVAChange(e) {
        this.setState({ CarrierLevelVA: e.target.value });
    }

    handleallowCarrierLevelVSChange(e) {
        this.setState({ CarrierLevelVS: e.target.value });
    }

    handleallowCarrierLeverCSRMessagingChange(e) {
        this.setState({ AllowCSRMessaging: e.target.value });
    }
    testAlphaNumericwithSpaceForEmailNew(e) {
        let validationPassed = true;
        if (/^[a-zA-Z0-9,#!%$'&+*\-/=?^_`.{|}~@]+$/g.test(e)) {
            if (/\s{2,}/g.test(e) || /\}{2,}/g.test(e) || /\|{2,}/g.test(e) || /={2,}/g.test(e) || /,{2,}/g.test(e) || /[\\]{2,}/g.test(e) || /!{2,}/g.test(e) || /-{2,}/g.test(e) || /[{]{2,}/g.test(e) || /#{2,}/g.test(e) || /@{2,}/g.test(e) || /[.]{2,}/g.test(e) || /_{2,}/g.test(e) || /&{2,}/g.test(e) || /[$]{2,}/g.test(e) || /%{2,}/g.test(e) || /[*]{2,}/g.test(e) || /\^{2,}/g.test(e)) {
                validationPassed = false;
            }
            else {
                let str = e;
                let a = str.indexOf(".");
                if (a === 0) {
                    validationPassed = false;
                }
                let b = str.indexOf("#");
                if (b === 0) {
                    validationPassed = false;
                }
                let c = str.indexOf("!");
                if (c === 0) {
                    validationPassed = false;
                }
                let d = str.indexOf("%");
                if (d === 0) {
                    validationPassed = false;
                }
                let z = str.indexOf("$");
                if (z === 0) {
                    validationPassed = false;
                }
                let f = str.indexOf("`");
                if (f === 0) {
                    validationPassed = false;
                }

                let h = str.indexOf("&");
                if (h === 0) {
                    validationPassed = false;
                }

                let j = str.indexOf("+");
                if (j === 0) {
                    validationPassed = false;
                }
                let k = str.indexOf("*");
                if (k === 0) {
                    validationPassed = false;
                }
                let l = str.indexOf("/");
                if (l === 0) {
                    validationPassed = false;
                }
                let m = str.indexOf("=");
                if (m === 0) {
                    validationPassed = false;
                }
                let n = str.indexOf("?");
                if (n === 0) {
                    validationPassed = false;
                }
                let o = str.indexOf("^");
                if (o === 0) {
                    validationPassed = false;
                }
                let p = str.indexOf("{");
                if (p === 0) {
                    validationPassed = false;
                }
                let q = str.indexOf("}");
                if (q === 0) {
                    validationPassed = false;
                }
                let r = str.indexOf("|");
                if (r === 0) {
                    validationPassed = false;
                }
                let s = str.indexOf("~");
                if (s === 0) {
                    validationPassed = false;
                }

                let t = str.indexOf("$");
                if (t === 0) {
                    validationPassed = false;
                }
                let u = str.indexOf(",");
                if (u === 0) {
                    validationPassed = false;
                }
                let w = str.indexOf("_");
                if (w === 0) {
                    validationPassed = false;
                }
                let x = str.indexOf("-");
                if (x === 0) {
                    validationPassed = false;
                }
                let g = str.indexOf("@");
                if (g === 0) {
                    validationPassed = false;
                }
                else if (g !== 0) {
                    let i = str.indexOf("@") - 1;
                    let v = str.indexOf("@") + 1;
                    if (str.charAt(i) === "." || str.charAt(i) === "~" || str.charAt(i) === "," || str.charAt(i) === "#" || str.charAt(i) === "!" || str.charAt(i) === "%" || str.charAt(i) === "$" || str.charAt(i) === "'" || str.charAt(i) === "&" || str.charAt(i) === "+" || str.charAt(i) === "*" || str.charAt(i) === "-" || str.charAt(i) === "/" || str.charAt(i) === "?" || str.charAt(i) === "^" || str.charAt(i) === "_" || str.charAt(i) === "`" || str.charAt(i) === "{" || str.charAt(i) === "|" || str.charAt(i) === "}" || str.charAt(i) === "~" || str.charAt(v) === "." || str.charAt(v) === "," || str.charAt(v) === "#" || str.charAt(v) === "!" || str.charAt(v) === "%" || str.charAt(v) === "$" || str.charAt(v) === "'" || str.charAt(v) === "&" || str.charAt(v) === "+" || str.charAt(v) === "*" || str.charAt(v) === "-" || str.charAt(v) === "/" || str.charAt(v) === "?" || str.charAt(v) === "^" || str.charAt(v) === "_" || str.charAt(v) === "`" || str.charAt(v) === "{" || str.charAt(v) === "|" || str.charAt(v) === "}" || str.charAt(v) === "~") {
                        validationPassed = false;
                    }

                }
            }
        }
        else {
            validationPassed = false;
        }
        return validationPassed;
    }
    testEmailNew(e) {
        let strlength = e.length;
        let a = e.lastIndexOf(".");
        let b = e.lastIndexOf("#");
        let c = e.lastIndexOf("!");
        let d = e.lastIndexOf("%");
        let y = e.lastIndexOf("$");
        let f = e.lastIndexOf("`");
        let g = e.lastIndexOf("@");
        let h = e.lastIndexOf("&");
        let j = e.lastIndexOf("+");
        let k = e.lastIndexOf("*");
        let l = e.lastIndexOf("/");
        let m = e.lastIndexOf("=");
        let n = e.lastIndexOf("?");
        let o = e.lastIndexOf("^");
        let p = e.lastIndexOf("{");
        let q = e.lastIndexOf("}");
        let r = e.lastIndexOf("|");
        let s = e.lastIndexOf("~");
        let t = e.lastIndexOf("$");
        let u = e.lastIndexOf(",");
        let w = e.lastIndexOf("_");
        let x = e.lastIndexOf("-");
        let str = e;
        let eSubstring = e.substring(g + 1, e.length);
        let beforeatValue = e.substring(0, g - 1);
        let firstIndexofDot = eSubstring.indexOf(".");
        if (eSubstring.length > 0) {
            let lastIndexofDot = eSubstring.lastIndexOf(".");
            let aa = lastIndexofDot + 1;
            let bb = lastIndexofDot - 1;
            let cc = firstIndexofDot + 1;
            let dd = firstIndexofDot - 1;
            if (eSubstring.charAt(aa) === "." || eSubstring.charAt(aa) === "~" || eSubstring.charAt(aa) === "," || eSubstring.charAt(aa) === "#" || eSubstring.charAt(aa) === "!" || eSubstring.charAt(aa) === "%" || eSubstring.charAt(aa) === "$" || eSubstring.charAt(aa) === "'" || eSubstring.charAt(aa) === "&" || eSubstring.charAt(aa) === "+" || eSubstring.charAt(aa) === "*" || eSubstring.charAt(aa) === "-" || eSubstring.charAt(aa) === "/" || eSubstring.charAt(aa) === "?" || eSubstring.charAt(aa) === "^" || eSubstring.charAt(aa) === "_" || eSubstring.charAt(aa) === "`" || eSubstring.charAt(aa) === "{" || eSubstring.charAt(aa) === "|" || eSubstring.charAt(aa) === "}" || eSubstring.charAt(aa) === "~" || eSubstring.charAt(bb) === "." || eSubstring.charAt(bb) === "," || eSubstring.charAt(bb) === "#" || eSubstring.charAt(bb) === "!" || eSubstring.charAt(bb) === "%" || eSubstring.charAt(bb) === "$" || eSubstring.charAt(bb) === "'" || eSubstring.charAt(bb) === "&" || eSubstring.charAt(bb) === "+" || eSubstring.charAt(bb) === "*" || eSubstring.charAt(bb) === "-" || eSubstring.charAt(bb) === "/" || eSubstring.charAt(bb) === "?" || eSubstring.charAt(bb) === "^" || eSubstring.charAt(bb) === "_" || eSubstring.charAt(bb) === "`" || eSubstring.charAt(bb) === "{" || eSubstring.charAt(bb) === "|" || eSubstring.charAt(bb) === "}" || eSubstring.charAt(bb) === "~") {
                return false;
            }
            if (eSubstring.charAt(cc) === "." || eSubstring.charAt(cc) === "~" || eSubstring.charAt(cc) === "," || eSubstring.charAt(cc) === "#" || eSubstring.charAt(cc) === "!" || eSubstring.charAt(cc) === "%" || eSubstring.charAt(cc) === "$" || eSubstring.charAt(cc) === "'" || eSubstring.charAt(cc) === "&" || eSubstring.charAt(cc) === "+" || eSubstring.charAt(cc) === "*" || eSubstring.charAt(cc) === "-" || eSubstring.charAt(cc) === "/" || eSubstring.charAt(cc) === "?" || eSubstring.charAt(cc) === "^" || eSubstring.charAt(cc) === "_" || eSubstring.charAt(cc) === "`" || eSubstring.charAt(cc) === "{" || eSubstring.charAt(cc) === "|" || eSubstring.charAt(cc) === "}" || eSubstring.charAt(cc) === "~" || eSubstring.charAt(dd) === "." || eSubstring.charAt(dd) === "," || eSubstring.charAt(dd) === "#" || eSubstring.charAt(dd) === "!" || eSubstring.charAt(dd) === "%" || eSubstring.charAt(dd) === "$" || eSubstring.charAt(dd) === "'" || eSubstring.charAt(dd) === "&" || eSubstring.charAt(dd) === "+" || eSubstring.charAt(dd) === "*" || eSubstring.charAt(dd) === "-" || eSubstring.charAt(dd) === "/" || eSubstring.charAt(dd) === "?" || eSubstring.charAt(dd) === "^" || eSubstring.charAt(dd) === "_" || eSubstring.charAt(dd) === "`" || eSubstring.charAt(dd) === "{" || eSubstring.charAt(dd) === "|" || eSubstring.charAt(dd) === "}" || eSubstring.charAt(dd) === "~") {
                return false;
            }
        }
        let eSubstringonlyDomain = eSubstring.substring(0, firstIndexofDot);
        if (a === strlength - 1 || b === strlength - 1 || c === strlength - 1 || d === strlength - 1 || e === strlength - 1 || f === strlength - 1 || g === strlength - 1 || h === strlength - 1 || j === strlength - 1 || k === strlength - 1 || l === strlength - 1 || m === strlength - 1 || n === strlength - 1 || o === strlength - 1 || p === strlength - 1 || q === strlength - 1 || r === strlength - 1 || s === strlength - 1 || t === strlength - 1 || u === strlength - 1 || w === strlength - 1 || x === strlength - 1 || y === strlength - 1) {
            return false;
        }
        else if (eSubstringonlyDomain.length > 253 || beforeatValue.length > 60 || str.length > 320) {
            return false;
        }
        else {
            return this.validationForSpecialchar(eSubstring);
        }
    }
    validationForSpecialchar(e) {
        let regex = /^@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,253}))$/;
        let str = e;
        return regex.test(str);
    }
    handlIdentifierssovalueChange(e) {
        if (e.target.value.length != 0) {
            this.setState({ rIdentifiersso: false });
        }
        this.setState({ Identifiersso: e.target.value });
    }
    handleEnableSSOConnectionSwitch(elem, state) {
        this.setState({
            EnableSSOConnection: state,
            vIdentifiersso: state,
            DisCheckPassNeverExpires: state,
            DisPreviousPassword: state,
            DisCheckInactive: state,
            IPAddressFiltering: state,
            IpAddressListing: false,
            passNeverExpires: state,
            DisInactive: state
        });
        if (!state) {
            if (this.state.ipAddressChange === true) {
                this.setState({ IpAddressListing: true });
            }
            if (this.state.IsPassNeverExpires === true) {
                this.setState({
                    passNeverExpires: true
                });
            }
            else {
                this.setState({
                    passNeverExpires: false
                });
            }
            if (this.state.IsNeverInactive === true) {
                this.setState({
                    DisInactive: true
                });
            }
            else {
                this.setState({
                    DisInactive: false
                });
            }
        }
        if (!state) {
            if (this.state.ipAddressChange === true) {
                this.setState({ IpAddressListing: true });
            }

            if (this.state.IsPassNeverExpires === true) {
                this.setState({
                    passNeverExpires: true
                });
            }
            else {
                this.setState({
                    passNeverExpires: false
                });

            }
            if (this.state.IsNeverInactive === true) {
                this.setState({
                    DisInactive: true
                });
            }
            else {
                this.setState({
                    DisInactive: false
                });
            }
        }




        if (!state) {
            this.setState({ Identifiersso: "" });
        }
    }
    handleEnableMFASwitch(elem, state) {
        this.setState({
            EnableMFA: state
        });
    }
    HideeNoahAdminMenu() {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Get('api/login/HideeNoahAdminMenu', currentComponent).then(function (result) {
            if (result.statusCode == 401) {
                currentComponent.toasterredfun("You are not eligible to Place or Edit Order");
            }
            else {
                currentComponent.setState({ hideEnoahAdmin: result.hideEnoahAdmin, hideEnoahSystemAdmin: result.hideEnoahSystemAdmin });
                currentComponent.setState({ loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    GetRequiredFields(SelectedSectionId) {
        let CompanyType = localStorage.getItem("CompanyType");
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/login/GetRequiredFieldsBySectionId', { "SectionId": SelectedSectionId, "CompanyType": CompanyType }, currentComponent).then(function (result) {
            if (result.statusCode == 401) {
                currentComponent.toasterredfun("You are not eligible to Place or Edit Order");
            }
            else if (result.statusCode == 429) {
                currentComponent.toasterredfun2(result.statusMessage);
                currentComponent.setState({ loading: false });
            }
            else {
                currentComponent.setState({ RequiredFieldsList: result.requiredFields });
                currentComponent.setState({ loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    ShowCarrierManagerFields = () => {
        this.GetRequiredFields(6);

        this.setState({
            PatientInfoFields: false,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            CarrierManagerFields: true,
            UnderwriterFields: false
        });
    };

    ShowAgentInfoFields = () => {

        this.GetRequiredFields(4);

        this.setState({
            showFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: false,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: true,
            UnderwriterFields: false
        });
    };

    ShowFacilityInfoFields = () => {

        this.GetRequiredFields(5);

        this.setState({
            showFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: false,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: true,
            AgentInfoFields: false,
            UnderwriterFields: false
        });
    };

    ShowAdjusterInfoFields = () => {

        this.GetRequiredFields(13);

        this.setState({
            showFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: false,
            AttorneyInfoFields: false,
            AdjusterInfoFields: true,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            UnderwriterFields: false
        });
    };

    ShowAttorneyInfoFields = () => {

        this.GetRequiredFields(7);

        this.setState({
            showFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: false,
            AttorneyInfoFields: true,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            UnderwriterFields: false
        });
    };

    ShowPatientInfoFields = () => {
        this.GetRequiredFields(1);

        this.setState({
            showFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: true,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            UnderwriterFields: false
        });
    };

    ShowUnderwriterFields = () => {

        this.GetRequiredFields(14);

        this.setState({
            showFieldSetup: false,
            CarrierManagerFields: false,
            PatientInfoFields: false,
            AttorneyInfoFields: false,
            AdjusterInfoFields: false,
            FacilityInfoFields: false,
            AgentInfoFields: false,
            UnderwriterFields: true
        });
    };

    handleIsActiveChange(e) {
        if (e.target.value == "No") {
            this.setState({ orderIsRequiredSelectedValue: "No", orderSummaryIsRequiredSelectedValue: "No" });
        }
        this.setState({ orderIsActiveSelectedValue: e.target.value });
    }

    handleIsRequiredChange(e) {
        if (e.target.value == "Yes") {
            this.setState({ orderIsActiveSelectedValue: "Yes" });
        }
        this.setState({ orderIsRequiredSelectedValue: e.target.value });
    }

    handleSummaryIsRequiredChange(e) {
        if (e.target.value == "Yes") {
            this.setState({ orderIsActiveSelectedValue: "Yes" });
        }
        this.setState({ orderSummaryIsRequiredSelectedValue: e.target.value });
    }

    onClickclosePopup() {
        this.setState({ isviewModalopen: false });
    }

    UnlockUser(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        let userType = localStorage.getItem('UserType');
        Api.Post('api/login/UnlockAccount', { "AgentUserId": e, "UserType": userType }, currentComponent).then(function (result) {
            if (result.statusMessage == "Success") {
                currentComponent.setState({
                    UserList: result.userList
                });
                currentComponent.toasterfun2("User has been unlocked successfully.");
            }
            else if (result.statusCode == 403) {
                currentComponent.setState({ loading: false });
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                currentComponent.setState({ adminAlert: true });
            }
            else if (result.statusCode == 402) {
                currentComponent.setState({ loading: false });
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                currentComponent.setState({ adminAlert: true });
            }
            else {
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ unlockalert: false, unlockid: "" });
            currentComponent.setState({ loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

    }
    SendConfirmation(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        let userType = localStorage.getItem('UserType');
        Api.Post('api/placeorder/ResendEmail', { "UserId": e, "UserType": userType }, currentComponent).then(function (result) {
            if (result.statusMessage == "Success") {
                currentComponent.toasterfun2("Confirmation email has been resend successfully.");
            }
            else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                currentComponent.setState({ loading: false });
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                currentComponent.setState({ adminAlert: true });
            }
            else {
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    isviewModalopen(id) {

        if (id != null && id.length != 0) {
            this.setState(prevState => ({
                ObjArrayDetails: prevState.ObjectArray
            }));
            let currentComponent = this;
            currentComponent.setState({ loading: true });
            let userType = localStorage.getItem('UserType');
            Api.Post('api/placeorder/GetUserDetials', { "AgentUserId": id, "UserType": userType }, currentComponent).then(function (result) {
                if (result.statusCode == 401) {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterredfun("You are not eligible to Add or Edit User");
                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.setState({ SelectedValues: [], FirstName: result.result.firstName, LastName: result.result.lastName, AgentId: result.result.agentId, AgentEmail: result.result.agentEmail, Fax: result.result.fax, Phone1: result.result.phone1, Phone2: result.result.phone2, });
                    currentComponent.setState({ AddressLine1: result.result.addressLine1, UCity: result.result.city, ST: result.result.st });
                    currentComponent.setState({ Zip: result.result.zip, Phone1: result.result.phone1, isviewModalopen: true, AgentUserId: result.result.agentUserId });
                    currentComponent.setState({ Phone2: result.result.phone2, Ext1: result.result.ext1, UserType: result.result.userType, isEdit: true, });
                    currentComponent.setState({ AccessType: result.result.accessLevel, PlaceOrder: result.result.placeOrder.toString() });
                    currentComponent.setState({ DownloadAccess: result.result.downloadImages.toString(), StatusView: result.result.viewStatus.toString(), AuthView: result.result.viewAuth.toString(), DefaultOffice: result.result.defaultOfficeName.toString(), Identifier: result.result.Identifier, ExportSearchResultsValue: result.result.exportSearchResults === true ? "Yes" : "No", PerformanceMetricsValue: result.result.performanceMetrics === true ? "Yes" : "No" });
                    currentComponent.setState({ OrderingOffices: result.result.orderingCarriersDd.map(function (elem) { return elem.key; }).join(", ") });
                    if (result.result.orderingCarriersDd) {
                        currentComponent.setState({ SelectedValues: result.result.orderingCarriersDd, ToDefaultOffice: [] });
                        if (result.result.userType == "Client Admin" || result.result.userType == "eNoah Admin" || result.result.userType == "eNoah System Admin") {
                            currentComponent.setState({
                                PlaceOrder: "true", DownloadAccess: "true", AccessType: "All", StatusView: "true", AuthView: "true", IsAdmin: true,
                                ATReadOnly: true, POReadOnly: true, DAReadOnly: true, SVReadOnly: true, AVReadOnly: true
                            });

                        }
                        else if (result.result.userType == "Client Home Office") {
                            currentComponent.setState({
                                CHOViewResultsValue: result.result.downloadImages === true ? "Yes" : "No",
                                CHOPlaceOrderValue: result.result.placeOrder === true ? "Yes" : "No",
                                CHOViewStatusValue: result.result.viewStatus === true ? "Yes" : "No",
                                CHOViewNoteValue: result.result.viewNotes === true ? "Yes" : "No",
                                CHOViewAuthValue: result.result.viewAuth === true ? "Yes" : "No",
                                IsAdmin: true
                            });
                        }
                        else {
                            currentComponent.setState({ SelectedRows: [], ToDefaultOffice: [] });
                            let rows = [];
                            let DefaultRows = [];
                            let record = {};
                            result.result.orderingCarriersDd.map((item) => {
                                let ofc = currentComponent.state.ObjectArray.find(s => s.id == item.id)
                                if (ofc) {
                                    ofc.selectedOfc = true;
                                    ofc.accessLevel = item.accessLevel;
                                    ofc.vr = item.vr;
                                    ofc.po = item.po;
                                    ofc.vs = item.vs;
                                    ofc.vn = item.vn;
                                    ofc.va = item.va;
                                    ofc.isCustomize = item.isCustomize;
                                    rows.push(ofc);
                                    DefaultRows.push(ofc);
                                    record = { officeId: item.id, selectedOffice: item.selectedOfc, setViewResults: item.vr, setPlaceOrder: item.po, setViewStatus: item.vs, setViewNotes: item.vn, setViewAuth: item.va, setAccessType: item.accessLevel }
                                }
                            });
                            const sortedDescending = [...currentComponent.state.ObjArrayDetails].sort((a, b) => {
                                return b.selectedOfc - a.selectedOfc;
                            });
                            currentComponent.setState({ SelectedRows: rows });
                            currentComponent.setState({ selectedOfc: sortedDescending });
                            currentComponent.setState({ ToDefaultOffice: DefaultRows });
                            currentComponent.setState({ officeId: record.officeId, selectedOffice: record.selectedOffice, setViewResults: record.setViewResults, setPlaceOrder: record.setPlaceOrder, setViewStatus: record.setViewStatus, setViewNotes: record.setViewNotes, setViewAuth: record.setViewAuth, setAccessType: record.setAccessType })
                            currentComponent.setState({
                                ATReadOnly: false, POReadOnly: false, DAReadOnly: false, SVReadOnly: false, AVReadOnly: false, IsAdmin: false
                            });
                        }

                    }


                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });

        }
    }
    testAlphaNumeric(e) {
        return /^[a-zA-Z0-9]+$/g.test(e);
    }
    testAlphawithSpace(e) {
        if (/^[a-zA-Z.\s]+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testAlphawithSpaceforCity(e) {
        if (/^[a-zA-Z.\s'-]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else if (/-{2,}/g.test(e)) {
                return false;
            } else if (/'{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    handlePushtoOfficesandUsers(e) {
        this.setState({
            PushToOffices: e.target.checked
        });
    }

    handlePlaceOrderForUsers(e, rowData) {
        this.setState({ SelectPlaceorderAll: false })
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.po = e.target.checked;
        this.setState({
            ObjectArray: data
        });
    }

    handleViewStatusForUsers(e, rowData) {
        this.setState({ SelectStatusAll: false })
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.vs = e.target.checked;
        this.setState({
            ObjectArray: data
        });
    }

    handleViewNotesForUsers(e, rowData) {
        this.setState({ SelectNotessAll: false })
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.vn = e.target.checked;
        this.setState({
            ObjectArray: data
        });
    }

    handleViewAuthForUsers(e, rowData) {
        this.setState({ SelectAuthAll: false })
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.va = e.target.checked;
        this.setState({
            ObjectArray: data
        });
    }

    handleViewResultsForUsers(e, rowData) {
        this.setState({ SelectResultsAll: false })
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.vr = e.target.checked;
        this.setState({
            ObjectArray: data
        });
    }

    handlePlaceOrderForAllOffices(e) {

        let data = this.state.ObjectArray;
        this.setState({ SelectPlaceorderAll: e.target.checked });
        for (const item of data) {
            let filteredData = item;
            filteredData.po = e.target.checked;
            if (e.target.checked === false) {
                this.setState({ SelectPlaceorderAll: e.target.checked });
            }
        }
        this.setState({
            ObjectArray: data
        });

    }

    handleViewStatusForAllOffices(e) {

        let data = this.state.ObjectArray;

        this.setState({ SelectStatusAll: e.target.checked });
        for (const item of data) {
            let filteredData = item;
            filteredData.vs = e.target.checked;
            if (e.target.checked === false) {
                this.setState({ SelectStatusAll: e.target.checked });
            }
        }
        this.setState({
            ObjectArray: data
        });
    }

    handleViewNotesForAllOffices(e) {

        let data = this.state.ObjectArray;

        this.setState({ SelectNotessAll: e.target.checked });
        for (const item of data) {
            let filteredData = item;
            filteredData.vn = e.target.checked;
            if (e.target.checked === false) {
                this.setState({ SelectNotessAll: e.target.checked });
            }
        }
        this.setState({
            ObjectArray: data
        });
    }

    handleViewAuthForAllOffices(e) {

        let data = this.state.ObjectArray;
        this.setState({ SelectAuthAll: e.target.checked });
        for (const item of data) {
            let filteredData = item;
            filteredData.va = e.target.checked;
            if (e.target.checked === false) {
                this.setState({ SelectAuthAll: e.target.checked });
            }
        }
        this.setState({
            ObjectArray: data
        });
    }

    handleViewResultsForAllOffices(e) {

        let data = this.state.ObjectArray;
        this.setState({ SelectResultsAll: e.target.checked });
        for (const item of data) {
            let filteredData = item;
            filteredData.vr = e.target.checked;
            if (e.target.checked === false) {
                this.setState({ SelectResultsAll: e.target.checked });
            }
        }
        this.setState({
            ObjectArray: data
        });
    }

    handleSelectAllForAllOffices(e) {
        //select all after Search (PE-476)
        let data = [...this.state.ObjectArray];
        let unFilteredData = [];
        let FilteredKeyData = this.state.AgentListFilterKey;
        if (FilteredKeyData) {
            unFilteredData = data.filter(function (item) {
                return item.key.toUpperCase().indexOf(FilteredKeyData.toUpperCase()) === -1;
            })
            data = data.filter(function (item) {
                return item.key.toUpperCase().indexOf(FilteredKeyData.toUpperCase()) > -1;
            })
        }
        this.setState({ SelectAllOffices: e.target.checked, DefaultOffice: "" });
        for (let i = 0; i < data.length; i++) {
            let filtereddata = data;
            filtereddata[i].selectedOfc = e.target.checked;
            filtereddata[i].accessLevel = filtereddata[i].accessLevel == null ? "Own" : filtereddata[i].accessLevel;
            if (e.target.checked) {
                const Oldindex = this.state.SelectedRows.indexOf(filtereddata[i]);
                if (Oldindex === -1) {
                    this.state.SelectedRows.push(data[i]);
                    if (filtereddata[i].po) {
                        this.state.ToDefaultOffice.push(data[i]);
                    }
                }
            }
            //PE-476 For Deselect
            else if (!e.target.checked && FilteredKeyData) {
                const Oldindex = this.state.SelectedRows.indexOf(filtereddata[i]);
                if (Oldindex !== -1) {
                    this.state.SelectedRows.splice(Oldindex, 1);
                    if (filtereddata[i].po) {
                        this.state.ToDefaultOffice.splice(Oldindex, 1);
                    }
                }
            }
            else {
                this.setState({ SelectedRows: [] });
                this.setState({ ToDefaultOffice: [] });
            }
        }
        this.setState({
            ObjectArray: [...data, ...unFilteredData]
        });
    }
    handleSelectAllForUsers(e, rowData) {
        this.setState({ SelectAllOffices: false, DefaultOffice: "" });
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        const Exindex = this.state.SelectedRows.indexOf(rowData);
        if (Exindex == -1) {
            this.setState({ SelectedRows: [...this.state.SelectedRows, filtereddata] });
            if (filtereddata.po) {
                this.setState({ ToDefaultOffice: [...this.state.ToDefaultOffice, filtereddata] });
            }
        }
        else {
            const index = this.state.SelectedRows.indexOf(rowData);
            if (index != -1) {
                this.state.SelectedRows.splice(index, 1);
            }
            const index_off = this.state.ToDefaultOffice.indexOf(rowData);
            if (index_off != -1) {
                this.state.ToDefaultOffice.splice(index_off, 1);
            }
        }

        filtereddata.selectedOfc = e.target.checked;
        filtereddata.accessLevel = filtereddata.accessLevel == null ? "Own" : filtereddata.accessLevel;
        this.setState({
            ObjectArray: data
        });
    }
    //for clearing drop-down values after save and cancel (pe-494)
    resetAccessLevel() {
        this.setState(prevState => {
            let data = prevState.ObjectArray
            for (const item of data) {
                let filteredData = item;
                filteredData.accessLevel = "Own";
            }
            this.setState({
                ObjectArray: data, accessLevel_AllOffice: "Own"
            });
        });
    }

    handleResetSelectOfc() {
        this.GetOrderingOfficeInformationListByCompanyId();
    }


    handleCustomizeForAllOffices(e) {
        let i = 0;
        let data = this.state.ObjectArray;
        this.setState({ EnableOtherCheckBoxes: e.target.checked, SelectCustomizeAll: e.target.checked, accessLevel_AllOffice: "" });
        for (i = 0; i < data.length; i++) {
            let filtereddata = data[i];
            filtereddata.Customize = e.target.checked;
            if (e.target.checked === false) {
                this.setState({ SelectCustomizeAll: e.target.checked });
            }
            this.setState({ IsCustomize: e.target.checked })
        }
        this.setState({
            ObjectArray: data
        });
    }

    handleCustomizeForUsers(e, rowData) {
        this.setState({ SelectCustomizeAll: false })
        this.setState({ IsCustomize: e.target.checked })
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.isCustomize = e.target.checked;
        this.setState({
            ObjectArray: data
        });
    }

    testAlphaNumericwithSpaceForName(e) {
        let valtestAlphaNumericwithSpaceForName = true;
        if (/^[._&a-zA-Z0-9'\-\s]+$/g.test(e)) {

            if (/\s{2,}/g.test(e) || /-{2,}/g.test(e) || /'{2,}/g.test(e) || /\.{2,}/g.test(e)) {
                valtestAlphaNumericwithSpaceForName = false;
            }
        }
        else {
            valtestAlphaNumericwithSpaceForName = false;
        }
        return valtestAlphaNumericwithSpaceForName;
    }
    testAlphaNumericwithSpaceForCarrier(e) {
        if (/^[-_.&a-zA-Z0-9',\s]+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testAlphaNumericwithSpaceForOrderingOfficeName(e) {
        if (/^[-_.&a-zA-Z0-9',\s{}<>()[\]]+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testAlphaNumericwithSpaceAndSpecialForCustomFieldsName(e) {
        if (/^[-_.&#a-zA-Z0-9',\s/]+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testAlphaNumericwithSpaceForEmail(e) {
        if (/^[-_.a-zA-Z0-9@]+$/g.test(e)) {
            if (/@{2,}/g.test(e)) {
                return false;
            }
            else if (/[.]{2,}/g.test(e)) {
                return false;
            }
            else if (/_{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    testEXT(e) {
        if (/^\d+$/g.test(e)) {
            return !/\s{2,}/g.test(e);
        }
        else {
            return false;
        }
    }
    testEmail(e) {
        return /^([a-zA-Z0-9_.'&-]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})+$/.test(e);
    }
    testPhone(e) {
        return /^\(\d{3}\)\s\d{3}-\d{4}$/.test(e);
    }
    testZip(e) {
        return /^\d\d{4}$/g.test(e);

    }
    testZip5(e) {
        return /^\d\d{4}-\d\d{3}$/g.test(e);
    }
    handlersaveOpenbttn() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeAgentAlert: true });
    }
    handleremoveCancelbttn() {
        this.setState({ removeAgentAlert: false });
    }
    openEditPopup(id) {
        this.setState({
            ObjArrayDetails: this.state.ObjectArray
        });
        if (id != null && id.length != 0) {
            let uId = this.state.UserId;
            if (uId == id) {
                this.setState({ HideAdminMenuForCurrentUser: false });
            }
            else {
                this.setState({ HideAdminMenuForCurrentUser: true });
            }

            let currentComponent = this;
            currentComponent.setState({ loading: true });
            let userType = localStorage.getItem('UserType');
            Api.Post('api/placeorder/GetUserDetials', { "AgentUserId": id, "UserType": userType }, currentComponent).then(function (result) {
                if (result.statusCode == 401) {
                    currentComponent.toasterredfun("You are not eligible to Add or Edit User");
                    currentComponent.setState({ loading: false });
                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.setState({
                        ExportSearchResultsValue: result.result.exportSearchResults === true ? "Yes" : "No",
                        PerformanceMetricsValue: result.result.performanceMetrics === true ? "Yes" : "No",
                    })
                    if (result.result.orderingCarriersDd) {
                        currentComponent.setState({ SelectedValues: result.result.orderingCarriersDd, ToDefaultOffice: [] });
                    }
                    currentComponent.setState({ FirstName: result.result.firstName, LastName: result.result.lastName, AgentId: result.result.agentId, AgentEmail: result.result.agentEmail, Fax: result.result.fax, Phone1: result.result.phone1, Phone2: result.result.phone2, });
                    currentComponent.setState({ AddressLine1: result.result.addressLine1, UCity: result.result.city, ST: result.result.st });
                    currentComponent.setState({ Zip: result.result.zip, Phone1: result.result.phone1, isAgentModalopen: true, AgentUserId: result.result.agentUserId });
                    currentComponent.setState({ Phone2: result.result.phone2, Ext1: result.result.ext1, UserType: result.result.userType, isEdit: true, });
                    currentComponent.setState({ AccessType: result.result.accessLevel, PlaceOrder: result.result.placeOrder.toString() });
                    currentComponent.setState({ DownloadAccess: result.result.downloadImages.toString(), StatusView: result.result.viewStatus.toString(), AuthView: result.result.viewAuth.toString(), DefaultOffice: result.result.defaultOffice, EnableSSOConnection: result.result.isEnableSSO, Identifier: result.result.identifier, Identifiervalue: result.result.identifiervalue, ShowAdminMenu: result.result.showAdminMenu });
                    currentComponent.setState({ rowList: result.result.orderingOffices, muiTableKey: currentComponent.state.muiTableKey + 1 });
                    if (currentComponent.state.EnableSSOConnection && !currentComponent.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
                        currentComponent.setState({ mIdentifierSSO: true });
                    }
                    else {
                        currentComponent.setState({ mIdentifierSSO: false });
                    }
                    if (result.result.userType == "Client Admin" || result.result.userType == "eNoah Admin" || result.result.userType == "eNoah System Admin") {
                        currentComponent.setState({
                            PlaceOrder: "true", DownloadAccess: "true", AccessType: "All", StatusView: "true", AuthView: "true",
                            ATReadOnly: true, POReadOnly: true, DAReadOnly: true, SVReadOnly: true, AVReadOnly: true, IsAdmin: true
                        });
                        if (result.result.userType == "Client Admin") {
                            currentComponent.setState({ ToDefaultOffice: currentComponent.state.ObjectArray });
                        }
                    }
                    else if (result.result.userType == "Client Home Office") {
                        currentComponent.setState({ ToDefaultOffice: currentComponent.state.ObjectArray });
                        currentComponent.setState({
                            CHOViewResultsValue: result.result.downloadImages === true ? "Yes" : "No",
                            CHOPlaceOrderValue: result.result.placeOrder === true ? "Yes" : "No",
                            CHOViewStatusValue: result.result.viewStatus === true ? "Yes" : "No",
                            CHOViewNoteValue: result.result.viewNotes === true ? "Yes" : "No",
                            CHOViewAuthValue: result.result.viewAuth === true ? "Yes" : "No",
                            IsAdmin: true
                        });
                    }
                    else {
                        currentComponent.setState({ SelectedRows: [], ToDefaultOffice: [] });
                        let rows = [];
                        let DefaultRows = [];
                        let record = {};
                        result.result.orderingCarriersDd.map((item) => {
                            let ofc = currentComponent.state.ObjectArray.find(s => s.id == item.id)
                            if (ofc) {
                                ofc.selectedOfc = true;
                                ofc.accessLevel = item.accessLevel;
                                ofc.vr = item.vr;
                                ofc.po = item.po;
                                ofc.vs = item.vs;
                                ofc.vn = item.vn;
                                ofc.va = item.va;
                                if (item.isCustomize) {
                                    ofc.isCustomize = true;
                                }
                                else {
                                    ofc.isCustomize = false;
                                }
                                rows.push(ofc);
                                DefaultRows.push(ofc);
                                record = { officeId: item.id, selectedOffice: item.selectedOfc, setViewResults: item.vr, setPlaceOrder: item.po, setViewStatus: item.vs, setViewNotes: item.vn, setViewAuth: item.va, setAccessType: item.accessLevel, setIsCustomize: item.isCustomize }
                            }
                        });
                        const sortedDescending = [...currentComponent.state.ObjArrayDetails].sort((a, b) => {
                            return b.selectedOfc - a.selectedOfc;
                        });
                        currentComponent.setState({ SelectedRows: rows });
                        currentComponent.setState({ selectedOfc: sortedDescending });
                        currentComponent.setState({ ToDefaultOffice: DefaultRows });
                        currentComponent.setState({ officeId: record.officeId, selectedOffice: record.selectedOffice, setViewResults: record.setViewResults, setPlaceOrder: record.setPlaceOrder, setViewStatus: record.setViewStatus, setViewNotes: record.setViewNotes, setViewAuth: record.setViewAuth, setAccessType: record.setAccessType, setIsCustomize: record.setIsCustomize })
                        currentComponent.setState({
                            ATReadOnly: false, POReadOnly: false, DAReadOnly: false, SVReadOnly: false, AVReadOnly: false, IsAdmin: false
                        });
                    }
                    currentComponent.setState({ loading: false });
                }
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });

        }
    }
    handleOpendeletebttn(e, etype) {


        this.setState({ removehidden: e });
        this.setState({ typehidden: etype });

        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ deleteAlert: true });
    }
    handleIPdeletebttn(e, etype) {
        //debugger;
        this.setState({ removehidden: e });
        this.setState({ typehidden: etype });

        $("html, body").animate({ scrollTop: 0 }, "slow");
        if (this.state.SelectedRowsForIp.length == 0) {

            this.toasterredfun2("Please select the Ip Address for deletion");
        }
        else {
            this.setState({ deleteAlert: true });
        }
    }
    handleOpenUnlockAlert(e) {

        this.setState({ unlockid: e });


        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ unlockalert: true });
    }

    handleOpenInactivityAlert(e) {

        this.setState({ inactiveid: e });


        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ inactivealert: true });
    }
    handleOpenCMdeletebttn(e, etype) {

        this.setState({ removehidden: e });
        this.setState({ typehidden: etype });

        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ deleteAlert: true });
    }
    Onclicktabledelpopup(e, type) {
        this.setState({ deleteAlert: true });
        let userType = localStorage.getItem('UserType');
        let uId = this.state.UserId;
        this.setState({ deleteAlert: false, loading: true });
        if (type === 'AgentList') {
            if (uId == e) {
                this.toasterredfun2("You cannot delete yourself. Contact Administrator.");
            }
            else {
                let currentComponent = this;

                Api.Post('api/placeorder/DeleteAgentUser', { "AgentUserId": e, "CarrierId": 1, "UserType": userType }, currentComponent).then(function (result) {
                    currentComponent.setState({ loading: false });
                    if (result.statusMessage == "Success") {
                        currentComponent.setState({
                            UserList: result.userList, OrderingOfficeInformationList: result.orderOfficeInformation
                        });
                        currentComponent.toasterfun2("User has been Deleted successfully.");
                    }
                    else if (result.statusCode == 403) {
                        currentComponent.setState({ loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });
                    }
                    else if (result.statusCode == 402) {
                        currentComponent.setState({ loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });
                    }

                    else {
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                }).catch(function (error) {
                    currentComponent.setState({ loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });

            }
        }
        if (type === 'OrderInfo') {
            let CompanyId = 1;
            let currentComponent = this;

            Api.Post('api/placeorder/DeleteOrderingOfficeInformationbyId', {
                "OrderOfficeInformation": {
                    "Id": e,
                    "CompanyId": CompanyId,
                    "UserType": userType
                }
            }, currentComponent).then(function (result) {
                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else if (result.statusCode == 402) {

                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.setState({ OrderingOfficeInformationList: result.orderOfficeInformationList });
                }
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
            this.setState({ loading: false });
        }

        if (type === 'IpList') {
            this.setState({ deleteAlert: false });
            this.handleDeleteIpAddress();
            this.setState({ loading: false });
        }
    }
    handledeletebttn() {
        this.setState({ deleteAlert: false });
    }

    onClickopenFieldEditPopup = (rowData) => {
        this.setState({ isFieldsEditModalopen: true });
        this.setState({ originalFieldName: rowData.originalFieldName, customFieldName: rowData.customFieldName, orderIsActiveSelectedValue: rowData.isActive ? "Yes" : "No", orderIsRequiredSelectedValue: rowData.isRequired ? "Yes" : "No", fieldId: rowData.id, isDefault: rowData.isDefault, SectionId: rowData.sectionId, orderSummaryIsRequiredSelectedValue: rowData.summaryIsRequired ? "Yes" : "No" })
    }

    handleUpdateFieldInformation() {
        let isvalid = true;
        let currentComponent = this;
        if (this.state.customFieldName == null || this.state.customFieldName.trim().length == 0) {
            this.setState({ customField: true });
            isvalid = false;
        }
        else {
            this.setState({ customField: false });
        }

        if (isvalid) {
            this.setState({ loading: true });
            let SelectedCompanyType = localStorage.getItem("CompanyType");
            let userType = localStorage.getItem('UserType');
            Api.Post('api/login/UpdateFieldInformation', {
                "CompanyType": SelectedCompanyType, "SectionId": currentComponent.state.SectionId, "CustomFieldName": currentComponent.state.customFieldName, "IsActive": currentComponent.state.orderIsActiveSelectedValue,
                "FieldId": currentComponent.state.fieldId, "IsRequired": currentComponent.state.orderIsRequiredSelectedValue, "FromCreatecarrier": false, "UserType": userType, "SummaryIsRequired": currentComponent.state.orderSummaryIsRequiredSelectedValue

            }, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({ isFieldsEditModalopen: false });
                    currentComponent.setState({ loading: false });
                    currentComponent.setState({ RequiredFieldsList: result.requiredFields });
                    currentComponent.clearOfficeInformation();
                    currentComponent.toasterfun2("Field has been updated successfully.");
                }
                else if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else if (result.statusCode == 402) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }

                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });

            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }

    GetRequiredFields_CompanyType() {
        let currentComponent = this;
        let SelectedCompanyType = localStorage.getItem("CompanyType");
        currentComponent.setState({ loading: true });
        Api.Post('api/login/GetRequiredFieldsCarrierDetails', { "CompanyType": SelectedCompanyType }, currentComponent).then(function (result) {
            if (result.statusCode == 401) {
                currentComponent.toasterredfun("You are not eligible to Place or Edit Order");
            }
            else {
                currentComponent.setState({ RequiredFieldsList: result.requiredFields });
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    componentWillMount() {
        let user = localStorage.getItem("UserType");
        if (user == "Agent") {
            this.props.history.goBack();
        }
        let ctype = localStorage.getItem("ShowAdminMenu");
        if (user.toLowerCase() == "client home office") {
            if (!isNullOrUndefined(ctype) && ctype == "false") {
                this.props.history.push('/dashboard');
            }
        }
        this.setState({ iseNoahAdmin: user === "eNoah Admin" || user === "eNoah System Admin" });
        this.setState({ iseNoahSystemAdmin: user === "eNoah System Admin" ? true : false });
        if (user === "Client Admin" || user === "Client Home Office") {
            this.setState({ isClientAdmin: true });
        }
        else {
            this.setState({ isClientAdmin: false });
        }
        this.HideeNoahAdminMenu();
        this.GetCustomerContactByCompanyId();
        this.GetCarrierDetails();
        this.GetUserListByCompanyId();
        this.GetCarrierManagerList();
        this.GetRequiredFields(1);
        this.ShowHideFields();
        this.EnableDisableAccessPermissions();
        this.GetOrderingOfficeInformationListByCompanyId();
        this.GetUnderwriterList();

    }

    EnableDisableAccessPermissions() {
        let CompanyId = 1;
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/EnableDisableAccessPermissions', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({
                DisableAllowOverride: result.accessList[0].disableAllowOverrideAccess,
                DisableAllowPlaceOrder: result.accessList[0].disableAllowPlaceOrder,
                DisableAllowViewResults: result.accessList[0].disableAllowViewResults,
                DisableAllowViewStatus: result.accessList[0].disableAllowViewStatus,
                DisableAllowViewNotes: result.accessList[0].disableAllowViewNotes,
                DisableAllowViewAuth: result.accessList[0].disableAllowViewAuth, loading: false,
                AllowPlaceOrder: result.accessList[0].disableAllowPlaceOrder === false ? "No" : "Yes",
                AllowViewResults: result.accessList[0].disableAllowViewResults === false ? "No" : "Yes",
                AllowViewStatus: result.accessList[0].disableAllowViewStatus === false ? "No" : "Yes",
                AllowViewNotes: result.accessList[0].disableAllowViewNotes === false ? "No" : "Yes",
                AllowViewAuth: result.accessList[0].disableAllowViewAuth === false ? "No" : "Yes",
                DisablePushOfficesandUsers: result.accessList[0].disableAllowOverrideAccess,
                PushToOffices: !result.accessList[0].disableAllowOverrideAccess,
            });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    ShowHideFields() {
        let CompanyType = localStorage.getItem("CompanyType");
        if (CompanyType == "LIFE") {
            this.setState({ CompanyType: localStorage.getItem("CompanyType") });
        }
        if (CompanyType == "LEGAL") {
            this.setState({ CompanyType: localStorage.getItem("CompanyType") });
        }
    }

    handleCropNumberChange(e) {
        this.setState({ rCropNumber: false });
        if (e.target.value.length === 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ CropNumber: e.target.value });
        }
    }
    handleSummaryCropNumberChange(e) {
        this.setState({ rSummaryCropNumber: false });
        if (e.target.value.length == 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ SummaryCropNumber: e.target.value });
        }
    }
    handleCarrierNameChange(e) {
        this.setState({ rCarrierName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ CarrierName: e.target.value });
        }
    }
    handleAddressChange(e) {
        this.setState({ rAddress: false });
        this.setState({ Address: e.target.value });
    }
    handleCityChange(e) {
        this.setState({ rCity: false });
        if (e.target.value.length == 0 || this.testAlphawithSpaceforCity(e.target.value)) {
            this.setState({ City: e.target.value });
        }
    }
    handleStateChange(e) {
        this.setState({ rState: false });
        this.setState({ State: e.target.value });
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    toasterfun3(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
                this.props.history.push('/');
            }.bind(this), 5000);
    }
    handleZipCodeChange(e) {
        this.setState({ rZipCode: false });
        let zip = e.target.value.replace(/\D+/g, "");
        let curStart = this.zipRef.current.selectionStart;
        if (zip.length === 0) {
            this.setState({ ZipCode: "" });
        }
        else if (/\d$/.test(zip)) {
            if (zip.length < 6) {
                this.setState({ ZipCode: zip }, () => this.zipRef.current.selectionStart = this.zipRef.current.selectionEnd = curStart);
            }
            else if (zip.length === 6) {
                this.setState({ ZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 6) }, () => this.zipRef.current.selectionStart = this.zipRef.current.selectionEnd = curStart + 1);
            }
            else {
                this.setState({ ZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 9) }, () => this.zipRef.current.selectionStart = this.zipRef.current.selectionEnd = curStart);
            }
        }
    }
    handleMainContactNameChange(e) {
        this.setState({ rMainContactName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ MainContactName: e.target.value });
        }
    }
    handleMainContactEmailChange(e) {
        this.setState({ rMainContactEmail: false });
        this.setState({ MainContactEmail: e.target.value.replace(/ /g, '') });
    }
    async handleblurMainContactEmailChange(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rMainContactEmail: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rMainContactEmail: false });
                        currentComponent.setState({ loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }

    }
    handleMainContactPhoneChange(e) {
        this.setState({ rMainContactPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.mcpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ MainContactPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ MainContactPhone: "(" + phnArray[0] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ MainContactPhone: "(" + phn }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ MainContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.mcpRef.current.selectionStart = this.mcpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleAlternateContactNameChange(e) {
        this.setState({ rAlternateContactName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ AlternateContactName: e.target.value });
        }
    }
    handleAlternateContactEmailChange(e) {
        this.setState({ rAlternateContactEmail: false });
        this.setState({ AlternateContactEmail: e.target.value.replace(/ /g, '') });
        if (e.target.value.length != 0) {
            if (this.testAlphaNumericwithSpaceForEmail(e.target.value)) {
                this.setState({ AlternateContactEmail: e.target.value });
            }
        }
        else {
            this.setState({ AlternateContactEmail: e.target.value });
        }
    }
    async CheckForAdmin() {
        let currentComponent = this;
        let responce = false;
        currentComponent.setState({ loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.downloadImages);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.allowExport);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
            if (result.statusCode == "100") {
                if (!isNullOrUndefined(result)) {

                    if (!result.isAdmin) {

                        currentComponent.setState({ loading: false });
                        currentComponent.setState({ adminAlert: true });
                        responce = false;

                    }
                    else {
                        currentComponent.setState({ loading: false });
                        responce = true;
                    }

                }
                else {
                    currentComponent.setState({ loading: false });
                }

            } else {
                currentComponent.setState({ loading: false });
                currentComponent.toasterredfun(result.statusMessage);
                responce = false;
            }
        });

        return responce;
    }

    async handleblurAlternateContactEmailChange(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rAlternateContactEmail: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rAlternateContactEmail: false });
                        currentComponent.setState({ loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    handleAlternateContactPhoneChange(e) {
        this.setState({ rAlternateContactPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.acpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ AlternateContactPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ AlternateContactPhone: "(" + phn }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ AlternateContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.acpRef.current.selectionStart = this.acpRef.current.selectionEnd = curStart);
            }
        }

    }
    handleCustomerServiceContactNameChange(e) {
        this.setState({ rCustomerServiceContactName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ CustomerServiceContactName: e.target.value });
        }
    }
    handleCustomerServiceContactNameChangeforSummary(e) {
        this.setState({ rCustomerServiceContactNameforSummary: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ CustomerServiceContactNameforSummary: e.target.value });
        }
    }
    handleCustomerServiceContactEmailChange(e) {
        this.setState({ rCustomerServiceContactEmail: false });
        this.setState({ CustomerServiceContactEmail: e.target.value.replace(/ /g, '') });
    }
    handleCustomerServiceContactEmailChangeforSummary(e) {
        this.setState({ rCustomerServiceContactEmailforSummary: false });
        this.setState({ CustomerServiceContactEmailforSummary: e.target.value.replace(/ /g, '') });
    }
    async handleblurCustomerServiceContactEmailChange(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rCustomerServiceContactEmail: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rCustomerServiceContactEmail: false });
                        currentComponent.setState({ loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    async handleblurCustomerServiceContactEmailChangeforSummary(e) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rCustomerServiceContactEmailforSummary: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rCustomerServiceContactEmailforSummary: false });
                        currentComponent.setState({ loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    handleCustomerServiceContactPhoneChange(e) {
        this.setState({ rCustomerServiceContactPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.ccpnRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ CustomerServiceContactPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ CustomerServiceContactPhone: "(" + phn }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ CustomerServiceContactPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
        }
    }
    handleCustomerServiceContactPhoneChangeforSummary(e) {
        this.setState({ rCustomerServiceContactPhoneforSummary: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = e.target.selectionStart;
        if (phn.length == 0) {
            this.setState({ CustomerServiceContactPhoneforSummary: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length == 1) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length == 2) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 3) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phn }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 4) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length == 5) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 6) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 7) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length == 8) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 9) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
            else if (phn.length == 10) {
                this.setState({ CustomerServiceContactPhoneforSummary: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ccpnRef.current.selectionStart = this.ccpnRef.current.selectionEnd = curStart);
            }
        }
    }

    handleOPhnChange(e) {
        this.setState({ rOPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.ocpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ OPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ OPhone: "(" + phnArray[0] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ OPhone: "(" + phn }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ OPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.ocpRef.current.selectionStart = this.ocpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleOfficeNameChange(e) {
        this.setState({ ofcName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForOrderingOfficeName(e.target.value)) {
            this.setState({ OfficeName: e.target.value });
        }
    }

    handleAssignedOfficeNameChange(e) {
        this.setState({ pAssignedOfficeName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForOrderingOfficeName(e.target.value)) {
            this.setState({ AssignedOfficeName: e.target.value });
        }
    }

    handleOfficeAccountChange(e) {
        this.setState({ ofcAccount: false });
        if (e.target.value.length == 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ OfficeAccountNumber: e.target.value });
        }
    }
    handleNumberOfUsersChange(e) {
        this.setState({ NumberOfUsers: e.target.value });
    }
    handleFirstNameChange(e) {
        this.setState({ rFirstName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ FirstName: e.target.value });
        }
    }
    handleLastNameChange(e) {
        this.setState({ rLastName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForName(e.target.value)) {
            this.setState({ LastName: e.target.value });
        }
    }
    handleAIdChange(e) {
        this.setState({ rAgentId: false });
        if (e.target.value.length == 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ AgentId: e.target.value });
        }
    }
    handleAgentEmailChange(e) {
        this.setState({ AgentEmail: e.target.value.replace(/ /g, ''), rAgentEmail: false },
            () => {

                if (this.state.UserType == "eNoah Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
                    this.setState({ rAgentEmail: true, rUserType: true });
                    this.toasterredfun2("eNoah Admin user must be eNoah Domain User");
                }
                else if (this.state.UserType == "eNoah System Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
                    this.setState({ rAgentEmail: true, rUserType: true });
                    this.toasterredfun2("eNoah System Admin user must be eNoah Domain User");
                }
                else {
                    this.setState({ rAgentEmail: false, rUserType: false });
                }
            });
    }
    async handleblurAgentEmailChange(e) {
        let currentComponent = this;
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length !== 0) {
                currentComponent.setState({ loading: true });
                let eMail = e.target.value;
                let data = { Email: eMail.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {
                        currentComponent.setState({ rAgentEmail: !result, loading: false, rIdentifiervalue: false });
                        if (!eMail.replace(/ /g, '').toLowerCase().includes("@enoahisolution.com")) {
                            currentComponent.setState({ mIdentifierSSO: true });
                        }
                        else {
                            currentComponent.setState({ mIdentifierSSO: false });
                        }
                    })
                    .catch(function (error) {
                        currentComponent.setState({ rAgentEmail: false });
                        currentComponent.setState({ loading: false });
                        if (error === "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    handleOemailChange(e) {
        this.setState({ rOEmail: false });
        this.setState({ OEmail: e.target.value.replace(/ /g, '') });
    }
    async handleblurOemailChange(e) {
        let currentComponent = this;
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rOEmail: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rOEmail: false });
                        currentComponent.setState({ loading: false });
                        if (error === "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    handlePhone1Change(e) {
        this.setState({ rPhone1: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.inputRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ Phone1: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ Phone1: "(" + phnArray[0] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ Phone1: "(" + phn }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ Phone1: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = curStart);
            }
        }
    } handleExt1Change(e) {
        this.setState({ rExt1: false });
        if (e.target.value.length == 0 || this.testEXT(e.target.value)) {
            this.setState({ Ext1: e.target.value });
        }
    }
    handlePhone2Change(e) {
        this.setState({ rPhone2: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.phn2Ref.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ Phone2: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ Phone2: "(" + phnArray[0] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ Phone2: "(" + phn }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ Phone2: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.phn2Ref.current.selectionStart = this.phn2Ref.current.selectionEnd = curStart);
            }
        }
    }
    handleExt2Change(e) {
        this.setState({ rExt2: false });
        if (e.target.value.length == 0 || this.testEXT(e.target.value)) {
            this.setState({ Ext2: e.target.value });
        }
    }
    handleAddressLine1Change(e) {
        this.setState({ rAddressLine1: false });
        this.setState({ AddressLine1: e.target.value });
    }
    handleAddressLine2Change(e) {
        this.setState({ rAddressLine2: false });
        this.setState({ AddressLine2: e.target.value });
    }
    handleUCityChange(e) {
        this.setState({ rUCity: false });
        if (e.target.value.length == 0 || this.testAlphawithSpaceforCity(e.target.value)) {
            this.setState({ UCity: e.target.value });
        }
    }
    handlIdentifiervalueChange(e) {
        if (e.target.value.length != 0) {
            this.setState({ rIdentifiervalue: false });
        }
        this.setState({ Identifiervalue: e.target.value });
    }
    handleSTChange(e) {
        this.setState({ rST: false });
        this.setState({ ST: e.target.value });
    }
    handleZipChange(e) {
        this.setState({ rZip: false });
        let zip = e.target.value.replace(/\D+/g, "");
        let curStart = this.zipdRef.current.selectionStart;
        if (zip.length === 0) {
            this.setState({ Zip: "" });
        }
        else if (/\d$/.test(zip)) {
            if (zip.length < 6) {
                this.setState({ Zip: zip }, () => this.zipdRef.current.selectionStart = this.zipdRef.current.selectionEnd = curStart);
            }
            else if (zip.length === 6) {
                this.setState({ Zip: zip.substring(0, 5) + "-" + zip.substring(5, 6) }, () => this.zipdRef.current.selectionStart = this.zipdRef.current.selectionEnd = curStart + 1);
            }
            else {
                this.setState({ Zip: zip.substring(0, 5) + "-" + zip.substring(5, 9) }, () => this.zipdRef.current.selectionStart = this.zipdRef.current.selectionEnd = curStart);
            }

        }
    }
    handleOHBChange(e) {
        this.setState({ OHB: e.target.value, rOHB: false });
    }
    handleRHBChange(e) {
        this.setState({ RHB: e.target.value, rRHB: false });
    }
    handleMFATokenTypeChange(e) {
        this.setState({ MFATokenType: e.target.value });
    }
    handleMFATokenExpiryDaysChange(e) {
        this.setState({ MFATokenExpiryDays: e.target.value });
    }
    handleMFATokenExpiryMinChange(e) {
        this.setState({ MFATokenExpiryMin: e.target.value });
    }
    handleMFATokenGenTypeChange(e) {
        this.setState({ MFATokenGenType: e.target.value, rMFATokenLength: false });
    }
    handleMFATokenLengthChange(e) {
        if (e.target.value.length != 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ MFATokenLength: e.target.value, rMFATokenLength: false });
            }
        } else {
            this.setState({ MFATokenLength: e.target.value, rMFATokenLength: true });
        }
    }
    testNumericForTokenLength(e) {
        return /^\d+$/g.test(e);
    }
    handleMFAIpWithUserChange(e) {
        this.setState({
            MFAUserwithIP: e.target.value
        });
    }
    handleRetentionPeriodDaysChange(e) {
        this.setState({ rRetentionPeriodDays: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ RetentionPeriodDays: e.target.value });
            }
        } else {
            this.setState({ RetentionPeriodDays: e.target.value });
        }
    }
    handleProductionPurgeDaysChange(e) {
        this.setState({ rProductionPurgeDays: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ ProductionPurgeDays: e.target.value });
            }
        } else {
            this.setState({ ProductionPurgeDays: e.target.value });
        }
    }
    handleOfflineArchivePurgeDaysChange(e) {
        this.setState({ rOfflineArchivePurgeDays: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForTokenLength(e.target.value)) {
                this.setState({ OfflineArchivePurgeDays: e.target.value });
            }
        } else {
            this.setState({ OfflineArchivePurgeDays: e.target.value });
        }
    }
    handleCMFirstNameChange(e) {
        this.setState({ rCMFirstName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ CMFirstName: e.target.value });
        }
    }
    handleCMLastNameChange(e) {
        this.setState({ rCMLastName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ CMLastName: e.target.value });
        }
    }
    handleCMEmailAddressChange(e) {
        this.setState({ rCMEmailAddress: false });
        this.setState({ CMEmailAddress: e.target.value.replace(/ /g, '') });
    }
    async handleblurCMEmailAddressChange(e) {
        let currentComponent = this;
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rCMEmailAddress: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rCMEmailAddress: false });
                        currentComponent.setState({ loading: false });
                        if (error === "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }

    handleCMWorkPhoneChange(e) {
        this.setState({ rCMWorkPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.cmwpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ CMWorkPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ CMWorkPhone: "(" + phn }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ CMWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.cmwpRef.current.selectionStart = this.cmwpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleCMExtChange(e) {
        this.setState({ rCMExt: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ CMExt: e.target.value });
            }
        } else {
            this.setState({ CMExt: e.target.value });
        }
    }

    handleCMCellPhoneChange(e) {
        this.setState({ rCMCellPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.cmcpRef.current.selectionStart;
        if (phn.length === 0) {
            this.setState({ CMCellPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ CMCellPhone: "(" + phnArray[0] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ CMCellPhone: "(" + phn }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ CMCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.cmcpRef.current.selectionStart = this.cmcpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleCMFaxhange(e) {
        this.setState({ rCMFax: false });
        let CMFax = e.target.value.replace(/\D+/g, "");
        let curStart = this.CMFaxRef.current.selectionStart;
        if (CMFax.length === 0) {
            this.setState({ CMFax: "" });
        }
        if (/\d$/.test(CMFax)) {
            let CMFaxArray = [...CMFax];
            if (CMFax.length === 1) {
                this.setState(
                    { CMFax: "(" + CMFaxArray[0] },
                    () =>
                    (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd =
                        curStart + 1)
                );
            }
            if (CMFax.length === 2) {
                this.setState(
                    { CMFax: "(" + CMFaxArray[0] + CMFaxArray[1] },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            } else if (CMFax.length === 3) {
                this.setState(
                    { CMFax: "(" + CMFax },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            } else if (CMFax.length === 4) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3],
                    },
                    () =>
                    (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd =
                        curStart + 2)
                );
            } else if (CMFax.length === 5) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3] +
                            CMFaxArray[4],
                    },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            } else if (CMFax.length === 6) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3] +
                            CMFaxArray[4] +
                            CMFaxArray[5],
                    },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            } else if (CMFax.length === 7) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3] +
                            CMFaxArray[4] +
                            CMFaxArray[5] +
                            "-" +
                            CMFaxArray[6],
                    },
                    () =>
                    (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd =
                        curStart + 1)
                );
            } else if (CMFax.length === 8) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3] +
                            CMFaxArray[4] +
                            CMFaxArray[5] +
                            "-" +
                            CMFaxArray[6] +
                            CMFaxArray[7],
                    },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            } else if (CMFax.length === 9) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3] +
                            CMFaxArray[4] +
                            CMFaxArray[5] +
                            "-" +
                            CMFaxArray[6] +
                            CMFaxArray[7] +
                            CMFaxArray[8],
                    },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            } else if (CMFax.length === 10) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMFaxArray[0] +
                            CMFaxArray[1] +
                            CMFaxArray[2] +
                            ") " +
                            CMFaxArray[3] +
                            CMFaxArray[4] +
                            CMFaxArray[5] +
                            "-" +
                            CMFaxArray[6] +
                            CMFaxArray[7] +
                            CMFaxArray[8] +
                            CMFaxArray[9],
                    },
                    () =>
                        (this.CMFaxRef.current.selectionStart = this.CMFaxRef.current.selectionEnd = curStart)
                );
            }
        }
    }
    handleUWFirstNameChange(e) {
        this.setState({ rUWFirstName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ UWFirstName: e.target.value });
        }
    }
    handleUWLastNameChange(e) {
        this.setState({ rUWLastName: false });
        if (e.target.value.length == 0 || this.testAlphaNumericwithSpaceForCarrier(e.target.value)) {
            this.setState({ UWLastName: e.target.value });
        }
    }
    handleUWEmailAddressChange(e) {
        this.setState({ rUWEmailAddress: false });
        this.setState({ UWEmailAddress: e.target.value.replace(/ /g, '') });
    }
    async handleblurUWEmailAddressChange(e) {
        let currentComponent = this;
        e.persist()
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ loading: true });
                let data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ rUWEmailAddress: !result });
                        currentComponent.setState({ loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ rUWEmailAddress: false });
                        currentComponent.setState({ loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    handleUWWorkPhoneChange(e) {
        this.setState({ rUWWorkPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.uwwpRef.current.selectionStart;
        if (phn.length == 0) {
            this.setState({ UWWorkPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ UWWorkPhone: "(" + phn }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ UWWorkPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.uwwpRef.current.selectionStart = this.uwwpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleUWExtChange(e) {
        this.setState({ rUWExt: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ UWExt: e.target.value });
            }
        } else {
            this.setState({ UWExt: e.target.value });
        }
    }
    handleUWCellPhoneChange(e) {
        this.setState({ rUWCellPhone: false });
        let phn = e.target.value.replace(/\D+/g, "");
        let curStart = this.uwcpRef.current.selectionStart;
        if (phn.length == 0) {
            this.setState({ UWCellPhone: "" });
        }
        if (/\d$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length === 1) {
                this.setState({ UWCellPhone: "(" + phnArray[0] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart + 1);
            }
            if (phn.length === 2) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 3) {
                this.setState({ UWCellPhone: "(" + phn }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 4) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart + 2);
            }
            else if (phn.length === 5) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 6) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 7) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart + 1);
            }
            else if (phn.length === 8) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 9) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
            else if (phn.length === 10) {
                this.setState({ UWCellPhone: "(" + phnArray[0] + phnArray[1] + phnArray[2] + ") " + phnArray[3] + phnArray[4] + phnArray[5] + "-" + phnArray[6] + phnArray[7] + phnArray[8] + phnArray[9] }, () => this.uwcpRef.current.selectionStart = this.uwcpRef.current.selectionEnd = curStart);
            }
        }
    }
    handleUWFaxChange(e) {
        this.setState({ rUWFax: false });
        let UWFax = e.target.value.replace(/\D+/g, "");
        let curStart = this.uwFaxRef.current.selectionStart;
        if (UWFax.length === 0) {
            this.setState({ UWFax: "" });
        }
        if (/\d$/.test(UWFax)) {
            let UWFaxArray = [...UWFax];
            if (UWFax.length === 1) {
                this.setState(
                    { UWFax: "(" + UWFaxArray[0] },
                    () =>
                    (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd =
                        curStart + 1)
                );
            }
            if (UWFax.length === 2) {
                this.setState(
                    { UWFax: "(" + UWFaxArray[0] + UWFaxArray[1] },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            } else if (UWFax.length === 3) {
                this.setState(
                    { UWFax: "(" + UWFax },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            } else if (UWFax.length === 4) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3],
                    },
                    () =>
                    (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd =
                        curStart + 2)
                );
            } else if (UWFax.length === 5) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3] +
                            UWFaxArray[4],
                    },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            } else if (UWFax.length === 6) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3] +
                            UWFaxArray[4] +
                            UWFaxArray[5],
                    },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            } else if (UWFax.length === 7) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3] +
                            UWFaxArray[4] +
                            UWFaxArray[5] +
                            "-" +
                            UWFaxArray[6],
                    },
                    () =>
                    (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd =
                        curStart + 1)
                );
            } else if (UWFax.length === 8) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3] +
                            UWFaxArray[4] +
                            UWFaxArray[5] +
                            "-" +
                            UWFaxArray[6] +
                            UWFaxArray[7],
                    },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            } else if (UWFax.length === 9) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3] +
                            UWFaxArray[4] +
                            UWFaxArray[5] +
                            "-" +
                            UWFaxArray[6] +
                            UWFaxArray[7] +
                            UWFaxArray[8],
                    },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            } else if (UWFax.length === 10) {
                this.setState(
                    {
                        UWFax:
                            "(" +
                            UWFaxArray[0] +
                            UWFaxArray[1] +
                            UWFaxArray[2] +
                            ") " +
                            UWFaxArray[3] +
                            UWFaxArray[4] +
                            UWFaxArray[5] +
                            "-" +
                            UWFaxArray[6] +
                            UWFaxArray[7] +
                            UWFaxArray[8] +
                            UWFaxArray[9],
                    },
                    () =>
                        (this.uwFaxRef.current.selectionStart = this.uwFaxRef.current.selectionEnd = curStart)
                );
            }
        }
    }
    handlerFaxhange(e) {
        this.setState({ rFax: false });
        let Afax = e.target.value.replace(/\D+/g, "");
        let curStart = this.AfaxRef.current.selectionStart;
        if (Afax.length === 0) {
            this.setState({ Fax: "" });
        }
        if (/\d$/.test(Afax)) {
            let AfaxArray = [...Afax];
            if (Afax.length === 1) {
                this.setState(
                    { Fax: "(" + AfaxArray[0] },
                    () =>
                    (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd =
                        curStart + 1)
                );
            }
            if (Afax.length === 2) {
                this.setState(
                    { Fax: "(" + AfaxArray[0] + AfaxArray[1] },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            } else if (Afax.length === 3) {
                this.setState(
                    { Fax: "(" + Afax },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            } else if (Afax.length === 4) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3],
                    },
                    () =>
                    (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd =
                        curStart + 2)
                );
            } else if (Afax.length === 5) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4],
                    },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            } else if (Afax.length === 6) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5],
                    },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            } else if (Afax.length === 7) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6],
                    },
                    () =>
                    (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd =
                        curStart + 1)
                );
            } else if (Afax.length === 8) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6] +
                            AfaxArray[7],
                    },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            } else if (Afax.length === 9) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6] +
                            AfaxArray[7] +
                            AfaxArray[8],
                    },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            } else if (Afax.length === 10) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6] +
                            AfaxArray[7] +
                            AfaxArray[8] +
                            AfaxArray[9],
                    },
                    () =>
                        (this.AfaxRef.current.selectionStart = this.AfaxRef.current.selectionEnd = curStart)
                );
            }
        }
    }

    GetCarrierDetails() {
        let carrierId = 1;
        let currentComponent = this;
        currentComponent.setState({ loading: true });

        Api.Post('api/placeorder/GetCarrierDetailsByCarrierId', { "CarrierId": carrierId }, currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.downloadImages);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);

            if (result.statusCode === 401) {
                currentComponent.toasterredfun("You are not eligible to Place or Edit Order");
            }
            else {
                let IPs = [];
                result.ipAddressList.forEach((doc, index) => {
                    IPs.push({
                        id: result.ipAddressList[index].id,
                        ipAddress: Api.decryptData(result.ipAddressList[index].ipAddress)
                    });
                });
                let FetchIPAddressList = Object.assign([], IPs);
                currentComponent.setState({ CropNumber: result.carrier.cropNumber, SummaryCropNumber: result.carrier.summaryCropNumber, StatesList: result.states, CarrierName: result.carrier.name, Carrier: result.carrier.name });
                currentComponent.setState({ Address: result.carrier.address, City: result.carrier.city, State: result.carrier.state });
                currentComponent.setState({ ZipCode: result.carrier.zipCode, MainContactName: result.carrier.mainContactName });
                currentComponent.setState({ MainContactEmail: result.carrier.mainContactEmail, });
                currentComponent.setState({
                    CarrierLevelPO: result.carrier.allowPlaceOrder === false ? "No" : "Yes", CarrierLevelVR: result.carrier.allowViewResults === false ? "No" : "Yes", CarrierLevelVN: result.carrier.allowViewNotes === false ? "No" : "Yes", CarrierLevelVS: result.carrier.allowViewStatus === false ? "No" : "Yes",
                    CarrierLevelAOA: result.carrier.allowPermissionOverride === false ? "No" : "Yes", CarrierLevelDCA: result.carrier.allowDefaultOfficeCA === false ? "No" : "Yes", CarrierLevelVA: result.carrier.allowViewAuth === false ? "No" : "Yes",
                    CarrierLevelESR: result.carrier.allowExportSearchResults === false ? "No" : "Yes", CarrierLevelPMA: result.carrier.allowPerformanceMetrics === false ? "No" : "Yes", AllowCSRMessaging: result.carrier.allowCSRMessaging === false ? "No" : "Yes"
                });
                currentComponent.setState({
                    valueCarrierLevelVR: currentComponent.state.CarrierLevelVR,
                    valueCarrierLevelPO: currentComponent.state.CarrierLevelPO,
                    valueCarrierLevelVS: currentComponent.state.CarrierLevelVS,
                    valueCarrierLevelVN: currentComponent.state.CarrierLevelVN,
                    valueCarrierLevelVA: currentComponent.state.CarrierLevelVA,
                    valueCarrierLevelESR: currentComponent.state.CarrierLevelESR,
                    valueCarrierLevelPMA: currentComponent.state.CarrierLevelPMA,
                })
                currentComponent.setState({
                    AllowPlaceOrder: result.carrier.allowPlaceOrder === false ? "No" : "Yes", AllowViewResults: result.carrier.allowViewResults === false ? "No" : "Yes", AllowViewNotes: result.carrier.allowViewNotes === false ? "No" : "Yes", AllowViewStatus: result.carrier.allowViewStatus === false ? "No" : "Yes",
                    AllowCarrierLevelDCA: result.carrier.allowDefaultOfficeCA === false ? "No" : "Yes", AllowViewAuth: result.carrier.allowViewAuth === false ? "No" : "Yes", MFATokenTypeList: result.mfaTokenTypeList, MFATokenExpiryDaysList: result.mfaTokenExpiryDaysList, MFATokenExpiryMinList: result.mfaTokenExpiryMinList, MFATokenGenTypeList: result.mfaTokenGenTypeList
                });
                currentComponent.setState({ MainContactPhone: result.carrier.mainContactPhone, AlternateContactName: result.carrier.alternateContactName });
                currentComponent.setState({ AlternateContactEmail: result.carrier.alternateContactEmail, AlternateContactPhone: result.carrier.alternateContactPhone });
                currentComponent.setState({
                    Summarization: result.carrier.summarization, OHB: result.carrier.ordersHandledBy, EnableSSOConnection: result.carrier.isEnableSSO, Identifier: result.carrier.identifier, Identifiersso: result.carrier.identifier, vIdentifiersso: result.carrier.isEnableSSO,
                    EnableMFA: result.carrier.isEnableMFA, MFATokenType: result.carrier.mfaTokenType, MFATokenExpiryDays: result.carrier.tokenExpiryDays, MFATokenExpiryMin: result.carrier.tokenExpiryMin, MFATokenGenType: result.carrier.tokenGenType, MFATokenLength: result.carrier.tokenLength, MFAUserwithIP: result.carrier.isIpWithUser, RHB: result.carrier.reviewsHandledBy,
                    RetentionPeriodDays: result.carrier.retentionPeriodDays, OfflineArchivePurgeDays: result.carrier.offlineArchivePurgeDays, ProductionPurgeDays: result.carrier.productionPurgeDays,
                    DaysForEnforcePass: result.carrier.daysForEnforcePass, RememberPassCount: result.carrier.rememberPassCount, InactiveDays: result.carrier.inactiveDays, IsPassNeverExpires: result.carrier.passwordNeverExpires, IsNeverInactive: result.carrier.neverInactive, APS: result.carrier.aps, Summary: result.carrier.summary, clientAPS: result.carrier.aps, clientSummary: result.carrier.summary,
                    EnableOfficebyAccountNum: result.carrier.assignOfficebyAccountNumb
                });

                if (result.carrier.passwordNeverExpires) {
                    currentComponent.setState({
                        passNeverExpires: true
                    });
                }
                if (result.carrier.neverInactive) {
                    currentComponent.setState({
                        DisInactive: true
                    });
                }

                currentComponent.handleIpAddressChangeOnLoad(null, result.carrier.isEnableIP);
                if (result.carrier.isEnableSSO) {
                    currentComponent.handleEnableSSOConnectionSwitch(null, result.carrier.isEnableSSO);
                }
                currentComponent.setState({
                    IpAddressList: FetchIPAddressList.sort((a, b) => {
                        const num1 = Number(a.ipAddress.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                        const num2 = Number(b.ipAddress.split(".").map((num) => (`000${num}`).slice(-3)).join(""));
                        return num1 - num2;
                    })
                });
            }
            currentComponent.setState({ loading: false, UserId: Api.decryptData(result.userId) });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }
    GetOrderingOfficeInformationListByCompanyId() {

        let CompanyId = 1;
        let currentComponent = this;
        Api.Post('api/placeorder/GetOrderingOfficeInformationListByCompanyId', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ OrderingOfficeInformationList: result.orderOfficeInformationList });
            currentComponent.setState({ ObjectArray: result.dropdownList, isDDLoaded: true, AllofficeObjectArray: result.dropdownList });
        }).catch((error) => {
            currentComponent.setState({ isDDLoaded: true });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    GetUserListByCompanyId() {
        let CompanyId = 1;
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetUserListByCompanyId', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ UserList: result.userList, loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    GetCustomerContactByCompanyId() {
        let CompanyId = 1;
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetCustomerServiceContact', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ CustomerServiceContactName: result.apscsr.customerServiceContactName, CustomerServiceContactEmail: result.apscsr.customerServiceContactEmail, CustomerServiceContactPhone: result.apscsr.customerServiceContactPhone, CustomerServiceContactNameforSummary: result.summaryCSR.customerServiceContactName, CustomerServiceContactEmailforSummary: result.summaryCSR.customerServiceContactEmail, CustomerServiceContactPhoneforSummary: result.summaryCSR.customerServiceContactPhone, loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    GetUserAndOfficeList() {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetUsersandOrderingOfficeList', "", currentComponent).then(function (result) {
            currentComponent.setState({ UserandOfficeList: result.usersandOfficesList, loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    GetCarrierManagerList() {

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetCarrierManagerList', "", currentComponent).then(function (result) {
            currentComponent.setState({ CMList: result.carrierManagerList, loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    GetUnderwriterList() {

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetUnderwriterList', "", currentComponent).then(function (result) {
            currentComponent.setState({ UWList: result.underwriterList, loading: false });
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    handleAddOfficeInformation() {
        let isvalid = true;
        let CompanyId = 1;
        let currentComponent = this;
        if (this.state.OfficeName.length === 0 || this.state.OfficeName == null) {
            this.setState({ ofcName: true });
            isvalid = false;
        }
        else {
            this.setState({ ofcName: false });
        }
        if (this.state.rOEmail) {
            isvalid = false;
        }
        if (this.state.OPhone && this.state.OPhone.trim().length > 0 && !this.testPhone(this.state.OPhone)) {
            isvalid = false;
            this.setState({ rOPhone: true });
        }
        if (!this.state.oAPS && !this.state.oSummary) {
            isvalid = false;
            this.toasterredfun2("Product must be selected");
        }

        if (this.state.clientAPS && this.state.clientSummary) {
            if ((this.state.OfficeAccountNumber.trim().length == 0 || this.state.OfficeAccountNumber == null) && (this.state.SummaryOfficeAccountNumber.trim().length == 0 || this.state.SummaryOfficeAccountNumber == null)) {
                isvalid = false;
                this.toasterredfun2("Office Account # is required");
            }
        } else if (this.state.clientAPS && this.state.OfficeAccountNumber.trim().length == 0 || this.state.OfficeAccountNumber == null) {
            isvalid = false;
            this.toasterredfun2("APS Office Account # is required");
        } else if (this.state.clientSummary && this.state.SummaryOfficeAccountNumber.trim().length == 0 || this.state.SummaryOfficeAccountNumber == null) {
            isvalid = false;
            this.toasterredfun2("Summary Office Account # is required");
        }
        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true });
            Api.Post('api/placeorder/AddOrderingOfficeInformation', {
                "OfficeName": currentComponent.state.OfficeName, "OfficeAccountNumber": currentComponent.state.OfficeAccountNumber,
                "OfficeCode": currentComponent.state.officeCode, "OfficeStatus": currentComponent.state.orderselectValue,
                "CompanyId": CompanyId, "Email": currentComponent.state.OEmail, "Phone": currentComponent.state.OPhone,
                "AllowViewResults": currentComponent.state.AllowViewResults === "Yes",
                "AllowViewStatus": currentComponent.state.AllowViewStatus === "Yes",
                "AllowViewNotes": currentComponent.state.AllowViewNotes === "Yes",
                "AllowPlaceOrder": currentComponent.state.AllowPlaceOrder === "Yes",
                "AllowOverrideAccess": currentComponent.state.AllowOverrideAccess == "Yes",
                "AllowViewAuth": currentComponent.state.AllowViewAuth === "Yes",
                "UserType": userType,
                "SummaryOfficeAccountNumber": currentComponent.state.SummaryOfficeAccountNumber,
                "APS": currentComponent.state.oAPS,
                "Summary": currentComponent.state.oSummary
            }, currentComponent).then(function (result) {
                if (result.statusMessage === "Success") {
                    currentComponent.setState({ OrderingOfficeInformationList: result.orderOfficeInformationList, isOfficeModalopen: false });
                    let jsonArr = [];
                    result.orderOfficeInformationList.forEach((doc, index) => {
                        if (doc.officeStatus === "Active") {
                            jsonArr.push({
                                id: result.orderOfficeInformationList[index].id,
                                key: result.orderOfficeInformationList[index].officeName,
                                po: result.orderOfficeInformationList[index].allowPlaceOrder,
                                vs: result.orderOfficeInformationList[index].allowViewStatus,
                                vn: result.orderOfficeInformationList[index].allowViewNotes,
                                vr: result.orderOfficeInformationList[index].allowViewResults,
                                va: result.orderOfficeInformationList[index].allowViewAuth
                            });
                        }
                    })
                    currentComponent.setState({ ObjectArray: jsonArr, loading: false });
                    currentComponent.clearOfficeInformation();
                    currentComponent.toasterfun2("Ordering office information has been added successfully.");
                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                    currentComponent.setState({ loading: false });
                }

            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }
    handleUpdateOfficeInformation() {
        let CompanyId = 1;
        let isvalid = true;
        let currentComponent = this;
        if (this.state.OfficeName == null || this.state.OfficeName.length === 0) {
            this.setState({ ofcName: true });
            isvalid = false;
        }
        else {
            this.setState({ ofcName: false });
        }
        if (this.state.rOEmail) {
            isvalid = false;

        }
        if (this.state.OPhone && this.state.OPhone.trim().length > 0 && !this.testPhone(this.state.OPhone)) {
            isvalid = false;
            this.setState({ rOPhone: true });
        }
        if (!this.state.oAPS && !this.state.oSummary) {
            isvalid = false;
            this.toasterredfun2("Product must be selected");
        }

        if (this.state.clientAPS && this.state.clientSummary) {
            if ((this.state.OfficeAccountNumber.trim().length == 0 || this.state.OfficeAccountNumber == null) && (this.state.SummaryOfficeAccountNumber.trim().length == 0 || this.state.SummaryOfficeAccountNumber == null)) {
                isvalid = false;
                this.toasterredfun2("APS Office Account # is required");
            }
            if ((this.state.oAPS && !this.state.oSummary) && (this.state.OfficeAccountNumber.trim().length == 0 || this.state.OfficeAccountNumber == null)) {
                isvalid = false;
                this.toasterredfun2("APS Office Account # is required");
            }
            if ((!this.state.oAPS && this.state.oSummary) && (this.state.SummaryOfficeAccountNumber.trim().length == 0 || this.state.SummaryOfficeAccountNumber == null)) {
                isvalid = false;
                this.toasterredfun2("Summary Office Account # is required");
            }
        } else if (this.state.clientAPS && this.state.OfficeAccountNumber.trim().length == 0 || this.state.OfficeAccountNumber == null) {
            isvalid = false;
            this.toasterredfun2("APS Office Account # is required");
        } else if (this.state.clientSummary && this.state.SummaryOfficeAccountNumber.trim().length == 0 || this.state.SummaryOfficeAccountNumber == null) {
            isvalid = false;
            this.toasterredfun2("Summary Office Account # is required");
        }

        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true });
            Api.Post('api/placeorder/UpdateOrderingOfficeInformation', {
                "OfficeName": currentComponent.state.OfficeName, "OfficeAccountNumber": currentComponent.state.OfficeAccountNumber,
                "Id": currentComponent.state.OfficeInformationId, "OfficeStatus": currentComponent.state.orderselectValue,
                "CompanyId": CompanyId, "NumberOfUsers": currentComponent.state.numberOfUsers, "OfficeCode": currentComponent.state.officeCode, "Email": currentComponent.state.OEmail, "Phone": currentComponent.state.OPhone,
                "AllowViewResults": currentComponent.state.AllowViewResults === "Yes",
                "AllowViewStatus": currentComponent.state.AllowViewStatus === "Yes",
                "AllowViewNotes": currentComponent.state.AllowViewNotes === "Yes",
                "AllowPlaceOrder": currentComponent.state.AllowPlaceOrder === "Yes",
                "AllowOverrideAccess": currentComponent.state.AllowOverrideAccess === "Yes",
                "AllowViewAuth": currentComponent.state.AllowViewAuth === "Yes",
                "IsCustomize": currentComponent.state.IsCustomize,
                "UserType": userType,
                "APS": currentComponent.state.oAPS,
                "Summary": currentComponent.state.oSummary,
                "SummaryOfficeAccountNumber": currentComponent.state.SummaryOfficeAccountNumber
            }, currentComponent).then(function (result) {
                if (result.statusMessage === "Success") {
                    currentComponent.setState({ OrderingOfficeInformationList: result.orderOfficeInformationList });
                    currentComponent.setState({ isOfficeEditModalopen: false });
                    let jsonArr = [];
                    result.orderOfficeInformationList.forEach((doc, index) => {
                        if (doc.officeStatus === "Active") {
                            jsonArr.push({
                                officeId: result.orderOfficeInformationList[index].id,
                                officeName: result.orderOfficeInformationList[index].officeName,
                                placeordervalue: result.orderOfficeInformationList[index].allowPlaceOrder,
                                viewstatusvalue: result.orderOfficeInformationList[index].allowViewStatus,
                                viewnotesvalue: result.orderOfficeInformationList[index].allowViewNotes,
                                viewresultsvalue: result.orderOfficeInformationList[index].allowViewResults,
                                viewauthvalue: result.orderOfficeInformationList[index].allowViewAuth,
                                Customize: false,
                            });
                        }
                    })
                    currentComponent.setState({ loading: false });
                    currentComponent.clearOfficeInformation();
                    currentComponent.GetOrderingOfficeInformationListByCompanyId();
                    currentComponent.toasterfun2("Ordering office has been updated successfully.");
                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });

            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }

    handleUpdateCMInformation() {
        let isvalid = false;
        let currentComponent = this;
        if (this.state.CMEmailAddress.length !== 0) {
            if (currentComponent.state.rCMEmailAddress) {
                currentComponent.setState({ rCMEmailAddress: true });
            }
            else if (this.state.CMFirstName.length !== 0 || this.state.CMLastName.length !== 0 || this.state.CMWorkPhone.length !== 0 || this.state.CMExt.length !== 0 || this.state.CMCellPhone.length !== 0 || this.state.CMFax.length !== 0) {
                isvalid = true;
            }
        }
        else {
            currentComponent.setState({ loading: false });
            currentComponent.toasterredfun2("At Least One Field Required For Client Manager Record Update.");
            currentComponent.setState({ loading: false });
        }
        if (this.state.CMCellPhone != null && this.state.CMCellPhone.length !== 0) {
            if (!this.testPhone(this.state.CMCellPhone)) {
                isvalid = false;
                this.setState({ rCMCellPhone: true });
            }
        }
        if (this.state.CMWorkPhone.length !== 0 && this.state.CMWorkPhone != null) {
            if (!this.testPhone(this.state.CMWorkPhone)) {
                isvalid = false;
                this.setState({ rCMWorkPhone: true });
            }
        }
        if (this.state.CMFax.length !== 0 && this.state.CMFax != null) {
            if (!this.testPhone(this.state.CMFax)) {
                isvalid = false;
                this.setState({ rCMFax: true });
            }
        }
        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true });
            Api.Post('api/placeorder/UpdateCarrierManagerInformation', {
                "Id": currentComponent.state.CMId, "FirstName": currentComponent.state.CMFirstName,
                "LastName": currentComponent.state.CMLastName, "EmailAddress": currentComponent.state.CMEmailAddress,
                "WorkPhone": currentComponent.state.CMWorkPhone, "Ext": currentComponent.state.CMExt,
                "CellPhone": currentComponent.state.CMCellPhone, "Fax": currentComponent.state.CMFax,
                "UserType": userType

            }, currentComponent).then(function (result) {
                if (result.statusMessage === "Success") {
                    currentComponent.setState({ CMList: result.carrierManagerList });
                    currentComponent.setState({ isCMEditModalopen: false });
                    currentComponent.clearCMInformation();
                    currentComponent.toasterfun2("Client Manager has been Updated Successfully.");
                }
                else if ((result.statusCode === 403) || (result.statusCode === 402)) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });

            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }
    handleUpdateUWInformation() {

        let isvalid = false;
        let currentComponent = this;
        if (this.state.UWEmailAddress.length != 0) {
            if (currentComponent.state.rUWEmailAddress) {
                currentComponent.setState({ rUWEmailAddress: true });
                isvalid = false;
            }
            else if (this.state.UWFirstName.length != 0 || this.state.UWLastName.length != 0 || this.state.UWWorkPhone.length != 0 || this.state.UWExt.length != 0 || this.state.UWCellPhone.length != 0 || this.state.UWFax.length != 0) {
                isvalid = true;
            }
        }
        else {
            isvalid = false;
            currentComponent.setState({ loading: false });
            currentComponent.toasterredfun2("At Least One Field Required For Underwriter Record Update.");
            currentComponent.setState({ loading: false });
        }
        if (this.state.UWCellPhone != null && this.state.UWCellPhone.length != 0) {
            if (!this.testPhone(this.state.UWCellPhone)) {
                isvalid = false;
                this.setState({ rUWCellPhone: true });
            }
        }
        if (this.state.UWWorkPhone.length != 0 && this.state.UWWorkPhone != null) {
            if (!this.testPhone(this.state.UWWorkPhone)) {
                isvalid = false;
                this.setState({ rUWWorkPhone: true });
            }
        }
        if (this.state.UWFax.length != 0 && this.state.UWFax != null) {
            if (!this.testPhone(this.state.UWFax)) {
                isvalid = false;
                this.setState({ rUWFax: true });
            }
        }
        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true });
            Api.Post('api/placeorder/UpdateUnderwriterInformation', {
                "Id": currentComponent.state.UWId, "FirstName": currentComponent.state.UWFirstName,
                "LastName": currentComponent.state.UWLastName, "EmailAddress": currentComponent.state.UWEmailAddress,
                "WorkPhone": currentComponent.state.UWWorkPhone, "Ext": currentComponent.state.UWExt,
                "CellPhone": currentComponent.state.UWCellPhone, "Fax": currentComponent.state.UWFax,
                "UserType": userType

            }, currentComponent).then(function (result) {

                if (result.statusMessage == "Success") {
                    currentComponent.setState({ UWList: result.underwriterList });
                    currentComponent.setState({ isUWEditModalopen: false });
                    currentComponent.clearUWInformation();
                    currentComponent.toasterfun2("Underwriter has been Updated Successfully.");
                }
                else if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else if (result.statusCode == 402) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });

            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }
    handleAddAgentUser(e) {
        let CompanyId = 1, isvalid = true;
        this.setState({
            selectedOfficeList: []
        });
        this.state.SelectedRows.forEach((doc, index) => {
            this.state.selectedOfficeList.push(doc);
        });

        if (this.state.UserType == "Agent") {
            if (this.state.selectedOfficeList.length == 0) {
                this.toasterredfun2("Atlease one office should be selected");
                isvalid = false;
            }
        }

        if (this.state.FirstName == null || this.state.FirstName.trim().length == 0) {
            isvalid = false;
            this.setState({ rFirstName: true });
        }
        if (this.state.LastName == null || this.state.LastName.trim().length == 0) {
            isvalid = false;
            this.setState({ rLastName: true });
        }
        if (this.state.Phone1 == null || this.state.Phone1.trim().length == 0 || !this.testPhone(this.state.Phone1)) {
            isvalid = false;
            this.setState({ rPhone1: true });
        }
        if (this.state.Ext1.trim().length != 0) {
            if (!this.testEXT(this.state.Ext1)) {
                isvalid = false;
                this.setState({ rExt1: true });
            }
        }
        if (this.state.Phone2.trim().length != 0) {
            if (!this.testPhone(this.state.Phone2)) {
                isvalid = false;
                this.setState({ rPhone2: true });
            }
        }
        if (this.state.Fax.trim().length != 0) {
            if (!this.testPhone(this.state.Fax)) {
                isvalid = false;
                this.setState({ rFax: true });
            }
        }
        if (this.state.AgentEmail == null || this.state.AgentEmail.trim().length == 0) {
            isvalid = false;
            this.setState({ rAgentEmail: true });
        }

        if (this.state.AgentEmail.trim().length > 0 && this.state.UserType == "eNoah Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
            this.setState({ rAgentEmail: true, rUserType: true });
            isvalid = false;
            this.toasterredfun2("eNoah Admin user must be eNoah Domain User");
        }
        else if (this.state.AgentEmail.trim().length > 0 && this.state.UserType == "eNoah System Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
            this.setState({ rAgentEmail: true, rUserType: true });
            isvalid = false;
            this.toasterredfun2("eNoah System Admin user must be eNoah Domain User");
        }

        if (this.state.UCity == null || this.state.UCity.trim().length == 0) {
            isvalid = false;
            this.setState({ rUCity: true });
        }
        if (this.state.ST == null || this.state.ST.trim().length == 0 || this.state.ST == "Select") {
            isvalid = false;
            this.setState({ rST: true });
        }
        if (this.state.Zip == null || this.state.Zip.trim().length === 0 || this.state.Zip.length < 5 ||
            (this.state.Zip.length > 5 && this.state.Zip.length < 10) || (this.state.Zip.length === 5 && !this.testZip(this.state.Zip))
            || (this.state.Zip.length === 10 && !this.testZip5(this.state.Zip))) {
            isvalid = false;
            this.setState({ rZip: true });
        }
        if (this.state.UserType == null || this.state.UserType.trim().length == 0 || this.state.UserType == "Select") {
            isvalid = false;
            this.setState({ rUserType: true });
        }
        if (this.state.EnableSSOConnection && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
            if (this.state.Identifiervalue.trim().length == 0) {
                isvalid = false;
                this.setState({ rIdentifiervalue: true });
            }
        }
        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true });
            let currentComponent = this;
            Api.Post('api/placeorder/AddAgentUser', {
                "CarrierId": CompanyId,
                "FirstName": currentComponent.state.FirstName, "LastName": currentComponent.state.LastName, "AgentId": currentComponent.state.AgentId, "Fax": currentComponent.state.Fax,
                "AgentEmail": currentComponent.state.AgentEmail, "CompanyId": CompanyId, "Phone1": currentComponent.state.Phone1, "Ext1": currentComponent.state.Ext1,
                "Phone2": currentComponent.state.Phone2, "Ext2": currentComponent.state.Ext2, "AddressLine1": currentComponent.state.AddressLine1,
                "AddressLine2": currentComponent.state.AddressLine2, "City": currentComponent.state.UCity, "ST": currentComponent.state.ST, "Zip": currentComponent.state.Zip,
                "UserType": currentComponent.state.UserType,
                "AccessLevel": currentComponent.state.AccessType,
                "ShowAdminMenu": currentComponent.state.ShowAdminMenu,
                "DefaultOffice": currentComponent.state.DefaultOffice,
                "OrderingCarriers": currentComponent.state.SelectedValues.map(item => item.id), "IsEnableSSO": currentComponent.state.EnableSSOConnection, "Identifier": currentComponent.state.Identifier, "Identifiervalue": (currentComponent.state.Identifiervalue ? currentComponent.state.Identifiervalue.trim() : ""),
                "OrderingOffices": currentComponent.state.selectedOfficeList,
                "IsCustomize": currentComponent.state.IsCustomize,
                "CHOPlaceOrder": currentComponent.state.CHOPlaceOrderValue === "Yes",
                "CHODownloadImages": currentComponent.state.CHOViewResultsValue === "Yes",
                "CHOViewStatus": currentComponent.state.CHOViewStatusValue === "Yes",
                "CHOViewNotes": currentComponent.state.CHOViewNoteValue === "Yes",
                "CHOViewAuth": currentComponent.state.CHOViewAuthValue === "Yes",
                "ExportSearchResults": currentComponent.state.ExportSearchResultsValue === "Yes",
                "PerformanceMetrics": currentComponent.state.PerformanceMetricsValue === "Yes",
                "OnLoadUserType": userType,
            }, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({
                        UserList: [], OrderingOfficeInformationList: []
                    }); currentComponent.setState({
                        UserList: result.userList, OrderingOfficeInformationList: result.orderOfficeInformation
                    });
                    currentComponent.handleResetSelectOfc();
                    currentComponent.resetAccessLevel();
                    currentComponent.setState({ isUserModalopen: false, isOfficeModalopen: false, isAgentModalopen: false });
                    currentComponent.setState({ FirstName: "", LastName: "", AgentId: "", AgentEmail: "", Phone1: "", Fax: "", Ext1: "", Phone2: "", Ext2: "", AddressLine1: "", AddressLine2: "", UCity: "", ST: "", Zip: "", Identifiervalue: "", accessLevel_AllOffice: "" });
                    currentComponent.setState({ UserType: "", AccessType: "", PlaceOrder: "", DownloadAccess: "", StatusView: "", AuthView: "", SelectedValues: [], ATReadOnly: false, POReadOnly: false, DAReadOnly: false, SVReadOnly: false, AVReadOnly: false, ridentifier: false, rIdentifiervalue: false, ObjArrayDetails: [] });
                    currentComponent.toasterfun2("New User has been Created successfully.");
                }
                else if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else if (result.statusCode == 402) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
    }
    handleSubmitCompanyDetails(e) {
        this.setState({ removeAgentAlert: false });
        let CompanyId = 1, isvalid = true;
        this.setState({ rRetentionPeriodDays: false, rProductionPurgeDays: false, rOfflineArchivePurgeDays: false });
        if (this.state.APS) {
            if (this.state.CropNumber == null || this.state.CropNumber.length == 0) {
                isvalid = false;
                this.setState({ rCropNumber: true });
            }
            if (this.state.CropNumber.length != 0) {
                if (!this.testAlphaNumeric(this.state.CropNumber)) {
                    isvalid = false;
                    this.setState({ rCropNumber: true });
                }
            }
        }
        if (!this.state.APS && this.state.Summary) {
            if (this.state.SummaryCropNumber == null || this.state.SummaryCropNumber.length == 0) {
                isvalid = false;
                this.setState({ rSummaryCropNumber: true });
            }
            if (this.state.SummaryCropNumber.length != 0) {
                if (!this.testAlphaNumeric(this.state.SummaryCropNumber)) {
                    isvalid = false;
                    this.setState({ rSummaryCropNumber: true });
                }
            }
        }
        if (this.state.CarrierName == null || this.state.CarrierName.length == 0) {
            isvalid = false;
            this.setState({ rCarrierName: true });
        }
        if (this.state.CarrierName.length != 0) {
            if (!this.testAlphaNumericwithSpaceForCarrier(this.state.CarrierName)) {
                isvalid = false;
                this.setState({ rCarrierName: true });
            }
        }
        if (this.state.Address == null || this.state.Address.length === 0) {
            isvalid = false;
            this.setState({ rAddress: true });
        }
        if (this.state.City == null || this.state.City.length === 0) {
            isvalid = false;
            this.setState({ rCity: true });
        }
        if (this.state.City.length !== 0) {
            if (!this.testAlphawithSpaceforCity(this.state.City)) {
                isvalid = false;
                this.setState({ rCity: true });
            }
        }
        if (this.state.State == null || this.state.State.length === 0 || this.state.State === "Select") {
            isvalid = false;
            this.setState({ rState: true });
        }
        if (this.state.APS) {
            if (this.state.OHB == null || this.state.OHB.length === 0) {
                isvalid = false;
                this.setState({ rOHB: true });
            }
            if (this.state.OHB == "0") {
                isvalid = false;
                this.setState({ rOHB: true });
            }
            if (this.state.RHB == null || this.state.RHB.length == 0) {
                isvalid = false;
                this.setState({ rRHB: true });
            }
            if (this.state.RHB == "0") {
                isvalid = false;
                this.setState({ rRHB: true });
            }
        }
        if (this.state.ZipCode == null || this.state.ZipCode.length == 0) {
            isvalid = false;
            this.setState({ rZipCode: true });
        }
        else if (this.state.ZipCode.length < 5) {
            isvalid = false;
            this.setState({ rZipCode: true });
        }
        else if (this.state.ZipCode.length > 5 && this.state.ZipCode.length < 10) {
            isvalid = false;
            this.setState({ rZipCode: true });
        }
        else if (this.state.ZipCode.length == 5 && !this.testZip(this.state.ZipCode)) {
            isvalid = false;
            this.setState({ rZipCode: true });
        }
        else if (this.state.ZipCode.length == 10 && !this.testZip5(this.state.ZipCode)) {
            isvalid = false;
            this.setState({ rZipCode: true });
        }
        if (this.state.MainContactName == null || this.state.MainContactName.length == 0) {
            isvalid = false;
            this.setState({ rMainContactName: true });
        }
        if (this.state.MainContactEmail == null || this.state.MainContactEmail.length == 0) {
            isvalid = false;
            this.setState({ rMainContactEmail: true });
        }
        else if (this.state.rMainContactEmail) {
            isvalid = false;
        }
        if (this.state.MainContactPhone == null || this.state.MainContactPhone.length == 0 || !this.testPhone(this.state.MainContactPhone)) {
            isvalid = false;
            this.setState({ rMainContactPhone: true });
        }


        if (this.state.AlternateContactPhone.length != 0) {
            if (!this.testPhone(this.state.AlternateContactPhone)) {
                isvalid = false;
                this.setState({ rAlternateContactPhone: true });
            }
        }

        if (this.state.AlternateContactName.length != 0) {
            if (!this.testAlphaNumericwithSpaceForName(this.state.AlternateContactName)) {
                isvalid = false;
                this.setState({ rAlternateContactName: true });

            }
        }

        if (this.state.AlternateContactEmail.length != 0) {
            if (!this.testAlphaNumericwithSpaceForEmail(this.state.AlternateContactEmail)) {
                isvalid = false;
                this.setState({ rAlternateContactEmail: true });

            }
        }
        if (this.state.MainContactName.length != 0) {
            if (!this.testAlphaNumericwithSpaceForName(this.state.MainContactName)) {
                isvalid = false;
                this.setState({ rMainContactName: true });
            }
        }
        if (this.state.MainContactEmail.length != 0) {
            if (!this.testAlphaNumericwithSpaceForEmail(this.state.MainContactEmail)) {
                isvalid = false;
                this.setState({ rMainContactEmail: true });
            }
        }
        if (this.state.MainContactPhone.length != 0) {
            if (!this.testPhone(this.state.MainContactPhone)) {
                isvalid = false;
                this.setState({ rMainContactPhone: true });
            }
        }
        if (this.state.APS) {
            if (this.state.CustomerServiceContactName == null || this.state.CustomerServiceContactName.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactName: true });
            }
            else if (this.state.CustomerServiceContactName.length != 0) {
                if (!this.testAlphaNumericwithSpaceForName(this.state.CustomerServiceContactName)) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactName: true });
                }
            }
            if (this.state.rCustomerServiceContactEmail) {
                isvalid = false;
            }
            if (this.state.CustomerServiceContactEmail == null || this.state.CustomerServiceContactEmail.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactEmail: true });
            }
            else if (this.state.CustomerServiceContactEmail != null && this.state.CustomerServiceContactEmail.length != 0) {
                if (this.state.rCustomerServiceContactEmail) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactEmail: true });
                }
            }
            if (this.state.CustomerServiceContactPhone == null || this.state.CustomerServiceContactPhone.length == 0
                || (this.state.CustomerServiceContactPhone != null && this.state.CustomerServiceContactPhone.length != 0 && !this.testPhone(this.state.CustomerServiceContactPhone))) {
                isvalid = false;
                this.setState({ rCustomerServiceContactPhone: true });
            }
        }
        if (this.state.Summary) {
            if (this.state.CustomerServiceContactNameforSummary == null || this.state.CustomerServiceContactNameforSummary.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactNameforSummary: true });
            }
            else if (this.state.CustomerServiceContactNameforSummary.length != 0) {
                if (!this.testAlphaNumericwithSpaceForName(this.state.CustomerServiceContactNameforSummary)) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactNameforSummary: true });
                }
            }
            if (this.state.rCustomerServiceContactEmailforSummary) {
                isvalid = false;
            }
            if (this.state.CustomerServiceContactEmailforSummary == null || this.state.CustomerServiceContactEmailforSummary.length == 0) {
                isvalid = false;
                this.setState({ rCustomerServiceContactEmailforSummary: true });
            }
            else if (this.state.CustomerServiceContactEmailforSummary != null && this.state.CustomerServiceContactEmailforSummary.length != 0) {
                if (this.state.rCustomerServiceContactEmailforSummary) {
                    isvalid = false;
                    this.setState({ rCustomerServiceContactEmailforSummary: true });
                }
            }
            if (this.state.CustomerServiceContactPhoneforSummary == null || this.state.CustomerServiceContactPhoneforSummary.length == 0 || (this.state.CustomerServiceContactPhoneforSummary != null && this.state.CustomerServiceContactPhoneforSummary.length != 0 && !this.testPhone(this.state.CustomerServiceContactPhoneforSummary))) {
                isvalid = false;
                this.setState({ rCustomerServiceContactPhoneforSummary: true });
            }
        }
        if (this.state.DaysForEnforcePass.length != 0) {
            if (!this.testNumericForThreeDigitTokenLength(this.state.DaysForEnforcePass)) {
                isvalid = false;
                this.setState({ rpassUpdateDays: true });
            }
        }
        if (this.state.RememberPassCount.length != 0) {
            if (!this.testNumericForTwoDigitTokenLength(this.state.RememberPassCount)) {
                isvalid = false;
                this.setState({ rRemPasswordCount: true });
            }
        }
        if (this.state.InactiveDays.length != 0) {
            if (!this.testNumericForThreeDigitTokenLength(this.state.InactiveDays)) {
                isvalid = false;
                this.setState({ rInactiveDays: true });
            }
        }
        if (this.state.RetentionPeriodDays.length != 0) {
            if (!this.testNumericForTokenLength(this.state.RetentionPeriodDays)) {
                isvalid = false;
                this.setState({ rRetentionPeriodDays: true })
            }
        }
        if (this.state.ProductionPurgeDays.length != 0) {
            if (!this.testNumericForTokenLength(this.state.ProductionPurgeDays)) {
                isvalid = false;
                this.setState({ rProductionPurgeDays: true })
            }
        }
        if (this.state.OfflineArchivePurgeDays.length != 0) {
            if (!this.testNumericForTokenLength(this.state.OfflineArchivePurgeDays)) {
                isvalid = false;
                this.setState({ rOfflineArchivePurgeDays: true })
            }
        }
        if (this.state.EnableSSOConnection === true && (!this.state.Identifiersso || this.state.Identifiersso.trim().length == 0)) {
            isvalid = false;
            this.setState({ rIdentifiersso: true });
        }
        if (this.state.EnableMFA === true && (!this.state.MFATokenLength || this.state.MFATokenLength.length == 0 || this.state.MFATokenLength == "0")) {
            isvalid = false;
            this.setState({ rMFATokenLength: true });
            this.toasterredfun2("The Token length cannot be 0.");
        }
        if (this.state.EnableMFA === true && this.state.MFATokenGenType == "7" && parseInt(this.state.MFATokenLength) < 2) {
            isvalid = false;
            this.setState({ rMFATokenLength: true });
            this.toasterredfun2("The Token length should be greater than 1.");
        }
        if (this.state.RetentionPeriodDays == null || this.state.RetentionPeriodDays.length == 0 || this.state.RetentionPeriodDays == "0") {
            this.setState({ rRetentionPeriodDays: true });
            this.toasterredfun2("The Retention Period Days should not be empty, and the value should be greater than 0.");
            return;
        }
        if (this.state.ProductionPurgeDays == null || this.state.ProductionPurgeDays.length == 0 || this.state.ProductionPurgeDays == "0") {
            this.setState({ rProductionPurgeDays: true });
            this.toasterredfun2("The Production Purge Days should not be empty, and the value should be greater than 0.");
            return;
        }
        if (this.state.OfflineArchivePurgeDays == null || this.state.OfflineArchivePurgeDays.length == 0 || this.state.OfflineArchivePurgeDays == "0") {
            this.setState({ rOfflineArchivePurgeDays: true });
            this.toasterredfun2("The Offline Archive Purge Days should not be empty, and the value should be greater than 0.");
            return;
        }
        let retentionPeriodDays = Number(this.state.RetentionPeriodDays);
        let productionPurgeDays = Number(this.state.ProductionPurgeDays);

        if (retentionPeriodDays == productionPurgeDays || retentionPeriodDays > productionPurgeDays) {
            this.setState({ rRetentionPeriodDays: true });
            this.toasterredfun2("Rentention Days must be lesser than Production Purge Days");
            return;
        }

        if (!this.state.IsPassNeverExpires) {

            if (this.state.DaysForEnforcePass == null || this.state.DaysForEnforcePass.length == 0 || this.state.DaysForEnforcePass > 120) {
                this.toasterredfun2("The Password Enforce Days should not be empty, and the value should not be greater than 120 ");
                this.setState({ rpassUpdateDays: true });
                return;
            }
            if (this.state.DaysForEnforcePass == 0 || this.state.DaysForEnforcePass < 0) {
                this.toasterredfun2("The Password Enforce Days should be  greater than 0 ");
                this.setState({ rpassUpdateDays: true });
                return;
            }
        }

        if (this.state.RememberPassCount == null || this.state.RememberPassCount.length == 0 || this.state.RememberPassCount > 10) {
            this.toasterredfun2("The Remember Password Count should not be empty, and the value should not be greater than 10 ");
            this.setState({ rRemPasswordCount: true });
            return;
        }
        if (this.state.RememberPassCount == 0 || this.state.RememberPassCount < 0) {
            this.toasterredfun2("The Remember Password Count should  be greater than 0 ");
            this.setState({ rRemPasswordCount: true });
            return;
        }
        if (!this.state.DisInactive) {

            if (this.state.InactiveDays == null || this.state.InactiveDays.length == 0 || this.state.InactiveDays > 120) {
                this.toasterredfun2("An Inactive Days should not be empty, and the value should not be greater than 120 ");
                this.setState({ rInactiveDays: true });
                return;
            }
            if (this.state.InactiveDays == 0 || this.state.InactiveDays < 0) {
                this.toasterredfun2("An Inactive Days should be greater than 0");
                this.setState({ rInactiveDays: true });
                return;
            }
        }

        if (!this.state.APS && !this.state.Summary) {
            this.toasterredfun2("Please choose the avialable products");
            return;
        }
        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true })
            let currentComponent = this;
            let data = {
                "Summarization": currentComponent.state.Summarization, "OrderHandledBy": currentComponent.state.OHB, "ReviewsHandledBy": currentComponent.state.RHB,
                "CropNumber": currentComponent.state.CropNumber, "SummaryCropNumber": currentComponent.state.SummaryCropNumber, "Name": currentComponent.state.CarrierName, "Address": currentComponent.state.Address,
                "City": currentComponent.state.City, "CompanyId": CompanyId, "State": currentComponent.state.State, "Zip": currentComponent.state.ZipCode,
                "MainContactName": currentComponent.state.MainContactName, "MainContactEmail": currentComponent.state.MainContactEmail, "MainContactPhone": currentComponent.state.MainContactPhone,
                "AlternateContactName": currentComponent.state.AlternateContactName, "AlternateContactEmail": currentComponent.state.AlternateContactEmail, "AlternateContactPhone": currentComponent.state.AlternateContactPhone,
                "CustomerServiceContactName": currentComponent.state.CustomerServiceContactName, "CustomerServiceContactEmail": currentComponent.state.CustomerServiceContactEmail, "CustomerServiceContactPhone": currentComponent.state.CustomerServiceContactPhone,
                "CustomerServiceContactNameforSummary": currentComponent.state.CustomerServiceContactNameforSummary, "CustomerServiceContactEmailforSummary": currentComponent.state.CustomerServiceContactEmailforSummary, "CustomerServiceContactPhoneforSummary": currentComponent.state.CustomerServiceContactPhoneforSummary,
                "IsEnableSSO": this.state.EnableSSOConnection, "Identifier": this.state.Identifiersso,
                "AllowViewResults": currentComponent.state.CarrierLevelVR === "Yes",
                "AllowViewStatus": currentComponent.state.CarrierLevelVS === "Yes",
                "AllowViewNotes": currentComponent.state.CarrierLevelVN === "Yes",
                "AllowViewAuth": currentComponent.state.CarrierLevelVA === "Yes",
                "AllowPlaceOrder": currentComponent.state.CarrierLevelPO === "Yes",
                "AllowPermissionOverride": currentComponent.state.CarrierLevelAOA === "Yes",
                "AllowDefaultOfficeCA": currentComponent.state.CarrierLevelDCA === "Yes",
                "PushToOfficesandUsers": currentComponent.state.PushToOffices,
                "IsCustomize": currentComponent.state.IsCustomize === "Yes",
                "AllowExportSearchResults": currentComponent.state.CarrierLevelESR === "Yes",
                "AllowPerformanceMetrics": currentComponent.state.CarrierLevelPMA === "Yes",
                "IsEnableMFA": currentComponent.state.EnableMFA,
                "MFATokenType": currentComponent.state.MFATokenType,
                "TokenExpiryDays": currentComponent.state.MFATokenExpiryDays,
                "TokenExpiryMin": currentComponent.state.MFATokenExpiryMin,
                "TokenGenType": currentComponent.state.MFATokenGenType,
                "TokenLength": currentComponent.state.MFATokenLength,
                "IsIpWithUser": currentComponent.state.MFAUserwithIP,
                "RetentionPeriodDays": currentComponent.state.RetentionPeriodDays,
                "OfflineArchivePurgeDays": currentComponent.state.OfflineArchivePurgeDays,
                "ProductionPurgeDays": currentComponent.state.ProductionPurgeDays,
                "DaysForEnforcePass": currentComponent.state.DaysForEnforcePass,
                "RememberPassCount": currentComponent.state.RememberPassCount,
                "InactiveDays": currentComponent.state.InactiveDays,
                "PassNeverExpires": currentComponent.state.IsPassNeverExpires,
                "NeverInactive": currentComponent.state.IsNeverInactive,
                "IsEnableIp": currentComponent.state.ipAddressChange,
                "UserType": userType,
                "AllowCSRMessaging": currentComponent.state.AllowCSRMessaging === "Yes",
                "APS": currentComponent.state.APS,
                "Summary": currentComponent.state.Summary,
                "AssignOfficebyAccountNumb": currentComponent.state.EnableOfficebyAccountNum
            };

            Api.Post('api/placeorder/SubmitCompanyDetails', data, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({ Carrier: currentComponent.state.CarrierName, Identifier: currentComponent.state.Identifiersso });
                    currentComponent.toasterfun2("Client Details updated successfully.");
                    currentComponent.setState({ rRetentionPeriodDays: false, rProductionPurgeDays: false, rOfflineArchivePurgeDays: false });
                    currentComponent.GetCarrierDetails();
                    currentComponent.EnableDisableAccessPermissions();
                    currentComponent.GetOrderingOfficeInformationListByCompanyId();
                    currentComponent.setState({ clientAPS: currentComponent.state.APS, clientSummary: currentComponent.state.Summary })
                }
                else if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else if (result.statusCode == 402) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    let errorMessage;
                    if (result.errorList != null && result.errorList.length > 0) {
                        errorMessage = result.errorList[0];
                    } else {
                        errorMessage = result.statusMessage;
                    }
                    currentComponent.toasterredfun2(errorMessage);
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
    }

    handleStatusViewChange(e) {
        this.setState({ AssignedViewStatusValue: e.target.value, StatusView: e.target.value, rStatusView: false });
    }
    handleAuthViewChange(e) {
        this.setState({ AssignedViewAuthValue: e.target.value, AuthView: e.target.value, rAuthView: false });
    }
    handleDownloadAccessChange(e) {
        this.setState({ AssignedViewResultsValue: e.target.value, DownloadAccess: e.target.value, rDownloadAccess: false });
    }

    handleShowAdminMenuChange(e) {
        this.setState({ ShowAdminMenu: e.target.value, rAdminMenu: false });
    }

    handlePlaceOrderChange(e) {
        this.setState({ PlaceOrder: e.target.value, rPlaceOrder: false });
    }
    handleAccessTypeChangeForAllOffice(e) {
        let data = this.state.ObjectArray;
        this.setState({ accessLevel_AllOffice: e.target.value });
        for (let filtereddata of data) {
            filtereddata.accessLevel = e.target.value;
        }
        this.setState({
            ObjectArray: data,
        });
    }
    handleAccessTypeChange(e, rowData) {
        let data = this.state.ObjectArray;
        let filtereddata = data.find((d) => { return d.id == rowData.id });
        filtereddata.accessLevel = e.target.value;
        this.setState({
            ObjectArray: data
        });
    }
    handleCHOViewResultsChange(e) {
        this.setState({ CHOViewResultsValue: e.target.value });
    }
    handleCHOPlaceOrderChange(e) {
        this.setState({ CHOPlaceOrderValue: e.target.value });
    }
    handleCHOViewStatusChange(e) {
        this.setState({ CHOViewStatusValue: e.target.value });
    }
    handleCHOViewNotesChange(e) {
        this.setState({ CHOViewNoteValue: e.target.value });
    }
    handleCHOViewAuthChange(e) {
        this.setState({ CHOViewAuthValue: e.target.value });
    }
    handleExportSearchResultsChange(e) {
        this.setState({ ExportSearchResultsValue: e.target.value });
    }
    handlePerformanceMetricsChange(e) {
        this.setState({ PerformanceMetricsValue: e.target.value });
    }
    handleallowCarrierLevelPMAChange(e) {
        this.setState({ CarrierLevelPMA: e.target.value });
    }
    handleallowCarrierLevelESRChange(e) {
        this.setState({ CarrierLevelESR: e.target.value });
    }
    handleUserTypeChange(e) {
        this.setState({ UserType: e.target.value, rUserType: false, ToDefaultOffice: [] });
        if (e.target.value == "Client Admin" || e.target.value == "eNoah Admin" || e.target.value == "eNoah System Admin") {
            if (e.target.value == "eNoah Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
                this.setState({ rAgentEmail: true, rUserType: true });
                this.toasterredfun2("eNoah Admin user must be eNoah Domain User");
            }
            else if (e.target.value == "eNoah System Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
                this.setState({ rAgentEmail: true, rUserType: true });
                this.toasterredfun2("eNoah Sytem Admin user must be eNoah Domain User");
            }
            else {
                this.setState({ rUserType: false });
                this.validateEmail();
            }
            if (e.target.value == "Client Admin") {
                this.setState({ ToDefaultOffice: this.state.ObjectArray });
            }

            this.setState({
                PlaceOrder: "true", DownloadAccess: "true", AccessType: "All", StatusView: "true", AuthView: "true",
                ATReadOnly: true, POReadOnly: true, DAReadOnly: true, SVReadOnly: true,
                rAccessType: false, IsAdmin: true, AVReadOnly: true
            });
        }
        else if (e.target.value == "Client Home Office") {
            this.setState({ ToDefaultOffice: this.state.ObjectArray });
            this.setState({
                CHODownloadImages: this.state.CHOViewResultsValue,
                CHOPlaceOrder: this.state.CHOPlaceOrderValue,
                CHOViewStatus: this.state.CHOViewStatusValue,
                CHOViewNotes: this.state.CHOViewNoteValue,
                CHOViewAuth: this.state.CHOViewAuthValue,
                AccessType: "All",
                IsAdmin: true,
            });
            this.validateEmail();
        }
        else if (e.target.value == "Agent") {
            let data = [...this.state.SelectedRows];
            let DefaultRows = [];
            for (let item of data) {
                if (item.selectedOfc && item.po) {
                    DefaultRows.push(item);
                }
            }
            this.setState({
                ATReadOnly: false, POReadOnly: false, DAReadOnly: false, SVReadOnly: false, AVReadOnly: false, IsAdmin: false, isClientAdmin: false, ToDefaultOffice: DefaultRows //this.state.SelectedRows
            });
            this.validateEmail();
        }
    }
    async validateEmail() {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        let isAdmin = await this.CheckForAdmin();
        if (isAdmin) {
            let data = { Email: currentComponent.state.AgentEmail.replace(/ /g, '') };
            Api.Post("services/api/IsValidEmailMailAddress/", data, currentComponent).then(function (result) {
                currentComponent.setState({ rAgentEmail: !result, loading: false });
            })
                .catch(function (error) {
                    currentComponent.setState({ rAgentEmail: false, loading: false });
                });
        }

    }

    handleOnSelect(selectedList, selectedItem) {
        this.setState({ SelectedValues: selectedList, Style: this.style1 });

    }

    remove(removedItem) {
        let rowList = this.state.rowList.length > 0 ? [...this.state.rowList] : 0;
        if (rowList.length != null && rowList != 0) {
            let removeIndex = rowList.map(item => item.officeName).indexOf(removedItem.key);
            ~removeIndex && rowList.splice(removeIndex, 1);
            this.setState({ rowList });
        }
    }

    handleOnRemove(selectedList, removedItem) {
        this.remove(removedItem);
        if (this.state.DefaultOffice != "" && this.state.DefaultOffice != "Select" && this.state.DefaultOffice == removedItem.id) {
            this.setState({ DefaultOffice: "" });
        }
        this.setState({ SelectedValues: selectedList, Style: this.style1 });
    }
    onClickopenUserPopup(e) {
        this.setState({ isUserModalopen: true, isEdit: false });
    }
    onClickopenOfficePopup(e) {
        this.setState({
            isOfficeModalopen: true,
            AllowViewResults: this.state.CarrierLevelVR,
            AllowPlaceOrder: this.state.CarrierLevelPO,
            AllowViewStatus: this.state.CarrierLevelVS,
            AllowViewNotes: this.state.CarrierLevelVN,
            AllowViewAuth: this.state.CarrierLevelVA
        });
        this.clearOfficeInformation();
    }
    onClickopenOfficeEditPopup = (rowData) => {
        this.setState({ isOfficeEditModalopen: true });
        this.setState({
            OfficeName: rowData.officeName, AllowPlaceOrder: rowData.allowPlaceOrder === true ? "Yes" : "No", AllowViewNotes: rowData.allowViewNotes === true ? "Yes" : "No", AllowViewResults: rowData.allowViewResults === true ? "Yes" : "No", AllowViewStatus: rowData.allowViewStatus === true ? "Yes" : "No", AllowViewAuth: rowData.allowViewAuth === true ? "Yes" : "No", OfficeAccountNumber: rowData.officeAccountNumber, officeCode: rowData.officeCode, orderselectValue: rowData.officeStatus, OfficeInformationId: rowData.id, OEmail: rowData.email, OPhone: rowData.phone,
            oAPS: rowData.aps, oSummary: rowData.summary
        })
    }
    onClickopenCMEditPopup = (rowData) => {
        this.setState({ isCMEditModalopen: true });
        this.setState({ CMId: rowData.id, CMFirstName: rowData.firstName, CMLastName: rowData.lastName, CMEmailAddress: rowData.emailAddress, CMWorkPhone: rowData.workPhone, CMExt: rowData.ext, CMCellPhone: rowData.cellPhone, CMFax: rowData.fax })
    }
    onClickopenUWEditPopup = (rowData) => {
        this.setState({ isUWEditModalopen: true });
        this.setState({ UWId: rowData.id, UWFirstName: rowData.firstName, UWLastName: rowData.lastName, UWEmailAddress: rowData.emailAddress, UWWorkPhone: rowData.workPhone, UWExt: rowData.ext, UWCellPhone: rowData.cellPhone, UWFax: rowData.fax })
    }
    onClickopenAgentPopup(e) {
        this.setState({ IsAdmin: false, isAgentModalopen: true, isEdit: false, rowList: [], ToDefaultOffice: [], SelectAllOffices: false, ObjArrayDetails: this.state.ObjectArray, muiTableKey: this.state.muiTableKey + 1, DefaultOffice: "", SelectedRows: [] });
        this.setState({
            CHOViewResultsValue: this.state.valueCarrierLevelVR,
            CHOPlaceOrderValue: this.state.valueCarrierLevelPO,
            CHOViewStatusValue: this.state.valueCarrierLevelVS,
            CHOViewNoteValue: this.state.valueCarrierLevelVN,
            CHOViewAuthValue: this.state.valueCarrierLevelVA,
            ExportSearchResultsValue: this.state.valueCarrierLevelESR,
            PerformanceMetricsValue: this.state.valueCarrierLevelPMA
        });
        this.resetAccessLevel();
    }

    handleDOChange(e) {
        this.setState({ DefaultOffice: e.target.value, rDefaultOffice: false });
    }
    handleOfficeCodeChange(e) {
        this.setState({ ofcCode: false });
        if (e.target.value.length == 0 || this.testAlphaNumeric(e.target.value)) {
            this.setState({ officeCode: e.target.value });
        }
    }

    closePopupUserOffice(e) {
        this.setState({ isOfficeForUserModalopen: false, isAgentModalopen: true });
    }

    closePopup(e) {
        this.setState({
            isUserModalopen: false, isFieldsEditModalopen: false, isviewModalopen: false, isOfficeModalopen: false, isAgentModalopen: false, rUCity: false, rFirstName: false, rLastName: false, rAgentId: false, rAgentEmail: false, rPhone1: false, rExt1: false, rPhone2: false, rExt2: false, rAddressLine1: false, rAddressLine2: false, rCity: false, rST: false, rZip: false, rFax: false,
            rUserType: "", rAccessType: "", rPlaceOrder: "", rDownloadAccess: "", rStatusView: "", rAuthView: "", rSelectedValues: false, Style: this.style1, isOfficeEditModalopen: false, isCMEditModalopen: false, isUWEditModalopen: false, ofcCode: false, ofcName: false, ofcAccount: false, OEmail: "", OPhone: "", rOPhone: false, rOEmail: false,
            rCMFirstName: false,
            rCMLastName: false,
            rCMEmailAddress: false,
            rCMWorkPhone: false,
            rCMExt: false,
            rCMCellPhone: false,
            rCMFax: false,
            Identifiervalue: "",
            mIdentifierSSO: false,
            rIdentifiervalue: false
        });
        this.setState({ CMFirstName: "", CMLastName: "", CMEmailAddress: "", CMWorkPhone: "", CMExt: "", CMCellPhone: "", CMFax: "" });
        this.setState({ FirstName: "", LastName: "", AgentId: "", AgentEmail: "", OrderingOffices: "", Phone1: "", Ext1: "", Phone2: "", Ext2: "", AddressLine1: "", AddressLine2: "", UCity: "", ST: "", Zip: "", AgentUserId: "", Fax: "" });
        this.setState({ UserType: "", AccessType: "", PlaceOrder: "", DownloadAccess: "", StatusView: "", AuthView: "", SelectedValues: [], SelectedRows: [], selectedOfficeList: [], ATReadOnly: false, POReadOnly: false, DAReadOnly: false, SVReadOnly: false, AVReadOnly: false, ObjectArray: this.state.AllofficeObjectArray, ObjArrayDetails: [] });
        this.setState({
            CHOViewResultsValue: "Yes",
            CHOPlaceOrderValue: "Yes",
            CHOViewStatusValue: "Yes",
            CHOViewNoteValue: "Yes",
            CHOViewAuthValue: "Yes"
        })
        this.setState({
            ExportSearchResultsValue: "Yes",
            PerformanceMetricsValue: "Yes",
            accessLevel_AllOffice: "",
            EnableOtherCheckBoxes: false,
            SelectCustomizeAll: false
        })
        this.handleResetSelectOfc();
        this.resetAccessLevel();
    }

    handleCustomFieldNameChange(e) {
        this.setState({ customField: false });
        if (e.target.value.length === 0 && e.target.value.length <= 35) {
            this.setState({ customFieldName: e.target.value });
        }
        else {
            const value = e.target.value;
            if (this.testAlphaNumericwithSpaceAndSpecialForCustomFieldsName(value) && value.length <= 35) {
                this.setState({ customFieldName: value });
            }
        }
    }

    onhandeditevent = (rowData) => {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ deleteAlert: true, OfficeInformationId: rowData.id, CompanyId: 1 });
    }
    handleEditAgentUser(e) {
        let CompanyId = 1, isvalid = true;
        if (this.state.FirstName == null || this.state.FirstName.trim().length == 0) {
            isvalid = false;
            this.setState({ rFirstName: true });
        }
        if (this.state.LastName == null || this.state.LastName.trim().length == 0) {
            isvalid = false;
            this.setState({ rLastName: true });
        }
        if (this.state.Phone1 == null || this.state.Phone1.trim().length == 0 || !this.testPhone(this.state.Phone1)) {
            isvalid = false;
            this.setState({ rPhone1: true });
        }
        if (this.state.Ext1 != null && this.state.Ext1.trim().length != 0) {
            if (!this.testEXT(this.state.Ext1)) {
                isvalid = false;
                this.setState({ rExt1: true });
            }
        }
        if (this.state.Phone2 != null && this.state.Phone2.trim().length != 0) {
            if (!this.testPhone(this.state.Phone2)) {
                isvalid = false;
                this.setState({ rPhone2: true });
            }
        }
        if (this.state.Fax != null && this.state.Fax.trim().length != 0) {
            if (!this.testPhone(this.state.Fax)) {
                isvalid = false;
                this.setState({ rFax: true });
            }
        }
        if (this.state.AgentEmail == null || this.state.AgentEmail.trim().length == 0) {
            isvalid = false;
            this.setState({ rAgentEmail: true });
        }
        if (this.state.UCity == null || this.state.UCity.trim().length == 0) {
            isvalid = false;
            this.setState({ rUCity: true });
        }
        if (this.state.ST == null || this.state.ST.trim().length == 0 || this.state.ST == "Select") {
            isvalid = false;
            this.setState({ rST: true });
        }
        if (this.state.Zip == null || this.state.Zip.trim().length === 0 || this.state.Zip.length < 5
            || (this.state.Zip.length > 5 && this.state.Zip.length < 10) || (this.state.Zip.length === 5 && !this.testZip(this.state.Zip)) ||
            (this.state.Zip.length === 10 && !this.testZip5(this.state.Zip))) {
            isvalid = false;
            this.setState({ rZip: true });
        }
        if (this.state.UserType == null || this.state.UserType.trim().length == 0 || this.state.UserType == "Select") {
            isvalid = false;
            this.setState({ rUserType: true });
        }
        if (this.state.AgentEmail.trim().length > 0 && this.state.UserType == "eNoah Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
            this.setState({ rAgentEmail: true, rUserType: true });
            isvalid = false;
            this.toasterredfun2("eNoah Admin user must be eNoah Domain User");
        }
        else if (this.state.AgentEmail.trim().length > 0 && this.state.UserType == "eNoah System Admin" && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
            this.setState({ rAgentEmail: true, rUserType: true });
            isvalid = false;
            this.toasterredfun2("eNoah System Admin user must be eNoah Domain User");
        }
        if (this.state.UserType == "Agent") {
            if (this.state.SelectedRows == null || this.state.SelectedRows.length == 0) {
                isvalid = false;
                this.setState({ Style: this.style2 });
                this.toasterredfun2("Atleast one office should be selected");
            }
        }

        this.setState({
            selectedOfficeList: [],
        });

        this.state.SelectedRows.forEach((doc) => {
            this.state.selectedOfficeList.push(doc);
        });


        if (this.state.EnableSSOConnection && !this.state.AgentEmail.toLowerCase().includes("@enoahisolution.com")) {
            if (isNullOrUndefined(this.state.Identifiervalue) || this.state.Identifiervalue.trim().length == 0) {
                isvalid = false;
                this.setState({ rIdentifiervalue: true });
            }
        }
        if (isvalid) {
            let userType = localStorage.getItem('UserType');
            this.setState({ loading: true });
            let currentComponent = this;

            Api.Post('api/placeorder/EditAgentUser', {
                "CarrierId": CompanyId, "AgentUserId": currentComponent.state.AgentUserId,
                "FirstName": currentComponent.state.FirstName, "LastName": currentComponent.state.LastName, "AgentId": currentComponent.state.AgentId, "Fax": currentComponent.state.Fax,
                "AgentEmail": currentComponent.state.AgentEmail, "CompanyId": CompanyId, "Phone1": currentComponent.state.Phone1, "Ext1": currentComponent.state.Ext1,
                "Phone2": currentComponent.state.Phone2, "Ext2": currentComponent.state.Ext2, "AddressLine1": currentComponent.state.AddressLine1,
                "AddressLine2": currentComponent.state.AddressLine2, "City": currentComponent.state.UCity, "ST": currentComponent.state.ST, "Zip": currentComponent.state.Zip,
                "PlaceOrder": currentComponent.state.PlaceOrder === "true", "UserType": currentComponent.state.UserType, "DownloadImages": currentComponent.state.DownloadAccess == "true" ? true : false,
                "AccessLevel": currentComponent.state.AccessType,
                "ShowAdminMenu": currentComponent.state.ShowAdminMenu,
                "ViewStatus": currentComponent.state.StatusView === "true", "DefaultOffice": currentComponent.state.DefaultOffice,
                "ViewAuth": currentComponent.state.AuthView === "true",
                "IsCustomize": currentComponent.state.IsCustomize,
                "OrderingCarriers": currentComponent.state.SelectedRows.map(item => item.id), "IsEnableSSO": currentComponent.state.EnableSSOConnection, "Identifier": currentComponent.state.Identifier, "Identifiervalue": (currentComponent.state.Identifiervalue ? currentComponent.state.Identifiervalue.trim() : ""),
                "OrderingOffices": currentComponent.state.selectedOfficeList,
                "CHOPlaceOrder": currentComponent.state.CHOPlaceOrderValue === "Yes",
                "CHODownloadImages": currentComponent.state.CHOViewResultsValue === "Yes",
                "CHOViewStatus": currentComponent.state.CHOViewStatusValue === "Yes",
                "CHOViewNotes": currentComponent.state.CHOViewNoteValue === "Yes",
                "CHOViewAuth": currentComponent.state.CHOViewAuthValue === "Yes",
                "ExportSearchResults": currentComponent.state.ExportSearchResultsValue === "Yes",
                "PerformanceMetrics": currentComponent.state.PerformanceMetricsValue === "Yes",
                "OnLoadUserType": userType
            }, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({
                        UserList: [], OrderingOfficeInformationList: []
                    });
                    currentComponent.setState({
                        UserList: result.userList, OrderingOfficeInformationList: result.orderOfficeInformation, SelectedValues: result.orderingCarriersDd
                    });
                    currentComponent.handleResetSelectOfc();
                    currentComponent.setState({ isUserModalopen: false, isOfficeModalopen: false, isAgentModalopen: false });
                    currentComponent.setState({ FirstName: "", LastName: "", AgentId: "", AgentEmail: "", Phone1: "", Fax: "", Ext1: "", Phone2: "", Ext2: "", AddressLine1: "", AddressLine2: "", UCity: "", ST: "", Zip: "", accessLevel_AllOffice: "" });
                    currentComponent.setState({ UserType: "", AccessType: "", PlaceOrder: "", DownloadAccess: "", StatusView: "", AuthView: "", SelectedValues: [], ATReadOnly: false, POReadOnly: false, DAReadOnly: false, SVReadOnly: false, AVReadOnly: false, rIdentifier: false, Identifiervalue: "" });
                    currentComponent.toasterfun2("User information has been updated successfully.");
                }
                else if (result.statusCode === 403 || result.statusCode === 402) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
    }

    Onclickisadminpopup() {
        let currentComponent = this;
        currentComponent.props.history.push("/dashboard");
        currentComponent.setState({ adminAlert: false, loading: true });
    }
    handleChange(e) {
        this.setState({ orderselectValue: e.target.value });
    }
    clearOfficeInformation() {
        this.setState({ OfficeName: "", OfficeAccountNumber: this.state.CropNumber, NumberOfUsers: "", CompanyId: "", officeCode: "", ofcCode: false, ofcAccount: false, ofcName: false, OEmail: "", OPhone: "", rOPhone: false, rOEmail: false, orderselectValue: "Active", oAPS: this.state.clientAPS, oSummary: this.state.clientSummary });
    }
    clearCMInformation() {
        this.setState({
            CMId: "", CMFirstName: "", CMLastName: "", CMEmailAddress: "", CMWorkPhone: "", CMExt: "", CMCellPhone: "", CMFax: "",
            rCMFirstName: false, rCMLastName: false, rCMEmailAddress: false, rCMWorkPhone: false, rCMExt: false, rCMCellPhone: false, rCMFax: false,
        });
    }
    ShowCareerManagerInfo = () => {
        this.setState({
            ShowUsersAndAgentsListing: false,
            ShowOrderingOfficeListing: false,
            ShowCareerManagerListing: true,
            ShowUnderwriterListing: false,
        });

    };
    clearUWInformation() {
        this.setState({
            UWId: "", UWFirstName: "", UWLastName: "", UWEmailAddress: "", UWWorkPhone: "", UWExt: "", UWCellPhone: "", UWFax: "",
            rUWFirstName: false, rUWLastName: false, rUWEmailAddress: false, rUWWorkPhone: false, rUWExt: false, rUWCellPhone: false, rUWFax: false,
        });
    }
    ShowUnderwriterInfo = () => {
        this.setState({
            ShowUsersAndAgentsListing: false,
            ShowOrderingOfficeListing: false,
            ShowCareerManagerListing: false,
            ShowUnderwriterListing: true,
        });

    };
    ShowOrderingOfficeInfo = () => {
        this.setState({
            ShowUsersAndAgentsListing: false,
            ShowCareerManagerListing: false,
            ShowUnderwriterListing: false,
            ShowOrderingOfficeListing: true,
        });
    };

    ShowUsersAndAgentsInfo = () => {
        this.setState({
            ShowOrderingOfficeListing: false,
            ShowCareerManagerListing: false,
            ShowUnderwriterListing: false,
            ShowUsersAndAgentsListing: true,
        });

    };
    onBlurAddressChange(e) {
        let checkClientAddress = Api.CheckForNoiseWords(e.target.value);
        if (!checkClientAddress) {

            this.setState({ Address: e.target.value });
        }
        else {

            this.setState({ Address: "" });
        }
    }
    render() {
        let landing = 'img/ems-logo.gif';
        const columns = [
            { title: 'Original Field Name', field: 'originalFieldName' },
            { title: 'Custom Field Name', field: 'customFieldName' },
            { title: 'Active', field: 'isActive', render: rowData => (rowData.isActive ? "Yes" : "No") }
        ];

        // Conditionally add columns based on state
        if (!this.state.UnderwriterFields) {
            columns.push({
                title: 'APS Required / Mandatory',
                field: 'isRequired',
                render: rowData => (rowData.isRequired ? "Yes" : "No")
            });
        }

        if (!this.state.CarrierManagerFields) {
            columns.push({
                title: 'Summary Required / Mandatory',
                field: 'summaryIsRequired',
                render: rowData => (rowData.summaryIsRequired ? "Yes" : "No")
            });
        }

        columns.push({
            title: 'Action',
            field: 'action',
            render: rowData => (
                <span>
                    <span className="edit-btnm editActionE" aria-hidden="true" onClick={() => this.onClickopenFieldEditPopup(rowData)}>
                        <img src="img/edit-icon.png" /> Edit
                    </span>
                </span>
            )
        });
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-div " + (this.state.deleteAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this item?
                        <div className="text-center-div">
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.Onclicktabledelpopup(this.state.removehidden, this.state.typehidden) }}>
                                Yes
                                <input
                                    type="hidden"
                                    value={this.state.removehidden}
                                    readOnly="readonly"
                                />
                                <input
                                    type="hidden"
                                    value={this.state.typehidden}
                                    readOnly="readonly"
                                />
                            </div>
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.handledeletebttn() }}>
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"removeAlert-divbrowser " + (this.state.adminAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" aria-hidden="true" onClick={this.Onclickisadminpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"removeAlert-div " + (this.state.removeAgentAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure to update Client information?
                        <div className="text-center-div">
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.handleSubmitCompanyDetails() }}>
                                Yes
                            </div>
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.handleremoveCancelbttn() }}>
                                No
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"removeAlert-div " + (this.state.unlockalert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you Sure to Unlock the User Account?
                        <div className="text-center-div">
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.UnlockUser(this.state.unlockid) }}>
                                Yes
                                <input
                                    type="hidden"
                                    value={this.state.unlockid}
                                    readOnly="readonly"
                                />
                            </div>
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.handleCloseAlert() }}>
                                No
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"removeAlert-div " + (this.state.inactivealert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you Sure to Unlock the User from Inactivity?
                        <div className="text-center-div">
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.UnlockInActiveUser(this.state.inactiveid) }}>
                                Yes
                                <input
                                    type="hidden"
                                    value={this.state.inactiveid}
                                    readOnly="readonly"
                                />
                            </div>
                            <div className="bttn popupButton" aria-hidden="true" onClick={(e) => { this.handleCloseAlert() }}>
                                No
                            </div>
                        </div>
                    </div>
                </div>

                <h3 className="text-right py-2 carrier-title bg-white rounded mt-2 pr-2">{this.state.Carrier}</h3>
                <div className="hgtDiv bg-white mt-2 px-3 rounded">
                    <div className="row">
                        <div className="col-md-6 card mb-2 placeholder-div">
                            <div className="card-header">Client Details</div>
                            <div className="card-body mt-2">
                                <div className="form-row">
                                    <div className="col-md-12 nopadding">
                                        <div className="col-md-3 pull-left nopadding">
                                            <label className="small mb-1" htmlFor="inputCarrierName">
                                                <span className="red ">*</span>Client Name{" "}
                                            </label>

                                        </div>
                                        <div className="col-md-7 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rCarrierName ? "redborder" : "blackborder")} id="inputCarrierName" value={this.state.CarrierName} onChange={this.handleCarrierNameChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 nopadding mt-1">
                                        <div className="col-md-3 pull-left nopadding">
                                            <label className="small mb-1" htmlFor="inputAddress">
                                                <span className="red ">*</span>Address{" "}
                                            </label>

                                        </div>
                                        <div className="col-md-8 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rAddress ? "redborder" : "blackborder")} id="inputAddress" value={this.state.Address} onChange={this.handleAddressChange} onBlur={this.onBlurAddressChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 nopadding">
                                        <div className="col-md-3 pull-left nopadding">
                                            <label className="small mdb-1" htmlFor="inputCity">
                                                <span className="red ">*</span>City
                                            </label>

                                        </div>
                                        <div className="col-md-2 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rCity ? "redborder" : "blackborder")} id="inputCity" value={this.state.City} onChange={this.handleCityChange} type="text" autoComplete="off" />
                                        </div>

                                        <div className="col-md-1 pull-left nopadding">
                                            <label className="small mdb-1" htmlFor="inputState">
                                                ST
                                            </label>
                                        </div>
                                        <div className="pull-left nopadding">
                                            <select className={"form-control " + (this.state.rState ? "redborder" : "blackborder")} id="inputState" onChange={this.handleStateChange} value={this.state.State}>
                                                <option key="0" value="Select">Select</option>
                                                {this.state.StatesList.length > 0 && this.state.StatesList.map((item, i) => {
                                                    return (
                                                        <option key={item.stateName} value={item.stateName}>{item.stateName}</option>
                                                    )
                                                }, this)}
                                            </select>
                                        </div>
                                        <div className="col-md-1 pull-left nopadding ml-4">
                                            <label className="small mdb-1" htmlFor="inputZipCode">
                                                <span className="red ">*</span>Zip
                                            </label>

                                        </div>
                                        <div className="col-md-2 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rZipCode ? "redborder" : "blackborder")} id="inputZipCode" maxLength={10} value={this.state.ZipCode} ref={this.zipRef} onChange={this.handleZipCodeChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 nopadding">
                                        {/*    <div className="col-md-4 pull-left nopadding align-self-end">
                                                        <div className="form-group">
                                                            <input
                                                                type="checkbox"
                                                                id="summ"
                                                                className="pull-left"
                                                                name="isDefaultOrderingOffice"
                                                                value="IsDefaultOrderingOffice"
                                                                onChange={this.handleSummarization}
                                                                checked={this.state.Summarization}
                                                            />
                                                            <label className="small leftside" htmlFor="medical">
                                                                APS Summarization
                            </label>
                                                        </div>
                                                    </div>*/}
                                        <div className="row no-gutters">
                                            <div className="col-md-3 pull-left nopadding align-self-end">

                                                <label className="small" htmlFor="medical">

                                                    <span className={"red  " + (this.state.APS ? "show" : "hide")}>*</span>APS Calls Handled By
                                                </label>
                                            </div>
                                            <div className="col-md-2 pull-left nopadding">
                                                <select className={"form-control " + (this.state.rOHB ? "redborder" : "blackborder")} id="ohb" onChange={this.handleOHBChange}
                                                    value={this.state.OHB}>
                                                    <option key="0" value="Select">Select</option>
                                                    <option key="1" value="Onshore">Onshore</option>
                                                    <option key="2" value="Offshore">Offshore</option>
                                                </select>

                                            </div>

                                            <div className="col-md-4 col-12 mt-2">
                                                <label className="small mb-1">Allow CSR Messaging{" "}</label>
                                            </div>
                                            <div className="col-md-2 col-12">
                                                <select className="form-control" disabled={!this.state.iseNoahSystemAdmin ? "disabled" : ""}
                                                    value={this.state.AllowCSRMessaging}
                                                    onChange={this.handleallowCarrierLeverCSRMessagingChange}>
                                                    <option key="1" value="Yes">Yes</option>
                                                    <option key="0" value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 card mb-2 placeholder-div">
                            <div className="card-header">Contact Details</div>
                            <div className="card-body mt-2">
                                <div className="form-row">
                                    <div className="col-md-12 nopadding">
                                        <div className="col-md-3 pull-left nopadding">
                                            <label className="small mb-1" htmlFor="inputMainContactName">
                                                <span className="red ">*</span>Main Contact
                                            </label>

                                        </div>
                                        <div className="col-md-7 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rMainContactName ? "redborder" : "blackborder")} id="inputMainContactName" value={this.state.MainContactName} onChange={this.handleMainContactNameChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 nopadding">
                                        <div className="col-md-3 pull-left nopadding">
                                            <label className="small mdb-1" htmlFor="inputMainContactEmail">
                                                <span className="red ">*</span>Email
                                            </label>

                                        </div>
                                        <div className="col-md-4 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rMainContactEmail ? "redborder" : "blackborder")} id="inputMainContactEmail" value={this.state.MainContactEmail} onChange={this.handleMainContactEmailChange} onBlur={this.handleblurMainContactEmailChange} type="text" autoComplete="off" />
                                        </div>

                                        <div className="col-md-2 pull-left nopadding">
                                            <label className="small mdb-1" htmlFor="inputMainContactPhone">
                                                <span className="red ">*</span>Phone
                                            </label>

                                        </div>
                                        <div className="col-md-3 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rMainContactPhone ? "redborder" : "blackborder")} id="inputMainContactPhone" ref={this.mcpRef} maxLength={14} value={this.state.MainContactPhone} onChange={this.handleMainContactPhoneChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 nopadding">
                                        <div className="col-md-3 pull-left nopadding">


                                            <label className="small mb-1" htmlFor="inputAlternateContactName">
                                                Alternate Contact
                                            </label>

                                        </div>
                                        <div className="col-md-7 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rAlternateContactName ? "redborder" : "blackborder")} id="inputAlternateContactName" value={this.state.AlternateContactName} onChange={this.handleAlternateContactNameChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 nopadding">
                                        <div className="col-md-3 pull-left nopadding">
                                            <label className="small mdb-1" htmlFor="inputAlternateContactEmail">
                                                Email
                                            </label>

                                        </div>
                                        <div className="col-md-4 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rAlternateContactEmail ? "redborder" : "blackborder")} id="inputAlternateContactEmail" value={this.state.AlternateContactEmail} onChange={this.handleAlternateContactEmailChange} onBlur={this.handleblurAlternateContactEmailChange} type="text" autoComplete="off" />
                                        </div>

                                        <div className="col-md-2 pull-left nopadding">
                                            <label className="small mdb-1" htmlFor="inputAlternateContactPhone">
                                                Phone
                                            </label>

                                        </div>
                                        <div className="col-md-3 pull-left nopadding">
                                            <input className={"form-control " + (this.state.rAlternateContactPhone ? "redborder" : "blackborder")} id="inputAlternateContactPhone" ref={this.acpRef} value={this.state.AlternateContactPhone} onChange={this.handleAlternateContactPhoneChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 card mb-2 placeholder-div">
                            <div className="row no-gutters ma-left-25">
                                <div className="col-md-5 mt-3">
                                    <label className="small" style={{ marginLeft: "-24px" }}>
                                        <span className={"red  " + (this.state.APS ? "show" : "hide")}>*</span>APS Reviews Handled By
                                    </label>
                                </div>
                                <div className="col-md-3 mt-2">
                                    <select className={"form-control " + (this.state.rRHB ? "redborder" : "blackborder")} id="rhb" onChange={this.handleRHBChange}
                                        value={this.state.RHB}>
                                        <option key="0" value="Select">Select</option>
                                        <option key="1" value="Onshore">Onshore</option>
                                        <option key="2" value="Offshore">Offshore</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 card mb-2 placeholder-div ma-left-91">
                            <div className="row no-gutters">
                                <div className="col-md-3 col-12">
                                    <label className="small mb-1" style={{ marginLeft: "-120px" }}><span className="red ">*</span>Available Products</label>
                                </div>
                                <div className="col-md-9 col-6">
                                    <div className="row no-gutters ml-2">
                                        <div className="col-lg-2 mt-2" style={{ marginLeft: "-8px" }}>
                                            <input type="checkbox"
                                                className="pull-left block"
                                                onChange={(e) => this.handleAPSCheck(e)}
                                                checked={this.state.APS} />
                                            <label className="small leftside"><p> APS </p></label>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <label className="small mb-1" htmlFor="inputCropNumber">
                                                <span className={"red " + (this.state.APS ? "show" : "hide") || (this.state.APS && this.state.Summary ? "show" : "hide")}>*</span>APS Account #{" "}
                                            </label>
                                        </div>
                                        <div className="col-md-3 ml-2" style={{ marginTop: "-6px" }}>
                                            <input className={"form-control " + (this.state.rCropNumber ? "redborder" : "blackborder")} id="inputCropNumber" value={this.state.CropNumber} onChange={this.handleCropNumberChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-lg-3">
                                            <input type="checkbox"
                                                className="pull-left block"
                                                onChange={(e) => this.handleSummaryCheck(e)}
                                                checked={this.state.Summary} />
                                            <label className="small leftside">
                                                <p> Summary</p>
                                            </label>
                                        </div>
                                        <div className={"col-md-5"} style={{ marginTop: "-8px" }}>
                                            <label className="small mb-1" htmlFor="inputCropNumber">
                                                <span className={"red  " + (!this.state.APS && this.state.Summary ? "show" : "hide")}>*</span>Summary Account #{" "}
                                            </label>
                                        </div>
                                        <div className="col-md-3" style={{ marginTop: "-15px" }}>
                                            <input className={"form-control " + (this.state.rSummaryCropNumber ? "redborder" : "blackborder")} id="inputCropNumber" value={this.state.SummaryCropNumber} onChange={this.handleSummaryCropNumberChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={"card mt-5 mb-2 placeholder-div " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                <div className="card-header" style={{ marginTop: "-28px" }}>Client Level Permissions</div>
                                <div className="card-body mt-2">
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <label className="small mb-1">Allow Override Access {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelAOA}
                                                onChange={this.handleallowCarrierLevelAOAChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="small mb-1">Allow Default Office for ClientAdmin Users {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelDCA}
                                                onChange={this.handleallowCarrierLevelDCAChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <label className="small mb-1">View Results {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelVR}
                                                onChange={this.handleallowCarrierLevelVRChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="small mb-1">Place Order {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelPO}
                                                onChange={this.handleallowCarrierLevelPOChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/*</div>*/}
                                    {/*<div className="row no-gutters">*/}

                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <label className="small mb-1">View Status {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelVS}
                                                onChange={this.handleallowCarrierLevelVSChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="small mb-1">View Notes {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelVN}
                                                onChange={this.handleallowCarrierLevelVNChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <label className="small mb-1">View Auth Documents {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelVA}
                                                onChange={this.handleallowCarrierLevelVAChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="small mb-1">Allow Export Search Results {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelESR}
                                                onChange={this.handleallowCarrierLevelESRChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <label className="small mb-1">Allow Performance Metric Access{" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control" disabled={this.state.isClientAdmin ? "disabled" : ""}
                                                value={this.state.CarrierLevelPMA}
                                                onChange={this.handleallowCarrierLevelPMAChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ color: "#FFFFFF" }}>
                                        White Spaces
                                    </div>
                                    <div className="row no-gutters">
                                        <input
                                            type="checkbox"
                                            id="summ"
                                            className="pull-left"
                                            name="isPushtoOfficeandUsers"
                                            value="isPushtoOfficeandUsers"
                                            onChange={this.handlePushtoOfficesandUsers}
                                            checked={this.state.PushToOffices}
                                            disabled={(this.state.DisablePushOfficesandUsers ? (this.state.isClientAdmin ? "disabled" : "") : "disabled")}
                                        />
                                        <label className="small leftside" htmlFor="medical">
                                            Push to Offices and Users
                                        </label>
                                    </div>
                                    <div className="row no-gutters">
                                        <p className="special-div-z">*Note : If You change any value with Allow Override as "No", then It will override all the Users and the Ordering Offices Permissions. These changes will be applied after the user performs logout and login</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card mb-2 placeholder-div mt-custom">
                                <div className={"card-header posrel"} style={{ marginTop: "-85px" }}>Customer Service Contact</div>
                                <div className="col-md-3">APS Contact</div>
                                <div className="card-body mt-2">
                                    <div className="form-row">
                                        <div className="col-md-3" >
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputFirstName"><span className={"red  " + (this.state.APS ? "show" : "hide")}>*</span>Name</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rCustomerServiceContactName ? "redborder" : "blackborder")} id="inputCustomerServiceContactName" value={this.state.CustomerServiceContactName} onChange={this.handleCustomerServiceContactNameChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputCustomerServiceContactEmail"><span className={"red  " + (this.state.APS ? "show" : "hide")}>*</span>Email Address</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rCustomerServiceContactEmail ? "redborder" : "blackborder")} id="inputCustomerServiceContactEmail" value={this.state.CustomerServiceContactEmail} onChange={this.handleCustomerServiceContactEmailChange} onBlur={this.handleblurCustomerServiceContactEmailChange} type="text" autoComplete="off" />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputCustomerServiceContactPhone"><span className={"red  " + (this.state.APS ? "show" : "hide")}>*</span>Work Phone</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rCustomerServiceContactPhone ? "redborder" : "blackborder")} id="inputCustomerServiceContactPhone" value={this.state.CustomerServiceContactPhone} ref={this.ccpnRef} onChange={this.handleCustomerServiceContactPhoneChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">Underwriting Contact</div>
                                <div className="card-body mt-2">
                                    <div className="form-row">
                                        <div className="col-md-3" >
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputFirstName"><span className={"red  " + (this.state.Summary ? "show" : "hide")}>*</span>Name</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rCustomerServiceContactNameforSummary ? "redborder" : "blackborder")} id="inputCustomerServiceContactName" value={this.state.CustomerServiceContactNameforSummary} onChange={this.handleCustomerServiceContactNameChangeforSummary} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputCustomerServiceContactEmail"><span className={"red  " + (this.state.Summary ? "show" : "hide")}>*</span>Email Address</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rCustomerServiceContactEmailforSummary ? "redborder" : "blackborder")} id="inputCustomerServiceContactEmail" value={this.state.CustomerServiceContactEmailforSummary} onChange={this.handleCustomerServiceContactEmailChangeforSummary} onBlur={this.handleblurCustomerServiceContactEmailChangeforSummary} type="text" autoComplete="off" />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputCustomerServiceContactPhone"><span className={"red  " + (this.state.Summary ? "show" : "hide")}>*</span>Work Phone</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rCustomerServiceContactPhoneforSummary ? "redborder" : "blackborder")} id="inputCustomerServiceContactPhone" value={this.state.CustomerServiceContactPhoneforSummary} ref="CCPN" onChange={this.handleCustomerServiceContactPhoneChangeforSummary} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 card mb-2 placeholder-div">
                            <div className={"card mb-2 placeholder-div"}>
                                <div className="card-header text-right"> Multi-Factor Authentication </div>
                                <div className="card-body mt-2">
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <label className="small mdb-1 pull-left">
                                                <span className="red ">*</span>Enable SSO Connection
                                            </label>
                                        </div>
                                        <div className="col-md-2 pt-2">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.EnableSSOConnection}
                                                onChange={(el, state) => this.handleEnableSSOConnectionSwitch(el, state)}
                                                name="enablesso"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="small mb-1">Enable MFA</label>
                                        </div>
                                        <div className="col-md-2">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.EnableMFA}
                                                onChange={(el, state) => this.handleEnableMFASwitch(el, state)}
                                                disabled={!this.state.iseNoahAdmin}
                                                name="enableMFA"
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-md-12 nopadding " + (this.state.vIdentifiersso ? "show" : "hide")}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="small mdb-1 pull-left">
                                                    <span className="red ">*</span>SSO Identifier
                                                </label>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <input className={"form-control " + (this.state.rIdentifiersso ? "redborder" : "blackborder")} id="inputIdentifiersso" maxLength={20} value={this.state.Identifiersso || ''} onChange={this.handlIdentifierssovalueChange} type="text" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters mt-3 pt-2">
                                <div className="card-header posrel"></div>
                                <div className="col-md-4">
                                    <label className="small mdb-1 pull-left">
                                        Assign Office by Account Number
                                    </label>
                                </div>
                                <div className="col-md-2  pt-2">
                                    <Switch
                                        defaultValue={false}
                                        bsSize="mini"
                                        onText="Yes"
                                        offText="No"
                                        value={this.state.EnableOfficebyAccountNum}
                                        onChange={(el, state) => this.handleEnableOfficeAccountSwitch(el, state)}
                                        name="enableoffice"
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <label className="small mb-1">MFA Type</label>
                                </div>
                                <div className="col-md-4">
                                    <select className={"form-control"} id="mfatoken" onChange={this.handleMFATokenTypeChange}
                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                        value={this.state.MFATokenType}>
                                        {this.state.MFATokenTypeList.length > 0 && this.state.MFATokenTypeList.map((item, i) => {
                                            return (
                                                <option key={item.code} value={item.code}>{item.tokenType}</option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <label className="small mb-1">MFA Required (in Days)</label>
                                </div>
                                <div className="col-md-4">
                                    <select className={"form-control"} id="mfatokenexpirydays" onChange={this.handleMFATokenExpiryDaysChange}
                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                        value={this.state.MFATokenExpiryDays}>
                                        {this.state.MFATokenExpiryDaysList.length > 0 && this.state.MFATokenExpiryDaysList.map((item, i) => {
                                            return (
                                                <option key={item.code} value={item.code}>{item.expiryDays}</option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <label className="small mb-1">One-Time Token Expires (in Min)</label>
                                </div>
                                <div className="col-md-4">
                                    <select className={"form-control"} id="DropDownmfatokenexpirymin" onChange={this.handleMFATokenExpiryMinChange}
                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                        value={this.state.MFATokenExpiryMin}>
                                        {this.state.MFATokenExpiryMinList.length > 0 && this.state.MFATokenExpiryMinList.map((item, i) => {
                                            return (
                                                <option key={item.code} value={item.code}>{item.expiryMin}</option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <label className="small mb-1">Token Format</label>
                                </div>
                                <div className="col-md-4">
                                    <select className={"form-control"} id="DropDownmfatokenGenType" onChange={this.handleMFATokenGenTypeChange}
                                        disabled={(!this.state.iseNoahAdmin ? "disabled" : "")}
                                        value={this.state.MFATokenGenType}>
                                        {this.state.MFATokenGenTypeList.length > 0 && this.state.MFATokenGenTypeList.map((item, i) => {
                                            return (
                                                <option key={item.code} value={item.code}>{item.tokenGenType}</option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <label className="small mb-1">One Time Token Length</label>
                                </div>
                                <div className="col-md-4">
                                    <input className={"form-control " + (this.state.rMFATokenLength ? "redborder" : "blackborder")} id="inputMFATokenLength" value={this.state.MFATokenLength} disabled={!this.state.iseNoahAdmin} onChange={this.handleMFATokenLengthChange} maxLength="2" type="text" autoComplete="off" />
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-7">
                                    <label className="small mb-1">Track MFA by</label>
                                </div>
                                <div className="col-md-5 pl-1">
                                    <div className="form-check form-check-inline">
                                        <div className="form-check-input">
                                            <input
                                                type="radio"
                                                id="byipwithuser"
                                                className="pull-left"
                                                name="mfaipwithuserradio"
                                                value="mfauseronly"
                                                onChange={this.handleMFAIpWithUserChange}
                                                checked={this.state.MFAUserwithIP == "mfauseronly"}
                                                disabled={!this.state.iseNoahAdmin ? "disabled" : ""}
                                            />
                                        </div>
                                        <label className="form-check-label">User Name</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <div className="form-check-input">
                                            <input
                                                type="radio"
                                                id="byipwithuser"
                                                className="pull-left"
                                                name="mfaipwithuserradio"
                                                value="mfaipwithuser"
                                                onChange={this.handleMFAIpWithUserChange}
                                                checked={this.state.MFAUserwithIP == "mfaipwithuser"}
                                                disabled={!this.state.iseNoahAdmin ? "disabled" : ""} />
                                        </div>
                                        <label className="form-check-label">IP Address with User Name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={
                                "card mb-2 placeholder-div " +
                                (this.state.iseNoahSystemAdmin ? "d-flex" : "d-none")
                            } >
                                <div className="card-header">Login Security Settings</div>
                                <div className="card-body mt-2" >
                                    <div className="form-row">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputPasswordUpdateDays"><span className="red ">*</span>Enforce Password Change After</label>

                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <input className={"form-control " + (this.state.rpassUpdateDays ? "redborder" : "blackborder")} id="inputPasswordUpdateDays" value={this.state.DaysForEnforcePass} onChange={this.handlePassUpdateDaysChange} disabled={!this.state.iseNoahSystemAdmin || (this.state.passNeverExpires)} type="text" autoComplete="off" />
                                        </div>
                                        <label className="small mdb-1" htmlFor="inputInactiveDays">Days</label>
                                        <div className="password">
                                            <input
                                                type="checkbox"
                                                id="PassNeverExpireCheck"
                                                className="pull-left"
                                                onChange={this.handlePasswordNeverExpires}
                                                disabled={(this.state.DisCheckPassNeverExpires)}

                                                checked={this.state.IsPassNeverExpires}

                                            />
                                            <label className="small leftside" htmlFor="medical">
                                                <p>Password Never Expires</p>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputRemPassDays"><span className="red ">*</span>Remember </label>

                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <input className={"form-control " + (this.state.rRemPasswordCount ? "redborder" : "blackborder")} id="inputRemPassDays" value={this.state.RememberPassCount} onChange={this.handleRemPasswordDaysChange} disabled={!this.state.iseNoahSystemAdmin || this.state.DisPreviousPassword} type="text" autoComplete="off" />
                                        </div>
                                        <label className="small mdb-1" htmlFor="inputInactiveDays">Previous Passwords</label>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputInactiveDays"><span className="red ">*</span>Inactive After</label>

                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <input className={"form-control " + (this.state.rInactiveDays ? "redborder" : "blackborder")} id="inputInactiveDays" value={this.state.InactiveDays} onChange={this.handleInactiveDaysChange} disabled={!this.state.iseNoahSystemAdmin || this.state.DisInactive} type="text" autoComplete="off" />
                                        </div>
                                        <label className="small mdb-1" htmlFor="inputInactiveDays">Days</label>
                                        <div className="password">
                                            <input
                                                type="checkbox"
                                                id="inactiveCheck"
                                                className="pull-left"
                                                checked={this.state.IsNeverInactive === false ? false : true}
                                                onChange={this.handleNeverInactive}
                                                disabled={(this.state.DisCheckInactive)}
                                            />
                                            <label className="small leftside" htmlFor="medical">
                                                <p>Never Inactive</p>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label className="small mdb-1" htmlFor="inputInactiveDays">Enable Public Ip Address Filtering</label>
                                        </div>
                                        <div className="col-md-2" id="bootstrapbutton" >
                                            <label className="checkbox-inline borderone">

                                                <Switch
                                                    defaultValue={false}
                                                    bsSize="mini"
                                                    onText="Yes"
                                                    offText="No"
                                                    value={this.state.ipAddressChange}
                                                    onChange={(el, state) => this.handleIpAddressChange(el, state)}

                                                    disabled={this.state.IPAddressFiltering}
                                                    name="enableip"
                                                />

                                            </label>
                                        </div>
                                        <div className="col-md-4 no-gutters">
                                            <p className="special-div-z">*Note: Click the "Save" button to save the changes in the IP Address Filtering.</p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            "card mb-2 placeholder-div " +
                                            (this.state.IpAddressListing ? "d-flex" : "d-none")
                                        }
                                    >
                                        <div className="form-row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="small mdb-1" htmlFor="inputRemPassDays">Public Ip Address</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <input className={"form-control " + (this.state.rFroIpAddress ? "redborder" : "blackborder")} id="inputFromIpAddress" value={this.state.FromIpAddress} onChange={this.handleFromIpAddressChange} disabled={!this.state.iseNoahAdmin ? "disabled" : ""} type="text" autoComplete="off" />
                                            </div>
                                            <label className="small mdb-1" htmlFor="inputRemPassDays">to</label>
                                            <div className="col-md-3">
                                                <input className={"form-control " + (this.state.rToIpAddress ? "redborder" : "blackborder")} id="inputToIpAddress" value={this.state.ToIpAddress} onChange={this.handleToIpAddressChange} disabled={!this.state.iseNoahAdmin ? "disabled" : ""} type="text" autoComplete="off" />
                                            </div>
                                            <div className="col-md-1">
                                                <button className="btn btn-blue-btnnm btn-type addbtn" onClick={this.handleAddIpAddress}>Add </button>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="table-responsive dataTable unsubmitted posrel lastwidth200 noExport-table ipTable  ">

                                                <MaterialTable
                                                    title=""

                                                    className="table data-table dataTable table-bordered"
                                                    columns={[
                                                        { title: 'Id', field: 'id', hidden: true },
                                                        { title: 'Current Public Ip Address', field: 'ipAddress' },

                                                    ]}
                                                    data={this.state.IpAddressList}
                                                    onSelectionChange={async (rows, rowData) => {

                                                        if (rows.length == this.state.PageLimit && isNullOrUndefined(rowData)) {
                                                            let isExport = await this.GetDataForSelectAll();
                                                            if (isExport) {
                                                                if (this.state.dataForSelectAll.length > 0) {
                                                                    this.state.SelectedRowsForIp.length = 0;
                                                                    for (const item of this.state.dataForSelectAll) {
                                                                        this.state.SelectedRowsForIp.push(item.id);
                                                                    }

                                                                }
                                                            }
                                                        }
                                                        else if (rows.length == 0 && isNullOrUndefined(rowData)) {
                                                            this.state.SelectedRowsForIp.length = 0;
                                                        }
                                                        else {
                                                            if (rows.length > 0) {
                                                                if (this.state.SelectedRowsForIp.length > 0) {
                                                                    for (const row of rows) {
                                                                        const index = this.state.SelectedRowsForIp.indexOf(row.id);
                                                                        if (index === -1) {
                                                                            this.state.SelectedRowsForIp.push(row.id);
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    for (const row of rows) {
                                                                        this.state.SelectedRowsForIp.push(row.id);
                                                                    }
                                                                }
                                                            }
                                                        }

                                                    }
                                                    }
                                                    options={{
                                                        search: false,
                                                        paging: false,
                                                        selection: true,
                                                        showTextRowsSelected: false,
                                                        toolbar: false,
                                                        thirdSortClick: false,
                                                        headerStyle: {
                                                            position: "sticky",
                                                            top: "0"
                                                        },
                                                        maxBodyHeight: "300px",
                                                        selectionProps: (rowData) => {
                                                            for (let i = 0; i < this.state.SelectedRowsForIp.length; i++) {
                                                                if (rowData.id == this.state.SelectedRowsForIp[i]) {
                                                                    if (rowData.tableData.checked || !rowData.tableData.checked) {
                                                                        if (!rowData.tableData.checked) {
                                                                            let index = this.state.SelectedRowsForIp.indexOf(rowData.id)
                                                                            if (index !== -1) {
                                                                                this.state.SelectedRowsForIp.splice(index, 1);
                                                                                this.setState({ SelectedRowsForIp: this.state.SelectedRowsForIp });
                                                                            }
                                                                            return { checked: false }
                                                                        }
                                                                        else {
                                                                            return { checked: true }
                                                                        }
                                                                    }
                                                                    else {
                                                                        rowData.tableData = { id: rowData.tableData.id, checked: true }
                                                                        return { checked: true }
                                                                    }
                                                                }
                                                            }
                                                        },
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <button className="btn btn-blue-btnnm btn-type deletebtn" onClick={(e) => { this.handleIPdeletebttn(null, 'IpList') }} >Delete</button>
                                        </div>
                                        {/*<div class="text-right">*/}
                                        {/*    <button class="btn btn-blue-btnnm btn-type">Delete </button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-2 placeholder-div">
                                <div className="card-header">Data Retention Settings</div>
                                <div className="card-body mt-2">
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputRetentionPeriodDays">Retention Period Days</label>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rRetentionPeriodDays ? "redborder" : "blackborder")} id="inputRetentionPeriodDays" value={this.state.RetentionPeriodDays} onChange={this.handleRetentionPeriodDaysChange} disabled={!this.state.iseNoahSystemAdmin ? "disabled" : ""} type="text" autoComplete="off" />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputProductionPurgeDays">Production Purge Days</label>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rProductionPurgeDays ? "redborder" : "blackborder")} id="inputProductionPurgeDays" value={this.state.ProductionPurgeDays} onChange={this.handleProductionPurgeDaysChange} disabled={!this.state.iseNoahSystemAdmin ? "disabled" : ""} type="text" autoComplete="off" />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="small mdb-1" htmlFor="inputOfflineArchivePurgeDays">Offline Archive Purge Days</label>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <input className={"form-control " + (this.state.rOfflineArchivePurgeDays ? "redborder" : "blackborder")} id="inputOfflineArchivePurgeDays" value={this.state.OfflineArchivePurgeDays} onChange={this.handleOfflineArchivePurgeDaysChange} disabled={!this.state.iseNoahSystemAdmin ? "disabled" : ""} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-blue-btnnm btn-type" onClick={this.handlersaveOpenbttn}>Save </button>
                    </div>
                    <hr />
                    <div
                        className={
                            "custom-tabs flex-row " +
                            (this.state.HideLegalDiv ? "d-flex" : "d-none")
                        }
                    >
                        <div
                            onClick={this.ShowOrderingOfficeInfo}
                            className={
                                "tab-item " +
                                (this.state.ShowOrderingOfficeListing ? "active" : "")
                            }
                            aria-hidden="true"
                        >
                            Ordering Office Information
                        </div>
                        <div
                            onClick={this.ShowUsersAndAgentsInfo}
                            className={
                                "tab-item " +
                                (this.state.ShowUsersAndAgentsListing ? "active" : "")
                            }
                            aria-hidden="true"
                        >
                            Client Level Users and Agent Listing
                        </div>
                        <div
                            onClick={this.ShowCareerManagerInfo}
                            className={
                                "tab-item " +
                                (this.state.ShowCareerManagerListing ? "active" : "")
                            }
                            aria-hidden="true"
                        >
                            Client Manager Listing
                        </div>
                        <div
                            onClick={this.ShowUnderwriterInfo}
                            className={
                                "tab-item " +
                                (this.state.ShowUnderwriterListing ? "active" : "")
                            }
                            aria-hidden="true"
                        >
                            Underwriter Listing
                        </div>
                    </div>
                    {/* Career Level Users and Agent Listing Grid*/}
                    <div
                        className={
                            "card mb-2 placeholder-div " +
                            (this.state.ShowUsersAndAgentsListing ? "d-flex" : "d-none")
                        }
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="my-0 withset">Client Level Users and Agent Listing</h1>
                            <button className="export-class text-nowrap" id="addUser" onClick={this.onClickopenAgentPopup}>
                                Add New User
                            </button>
                        </div>
                        <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table">
                            <div className="col-md-2 searchoff nopadding">
                                <span className="show-title">Show</span>
                            </div>
                            <div className="col-md-2 entriesoff nopadding">
                                <span className="show-entries">entries</span>
                            </div>
                            <div className="searchoff-x">
                                <span className="show-title-x">Search</span>
                            </div>
                            <MaterialTable
                                title=""
                                className="table data-table dataTable table-bordered"
                                columns={[
                                    { title: 'Name', field: 'name' },
                                    { title: 'User Name', field: 'username' },
                                    { title: 'Access Level', field: 'accessLevel' },
                                    { title: 'Agent ID', field: 'agentId' },
                                    { title: 'Email', field: 'email' },
                                    { title: 'Work Phone', field: 'phone1' },
                                    { title: 'Cell Phone', field: 'phone2' },
                                    { title: 'UId', field: 'userId', hidden: true },
                                    {
                                        title: 'Action ', field: 'action', render: rowData => <span style={{ minWidth: "190px", display: "inline-block" }}>
                                            {this.state.isDDLoaded ?
                                                (<span className="carrier-button" aria-hidden="true" onClick={(e) => { this.isviewModalopen(rowData.userId) }}  ><img className="eye" src="img/view-icon.png" /> </span>)
                                                :
                                                (<span className="carrier-button-loader"> <img style={{ width: "30px" }} src={process.env.PUBLIC_URL + landing} /> </span>)
                                            }
                                            {this.state.isDDLoaded ?
                                                (<span className="carrier-button" aria-hidden="true" onClick={(e) => { this.openEditPopup(rowData.userId) }} ><img className="edit" src="img/edit-icon.png" /> </span>)
                                                :
                                                (<span className="carrier-button-loader"> <img style={{ width: "30px" }} src={process.env.PUBLIC_URL + landing} /> </span>)
                                            }
                                            <span className="carrier-button" aria-hidden="true" onClick={(e) => { this.handleOpendeletebttn(rowData.userId, 'AgentList') }} ><img className="delete" src="img/delete.png" /> </span>
                                            <span className={"carrier-button " + (rowData.isConfirmed ? "hide" : "show")} aria-hidden="true" onClick={(e) => { this.SendConfirmation(rowData.userId) }} ><img className="resend" src="img/reload.png" /> </span>
                                            <span className={"carrier-button " + (rowData.isLocked === true && rowData.isInActive !== true ? "show" : "hide")} aria-hidden="true" onClick={(e) => { this.handleOpenUnlockAlert(rowData.userId) }} ><img className="unlock" src="img/unlock.png" /> </span>
                                            <span className={"carrier-button " + (rowData.isInActive ? "show" : "hide")} aria-hidden="true" onClick={(e) => { this.handleOpenInactivityAlert(rowData.userId) }} ><img className="unlock" src="img/inactivitylock.png" /> </span>
                                        </span>
                                    },
                                ]}
                                data={this.state.UserList}
                                options={{
                                    exportButton: false,
                                    search: true,
                                    paginationType: "stepped"
                                }}
                            />
                        </div>
                    </div>
                    {/* Career Manager Listing Grid*/}
                    <div
                        className={
                            "card mb-2 placeholder-div " +
                            (this.state.ShowCareerManagerListing ? "d-flex" : "d-none")
                        }
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="my-0 withset">Client Manager Listing</h1>

                        </div>
                        <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table">
                            <div className="col-md-2 searchoff nopadding">
                                <span className="show-title">Show</span>
                            </div>
                            <div className="col-md-2 entriesoff nopadding">
                                <span className="show-entries">entries</span>
                            </div>
                            <div className="searchoff-x">
                                <span className="show-title-x">Search</span>
                            </div>
                            <MaterialTable
                                title=""
                                className="table data-table dataTable table-bordered"
                                columns={[
                                    { title: 'First Name', field: 'firstName' },
                                    { title: 'Last Name', field: 'lastName' },
                                    { title: 'Email Address', field: 'emailAddress' },
                                    { title: 'Work Phone', field: 'workPhone' },
                                    { title: 'Ext', field: 'ext' },
                                    { title: 'Cell Phone', field: 'cellPhone' },
                                    { title: 'Fax ', field: 'fax' },
                                    {
                                        title: 'Action ', field: 'action', render: rowData => <span>
                                            <span className="edit-btnm editActionE" aria-hidden="true" onClick={() => this.onClickopenCMEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>
                                            <span className="edit-btnm hide" aria-hidden="true" onClick={(e) => { this.handleOpenCMdeletebttn(rowData.id, 'CarrierManagerInfo') }} ><img src="img/delete.png" /> Delete</span>
                                        </span>
                                    },
                                ]}
                                data={this.state.CMList}
                                options={{
                                    exportButton: false,
                                    search: true,
                                    paginationType: "stepped"
                                }}
                            />
                        </div>
                    </div>
                    {/* Underwriter Listing Grid*/}
                    <div
                        className={
                            "card mb-2 placeholder-div " +
                            (this.state.ShowUnderwriterListing ? "d-flex" : "d-none")
                        }
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="my-0 withset">Underwriter Listing</h1>

                        </div>
                        <div className="table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table">
                            <div className="col-md-2 searchoff nopadding">
                                <span className="show-title">Show</span>
                            </div>
                            <div className="col-md-2 entriesoff nopadding">
                                <span className="show-entries">entries</span>
                            </div>
                            <div className="searchoff-x">
                                <span className="show-title-x">Search</span>
                            </div>
                            <MaterialTable
                                title=""
                                className="table data-table dataTable table-bordered"
                                columns={[
                                    { title: 'First Name', field: 'firstName' },
                                    { title: 'Last Name', field: 'lastName' },
                                    { title: 'Email', field: 'emailAddress' },
                                    { title: 'Work Phone', field: 'workPhone' },
                                    { title: 'Ext', field: 'ext' },
                                    { title: 'Cell Phone', field: 'cellPhone' },
                                    { title: 'Fax ', field: 'fax' },
                                    {
                                        title: 'Action ', field: 'action', render: rowData => <span>
                                            <span className="edit-btnm editActionE" aria-hidden="true" onClick={() => this.onClickopenUWEditPopup(rowData)}><img src="img/edit-icon.png" /> Edit</span>{/*
                                            <span className="edit-btnm hide" onClick={(e) => { this.handleOpenCMdeletebttn(rowData.id, 'CarrierManagerInfo') }} ><img src="img/delete.png" /> Delete</span>*/}
                                        </span>
                                    },
                                ]}
                                data={this.state.UWList}
                                options={{
                                    exportButton: false,
                                    search: true,
                                    paginationType: "stepped"
                                }}
                            />
                        </div>
                    </div>
                    {/* Ordering Office Information Grid*/}
                    <div
                        className={
                            "card mb-2 placeholder-div " +
                            (this.state.ShowOrderingOfficeListing ? "d-flex" : "d-none")
                        }
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="my-0 withset">Ordering Office Information</h1>
                            <button className="export-class text-nowrap" onClick={this.onClickopenOfficePopup}>
                                Add New
                            </button>
                        </div>
                        <div className="table-responsive dataTable unsubmitted posrel">
                            <div className="col-md-2 searchoff nopadding">
                                <span className="show-title">Show</span>
                            </div>
                            <div className="col-md-2 entriesoff nopadding">
                                <span className="show-entries">entries</span>
                            </div>
                            <div className="searchoff-x">
                                <span className="show-title-x">Search</span>
                            </div>
                            <MaterialTable
                                title=""
                                className="table data-table dataTable table-bordered"
                                columns={[
                                    { title: 'Office Name', field: 'officeName' },
                                    { title: 'Office Code', field: 'officeCode' },
                                    { title: 'Number of Users', field: 'numberOfUsers' },
                                    { title: 'Email', field: 'email' },
                                    { title: 'Phone', field: 'phone' },
                                    { title: 'Office Status', field: 'officeStatus' },
                                    { title: 'Product', field: 'product' },
                                    {
                                        title: 'Action ', field: 'action', render: rowData => <span>
                                            <span className="edit-btnm editActionE" onClick={() => this.onClickopenOfficeEditPopup(rowData)} onKeyDown={(event) => {
                                                if (event.key == 13 || event.keyCode === 13) {
                                                    this.onClickopenOfficeEditPopup(rowData);
                                                    event.preventDefault();
                                                }
                                            }}><img src="img/edit-icon.png" /> Edit</span>
                                            <span className="edit-btnm hide" onClick={(e) => { this.handleOpendeletebttn(rowData.id, 'OrderInfo') }} onKeyDown={(event) => {
                                                if (event.key == 13 || event.keyCode === 13) {
                                                    this.handleOpendeletebttn(rowData.id, 'OrderInfo');
                                                    event.preventDefault();
                                                }
                                            }} ><img src="img/delete.png" /> Delete</span>
                                        </span>
                                    },
                                ]}
                                data={this.state.OrderingOfficeInformationList}
                                options={{
                                    exportButton: false,
                                    paginationType: "stepped"
                                }}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className={"withset " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>LABEL CONFIGURATIONS</h1>
                    </div>
                    <div className={(this.state.CompanyType == "LIFE" && !this.state.hideEnoahAdmin ? "show" : "hide") + " mt-2"}>
                        <div className="custom-tabs flex-row d-flex">
                            <div
                                onClick={this.ShowPatientInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.PatientInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Patient and Contact Info Fields
                            </div>
                            <div
                                onClick={this.ShowCarrierManagerFields}
                                className={
                                    "tab-item " +
                                    (this.state.CarrierManagerFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Client Manager Fields
                            </div>
                            <div
                                onClick={this.ShowAgentInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.AgentInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Agent Info Fields
                            </div>
                            <div
                                onClick={this.ShowFacilityInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.FacilityInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Provider / Doctor Info Fields
                            </div>
                            <div
                                onClick={this.ShowUnderwriterFields}
                                className={
                                    "tab-item " +
                                    (this.state.UnderwriterFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Underwriter Fields
                            </div>
                        </div>
                    </div>
                    <div className={(this.state.CompanyType == "LEGAL" && this.state.hideEnoahAdmin == false ? "show" : "hide") + " mt-2"}>
                        <div className="custom-tabs flex-row d-flex">
                            <div
                                onClick={this.ShowPatientInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.PatientInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Patient and Contact Info Fields
                            </div>
                            <div
                                onClick={this.ShowCarrierManagerFields}
                                className={
                                    "tab-item " +
                                    (this.state.CarrierManagerFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Client Manager Fields
                            </div>
                            <div
                                onClick={this.ShowAdjusterInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.AdjusterInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Adjuster Info Fields
                            </div>
                            <div
                                onClick={this.ShowFacilityInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.FacilityInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Facility / Doctor Info Fields
                            </div>
                            <div
                                onClick={this.ShowAttorneyInfoFields}
                                className={
                                    "tab-item " +
                                    (this.state.AttorneyInfoFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Attorney Info Fields
                            </div>
                            <div
                                onClick={this.ShowUnderwriterFields}
                                className={
                                    "tab-item " +
                                    (this.state.UnderwriterFields ? "active" : "")
                                }
                                aria-hidden="true"
                            >
                                Underwriter Fields
                            </div>
                        </div>
                    </div>
                    <div className={"table-responsive dataTable unsubmitted posrel lastwidth245 noExport-table " + (this.state.hideEnoahAdmin === false ? "show" : "hide")}>
                        <div className="col-md-2 searchoff nopadding">
                            <span className="show-title">Show</span>
                        </div>
                        <div className="col-md-2 entriesoff nopadding">
                            <span className="show-entries">entries</span>
                        </div>
                        <div className="searchoff-x">
                            <span className="show-title-x">Search</span>
                        </div>
                        <MaterialTable
                            title=""
                            className="table data-table dataTable table-bordered"
                            columns={columns}
                            data={this.state.RequiredFieldsList}
                            options={{
                                exportButton: true,
                                search: true,
                                paginationType: "stepped"
                            }}
                        />
                    </div>

                    <div className={"modal " + (this.state.isOfficeForUserModalopen ? "show" : "hide")}>
                        <span className="alert-bg-div-x"></span>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Permissions</h4>
                                    <button type="button" className="close" onClick={this.closePopupUserOffice} data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body p-3 placeholder-div">
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Office Name:{" "}</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className={"form-control " + (this.state.pAssignedOfficeName ? "redborder" : "blackborder")} value={this.state.AssignedOfficeName} disabled type="text" onChange={this.handleAssignedOfficeNameChange} autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-3 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <label className="small mb-1">View Results:{" "}</label>
                                        </div>
                                        <div className={"col-md-4 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.AssignedViewResultsValue}
                                                onChange={this.handleallowViewResultsChange}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-3 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <label className="small mb-1">Place Order :{" "}</label>
                                        </div>
                                        <div className={"col-md-4 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.AssignedPlaceOrderValue}
                                                onChange={this.handleallowPlaceOrderChange}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-3 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <label className="small mb-1">View Status:{" "}</label>
                                        </div>
                                        <div className={"col-md-4 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.AssignedViewStatusValue}
                                                onChange={this.handleallowViewStatusChange}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-3 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <label className="small mb-1">View Notes:{" "}</label>
                                        </div>
                                        <div className={"col-md-4 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.AssignedViewNotesValue}
                                                onChange={this.handleallowViewNotesChange}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-3 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <label className="small mb-1">View Auth Documents:{" "}</label>
                                        </div>
                                        <div className={"col-md-4 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.AssignedViewAuthValue}
                                                onChange={this.handleallowViewAuthChange}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateOfficesForUser_New}>Update </button>
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopupUserOffice}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"modal " + (this.state.isFieldsEditModalopen ? "show" : "hide")}>
                        <span className="alert-bg-div-x"></span>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Field</h4>
                                    <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body p-3 placeholder-div">
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Original Field Name{" "}</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className="form-control" value={this.state.originalFieldName} onChange={this.handleCustomFieldNameChange} type="text" autoComplete="off" disabled />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Custom Field Name{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-7">
                                            <input className={"form-control " + (this.state.customField ? "redborder" : "blackborder")} value={this.state.customFieldName} onChange={this.handleCustomFieldNameChange} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-3">
                                            <label className="small mb-1">Is Active {" "}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-control"
                                                value={this.state.orderIsActiveSelectedValue}
                                                onChange={this.handleIsActiveChange}
                                                disabled={this.state.isDefault}>

                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-3 " + (!this.state.UnderwriterFields ? "show" : "hide")}>
                                            <label className="small mb-1">APS Required{" "}</label>
                                        </div>
                                        <div className={"col-md-2 " + (!this.state.UnderwriterFields ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.orderIsRequiredSelectedValue}
                                                onChange={this.handleIsRequiredChange}
                                                disabled={this.state.isDefault}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-3 " + (!this.state.CarrierManagerFields ? "show" : "hide")}>
                                            <label className="small mb-1">Summary Required{" "}</label>
                                        </div>
                                        <div className={"col-md-2 " + (!this.state.CarrierManagerFields ? "show" : "hide")}>
                                            <select className="form-control"
                                                value={this.state.orderSummaryIsRequiredSelectedValue}
                                                onChange={this.handleSummaryIsRequiredChange}
                                                disabled={this.state.isDefault}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateFieldInformation}>Update </button>
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-info">
                    <div className={"modal " + (this.state.isAgentModalopen ? "show" : "hide")}>
                        <span className="alert-bg-div-x"></span>
                        <div className="modal-dialog">
                            <div className="modal-content h-new">
                                <div className="modal-header">
                                    <h4 className="modal-title">User Information</h4>
                                    <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body p-3 placeholder-div">
                                    <div className="row mt-1">
                                        <div className="col-md-1">
                                            <label className="small mb-1">First Name{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rFirstName ? "redborder" : "blackborder")} value={this.state.FirstName} maxLength={50} onChange={this.handleFirstNameChange} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Last Name{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rLastName ? "redborder" : "blackborder")} value={this.state.LastName} maxLength={50} onChange={this.handleLastNameChange} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Agent Id{" "}</label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rAgentId ? "redborder" : "blackborder")} value={this.state.AgentId} onChange={this.handleAIdChange} maxLength={50} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-1">
                                            <label className="small mb-1">Email{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rAgentEmail ? "redborder" : "blackborder")} value={this.state.AgentEmail}
                                                onChange={this.handleAgentEmailChange}
                                                onBlur={this.handleblurAgentEmailChange} maxLength={250} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Phone{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rPhone1 ? "redborder" : "blackborder")} value={this.state.Phone1} ref={this.inputRef} maxLength={14} onChange={this.handlePhone1Change} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Ext{" "}</label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rExt1 ? "redborder" : "blackborder")} value={this.state.Ext1} maxLength={5} onChange={this.handleExt1Change} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-1">
                                            <label className="small mb-1">Mobile{" "}</label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rPhone2 ? "redborder" : "blackborder")} value={this.state.Phone2} ref={this.phn2Ref} maxLength={14} onChange={this.handlePhone2Change} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Fax{" "}</label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rFax ? "redborder" : "blackborder")} value={this.state.Fax} ref={this.AfaxRef} maxLength={14} onChange={this.handlerFaxhange} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Address{" "}</label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rAddressLine1 ? "redborder" : "blackborder")} value={this.state.AddressLine1} maxLength={250} onChange={this.handleAddressLine1Change} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-1">
                                            <label className="small mb-1">City{" "}
                                                <span className="red">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rUCity ? "redborder" : "blackborder")} value={this.state.UCity} maxLength={100} onChange={this.handleUCityChange} type="text" autoComplete="off" />
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">ST{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <select className={"form-control " + (this.state.rST ? "redborder" : "blackborder")} id="St" onChange={this.handleSTChange} value={this.state.ST}>
                                                <option key="0" value="Select">Select</option>
                                                {this.state.StatesList.length > 0 && this.state.StatesList.map((item) => (
                                                    <option key={item.stateName} value={item.stateName}>{item.stateName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-1">
                                            <label className="small mb-1">Zip{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <input className={"form-control " + (this.state.rZip ? "redborder" : "blackborder")} value={this.state.Zip} maxLength={10} onChange={this.handleZipChange} ref={this.zipdRef} type="text" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-md-1">
                                            <label className="small mb-1">User Type{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <select className={"form-control " + (this.state.rUserType ? "redborder" : "blackborder")} value={this.state.UserType} onChange={this.handleUserTypeChange}>
                                                <option key="0" value="Select">Select</option>
                                                <option key="1" value="Client Admin">Client Admin</option>
                                                <option key="2" value="Agent">Agent</option>
                                                <option key="3" hidden={this.state.hideEnoahAdmin} value="eNoah Admin">eNoah Admin</option>
                                                <option key="4" value="Client Home Office">Client Home Office</option>
                                                <option key="5" hidden={this.state.hideEnoahSystemAdmin} value="eNoah System Admin">eNoah System Admin</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.IsAdmin === false) ? "show" : (this.state.AllowCarrierLevelDCA === "Yes" && (this.state.UserType === "Client Admin" || this.state.UserType === "Client Home Office")) ? "show" : "hide")}>
                                            <label className="small mb-1">Default Office{" "}</label>
                                        </div>
                                        <div className={"col-md-3 " + ((this.state.IsAdmin === false) ? "show" : (this.state.AllowCarrierLevelDCA === "Yes" && (this.state.UserType === "Client Admin" || this.state.UserType === "Client Home Office")) ? "show" : "hide")}>
                                            <select className={"form-control " + (this.state.rDefaultOffice ? "redborder" : "blackborder")} value={this.state.DefaultOffice} disabled={(this.state.DOReadOnly ? "disabled" : "")} onChange={this.handleDOChange}>
                                                <option key="0" value="">Select</option>
                                                {this.state.ToDefaultOffice != "" && this.state.ToDefaultOffice.length !== 0 && this.state.ToDefaultOffice.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.key}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <label className="small mb-1">View Results{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <select className={"form-control " + (this.state.rDownloadAccess ? "redborder" : "blackborder")} value={this.state.DownloadAccess} disabled={(this.state.DAReadOnly ? "disabled" : "")} onChange={this.handleDownloadAccessChange}>
                                                <option key="0" value="Select">Select</option>
                                                <option key="1" value="true">Yes</option>
                                                <option key="2" value="false">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <label className="small mb-1">Place Order{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <select className={"form-control " + (this.state.rPlaceOrder ? "redborder" : "blackborder")} value={this.state.PlaceOrder} disabled={(this.state.POReadOnly ? "disabled" : "")} onChange={this.handlePlaceOrderChange}>
                                                <option key="0" value="Select">Select</option>
                                                <option key="1" value="true">Yes</option>
                                                <option key="2" value="false">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <label className="small mb-1">Status Notes{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <select className={"form-control " + (this.state.rStatusView ? "redborder" : "blackborder")} value={this.state.StatusView} disabled={(this.state.SVReadOnly ? "disabled" : "")} onChange={this.handleStatusViewChange}>
                                                <option key="0" value="Select">Select</option>
                                                <option key="1" value="true">Yes</option>
                                                <option key="2" value="false">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <label className="small mb-1">Auth View{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-2 hide">
                                            <select className={"form-control " + (this.state.rAuthView ? "redborder" : "blackborder")} value={this.state.AuthView} disabled={(this.state.AVReadOnly ? "disabled" : "")} onChange={this.handleAuthViewChange}>
                                                <option key="0" value="Select">Select</option>
                                                <option key="1" value="true">Yes</option>
                                                <option key="2" value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-1 " + ((this.state.UserType === "eNoah Admin" || this.state.UserType === "eNoah System Admin" || this.state.UserType === "Client Admin" || this.state.UserType === "Client Home Office") && this.state.HideAdminMenuForCurrentUser === true ? "show" : "hide")}>
                                            <label className="small mb-1">Admin Menu{" "}
                                                <span className="red ">*</span>
                                            </label>
                                        </div>
                                        <div className={"col-md-3 " + ((this.state.UserType === "eNoah Admin" || this.state.UserType === "eNoah System Admin" || this.state.UserType === "Client Admin" || this.state.UserType === "Client Home Office") && this.state.HideAdminMenuForCurrentUser === true ? "show" : "hide")}>
                                            <select className={"form-control " + (this.state.rAdminMenu ? "redborder" : "blackborder")} value={this.state.ShowAdminMenu} onChange={this.handleShowAdminMenuChange}>
                                                <option key="1" value="true">Yes</option>
                                                <option key="2" value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewResults === true ? "show" : "hide")}>
                                            <label className="small mb-1">View Results{" "}</label>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewResults === true ? "show" : "hide")}>
                                            <select className={"form-control "}
                                                value={this.state.CHOViewResultsValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handleCHOViewResultsChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOPlaceOrder === true ? "show" : "hide")}>
                                            <label className="small mb-1">Place Order{" "}</label>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOPlaceOrder === true ? "show" : "hide")}>
                                            <select className={"form-control "}
                                                value={this.state.CHOPlaceOrderValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handleCHOPlaceOrderChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewStatus === true ? "show" : "hide")}>
                                            <label className="small mb-1">View Status{" "}</label>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewStatus === true ? "show" : "hide")}>
                                            <select className={"form-control "}
                                                value={this.state.CHOViewStatusValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handleCHOViewStatusChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewNote === true ? "show" : "hide")}>
                                            <label className="small mb-1">View Notes{" "}</label>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewNote === true ? "show" : "hide")}>
                                            <select className={"form-control "}
                                                value={this.state.CHOViewNoteValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handleCHOViewNotesChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewAuth === true ? "show" : "hide")}>
                                            <label className="small mb-1">View Auth Documents{" "}</label>
                                        </div>
                                        <div className={"col-md-1 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewAuth === true ? "show" : "hide")}>
                                            <select className={"form-control "}
                                                value={this.state.CHOViewAuthValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handleCHOViewAuthChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className={"col-md-1"}>
                                            <label className="small mb-1">Export Search Results{" "}</label>
                                        </div>
                                        <div className={"col-md-1"}>
                                            <select className={"form-control "}
                                                value={this.state.ExportSearchResultsValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handleExportSearchResultsChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-md-1"}>
                                            <label className="small mb-1">Performance Metric Access{" "}</label>
                                        </div>
                                        <div className={"col-md-1"}>
                                            <select className={"form-control "}
                                                value={this.state.PerformanceMetricsValue}
                                                disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}
                                                onChange={this.handlePerformanceMetricsChange}>
                                                <option key="1" value="Yes">Yes</option>
                                                <option key="0" value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.state.EnableSSOConnection === true && (!isNullOrUndefined(this.state.Identifier))) ? "show" : "hide"}>
                                        <div className="row mt-1">
                                            {this.state.EnableSSOConnection && this.state.mIdentifierSSO ?
                                                (<div className="col-md-1">
                                                    <label className="small mdb-2">
                                                        <span className="red  ">*</span>SSO Identifier
                                                    </label>
                                                </div>) :
                                                (<div className="col-md-1">
                                                    <label className="small mb-2">SSO Identifier</label>
                                                </div>)
                                            }
                                            <div className="col-md-3">
                                                <input className={"form-control " + (this.state.rIdentifiervalue ? "redborder" : "blackborder")} value={this.state.Identifiervalue} maxLength={100} type="text" onChange={this.handlIdentifiervalueChange} autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={((this.state.IsAdmin === false || this.state.UserType != "Select" && this.state.UserType != "eNoah Admin" && this.state.UserType != "eNoah System Admin" && this.state.UserType != "Client Admin" && this.state.UserType != "Client Home Office") ? "show" : "hide")}>
                                        {this.renderOfficeListinGrid()}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 " + (this.state.isEdit ? "hide" : "show")} onClick={this.handleAddAgentUser}>Add New User </button>
                                    <button className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 " + (this.state.isEdit ? "show" : "hide")} onClick={this.handleEditAgentUser}>Save </button>
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isOfficeModalopen ? "show" : "hide")}>
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="title"><img src="img/info.png" className="img-fluid mr-2" width="35" /> New Office Information</h4>
                                <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-3 placeholder-div">
                                <div className="row mt-1">
                                    <div className="col-md-10">
                                        <label className="small mb-1">Office Status</label>
                                    </div>
                                    <div className="col-md-2">
                                        <select className={"form-control "}
                                            value={this.state.orderselectValue}
                                            onChange={this.handleChange}>
                                            <option key="1" value="Active">Active</option>
                                            <option key="0" value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1"><span className="red float-none">*</span> Office Name</label>

                                    </div>
                                    <div className="col-md-10">
                                        <input className={"form-control " + (this.state.ofcName ? "redborder" : "blackborder")} value={this.state.OfficeName} onChange={this.handleOfficeNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Email</label>
                                    </div>
                                    <div className="col-md-5">
                                        <input className={"form-control " + (this.state.rOEmail ? "redborder" : "blackborder")} value={this.state.OEmail}
                                            onChange={this.handleOemailChange}
                                            onBlur={this.handleblurOemailChange} type="text" autoComplete="off" />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="small mb-1">Phone</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className={"form-control " + (this.state.rOPhone ? "redborder" : "blackborder")} value={this.state.OPhone} ref={this.ocpRef} onChange={this.handleOPhnChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-2">
                                        <label className="small mb-1"><span className="red float-none">*</span> Products</label>

                                    </div>
                                    <div className="col-md-2">
                                        <input type="checkbox" className="form-check-input position-static m-0" onChange={(e) => this.handleOfficeAPSCheck(e)} checked={this.state.oAPS} disabled={(this.state.clientAPS && !this.state.clientSummary) || (!this.state.clientAPS && this.state.clientSummary) ? "disabled" : ""} />
                                        <label className="form-check-label float-none ml-2">APS</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1">APS Office Account #</label>
                                    </div>
                                    <div className="col-md-5">
                                        <input className={"form-control " + (this.state.ofcAccount ? "redborder" : "blackborder")} value={this.state.OfficeAccountNumber} onChange={this.handleOfficeAccountChange} type="text" autoComplete="off" disabled={this.state.clientAPS === false ? "disabled" : ""} />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-2">
                                        <input type="checkbox" className="form-check-input position-static m-0" onChange={(e) => this.handleOfficeSummaryCheck(e)} checked={this.state.oSummary} disabled={(this.state.clientAPS && !this.state.clientSummary) || (!this.state.clientAPS && this.state.clientSummary) ? "disabled" : ""} />
                                        <label className="form-check-label float-none ml-2">Summary</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1">Summary Office Account #</label>
                                    </div>

                                    <div className="col-md-5">
                                        <input className={"form-control " + (this.state.summaryofcAccount ? "redborder" : "blackborder")} value={this.state.SummaryOfficeAccountNumber} onChange={this.handleSummaryOfficeAccountChange} type="text" autoComplete="off" disabled={this.state.clientSummary === false ? "disabled" : ""} />
                                    </div>
                                    <div className="col-md-7"></div>
                                    <div className="col-md-5">
                                        <p className="special-div-y" style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>*Not all ordering offices that order both products will have an account number per product.</p>
                                    </div>

                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Office Code</label>
                                    </div>
                                    <div className="col-md-2">
                                        <input className={"form-control " + (this.state.ofcCode ? "redborder" : "blackborder")} value={this.state.officeCode} onChange={this.handleOfficeCodeChange} type="text" autoComplete="off" />

                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">Place Order {" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowPlaceOrder}
                                            onChange={this.handleallowPlaceOrderChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Auth Documents {" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewAuth}
                                            onChange={this.handleallowViewAuthChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Notes {" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewNotes}
                                            onChange={this.handleallowViewNotesChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Results {" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewResults}
                                            onChange={this.handleallowViewResultsChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Status {" "}</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewStatus}
                                            onChange={this.handleallowViewStatusChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : "")}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm hvr-shutter-out-horizontal btn-type pull-right mt-1 mb-1" onClick={this.handleAddOfficeInformation}>Submit </button>
                                <button className="btn btn-blue-btnnm hvr-shutter-out-horizontal btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isOfficeEditModalopen ? "show" : "hide")}>
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="title"><img src="img/info.png" className="img-fluid mr-2" width="35" />Edit Office Information</h4>
                                <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-3 placeholder-div">
                                <div className="row mt-1">
                                    <div className="col-md-10">
                                        <label className="small mb-1">Office Status</label>
                                    </div>
                                    <div className="col-md-2">
                                        <select className={"form-control "}
                                            value={this.state.orderselectValue}
                                            onChange={this.handleChange}>
                                            <option key="1" value="Active">Active</option>
                                            <option key="0" value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">
                                            Office Name <span className="red">*</span>
                                        </label>
                                    </div>
                                    <div className="col-md-10">
                                        <input className={"form-control " + (this.state.ofcName ? "redborder" : "blackborder")} value={this.state.OfficeName} onChange={this.handleOfficeNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Email</label>
                                    </div>
                                    <div className="col-md-5">
                                        <input className={"form-control " + (this.state.rOEmail ? "redborder" : "blackborder")} value={this.state.OEmail} onChange={this.handleOemailChange} onBlur={this.handleblurOemailChange} type="text" autoComplete="off" />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="small mb-1">Phone</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className={"form-control " + (this.state.rOPhone ? "redborder" : "blackborder")} value={this.state.OPhone} ref="ocp" onChange={this.handleOPhnChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-2">
                                        <label className="small mb-1">
                                            Products <span className="red">*</span>
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <input type="checkbox" className="form-check-input position-static m-2" onChange={(e) => this.handleOfficeAPSCheck(e)} checked={this.state.oAPS} disabled={(this.state.clientAPS && !this.state.clientSummary) || (!this.state.clientAPS && this.state.clientSummary) ? "disabled" : ""} />
                                        <label className="form-check-label float-none ml-2">APS</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1">APS Office Account #</label>
                                    </div>
                                    <div className="col-md-5">
                                        <input className={"form-control " + (this.state.ofcAccount ? "redborder" : "blackborder")} value={this.state.OfficeAccountNumber} onChange={this.handleOfficeAccountChange} type="text" autoComplete="off" disabled={this.state.clientAPS === false ? "disabled" : ""} />

                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2">
                                        <input type="checkbox" className="form-check-input position-static m-2" onChange={(e) => this.handleOfficeSummaryCheck(e)} checked={this.state.oSummary} disabled={(this.state.clientAPS && !this.state.clientSummary) || (!this.state.clientAPS && this.state.clientSummary) ? "disabled" : ""} />
                                        <label className="form-check-label float-none ml-2">Summary</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1">Summary Office Account #</label>
                                    </div>
                                    <div className="col-md-5">
                                        <input className={"form-control " + (this.state.summaryofcAccount ? "redborder" : "blackborder")} value={this.state.SummaryOfficeAccountNumber} onChange={this.handleSummaryOfficeAccountChange} type="text" autoComplete="off" disabled={this.state.clientSummary === false ? "disabled" : ""} /></div>
                                    <div className="col-md-7"></div>
                                    <div className="col-md-5">

                                        <p className="special-div-y" style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>*Not all ordering offices that order both products will have an account number per product.</p>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Office Code</label>
                                    </div>
                                    <div className="col-md-2">
                                        <input className={"form-control " + (this.state.ofcCode ? "redborder" : "blackborder")} value={this.state.officeCode} onChange={this.handleOfficeCodeChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">Place Order</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowPlaceOrder}
                                            onChange={this.handleallowPlaceOrderChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : (this.state.isClientAdmin ? "disabled" : ""))}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Auth Documents</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewAuth}
                                            onChange={this.handleallowViewAuthChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : (this.state.isClientAdmin ? "disabled" : ""))}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>

                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Notes</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewNotes}
                                            onChange={this.handleallowViewNotesChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : (this.state.isClientAdmin ? "disabled" : ""))}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Results</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewResults}
                                            onChange={this.handleallowViewResultsChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : (this.state.isClientAdmin ? "disabled" : ""))}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <label className="small mb-1">View Status</label>
                                    </div>
                                    <div className={"col-md-2 " + (!this.state.hideEnoahAdmin ? "show" : "hide")}>
                                        <select className="form-control"
                                            value={this.state.AllowViewStatus}
                                            onChange={this.handleallowViewStatusChange}
                                            disabled={(this.state.DisableAllowOverride === false ? "disabled" : (this.state.isClientAdmin ? "disabled" : ""))}>
                                            <option key="1" value="Yes">Yes</option>
                                            <option key="0" value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm hvr-shutter-out-horizontal btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateOfficeInformation}>Update </button>
                                <button className="btn btn-blue-btnnm hvr-shutter-out-horizontal btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"modal " + (this.state.isCMEditModalopen ? "show" : "hide")}>
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Client Manager Information</h4>
                                <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-3 placeholder-div">
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">First Name{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMFirstName ? "redborder" : "blackborder")} value={this.state.CMFirstName} maxLength={50} onChange={this.handleCMFirstNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Last Name{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMLastName ? "redborder" : "blackborder")} value={this.state.CMLastName} maxLength={50} onChange={this.handleCMLastNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Email Address{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMEmailAddress ? "redborder" : "blackborder")} value={this.state.CMEmailAddress} maxLength={250}
                                            onChange={this.handleCMEmailAddressChange}
                                            onBlur={this.handleblurCMEmailAddressChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Work Phone{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMWorkPhone ? "redborder" : "blackborder")} value={this.state.CMWorkPhone} maxLength={14} onChange={this.handleCMWorkPhoneChange} ref={this.cmwpRef} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Ext{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMExt ? "redborder" : "blackborder")} value={this.state.CMExt} maxLength={5} onChange={this.handleCMExtChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Cell Phone{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMCellPhone ? "redborder" : "blackborder")} value={this.state.CMCellPhone} maxLength={14} onChange={this.handleCMCellPhoneChange} ref={this.cmcpRef} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Fax{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rCMFax ? "redborder" : "blackborder")} value={this.state.CMFax} ref="CMFax" maxLength={14} onChange={this.handleCMFaxhange} type="text" ref={this.CMFaxRef} autoComplete="off" />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateCMInformation}>Update </button>
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isUWEditModalopen ? "show" : "hide")}>
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Underwriter Listing</h4>
                                <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-3 placeholder-div">
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">First Name{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWFirstName ? "redborder" : "blackborder")} value={this.state.UWFirstName} maxLength={50} onChange={this.handleUWFirstNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Last Name{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWLastName ? "redborder" : "blackborder")} value={this.state.UWLastName} maxLength={50} onChange={this.handleUWLastNameChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Email Address{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWEmailAddress ? "redborder" : "blackborder")} value={this.state.UWEmailAddress} maxLength={250}
                                            onChange={this.handleUWEmailAddressChange}
                                            onBlur={this.handleblurUWEmailAddressChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Work Phone{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWWorkPhone ? "redborder" : "blackborder")} value={this.state.UWWorkPhone} maxLength={14} onChange={this.handleUWWorkPhoneChange} ref={this.uwwpRef} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Ext{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWExt ? "redborder" : "blackborder")} value={this.state.UWExt} maxLength={5} onChange={this.handleUWExtChange} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Cell Phone{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWCellPhone ? "redborder" : "blackborder")} value={this.state.UWCellPhone} maxLength={14} onChange={this.handleUWCellPhoneChange} ref={this.uwcpRef} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2">
                                        <label className="small mb-1">Fax{" "}</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className={"form-control " + (this.state.rUWFax ? "redborder" : "blackborder")} value={this.state.UWFax} ref="UWFax" maxLength={14} onChange={this.handleUWFaxChange} ref={this.uwFaxRef} type="text" autoComplete="off" />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1" onClick={this.handleUpdateUWInformation}>Update </button>
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1" onClick={this.closePopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isviewModalopen ? "show" : "hide")} id="editDoc">
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content h-55">
                            <div className="modal-header">
                                <h4 className="modal-title">View
                                </h4>
                                <button type="button" className="close" onClick={this.closePopup} data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body document-modal">
                                <div className="modal-body p-3 placeholder-div">
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">First Name{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.FirstName}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Last Name{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.LastName}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Agent Id{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.AgentId}  </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Email Address{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.AgentEmail}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Phone #1{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.Phone1}  </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Ext{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.Ext1}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Mobile{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center">  {this.state.Phone2} </span>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Address Line 1{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.AddressLine1}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">City{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.UCity}  </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">ST{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.ST}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Zip{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center">  {this.state.Zip} </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">User Type{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center">  {this.state.UserType} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="col-md-4 pull-left hide">
                                                <label className="small mb-1">Access Type{" "}</label>
                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0 hide">
                                                <span className="pop-label-center"> {this.state.AccessType}  </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Download Access{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0 hide">
                                                <span className="pop-label-center"> {this.state.DownloadAccess}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 hide">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Place Order{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.PlaceOrder}  </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide">
                                            <div className="col-md-4 pull-left">
                                                <label className="small mb-1">Status Notes{" "}</label>

                                            </div>
                                            <div className="col-md-8 pull-left p-lr-0">
                                                <span className="pop-label-center"> {this.state.StatusView}  </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-2 d-flex align-items-center">
                                        <div className="col-md-2 pull-left">
                                            <label className="small mb-1">Ordering Offices{" "}</label>

                                        </div>
                                        <div className="col-md-9 pull-left p-lr-0">
                                            <span className="pop-label-center">  {(this.state.UserType == "Agent" ? this.state.OrderingOffices : "All Offices")} </span>
                                        </div>
                                    </div>

                                    <div className={"row mt-2  d-flex align-items-center " + ((this.state.UserType != "eNoah Admin" || this.state.UserType != "eNoah System Admin") ? "show" : "hide")}>
                                        <div className="col-md-2 pull-left">
                                            <label className="small mb-1">Default Office{" "}</label>
                                        </div>
                                        <div className="col-md-8 pull-left p-lr-0">
                                            <span className="pop-label-center"> {((this.state.UserType == "Agent" || (this.state.DefaultOffice && this.state.AllowCarrierLevelDCA == "Yes")) ? (this.state.DefaultOffice == "All Offices" ? "Not Applicable" : this.state.DefaultOffice) : "Not Applicable")}  </span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewResults === true ? "show" : "hide")}>
                                                <label className="small mb-1">View Results{" "}</label>

                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewResults === true ? "show" : "hide")}>
                                                <span className="pop-label-center"> {this.state.CHOViewResultsValue} </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOPlaceOrder === true ? "show" : "hide")}>
                                                <label className="small mb-1">Place Order{" "}</label>

                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOPlaceOrder === true ? "show" : "hide")}>
                                                <span className="pop-label-center"> {this.state.CHOPlaceOrderValue} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewStatus === true ? "show" : "hide")}>
                                                <label className="small mb-1">View Status{" "}</label>

                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewStatus === true ? "show" : "hide")}>
                                                <span className="pop-label-center"> {this.state.CHOViewStatusValue} </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewNote === true ? "show" : "hide")}>
                                                <label className="small mb-1">View Notes{" "}</label>

                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewNote === true ? "show" : "hide")}>
                                                <span className="pop-label-center"> {this.state.CHOViewNoteValue} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewAuth === true ? "show" : "hide")}>
                                                <label className="small mb-1">View Auth Documents{" "}</label>

                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0 " + ((this.state.UserType == "Client Home Office") && this.state.hideCHOViewAuth === true ? "show" : "hide")}>
                                                <span className="pop-label-center"> {this.state.CHOViewAuthValue} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left "}>
                                                <label className="small mb-1">Export Search Results{" "}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0 "}>
                                                <span className="pop-label-center"> {this.state.ExportSearchResultsValue} </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className={"col-md-4 pull-left"}>
                                                <label className="small mb-1">Performance Metrics{" "}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left p-lr-0"}>
                                                <span className="pop-label-center"> {this.state.PerformanceMetricsValue} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={((this.state.IsAdmin === false || this.state.UserType != "Select" && this.state.UserType != "eNoah Admin" && this.state.UserType != "eNoah System Admin" && this.state.UserType != "Client Admin" && this.state.UserType != "Client Home Office") ? "show" : "hide")}>
                                        {this.renderOfficeListinGridNonEditable()}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

