import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Api from '../serviceCall';
import PropTypes from 'prop-types';
export class Error extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        this.state = { Message: "", Title: "", CopyRightYear: new Date().getFullYear(), Version: "", Env: "", Date: "", PrivacyPolicyURL: "" };
        this.handlehome = this.handlehome.bind(this);
    }
    componentWillMount() {
        let currentComponent = this;
        let msg = localStorage.getItem("Message");
        let title = localStorage.getItem("Title");
        localStorage.removeItem("Message");
        localStorage.removeItem("Title");
        if (msg!=null &&msg.length !== 0) {
            this.setState({ Message: msg, Title: title });
        }
        else {
            currentComponent.props.history.push('/');
        }
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
            }
        });
        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }
        });
    }
    handlehome(e){
        this.props.history.push('/');
    }
    render() {
        return (
            <Container fluid>                
                <div className="container-div">
                    <div className="con-div-pso">
                        <div className="con-div-xd lefeside-panel">
                        </div>
                    </div> H
                    <div className="con-div-xm">
                        <div className="con-div-x">
                            <div className="login-page">
                                <div className="logo">
                                    <img src="img/eSubmission-logo.png" alt="logo" />
                                    <h4>eNoah Online Portal </h4>
                                </div>

                                <div className="form">
                                    <form className="login-form">
                                        <h4>{this.state.Title}</h4>
                                        <div>
                                            <p className="form-text text-muted">{this.state.Message}</p>
                                        </div>

                                        <div>
                                            <button className="onLogin" onClick={this.handlehome}>Back to Login Screen</button>

                                        </div>
                                    </form>
                                </div>

                                <div className="footer-text text-center mb-2">Build: <span className="text-dark mr-2">{this.state.Date + ": v" + this.state.Version}</span> Environment: <span className="text-dark">{this.state.Env}</span></div>
                                <div className="footer-text text-center mb-2">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                                <div className="footer-text text-center">
                                    <a href={this.state.PrivacyPolicyURL} target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="error-message text-center">*/}
                    {/*    <h1>{this.state.Title}</h1>*/}
                    {/*    <p className="kt-error_subtitle">{this.state.Message}</p>*/}
                    {/*    <button className="btn btn-blue-btnnm btn-type pull-center mt-1 mb-1 btn-size-3" onClick={this.handlehome}>Back to Home </button>*/}
                    {/*</div>*/}
                </div>
            </Container>
        );
    }
}