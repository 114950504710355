import React, { Component } from 'react';
import { Layout } from './Layout';
import $ from 'jquery';
import Api from '../serviceCall';
import { isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
export class Confirmation extends Component {
    static displayName = Confirmation.name;
    constructor(props) {
        super(props);
        this.state = {
            Numbers: [], contactName:"", contactEmail: "",
            contactPhone: "",
            policyLabel: "",
            policyNumber: "",
            patientLastName: "",
            patientFirstName: "",
            Loading: true,

        };
        this.toasterredfun = this.toasterredfun.bind(this);
    }
    componentWillMount() {
        let currentComponent = this;
        let id = localStorage.getItem("OrderId");
       
        if (id === null || id.length === 0) {
            currentComponent.props.history.push('/place-order');
        } else {
            currentComponent.setState({ Loading: true });
            Api.Post('api/placeorder/GetConfirmation', { "orderId": parseInt(id) }, currentComponent).then(function (result) {
                if (result.statusMessage === "Success") {
                    currentComponent.setState({ Numbers: result.result });

                    if (!isNullOrUndefined(result.policyNumberValue)) {
                        currentComponent.setState({ policyLabel: Api.decryptData(result.policyNumberLabel) });
                        currentComponent.setState({ policyNumber: Api.decryptData(result.policyNumberValue) });
                    }
                    currentComponent.setState({ patientLastName: Api.decryptData(result.patientLN) });
                    currentComponent.setState({ patientFirstName: Api.decryptData(result.patientFN.trim()) });

                    localStorage.setItem("OrderId", "");
                }
               
                currentComponent.setState({ Loading: false });
            });

        }
        currentComponent.setState({ Loading: true });
        Api.Get('api/placeorder/GetConfigData', currentComponent).then(function (result) {
            if (result.statusMessage === "Success") {
                currentComponent.setState({ contactName: result.contactName, contactEmail: result.contactEmail, contactPhone: result.contactPhone });
            }

            currentComponent.setState({ Loading: false });
        });          
     }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    render() {
        let landing = "img/ems-logo.gif";
        return (
          <Layout>
                <TimeoutCounter />
                <div className='hgtDiv bgwhite'>
                    <div className="">
                        <h5 className="mt-4 mb-2 ml-2">Success! We have received your request(s)! Please see your Order number(s) below, write down or print this page for your records.
						</h5>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="mb-4">
                                    <ul className="confirm-list">
                                        {this.state.Numbers.map((num) => (
                                            <li key={num}> Order: {num} {this.state.policyNumber !== "" ? "- " + this.state.policyLabel + ": " + this.state.policyNumber : ""} - Patient: {this.state.patientLastName}, {this.state.patientFirstName.substring(0, 1)} </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>


                            <div className="col-xl-12">
                                <div className="mt-4 mb-2 ml-2">If you have any questions or concerns, please reach out to your Customer Service Representative at the information provided below
						    </div>
                                    <div className="mb-4">
                                        <ul className="letter-div-x ml-2">
                                            <li>{this.state.contactName}</li>
                                            <li> {this.state.contactEmail} </li>
                                            <li> {this.state.contactPhone} </li>                                            
                                    </ul>


                                    </div>
                                <h5 className="mb-6 ml-2"> Thank you! </h5>
                            </div>
                        </div>
                     </div>
                </div>
                <div>
                <span id="load" className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                        <img src={process.env.PUBLIC_URL + landing} alt="" />{" "}
                    </span>
                  </div>
            </Layout>
        );
    }
}
