import React, { Component } from 'react';
import { Container } from 'reactstrap';
import {deviceDetect } from "react-device-detect";
import { isNullOrUndefined } from 'util';
import PropTypes from 'prop-types';


export class LayoutContext extends Component {
    static displayName = LayoutContext.name;
    static propTypes = {
        children: PropTypes.node
    };
    constructor(props) {
        super(props);
        this.state = { UserId:"" }
        this.UserLogoutTime = this.UserLogoutTime.bind(this);
    }

    componentDidMount() {     
        let currentComponent = this;
        localStorage.removeItem('PressedKey');
        localStorage.removeItem('IsShiftPressed');
        localStorage.removeItem('IsCtrlPressed');

        document.addEventListener('keydown', function (event) {            
            localStorage.setItem('PressedKey', event.key);
            localStorage.setItem('IsCtrlPressed', event.ctrlKey);
            localStorage.setItem('IsShiftPressed', event.shiftKey);
        });
        window.addEventListener('beforeunload', function (e) {
            let IsSSOUser = localStorage.getItem("IsSSOUser");
            let SyncClicked = localStorage.getItem("SyncClicked");
            let IsTimeOut = localStorage.getItem("IsTimeOut");
            let IsTimeOutFromPlaceOrder = localStorage.getItem("IsTimeOutFromPlaceOrder");
            if ((IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') || (SyncClicked == 'false' && IsTimeOut == 'false' && IsTimeOutFromPlaceOrder == 'false')) {
                e.preventDefault();
                e.returnValue = '';
            }
        });
        window.addEventListener('unload', function (event) {            
            let IsSSOUser = localStorage.getItem("IsSSOUser");
            let SyncClicked = localStorage.getItem("SyncClicked");
            let IsTimeOut = localStorage.getItem("IsTimeOut");
            let IsTimeOutFromPlaceOrder = localStorage.getItem("IsTimeOutFromPlaceOrder");
            let Euid = localStorage.getItem("Euid");      
            let PressedKey = localStorage.getItem("PressedKey");
            let IsShiftPressed = localStorage.getItem("IsShiftPressed");
            let IsCtrlPressed = localStorage.getItem("IsCtrlPressed");
            if (IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') {
                if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                    //Normal refresh will happen without Logging out.
                } else {
                    currentComponent.UserLogoutTime();
                }
            }
            
            if ((IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') || (!isNullOrUndefined(Euid) && IsTimeOutFromPlaceOrder == 'false' && IsTimeOut == 'false' && SyncClicked == 'false')) {
                if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                    //Normal refresh will happen without Logging out.
                } else {
                    currentComponent.UserLogoutTime();
                }
            }
            setTimeout(() => {
                console.log('A Delay of 10 seconds');
            }, 10000);
        });       
    }
   
    async UserLogoutTime() {
        let CompanyCode = localStorage.getItem("Companycode");        
        let euid = localStorage.getItem("Euid");
        const headers = {
            'Content-Type': 'application/json',           
            'Euid': euid,
            'CompanyCode': CompanyCode,
            'BrowserType': deviceDetect().browserName,
            'BrowserVersion': deviceDetect().browserFullVersion
        };
        const formData = new FormData();
        Object.keys(headers).forEach((key) => {
            formData.append(key, headers[key]);
        });
        const result = navigator.sendBeacon('services/api/UserLogOutEntry', formData);
        localStorage.clear();
        if (result) {

            console.log('Beacon sent successfully');
        } else {
            console.log('Beacon failed to send');
        }
        setTimeout(
            function () {
                console.log("-------");
            }, 9000);
    }
    render() {
        return (
            <Container fluid>
                {this.props.children}                
            </Container>
            );
    }
   
}
