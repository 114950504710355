import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import $ from 'jquery';
import Api from '../serviceCall';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNullOrUndefined } from 'util';
import { MultiSelect } from "react-multi-select-component";
import "./dashboard.css";
import { TimeoutCounter } from './TimeoutCounter';
import { TableHead, Icon, TableRow, TableCell, TableSortLabel, TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import Moment from 'moment';

export class Dashboard extends Component {
    static displayName = Dashboard.name;
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            LandingPendingOrderList: [],
            status: '-1',
            barChartPendingOrder: [],
            barChartActionRequiredOrder: [], toasterCla: false,
            barChartDeliveredResultsOrder: [],
            value: { text: 'Select', value: 0 },
            selectDropDownUsers: '0', toasterredCla: false, isOwn: false,

            lblRequestType: "",
            lblFirstName: "",
            lblDoctorFirstName: "",
            lblFacilityName: "",
            lblPolicyNum: "",
            RequiredFieldsList: [],
            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,

            lblLastName: "",
            selectDropDownCycleTime: '0', RowCount: "", isPending: false, isActionRequired: false, isAll: false, UserId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            dataForExport: [],
            isSearchClicked: false,
            emptyDataSourceMessage: "Choose a quick filter to see results",
            orderingOfficeAccessList: [],
            selectedMultiItem: [],
            hideExportButton: false,
            sortOrder: '',
            sortColumn: '',
            clickCount: 0,
            selectedDropDownProduct: '0',
            productOptions: ['0', '1', '2'],
            disableDropdown: false,
            unFilteredOrderingOfficeList: [],
            productAPS: 0,
            productSummary: 0,
            storeSearchFilter: [],
            filtersApplied: false

        };
        this.handleSyncDashboard = this.handleSyncDashboard.bind(this);
        this.GetShowAdminMenu = this.GetShowAdminMenu.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.LandingPageOrders = this.LandingPageOrders.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.loadOrderingOfficeAccessList = this.loadOrderingOfficeAccessList.bind(this);
        this.setSelectedfun = this.setSelectedfun.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.tableRef = React.createRef();
        this.handleProductChange = this.handleProductChange.bind(this);
        this.isProgrammaticFilterChange = false;
    }
    setSelectedfun(e) {
        this.setState({ selectedMultiItem: e, sortColumn: '', sortOrder: '', clickCount: 0, currentPage: 0 }, () => {

            if (this.state.selectDropDownUsers === '0' || this.state.selectDropDownUsers === '1' || this.state.selectDropDownUsers === '2') {
                if (this.state.status !== "-1") {
                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct);
                }
                this.loadPendingOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, this.state.selectedDropDownProduct);
                this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, this.state.selectedDropDownProduct);
            }
        });
    }
    handleSyncDashboard(event) {
        localStorage.setItem('SyncClicked', 'true');
        let currentComponent = this;
        let url = "api/placeorder/UpdateDaysCalculation";
        currentComponent.setState({ loading: true });
        Api.Get(url, currentComponent)
            .then(function (resul) {
                if (resul.statusCode === 100) {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterfunc(resul.statusMessage);
                    window.location.reload(false);
                } else {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterredfun2(resul.statusMessage);
                }
            })
            .catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun("Check your internet connection.");
                } else {
                    currentComponent.toasterredfun(error);
                }
            });
    }
    handleStatusChange(event) {
        this.setState({ status: event.target.value, currentPage: 0 });
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
        });
        let selectDropDownUsers = this.state.selectDropDownUsers;
        let selectDropDownCycleTime = this.state.selectDropDownCycleTime;
        let targetValue = event.target.value;
        let selectedProductType = this.state.selectedDropDownProduct;
        if (event.target.value === "3") {
            this.setState({
                isPending: true,
                isActionRequired: false,
                isAll: false,
                isSearchClicked: true,
                storeSearchFilter: []
            }, () => { this.LandingPageOrders(targetValue, selectDropDownUsers, selectDropDownCycleTime, 0, this.state.PageLimit, '', '', selectedProductType); });
        }
        else if (event.target.value === "4") {
            this.setState({
                isPending: false,
                isActionRequired: true,
                isAll: false,
                isSearchClicked: true,
                storeSearchFilter: []
            }, () => { this.LandingPageOrders(targetValue, selectDropDownUsers, selectDropDownCycleTime, 0, this.state.PageLimit, '', '', selectedProductType); });
        }
        else {
            this.setState({
                isPending: false,
                isActionRequired: false,
                isAll: true,
                isSearchClicked: true,
                storeSearchFilter: []
            }, () => { this.LandingPageOrders(targetValue, selectDropDownUsers, selectDropDownCycleTime, 0, this.state.PageLimit, '', '', selectedProductType); });
        }
    }
    handleBasedUserListChange = (event) => {
        this.setState({
            selectDropDownUsers: event.target.value, LandingPendingOrderList: [], orderingOfficeAccessList: [], selectedMultiItem: [], RowCount: "", totalRecord: 0, emptyDataSourceMessage: "No records to display", sortColumn: '', sortOrder: '', clickCount: 0, currentPage: 0
        }, () => {

            this.loadOrderingOfficeAccessList(this.state.selectDropDownUsers, true);
        });
    }
    handleBasedUserCycleTimeListChange = (event) => {
        this.setState({
            selectDropDownCycleTime: event.target.value,
            currentPage: 0
        });
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
            currentPage: 0
        });
        if (['0', '1', '2', '3'].includes(event.target.value)) {
            if (this.state.status !== "-1") {
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, event.target.value, 0, this.state.PageLimit, '', '', this.state.selectedDropDownProduct);
            }
            this.loadPendingOrderBarChart(this.state.selectDropDownUsers, event.target.value, this.state.selectedDropDownProduct);
            this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, event.target.value, this.state.selectedDropDownProduct);
        }
    }

    async exportGridValues() {
        let currentComponent = this;
        let responce;
        if (currentComponent.state.isSearchClicked === true) {
            currentComponent.setState({ loading: true })
            let officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            await Api.Post('api/placeorder/GetLandingPageOrders', {
                status: currentComponent.state.status, selectDropDownUsers: currentComponent.state.selectDropDownUsers,
                selectDropDownCycleTime: currentComponent.state.selectDropDownCycleTime, dataOffSet: "0", dataLimit: "0", officeName: officeNameList, product: currentComponent.state.selectedDropDownProduct
            }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    if (!isNullOrUndefined(result.result)) {
                        if (result.allowExport) {
                            const modifiedData = result.result.map(rowData => {
                                return {
                                    ...rowData,
                                    patientFirstName: Api.decryptData(rowData.patientFirstName),
                                    patientLastName: Api.decryptData(rowData.patientLastName),
                                    policyNumber: Api.decryptData(rowData.policyNumber)
                                };
                            });
                            currentComponent.setState({ dataForExport: modifiedData, loading: false });
                            currentComponent.setState({ hideExportButton: result.allowExport });
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                        }
                        else
                        {
                            currentComponent.setState({ dataForExport: [], loading: false });
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                            if (currentComponent.state.hideExportButton) {
                                currentComponent.setState({ exportAlert: true });
                            }
                            responce = false;
                            return responce;
                        }
                    }
                    else {
                        currentComponent.setState({ dataForExport: [], loading: false });
                    }
                    responce = true;
                }
                else {
                    currentComponent.setState({ dataForExport: [], loading: false });
                    responce = false;
                }
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    LandingPageOrders(status, selectDropDownUsers, selectDropDownCycleTime, offset, Limit, fieldName, sortOrder, productType, filtersInJson = '') {
        let currentComponent = this;
        this.setState({ offSet: offset });
        if (currentComponent.state.isSearchClicked === true) {
            currentComponent.setState({ loading: true });

            let officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            Api.Post('api/placeorder/GetLandingPageOrders', {
                status: status, selectDropDownUsers: selectDropDownUsers,
                selectDropDownCycleTime: selectDropDownCycleTime, dataOffSet: offset, dataLimit: Limit,
                officeName: officeNameList, fieldName: fieldName, sortOrder: sortOrder, product: productType, filtersInJson: filtersInJson
            }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    currentComponent.setState({ LandingPendingOrderList: [], RowCount: "" });
                    if (!isNullOrUndefined(result.result)) {
                        if (result.rowCount === 0) {
                            currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                        }
                        const modifiedData = result.result.map(rowData => {
                            return {
                                ...rowData,
                                patientFirstName: Api.decryptData(rowData.patientFirstName),
                                patientLastName: Api.decryptData(rowData.patientLastName),
                                policyNumber: Api.decryptData(rowData.policyNumber)
                            };
                        });
                        localStorage.setItem('TotalRecordsInResult', result.rowCount)
                        currentComponent.setState({ LandingPendingOrderList: modifiedData, loading: false, isOwn: result.isOwn, RowCount: result.rowCount, totalRecord: result.rowCount });
                        const newPageLimit = Limit;
                        if (currentComponent.tableRef.current) {
                            const event = {
                                target: {
                                    value: newPageLimit,
                                },
                            };
                            currentComponent.tableRef.current.onChangeRowsPerPage(event);
                        }
                        localStorage.removeItem('IsFromCaseDetailsToDashboard');
                    }
                    else {
                        currentComponent.setState({ LandingPendingOrderList: [], loading: false, isOwn: result.isOwn, RowCount: "", totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                    }

                    if (currentComponent.tableRef.current) {
                        const storedFilters = currentComponent.state.storeSearchFilter;

                        if (!currentComponent.state.filtersApplied && storedFilters.length > 0) {
                            currentComponent.isProgrammaticFilterChange = true;
                            currentComponent.setState({ filtersApplied: true });
                            storedFilters.forEach(filter => {
                                const column = currentComponent.tableRef.current.dataManager.columns.find(col => col.field === filter.field);

                                if (column) {
                                    currentComponent.tableRef.current.onFilterChange(column.tableData.id, filter.value);
                                }
                            });

                            setTimeout(() => {
                                currentComponent.isProgrammaticFilterChange = false;
                            }, 300);
                        }
                    }
                }
                else if (result.statusCode === 429) {
                    currentComponent.toasterredfun2(result.statusMessage);
                    currentComponent.setState({ loading: false });
                }
                else {
                    currentComponent.setState({ LandingPendingOrderList: [], loading: false, RowCount: "", totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                }
            });


        }

    }
    loadOrderingOfficeAccessList(selectDropDownUsers, isDefault = false) {
        let currentComponent = this;
        currentComponent.setState({ loading: true, orderingOfficeAccessList: [], selectedMultiItem: [] });
        Api.Post('api/placeorder/GetOrderingOfficeAccessList', {
            AccessLevel: selectDropDownUsers
        }, currentComponent).then(function (result) {
            if (!isNullOrUndefined(result.result) && !isNullOrUndefined(result.productType)) {
                let productOptions = ['0', '1', '2'];
                let selectedProduct = '0';
                let disableDropdown = false;
                let productAPS = false;
                let productSummary = false;

                if (isDefault) {
                    let IsRedirectedFromDashboard = localStorage.getItem('IsFromCaseDetailsToDashboard');
                    if (IsRedirectedFromDashboard === "true") {
                        localStorage.removeItem('IsFromCaseDetailsToDashboard');
                        let OrderingOfficeAccessList = JSON.parse(localStorage.getItem('OrderingOfficeAccessList'));
                        let SelectedMultiItem = JSON.parse(localStorage.getItem('SelectedMultiItem'));
                        let ProductDropDownValue = localStorage.getItem('ProductDropDownValue');
                        let InitialDropdownValue = localStorage.getItem('InitialDropdownValue');

                        if (ProductDropDownValue === '1') {
                            productOptions = (InitialDropdownValue == "true") ? ['1'] : ['0', '1', '2'];
                            selectedProduct = '1';
                            disableDropdown = (InitialDropdownValue === "true");
                            productAPS = true;
                        } else if (ProductDropDownValue === '2') {
                            productOptions = (InitialDropdownValue == "true") ? ['2'] : ['0', '1', '2'];
                            selectedProduct = '2';
                            disableDropdown = (InitialDropdownValue === "true");
                            productSummary = true;
                        } else if (ProductDropDownValue === '0') {
                            productOptions = ['0', '1', '2'];
                            selectedProduct = (ProductDropDownValue == "0") ? '0' : '1';
                            productAPS = true;
                            productSummary = true;
                        }

                        currentComponent.setState({ orderingOfficeAccessList: OrderingOfficeAccessList, selectedMultiItem: SelectedMultiItem, selectedDropDownProduct: selectedProduct, productOptions: productOptions, disableDropdown: disableDropdown, unFilteredOrderingOfficeList: OrderingOfficeAccessList });
                    }
                    else {
                        if (result.productType === 'APS') {
                            productOptions = ['1'];
                            selectedProduct = '1';
                            disableDropdown = true;
                            productAPS = true;
                        } else if (result.productType === 'Summary') {
                            productOptions = ['2'];
                            selectedProduct = '2';
                            disableDropdown = true;
                            productSummary = true;
                        } else if (result.productType === 'Both') {
                            productOptions = ['0', '1', '2'];
                            selectedProduct = '1';
                            productAPS = true;
                            productSummary = true;
                        }
                        localStorage.setItem('ProductDropDownValue', selectedProduct);
                        let filteredOrderingOfficeAccessList = result.result.filter(item => {
                            let isAPS = item.aps && item.aps.toString().toLowerCase() === "true";
                            let isSummary = item.summary && item.summary.toString().toLowerCase() === "true";

                            return (productAPS && isAPS) || (productSummary && isSummary);
                        });
                        currentComponent.setState({
                            orderingOfficeAccessList: filteredOrderingOfficeAccessList,
                            unFilteredOrderingOfficeList: result.result,
                            selectedMultiItem: filteredOrderingOfficeAccessList,
                            selectedDropDownProduct: selectedProduct,
                            productOptions: productOptions,
                            disableDropdown: disableDropdown
                        });
                    }
                }
                else {
                    if (currentComponent.state.selectedDropDownProduct === '1') {
                        productOptions = ['1'];
                        selectedProduct = '1';
                        disableDropdown = true;
                        productAPS = true;
                        productSummary = false;
                    } else if (currentComponent.state.selectedDropDownProduct === '2') {
                        productOptions = ['2'];
                        selectedProduct = '2';
                        disableDropdown = true;
                        productSummary = true;
                        productAPS = false;
                    } else if (currentComponent.state.selectedDropDownProduct === '0') {
                        productOptions = ['0', '1', '2'];
                        selectedProduct = '1';
                        productAPS = true;
                        productSummary = true;
                    }
                    localStorage.setItem('ProductDropDownValue', selectedProduct);
                    let filteredOrderingOfficeAccessList = result.result.filter(item => {
                        let isAPS = item.aps && item.aps.toString().toLowerCase() === "true";
                        let isSummary = item.summary && item.summary.toString().toLowerCase() === "true";

                        return (productAPS && isAPS) || (productSummary && isSummary);
                    });
                    currentComponent.setState({
                        orderingOfficeAccessList: filteredOrderingOfficeAccessList,
                        unFilteredOrderingOfficeList: result.result,
                        selectedMultiItem: filteredOrderingOfficeAccessList,
                        selectedDropDownProduct: selectedProduct,
                        productOptions: productOptions,
                        disableDropdown: disableDropdown
                    });
                }

                if (currentComponent.state.status != "-1") {
                    currentComponent.LandingPageOrders(currentComponent.state.status, currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime, currentComponent.state.offSet, currentComponent.state.PageLimit, currentComponent.state.sortColumn, currentComponent.state.sortOrder, currentComponent.state.selectedDropDownProduct );
                }
                currentComponent.loadPendingOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime, currentComponent.state.selectedDropDownProduct);
                currentComponent.loadActionRequiredOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime, currentComponent.state.selectedDropDownProduct);
            }
            else {
                currentComponent.loadPendingOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime, currentComponent.state.selectedDropDownProduct);
                currentComponent.loadActionRequiredOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime, currentComponent.state.selectedDropDownProduct);
            }
            currentComponent.setState({ loading: false });
        });
    }



    loadPendingOrderBarChart(selectDropDownUsers, selectDropDownCycleTime, productType) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        let officeNameList = "0";
        if (currentComponent.state.selectedMultiItem.length > 0) {
            officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
        }
        Api.Post('api/placeorder/GetPendingOrderBarChart', { selectDropDownUsers: selectDropDownUsers, selectDropDownCycleTime: selectDropDownCycleTime, officeName: officeNameList, product: productType }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                let chart = am4core.create("chartdiv", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.result[0].month.length; i++) {
                    dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
                }
                chart.data = dataObject;

                // Create axes
                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "month";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Orders";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = result.max + 5;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "orders";
                series.dataFields.categoryX = "month";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
                currentComponent.setState({ loading: false });
            }
            else {
                currentComponent.setState({ barChartPendingOrder: result, loading: false });
            }
        });


    }
    loadActionRequiredOrderBarChart(selectDropDownUsers, selectDropDownCycleTime, productType) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        let officeNameList = "0";
        if (currentComponent.state.selectedMultiItem.length > 0) {
            officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
        }
        Api.Post('api/placeorder/GetActionRequiredOrderBarChart', { selectDropDownUsers: selectDropDownUsers, selectDropDownCycleTime: selectDropDownCycleTime, officeName: officeNameList, product: productType }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ barChartActionRequiredOrder: result.result });
                let chart = am4core.create("chartdiv1", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.result[0].month.length; i++) {
                    dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
                }
                chart.data = dataObject;
                // Create axes
                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "month";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;


                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Orders";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = result.max + 5;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "orders";
                series.dataFields.categoryX = "month";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
                currentComponent.setState({ loading: false });
            } else {
                currentComponent.setState({ barChartActionRequiredOrder: result.result, loading: false });
            }
        });


    }

    componentWillMount() {
        localStorage.setItem('SyncClicked', 'false');
        let currentComponent = this;
        this.GetRequiredFields();

        let userType = localStorage.getItem("UserType");
        let IsRedirectedFromDashboard = localStorage.getItem('IsFromCaseDetailsToDashboard');
        if (IsRedirectedFromDashboard === "true") {

            let OrderingOfficeAccessList = JSON.parse(localStorage.getItem('OrderingOfficeAccessList'));
            let SelectedMultiItem = JSON.parse(localStorage.getItem('SelectedMultiItem'));

            const pageLimit = parseInt(localStorage.getItem('PageLimit'), 10);
            const offset = parseInt(localStorage.getItem('OffSet'), 10);
            let vPageLimit;
            let vOffset;

            if (!isNaN(pageLimit)) {
                vPageLimit = pageLimit;
            } else {
                vPageLimit = 5;
            }
            if (!isNaN(offset)) {
                vOffset = offset;
            } else {
                vOffset = 0;
            }
            this.handlePageChange(localStorage.getItem('ClickedPageInResult'));

            this.setState((prevState) => ({
                status: localStorage.getItem('Status'),
                RowCount: localStorage.getItem('RowCount'),
                isPending: localStorage.getItem('IsPending') === "true",
                isActionRequired: localStorage.getItem('IsActionRequired') === "true",
                isAll: localStorage.getItem('IsAll') === "true",
                selectDropDownCycleTime: localStorage.getItem('SelectDropDownCycleTime'),
                selectDropDownUsers: localStorage.getItem('SelectDropDownUsers'),
                orderingOfficeAccessList: OrderingOfficeAccessList,
                selectedMultiItem: SelectedMultiItem,
                PageLimit: vPageLimit,
                offSet: vOffset,
                currentPage: parseInt(localStorage.getItem('ClickedPageInResult')) - 1,
                sortColumn: localStorage.getItem('DashboardSortColumn'),
                sortOrder: localStorage.getItem('DashboardSortOrder'),
                UserId: prevState.userId,
                isSearchClicked: true,
                Loading: true
            }), () => {
                if (userType == "Agent") {
                    currentComponent.loadOrderingOfficeAccessList(1, true);
                } else {
                    currentComponent.loadOrderingOfficeAccessList(2, true);
                }
            });

        }
        else if (userType === "Agent") {
            currentComponent.setState({ selectDropDownUsers: '1', selectDropDownCycleTime: '0' }, () => {
                currentComponent.loadOrderingOfficeAccessList(1, true);
            });

        } else {

            currentComponent.setState({ selectDropDownUsers: '2', selectDropDownCycleTime: '0' }, () => {
                currentComponent.loadOrderingOfficeAccessList(2, true);
            });

        }


        currentComponent.setState({ loading: true });

        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ loading: false, isOwn: result.isOwn, hideExportButton: result.exportSearchResults });
            }
            else {
                currentComponent.setState({ loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

    }
    GetRequiredFields() {
        let CompanyId = 1;

        let currentComponent = this;
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (const field of currentComponent.state.RequiredFieldsList) {
                    currentComponent.setState({
                        [field.className + 'Hide']: field.isActive,
                        ['lbl' + field.className]: field.customFieldName === "" || field.customFieldName.length === 0 ? field.originalFieldName : field.customFieldName,
                    });
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    GetShowAdminMenu() {

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Get('api/login/GetShowAdminMenu', currentComponent).then(function (result) {
            localStorage.setItem("ShowAdminMenu", result);
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    toasterfunc(e) {

        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    Onclickexportpopup() {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, loading: false });
    }
    handleColumnHeaderClick = (column) => {
        if (this.state.LandingPendingOrderList.length != 0)
        {
            let offSet = (this.state.currentPage) * this.state.PageLimit;
            let appliedFilters = this.state.storeSearchFilter;
            let filtersInJson = '';
            if (!isNullOrUndefined(appliedFilters)) {
                filtersInJson = JSON.stringify(appliedFilters);
                this.setState({ filtersApplied: false });
            }
            if (this.state.sortColumn === column.field) {
                const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
                this.setState({
                    sortOrder: newSortOrder,
                    sortColumn: column.field,
                });
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, column.field, newSortOrder, this.state.selectedDropDownProduct, filtersInJson);
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',
                });
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, column.field, "asc", this.state.selectedDropDownProduct, filtersInJson);
            }
        }

    }

    handlePageChange(e) {

        let CurrentPageLSR = localStorage.getItem('ClickedPageInResult');

        if (e === "first_page" || e === "0") {
            CurrentPageLSR = 1;
        }
        else if (e === "chevron_left") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronleftInResult')) + 1;
        }
        else if (e === "chevron_right") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronrightInResult')) + 1;
        }
        else if (e === "last_page") {
            let lp = localStorage.getItem('TotalRecordsInResult') / localStorage.getItem('PageLimit');
            lp = Math.ceil(lp) - 1;
            if (lp === 0) {
                CurrentPageLSR = 1;
            }
            else {
                CurrentPageLSR = parseInt(lp) + 1;
            }
        }
        localStorage.setItem('ClickedPageInResult', CurrentPageLSR);

    }

    handleProductChange = (event) => {
        let productAPS = false;
        let productSummary = false;

        if (event.target.value === '1') {
            productAPS = true;
        } else if (event.target.value === '2') {
            productSummary = true;
        } else if (event.target.value === '0') {
            productAPS = true;
            productSummary = true;
        }
        let filteredOrderingOfficeAccessList = this.state.unFilteredOrderingOfficeList.filter(item => {
            let isAPS = item.aps && item.aps.toString().toLowerCase() === "true";
            let isSummary = item.summary && item.summary.toString().toLowerCase() === "true";
            return (productAPS && isAPS) || (productSummary && isSummary);
        });
        this.setState({
            selectedDropDownProduct: event.target.value,
            currentPage: 0,
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
            orderingOfficeAccessList: filteredOrderingOfficeAccessList,
            selectedMultiItem: filteredOrderingOfficeAccessList
        });
        localStorage.setItem('ProductDropDownValue', this.state.selectedDropDownProduct);
        const value = event.target.value;
        if (['0', '1', '2', '3'].includes(value)) {
            if (this.state.status !== "-1") {
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, value, 0, this.state.PageLimit, '', '', value);
            }
            this.loadPendingOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, value);
            this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, value);
        }
    }

    render() {

        let landing = 'img/ems-logo.gif';
        let companyType = localStorage.getItem("CompanyType");
        let columns = [{
            title: 'Order Date',
            field: 'createdOnDateTime',
            type: 'date',
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                minWidth: '102px',
                whiteSpace: 'nowrap'
            },
            render: rowData => {
                return Moment(rowData.createdOnDateTime).format('MM/DD/yyyy')
            }
        },
        {
            title: 'eNoah Order Number',
            field: 'eNoahOrderId',
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                minWidth: '102px'
            }
        },
        {
            title: 'Patient ' + this.state.lblFirstName,
            field: 'patientFirstName',
            hidden: this.state.FirstNameHide === false,
            filterPlaceholder: 'Search',
            width: '9%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: 'Patient ' + this.state.lblLastName,
            field: 'patientLastName',
            hidden: this.state.LastNameHide === false,
            filterPlaceholder: 'Search',
            width: '9%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: this.state.lblDoctorFirstName,
            field: 'doctorFirstName',
            hidden: this.state.DoctorFirstNameHide === false,
            filterPlaceholder: 'Search',
            width: '11%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: this.state.lblDoctorLastName,
            field: 'doctorLastName',
            hidden: this.state.DoctorLastNameHide === false,
            filterPlaceholder: 'Search',
            width: '11%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: this.state.lblFacilityName,
            field: 'facilityName',
            hidden: this.state.FacilityNameHide === false,
            filterPlaceholder: 'Search',
            width: '20%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: this.state.lblPolicyNum,
            field: 'policyNumber',
            hidden: this.state.PolicyNumHide === false,
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                minWidth: '102px'
            }
        },
        {
            title: 'Request Type',
            field: 'requestType',
            hidden: companyType === "LIFE",
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                minWidth: '102px'
            }
        },
        {
            title: 'Certified',
            field: 'certified',
            hidden: companyType === "LIFE",
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                minWidth: '102px'
            }
        },
        {
            title: 'Order Type',
            field: 'orderType',
            hidden: companyType === "LIFE",
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                minWidth: '102px'
            }
        },
        {
            title: 'Requestor Name ',
            field: 'firstLastName',
            filterPlaceholder: 'Search',
            width: '20%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: 'Status ',
            field: 'status',
            render: rowData => <span>{rowData.status}</span>,
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: { minWidth: '102px' }
        },
        {
            title: 'Status Event',
            field: 'statusEvent',
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: 'Product',
            field: 'product',
            filterPlaceholder: 'Search',
            width: '3%',
            cellStyle: {
                whiteSpace: 'nowrap'
            }
        },
        {
            title: 'EncId ',
            field: 'encId',
            hidden: true
        }
        ];
        let visibleColumns = columns.filter((column) => !column.hidden);
        return (
            <Layout>
                <TimeoutCounter />
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" aria-hidden="true" onClick={this.Onclickexportpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv'>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                <div className="bg-white rounded p-2 form-input my-2 position-relative">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-12">
                                            <label className="form-label fs-8 mb-0 mt-2">Quick Dashboard</label>
                                        </div>

                                        <div className="col-lg-3 col-md-2 col-sm-2 col-12 px-0" style={{ marginLeft: "-70px" }}>
                                            <div className="d-flex align-items-center">
                                                <label className="small dashLabel form-label mb-0 w-100" htmlFor="inputLastName">Cycle Time Calculation</label>

                                                <div className="form-group w-75">
                                                    <select className="form-control" onChange={this.handleBasedUserCycleTimeListChange} value={this.state.selectDropDownCycleTime}>
                                                        <option value='0'>Select </option>
                                                        <option value='1'>Business Days </option>
                                                        <option value='3'>Calendar Days</option>
                                                        <option value='2'>End to End Days </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-lg-2 col-md-2 col-sm-2 col-12 "}>
                                            <div className="d-flex align-items-center">
                                                <label className="small dashLabel" htmlFor="inputLastName">Scope</label>
                                                <div className="form-group w-100">
                                                    <select className="form-control" onChange={this.handleBasedUserListChange} value={this.state.selectDropDownUsers}>
                                                        <option value='1'>My Orders</option>
                                                        <option value='2'>All Orders</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-lg-2 col-md-2 col-sm-2 col-12 "}>
                                            <div className="d-flex align-items-center">
                                                <label className="small dashLabel" htmlFor="inputProduct">Product</label>
                                                <div className="form-group w-50">
                                                    <select className="form-control" onChange={this.handleProductChange} value={this.state.selectedDropDownProduct} disabled={this.state.disableDropdown}>
                                                        {this.state.productOptions.includes('0') && <option value='0'>Both</option>}
                                                        {this.state.productOptions.includes('1') && <option value='1'>APS</option>}
                                                        {this.state.productOptions.includes('2') && <option value='2'>Summary</option>}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-lg-3 col-md-4 col-sm-4 col-12 px-0"} style={{ marginLeft: "-40px" }}>
                                            <div className="d-flex align-items-center">
                                                <label className="small dashLabel form-label w-50" htmlFor="inputLastName">Ordering Office</label>
                                                <MultiSelect className="form-control p-0 ml-1"
                                                    options={this.state.orderingOfficeAccessList}
                                                    value={this.state.selectedMultiItem}
                                                    onChange={this.setSelectedfun}
                                                    labelledBy={"Select Office"}
                                                    hasSelectAll={true}
                                                    overrideStrings={{ "allItemsAreSelected": "All Ordering Offices selected", "selectSomeItems": "Select Office" }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-sm-2 col-12 text-right" style={{ position: "absolute", right: "-5px" }}>
                                            <button className="btn btn-blue-btnnm hvr-sweep-to-right mt-1 mb-1" onClick={this.handleSyncDashboard}>
                                                <Icon className="material-icons">cached</Icon></button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 mb-2">
                                <div className="row">

                                    <div className={"dashboard-map " + (companyType === "LIFE" ? "col-xl-6 life" : "col-xl-6 legal")}>
                                        <div className="card">
                                            <div className="card-header">

                                                Pending Orders
                                            </div>
                                            <div className="card-body">
                                                <div id="chartdiv" style={{ width: "100%", height: "250px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"dashboard-map " + (companyType === "LIFE" ? "col-xl-6 life" : "col-xl-6 legal")}>
                                        <div className="card">
                                            <div className="card-header">

                                                Action Required
                                            </div>
                                            <div className="card-body">
                                                <div id="chartdiv1" style={{ width: "100%", height: "250px" }}></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-12 col-md-12 nopadding bg-white rounded p-0 mb-2">
                                <div className="card mb-4 dashboard-divv-x p-1">
                                    <div className="pending-list-title">Pending List <span>*click on column header to sort</span>                                       
                                    </div>
                                    <div className="card-body posrel p-0">
                                        <div className="export-div-x">
                                            <button className="export-class">Export</button>
                                        </div>
                                        <div className="col-md-2 searchoff nopadding">
                                            <span className="show-title">Show</span>
                                        </div>
                                        <div className="col-md-2 entriesoff nopadding">
                                            <span className="show-entries">entries</span>
                                        </div>
                                        <div className="col-md-7 filter-dashboard">
                                            <div className="form-group">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-12 pull-left">
                                                    <label className="small fotbold sizelabel mr-1" >Quick Filter by Status </label>
                                                </div>

                                                <fieldset onChange={this.handleStatusChange.bind(this)}>
                                                    <div className="form-row">
                                                        <div className="col-md-2">
                                                            <div className="form-group radioLable-x">
                                                                <input type="radio" id="Pending" value="3" checked={this.state.status === "3"} readOnly={ true} />
                                                                <label className="small" >Pending</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 mt-1">
                                                            <div className="form-group">
                                                                <label className={(this.state.isPending ? "show" : "hide")} style={{ fontSize: "80%", marginLeft: "-20px", marginTop: "-1px" }}>({this.state.RowCount})</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group radioLable-x" style={{ marginLeft: "-13px" }}>
                                                                <input type="radio" id="ActionRequired" value="4" checked={this.state.status === "4"} readOnly={true} />
                                                                <label className="small">Action Required</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 mt-1">
                                                            <div className="form-group">
                                                                <label className={(this.state.isActionRequired ? "show" : "hide")} style={{ fontSize: "80%", marginLeft: "-71px", marginTop: "-1px" }}>({this.state.RowCount})</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group radioLable-x" style={{ marginLeft: "-61px" }}>
                                                                <input type="radio" id="All" value="0" checked={this.state.status === "0"} readOnly={true} />
                                                                <label className="small">All </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 mt-1">
                                                            <div className="form-group">
                                                                <label className={(this.state.isAll ? "show" : "hide")} style={{ fontSize: "80%", marginLeft: "-100px", marginTop: "-1px" }}>({this.state.RowCount})</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="table-responsive dataTable dashboard-table masterSearch">
                                            <MaterialTable
                                                title=""
                                                tableRef={this.tableRef}
                                                onRowClick={(event, rowData) => {
                                                    localStorage.setItem('PrevPage', 'dashboard');

                                                    let orderingOfficeAccessList = JSON.stringify(this.state.orderingOfficeAccessList);
                                                    let selectedMultiItem = JSON.stringify(this.state.selectedMultiItem);

                                                    localStorage.setItem('OrderingOfficeAccessList', orderingOfficeAccessList);
                                                    localStorage.setItem('SelectedMultiItem', selectedMultiItem);
                                                    localStorage.setItem('Status', this.state.status);
                                                    localStorage.setItem('RowCount', this.state.RowCount);
                                                    localStorage.setItem('IsPending', this.state.isPending);
                                                    localStorage.setItem('IsActionRequired', this.state.isActionRequired);
                                                    localStorage.setItem('IsAll', this.state.isAll);
                                                    localStorage.setItem('SelectDropDownCycleTime', this.state.selectDropDownCycleTime);
                                                    localStorage.setItem('SelectDropDownUsers', this.state.selectDropDownUsers);
                                                    localStorage.setItem('PageLimit', this.state.PageLimit);
                                                    localStorage.setItem('OffSet', this.state.offSet);
                                                    localStorage.setItem('DashboardSortColumn', this.state.sortColumn);
                                                    localStorage.setItem('DashboardSortOrder', this.state.sortOrder);
                                                    localStorage.setItem('ProductDropDownValue', this.state.selectedDropDownProduct);
                                                    localStorage.setItem('InitialDropdownValue', this.state.disableDropdown);
                                                    this.props.history.push('/case-details?id=' + rowData.encId);
                                                }}
                                                className="dataTable"
                                                localization={{ body: { emptyDataSourceMessage: <p>{this.state.emptyDataSourceMessage}</p> } }}
                                                columns={columns}
                                                data={this.state.LandingPendingOrderList}
                                                options={{
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    paginationType: "stepped",
                                                    exportFileName: "PendingOrderList",
                                                    filtering: true,
                                                    search: false,
                                                    export: true,
                                                    rowStyle: rowData => ({
                                                        color: (rowData.status === 'DE Problem') ? '#ff0000' : '#000'
                                                    }),
                                                    isLoading: true,
                                                    exportCsv: async (data, columns) => {
                                                        if (this.state.hideExportButton) {
                                                            let isExport = await this.exportGridValues();
                                                            if (isExport) {
                                                                if (this.state.dataForExport.length > 0) {
                                                                    const columnTitles = data.filter(a => !a.hidden).filter(a => !a.hidden).map(columnDef => columnDef.title);
                                                                    const csvData = this.state.dataForExport.map(rowData =>
                                                                        data.filter(a => !a.hidden).map(columnDef => rowData[columnDef.field]),
                                                                    );
                                                                    const builder = new CsvBuilder(`PendingOrderList.csv`)
                                                                        .setColumns(columnTitles)
                                                                        .addRows(csvData)
                                                                        .exportFile();
                                                                    return builder;
                                                                }
                                                            }
                                                            return null;
                                                        }
                                                        else {
                                                            this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                            return null;
                                                        }
                                                    },
                                                    exportPdf: async (data, columns) => {
                                                        if (this.state.hideExportButton) {
                                                            let isExport = await this.exportGridValues();
                                                            if (isExport) {
                                                                if (this.state.dataForExport.length > 0) {
                                                                    const unit = "pt";
                                                                    const size = "A4"; // Use A1, A2, A3 or A4
                                                                    const orientation = "landscape"; // portrait or landscape
                                                                    const doc = new jsPDF(orientation, unit, size);
                                                                    doc.text("Pending Order List", 40, 35);
                                                                    const columnTitles = data.filter(a => a.hidden !== true).map(columnDef => columnDef.title);
                                                                    const pdfData = this.state.dataForExport.map(rowData =>
                                                                        data.filter(a => a.hidden !== true).map(columnDef => rowData[columnDef.field]),
                                                                    );
                                                                    if (companyType === 'LIFE') {
                                                                        doc.autoTable({
                                                                            styles: {
                                                                                cellPadding: 2,
                                                                                cellWidth: 'auto'
                                                                            },
                                                                            bodyStyles: {
                                                                                cellPadding: 2,
                                                                                margin: 40,
                                                                            },
                                                                            columnStyles: {
                                                                                0: { cellWidth: 65 },
                                                                                1: { cellWidth: 65 },
                                                                                2: { cellWidth: 65 },
                                                                                3: { cellWidth: 65 },
                                                                                4: { cellWidth: 65 },
                                                                                5: { cellWidth: 65 },
                                                                                6: { cellWidth: 65 },
                                                                                7: { cellWidth: 65 },
                                                                                8: { cellWidth: 65 },
                                                                                9: { cellWidth: 65 },
                                                                                10: { cellWidth: 65 },

                                                                                text: {
                                                                                    cellWidth: 'wrap'
                                                                                }
                                                                            },
                                                                            head: [columnTitles],
                                                                            body: pdfData
                                                                        });
                                                                    }
                                                                    else {
                                                                        doc.autoTable({
                                                                            styles: {
                                                                                cellPadding: 2,
                                                                                cellWidth: 'auto'
                                                                            },
                                                                            bodyStyles: {
                                                                                cellPadding: 2,
                                                                                margin: 40,
                                                                            },
                                                                            columnStyles: {
                                                                                0: { cellWidth: 50 },
                                                                                1: { cellWidth: 50 },
                                                                                2: { cellWidth: 50 },
                                                                                3: { cellWidth: 50 },
                                                                                4: { cellWidth: 50 },
                                                                                5: { cellWidth: 50 },
                                                                                6: { cellWidth: 50 },
                                                                                7: { cellWidth: 50 },
                                                                                8: { cellWidth: 50 },
                                                                                9: { cellWidth: 50 },
                                                                                10: { cellWidth: 50 },
                                                                                11: { cellWidth: 50 },
                                                                                12: { cellWidth: 50 },
                                                                                13: { cellWidth: 50 },
                                                                                text: {
                                                                                    cellWidth: 'wrap'
                                                                                }
                                                                            },
                                                                            head: [columnTitles],
                                                                            body: pdfData
                                                                        });
                                                                    }


                                                                    doc.save(`PendingOrderList.pdf`);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                        }
                                                    }
                                                }}
                                                components={{
                                                    Header: (props) => (
                                                        <TableHead>
                                                            <TableRow>
                                                                {visibleColumns.map((column, index) => (
                                                                    <TableCell
                                                                        key={column.field}
                                                                        style={{
                                                                            width: column.width,
                                                                            ...column.cellStyle,
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {column.title}
                                                                        <TableSortLabel
                                                                            active={this.state.sortColumn === column.field}
                                                                            direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                            onClick={() => this.handleColumnHeaderClick(column)}
                                                                        />
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                    ),
                                                    Pagination: props => (
                                                        <TablePagination
                                                            {...props}
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                            rowsPerPage={this.state.PageLimit}
                                                            count={this.state.totalRecord}
                                                            page={this.state.currentPage}
                                                            onChangeRowsPerPage={event => {
                                                                props.onChangeRowsPerPage(event);
                                                                this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                                localStorage.setItem('ClickedPageInResult', 1);
                                                                let appliedFilters = this.state.storeSearchFilter;
                                                                let filtersInJson = '';
                                                                if (!isNullOrUndefined(appliedFilters)) {
                                                                    filtersInJson = JSON.stringify(appliedFilters);
                                                                    this.setState({ filtersApplied: false });
                                                                }
                                                                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, event.target.value, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct, filtersInJson);
                                                            }}
                                                            onChangePage={(event, page) => {
                                                                let ClickedButton = event.nativeEvent.target.innerText;
                                                                localStorage.setItem('ClickedPageInResult', ClickedButton)
                                                                let appliedFilters = this.state.storeSearchFilter;
                                                                let filtersInJson = '';
                                                                if (!isNullOrUndefined(appliedFilters)) {
                                                                    filtersInJson = JSON.stringify(appliedFilters);
                                                                    this.setState({ filtersApplied: false });
                                                                }

                                                                if (ClickedButton === "first_page") {
                                                                    this.setState({ currentPage: 0 })
                                                                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct, filtersInJson);
                                                                }
                                                                else if (ClickedButton === "chevron_left") {
                                                                    this.setState((prevState) => ({
                                                                        currentPage: prevState.currentPage - 1
                                                                    }));
                                                                    let Chevronleftvalue = this.state.currentPage - 1
                                                                    localStorage.setItem('ChevronleftInResult', Chevronleftvalue)
                                                                    let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct, filtersInJson);
                                                                }
                                                                else if (ClickedButton === "chevron_right") {
                                                                    this.setState((prevState) => ({
                                                                        currentPage: prevState.currentPage + 1
                                                                    }));
                                                                    let Chevronrightvalue = this.state.currentPage + 1
                                                                    localStorage.setItem('ChevronrightInResult', Chevronrightvalue)
                                                                    let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct, filtersInJson);
                                                                }
                                                                else if (ClickedButton === "last_page") {
                                                                    let lp = this.state.totalRecord / this.state.PageLimit;
                                                                    lp = Math.ceil(lp) - 1;
                                                                    let offSet;
                                                                    if (lp === 0) {
                                                                        this.setState({ currentPage: 1 })
                                                                        offSet = this.state.PageLimit
                                                                    }
                                                                    else {
                                                                        this.setState({ currentPage: lp })
                                                                        offSet = lp * this.state.PageLimit
                                                                    }
                                                                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct, filtersInJson);
                                                                }
                                                                else {
                                                                    let offSet;
                                                                    if (ClickedButton > this.state.currentPage) {
                                                                        this.setState((prevState) => ({
                                                                            currentPage: prevState.currentPage + 1
                                                                        }));
                                                                        offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                                    }
                                                                    else {
                                                                        this.setState((prevState) => ({
                                                                            currentPage: prevState.currentPage - 1
                                                                        }));
                                                                        offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                                    }
                                                                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder, this.state.selectedDropDownProduct, filtersInJson);
                                                                }
                                                            }}
                                                        />
                                                    ),
                                                }}
                                                onFilterChange={(filters) => {
                                                    let currentComponent = this;

                                                    if (currentComponent.isProgrammaticFilterChange) {
                                                        return;
                                                    }

                                                    const appliedFilters = filters
                                                        .filter(filter => filter.value)
                                                        .map(filter => {
                                                            if (filter.column.field === 'createdOnDateTime' && filter.value instanceof Date) {
                                                                // Format the date for API (yyyy-mm-dd)
                                                                const mm = String(filter.value.getMonth() + 1).padStart(2, '0');
                                                                const dd = String(filter.value.getDate()).padStart(2, '0');
                                                                const yyyy = filter.value.getFullYear();
                                                                const apiFormattedDate = `${yyyy}-${mm}-${dd}`;

                                                                return { field: filter.column.field, value: apiFormattedDate };
                                                            }
                                                            return { field: filter.column.field, value: filter.value };
                                                        });

                                                    const hasThreeWords = appliedFilters.some(filter => {
                                                        const wordCount = filter.value.trim().length;
                                                        return wordCount >= 3;
                                                    });

                                                    const filtersInJson = appliedFilters.length > 0 ? JSON.stringify(appliedFilters) : '';

                                                    if (hasThreeWords || filtersInJson === '') {
                                                        this.setState({ filtersApplied: false, storeSearchFilter: appliedFilters });
                                                        this.LandingPageOrders(
                                                            this.state.status,
                                                            this.state.selectDropDownUsers,
                                                            this.state.selectDropDownCycleTime,
                                                            0,
                                                            this.state.PageLimit,
                                                            this.state.sortColumn,
                                                            this.state.sortOrder,
                                                            this.state.selectedDropDownProduct,
                                                            filtersInJson
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="Loading" /> </span>
            </Layout>
        );
    }
}
