import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import 'rc-datepicker/lib/style.css';
import $ from 'jquery';
import Api from '../serviceCall';
import { TimeoutCounter } from './TimeoutCounter';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNullOrUndefined } from 'util';
import { TablePagination, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

export class Unsubmitted extends Component {
    static displayName = Unsubmitted.name;
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            goBack: PropTypes.func.isRequired,
        }).isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            unsubmittedOrderList: [],
            removeAlert: false, removehidden: '', removeDoc: '', toasterCla: false, toasterredCla: false, toaster: '',
            individualNameTableHeaderLabel: "",
            facilityNameTableHeaderLabel: "",
            providerNameTableHeaderLabel: "",
            fileNumberTableHeaderLabel: "",
            lblRequestType: "",
            lblFirstName: "",
            lblLastName: "",

            lblDoctorFirstName: "",
            lblFacilityName: "",
            lblPolicyNum: "",
            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,

            RequiredFieldsList: [],
            hideExportButton: false,
            userId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            isSearchClicked: false,
            SeachValue: "",
            sortColumn: "",
            clickCount: 0,
            sortOrder: "",
            DataForExport: []

        };
        this.deleteUnSubmittedOrders = this.deleteUnSubmittedOrders.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.onhandeditevent = this.onhandeditevent.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.GetUnsubmittedOrders = this.GetUnsubmittedOrders.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.getGridValues = this.getGridValues.bind(this);
        this.handleUnsubmittedOrderSearch = this.handleUnsubmittedOrderSearch.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.testName = this.testName.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.exportUnsubmittedOrders = this.exportUnsubmittedOrders.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);

    }

    async exportUnsubmittedOrders() {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        await Api.Post('api/PlaceOrder/GetHoldOrder', {
            "DataOffSet": 0, "DataLimit": 0, "SearchOrder": "", "SortField": "", "SortOrder": ""
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100 && result.result != null) {
                const modifiedData = result.result.map(rowData => ({
                    ...rowData,
                    patientFirstName: Api.decryptData(rowData.patientFirstName),
                    patientLastName: Api.decryptData(rowData.patientLastName),
                    policyNumber: Api.decryptData(rowData.policyNumber)
                }));

                currentComponent.setState({ DataForExport: modifiedData, loading: false, removeAlert: false, totalRecord: result.totalRowsCount });
            } else if (result === null || result.status === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
            } else {
                currentComponent.setState({ Loading: false, removeAlert: false, DataForExport: [], totalRecord: 0 });
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    handleUnsubmittedOrderSearch() {
        let currentComponent = this;
        currentComponent.setState({ offSet: 0, currentPage: 0, sortColumn: "", sortOrder: "" }, () => { currentComponent.GetUnsubmittedOrders() });
    }
    handleClearSearch() {
        let currentComponent = this;
        this.setState({ SeachValue: "", sortColumn: "", sortOrder: "", offSet: 0, currentPage: 0 }, () => { currentComponent.GetUnsubmittedOrders(); });
    }
    handleKeypress(event) {
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            this.handleUnsubmittedOrderSearch();
        }
    }
    testName(e) {
        // Check if the input is valid and does not contain consecutive spaces
        return /^[._a-zA-Z0-9&'-]+( [._a-zA-Z0-9&'-]+)*$/.test(e);
    }

    handleSearchChange(e) {
        if (e.target.value.length !== 0) {
            if (this.testName(e.target.value)) {
                this.setState({ SeachValue: e.target.value });
            }
        }
        else {
            this.setState({ SeachValue: e.target.value });
        }
    }

    getGridValues(offSet, dataLimit, sortcolumn, sortingorder) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true, offSet: offSet, PageLimit: dataLimit });
        Api.Post('api/PlaceOrder/GetHoldOrder', {
            "DataOffSet": offSet, "DataLimit": dataLimit, "SearchOrder": Api.encryptData(currentComponent.state.SeachValue), "SortField": sortcolumn, "SortOrder": sortingorder
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100 && result.result != null) {
                const modifiedData = result.result.map(rowData => ({
                    ...rowData,
                    patientFirstName: Api.decryptData(rowData.patientFirstName),
                    patientLastName: Api.decryptData(rowData.patientLastName),
                    policyNumber: Api.decryptData(rowData.policyNumber)
                }));
                currentComponent.setState({ unsubmittedOrderList: modifiedData, loading: false, removeAlert: false, totalRecord: result.totalRowsCount });
            } else if (result === null || result.status === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
            } else {
                currentComponent.setState({ Loading: false, removeAlert: false, unsubmittedOrderList: [], totalRecord: 0 });
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    handleremoveCancelbttn() {
        this.setState({ removeAlert: false });
    }
    componentWillMount() {
        let currentComponent = this;

        let ctype = localStorage.getItem("PlaceOrder");
        let user = localStorage.getItem("UserType");
        if (!isNullOrUndefined(user) && (user.toLowerCase() === "agent" || user.toLowerCase() === "client home office")) {
            if (!isNullOrUndefined(ctype) && ctype === "false") {
                currentComponent.toasterredfun2("You have no access to view. Contact Administrator.");
                setTimeout(function () { currentComponent.props.history.push('/dashboard'); }, 3000);
            }
        }

        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.viewResults);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
            let UserId = Api.decryptData(result.userId);
            if (result.statusCode === 100) {
                currentComponent.setState({ hideExportButton: result.exportSearchResults });
            }
            currentComponent.setState({ userId: UserId }, () => { currentComponent.GetUnsubmittedOrders(); currentComponent.GetRequiredFields(); });

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun(error);
            }
        });
    }

    GetUnsubmittedOrders() {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        Api.Post('api/PlaceOrder/GetHoldOrder', {
            "DataOffSet": currentComponent.state.offSet, "DataLimit": currentComponent.state.PageLimit, "SearchOrder": Api.encryptData(currentComponent.state.SeachValue), "SortField": currentComponent.state.sortColumn, "SortOrder": currentComponent.state.sortOrder
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100 && result.result != null) {
                const modifiedData = result.result.map(rowData => ({
                    ...rowData,
                    patientFirstName: Api.decryptData(rowData.patientFirstName),
                    patientLastName: Api.decryptData(rowData.patientLastName),
                    policyNumber: Api.decryptData(rowData.policyNumber)
                }));
                currentComponent.setState({ unsubmittedOrderList: modifiedData, loading: false, removeAlert: false, totalRecord: result.totalRowsCount });
            }
            else if (result === null || result.status === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
            } else {
                currentComponent.setState({ loading: false, removeAlert: false, unsubmittedOrderList: [], totalRecord: 0 });
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    GetRequiredFields() {
        let CompanyId = 1;
        let userId = this.state.userId;
        if (userId.length === 0 || userId === null) {
            this.props.history.push('/');
        }

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent)
            .then(function (result) {
                currentComponent.setState({ RequiredFieldsList: result.requiredFields, loading: false });
                if (currentComponent.state.RequiredFieldsList.length > 0) {
                    for (const field of currentComponent.state.RequiredFieldsList) {
                        currentComponent.setState({
                            [`${field.className}Hide`]: field.isActive,
                        });

                        currentComponent.setState({
                            [`lbl${field.className}`]: field.customFieldName === "" ? field.originalFieldName : field.customFieldName,
                        });
                    }
                }
            }).catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    onEditClick(HoldOrderId) {
        localStorage.removeItem("HoldOrderId");
        this.props.history.push("/place-order?id=" + HoldOrderId);
    }

    onhandeditevent(e) {
        this.setState({ removeAlert: true });
        this.setState({ removehidden: e });
    }

    toasterredfun(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: event });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }

    deleteUnSubmittedOrders(orderId) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });

        Api.Post('api/PlaceOrder/DeleteHoldOrders/', { holdOrderId: orderId, DataOffSet: currentComponent.state.offSet, DataLimit: currentComponent.state.PageLimit, SearchOrder: Api.encryptData(currentComponent.state.SeachValue), SortField: currentComponent.state.sortColumn, SortOrder: currentComponent.state.sortOrder }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                if (result.result) {
                    const modifiedData = result.result.map(rowData => ({
                        ...rowData,
                        patientFirstName: Api.decryptData(rowData.patientFirstName),
                        patientLastName: Api.decryptData(rowData.patientLastName),
                        policyNumber: Api.decryptData(rowData.policyNumber)
                    }));
                    currentComponent.setState({ unsubmittedOrderList: modifiedData, removeAlert: false });
                }
                else {
                    currentComponent.setState({ unsubmittedOrderList: [], removeAlert: false });
                }
            } else {
                currentComponent.setState({ removeAlert: false });
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
        currentComponent.setState({ Loading: false });
    }
    Onclickexportpopup() {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, Loading: false });
    }

    async exportGridValues() {
        let currentComponent = this;
        let responce = false;
        currentComponent.setState({ Loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                if (!isNullOrUndefined(result)) {
                    if (result.allowExport) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.setState({ hideExportButton: result.allowExport });
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.setItem('ExportSearchResults', result.allowExport);
                    }
                    else {
                        currentComponent.setState({ Loading: false });
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.setItem('ExportSearchResults', result.allowExport);
                        if (currentComponent.state.hideExportButton) {
                            currentComponent.setState({ exportAlert: true });
                        }
                        responce = false;
                        return responce;
                    }
                }
                else {
                    currentComponent.setState({ Loading: false });
                }
                responce = true;
            } else {
                currentComponent.setState({ Loading: false });
                currentComponent.toasterredfun(result.statusMessage);
                responce = false;
            }
        });

        return responce;
    }
    handleColumnHeaderClick = (column) => {
        if (this.state.unsubmittedOrderList.length !== 0) {
            let offSet = (this.state.currentPage) * this.state.PageLimit;
            if (this.state.sortColumn === column.field) {
                const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
                this.setState({
                    sortOrder: newSortOrder,
                    sortColumn: column.field,
                });
                this.getGridValues(offSet, this.state.PageLimit, column.field, newSortOrder);
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',
                });
                this.getGridValues(offSet, this.state.PageLimit, column.field, "asc");
            }
        }
    }
    render() {
        let landing = 'img/ems-logo.gif';
        let companyType = localStorage.getItem("CompanyType");
        let columns =
            [
                { title: 'Order Date', field: 'orderDate', type: 'date' },
                { title: 'Patient ' + this.state.lblFirstName, field: 'patientFirstName', hidden: this.state.FirstNameHide === false },
                { title: 'Patient ' + this.state.lblLastName, field: 'patientLastName', hidden: this.state.LastNameHide === false },
                { title: this.state.lblDoctorFirstName, field: 'doctorName', hidden: this.state.DoctorFirstNameHide === false },
                { title: this.state.lblDoctorLastName, field: 'doctorLastName', hidden: this.state.DoctorLastNameHide === false },
                { title: this.state.lblFacilityName, field: 'facilityName', hidden: this.state.FacilityNameHide === false },
                { title: this.state.lblPolicyNum, field: 'policyNumber', hidden: this.state.PolicyNumHide === false },
                { title: 'Request Type', field: 'requestType', hidden: companyType === "LIFE" },
                { title: 'Certified', field: 'certified', hidden: companyType === "LIFE" },
                { title: 'Order Type', field: 'orderType', hidden: companyType === "LIFE" },
                { title: 'Requestor Name ', field: 'requesterName' },
                { title: 'Status ', field: 'status' },
                { title: 'Product ', field: 'product' },
                {
                    title: 'Action ',
                    field: 'action',
                    disableClick: true,
                    disableSorting: true,
                    render: rowData => (
                        <span style={{ "width": "150px", "display": "block" }}>
                            <span
                                className="edit-btnm editActionE"
                                onClick={() => this.onEditClick(rowData.mapId + "_" + rowData.isIdFrom)}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        this.onEditClick(rowData.mapId + "_" + rowData.isIdFrom);
                                    }
                                }}
                            >
                                <img src="img/edit-icon.png" alt="Edit" /> Edit
                            </span>
                            <span
                                className="edit-btnm"
                                onClick={() => this.onhandeditevent(rowData.mapId + "_" + rowData.isIdFrom)}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        this.onhandeditevent(rowData.mapId + "_" + rowData.isIdFrom);
                                    }
                                }}
                            >
                                <img src="img/delete.png" alt="Delete" /> Delete
                            </span>
                        </span>
                    )
                }
            ]
        let visibleColumns = columns.filter((column) => !column.hidden);
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0"
                                onClick={this.Onclickexportpopup}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        this.Onclickexportpopup();
                                    }
                                }}
                            >
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"removeAlert-div " + (this.state.removeAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this Order?
                        <div className="text-center-div">
                            <div className="bttn popupButton"
                                onClick={(e) => { this.deleteUnSubmittedOrders(this.state.removehidden) }}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        this.deleteUnSubmittedOrders(this.state.removehidden);
                                    }
                                }}
                            >
                                Yes
                                <input type="hidden" value={this.state.removehidden} readOnly="readonly" />

                            </div>
                            <div className="bttn popupButton"
                                onClick={(e) => { this.handleremoveCancelbttn() }}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        this.handleremoveCancelbttn();
                                    }
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv bg-whitee mt-2'>
                    <div className="container-fluid nopadding bg-white rounded pb-1 max-width-96">
                        <h1 className="mt-1 mb-3 withset">Unsubmitted Orders</h1>
                        <div className=" mb-2 normal-data ">
                            <div className="card-body posrelative">

                                <div className="table-responsive dataTable unsubmitted posrel">
                                    <div className="export-div-xx">
                                        <button className="export-class-x hvr-sweep-to-right">Export</button>
                                    </div>
                                    <div className="col-md-2 searchoff nopadding">
                                        <span className="show-title">Show</span>
                                    </div>
                                    <div className="col-md-2 entriesoff nopadding">
                                        <span className="show-entries">entries</span>
                                    </div>
                                    <div className="searchoff-x">
                                        <span className="show-title-x">Search</span>
                                    </div>
                                    <div className="searchinput-x">
                                        <input className="form-control" id="SearchOrders" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.SeachValue} onChange={this.handleSearchChange} maxLength={50} placeholder="Search" />

                                    </div>
                                    <div className="searchicon-x">
                                        <span className="search-icon" onClick={this.handleUnsubmittedOrderSearch}>
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </div>

                                    <div className="clearsearch-x">
                                        <span className="clear-icon" onClick={this.handleClearSearch}>
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </div>
                                    <MaterialTable
                                        title=""
                                        className="dataTable"
                                        columns={columns}
                                        data={this.state.unsubmittedOrderList}
                                        class="mat-paginator-sticky"
                                        options={{
                                            search: false,
                                            filtering: true,
                                            exportButton: true,
                                            isLoading: true,
                                            paginationType: "stepped",
                                            exportAllData: true,
                                            exportCsv: async (data, columns) => {
                                                let isExport = await this.exportGridValues();
                                                if (this.state.hideExportButton) {
                                                    await this.exportUnsubmittedOrders();
                                                    if (isExport && this.state.DataForExport.length > 0) {
                                                        const columnTitles = data.filter(columnDef => !columnDef.hidden).map(columnDef => columnDef.title);
                                                        const csvData = this.state.DataForExport.map(rowData =>
                                                            data.filter(a => !a.hidden).map(columnDef => rowData[columnDef.field]),
                                                        );
                                                        const builder = new CsvBuilder(`Unsubmitted Orders.csv`)
                                                            .setColumns(columnTitles)
                                                            .addRows(csvData)
                                                            .exportFile();
                                                        return Promise.resolve(builder); // Ensure consistent promise return
                                                    }
                                                } else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                                return Promise.resolve(null); // Ensure consistent promise return
                                            },
                                            exportPdf: async (data, columns) => {
                                                if (this.state.hideExportButton) {
                                                    await this.exportUnsubmittedOrders();
                                                    if (this.state.DataForExport.length > 0) {
                                                        const unit = "pt";
                                                        const size = "A4"; // Use A1, A2, A3 or A4
                                                        const orientation = "landscape"; // portrait or landscape
                                                        const doc = new jsPDF(orientation, unit, size);
                                                        doc.text("Unsubmitted Orders", 40, 35);
                                                        const columnTitles = data.filter(a => !a.hidden).map(columnDef => columnDef.title);
                                                        const pdfData = this.state.DataForExport.map(rowData =>
                                                            data.filter(a => !a.hidden).map(columnDef => rowData[columnDef.field]),
                                                        );

                                                        doc.autoTable({
                                                            styles: {
                                                                cellPadding: 2,
                                                                cellWidth: 'auto'
                                                            },
                                                            bodyStyles: {
                                                                cellPadding: 2,
                                                                margin: 40,
                                                            },
                                                            head: [columnTitles],
                                                            body: pdfData
                                                        });

                                                        doc.save(`Unsubmitted Orders.pdf`);
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            }
                                        }}
                                        components={{
                                            Header: (props) => (
                                                <TableHead>
                                                    <TableRow>
                                                        {visibleColumns.map((column, index) => (
                                                            <TableCell
                                                                key={column.field}
                                                            >
                                                                {column.disableSorting ? (<span>{column.title}</span>) : (
                                                                    <TableSortLabel
                                                                        active={this.state.sortColumn === column.field}
                                                                        direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                        onClick={() => this.handleColumnHeaderClick(column)}
                                                                    >
                                                                        {column.title}
                                                                    </TableSortLabel>)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            ),
                                            Pagination: props => (
                                                <TablePagination
                                                    {...props}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                    rowsPerPage={this.state.PageLimit}
                                                    count={this.state.totalRecord}
                                                    page={this.state.currentPage}
                                                    onChangeRowsPerPage={event => {
                                                        props.onChangeRowsPerPage(event);
                                                        this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                        this.getGridValues(0, event.target.value, this.state.sortColumn, this.state.sortOrder)
                                                    }}
                                                    onChangePage={event => {
                                                        let ClickedButton = event.nativeEvent.target.innerText;
                                                        if (ClickedButton === "first_page") {
                                                            this.setState({ currentPage: 0 })
                                                            this.getGridValues(0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton === "chevron_left") {
                                                            this.setState(prevState => {
                                                                this.setState({ currentPage: prevState.currentPage - 1 })
                                                            });
                                                            let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton === "chevron_right") {
                                                            this.setState(prevState => {
                                                                this.setState({ currentPage: prevState.currentPage + 1 })
                                                            });
                                                            let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton === "last_page") {
                                                            let lp = this.state.totalRecord / this.state.PageLimit;
                                                            lp = Math.ceil(lp) - 1;
                                                            let offSet;
                                                            if (lp === 0) {
                                                                this.setState({ currentPage: 1 })
                                                                offSet = this.state.PageLimit
                                                            }
                                                            else {
                                                                this.setState({ currentPage: lp })
                                                                offSet = lp * this.state.PageLimit
                                                            }
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else {
                                                            let offSet;
                                                            if (ClickedButton > this.state.currentPage) {
                                                                this.setState({ currentPage: this.state.currentPage + 1 })
                                                                offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            }
                                                            else {
                                                                this.setState({ currentPage: this.state.currentPage - 1 })
                                                                offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            }
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                    }}
                                                    onOrderChange={(orderBy, orderDirection) => {
                                                    }
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>
        );
    }
}
