import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

import Api from '../serviceCall';
import { isNullOrUndefined } from 'util';
import PropTypes from 'prop-types';



export class ForgotPassword extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            UserName: '', NPassword: '', CPassword: '', isReset: true, Id: "", toasterredCla: false, toasterCla: false, toaster: "",
            puserName: false, ppassword: false, pcPassword: false, poPassword: false, Version: "", Env: "", Date: "", companycodeerror: false, cnminlengtherror: false, cnaplphanumericerror: false,
            Companycode: "", Token: "", CopyRightYear: new Date().getFullYear(), popupShow: false, passwordCount: "", PrivacyPolicyURL: "",
            OtpId: "", showSubmitButton: false, VerifyOtpButton: false, OTP: "",
            seconds: '0', minutes: '1', Mseconds: '0', emailsentmsg: false, Otpsendmsg: "", Source: "", OrderFacilityId: "", EncryptedUrlParams: ""
        };
        this.handleUNChange = this.handleUNChange.bind(this);
        this.handleNPSChange = this.handleNPSChange.bind(this);
        this.handleCPSChange = this.handleCPSChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkUN = this.checkUN.bind(this);
        this.checkPass = this.checkPass.bind(this);
        this.checkPassChars = this.checkPassChars.bind(this);
        this.checkRepeat = this.checkRepeat.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.handleCNChange = this.handleCNChange.bind(this);
        this.handleKeyDownCN = this.handleKeyDownCN.bind(this);
        this.closePopup = this.closePopup.bind(this);

        this.handleOTPChange = this.handleOTPChange.bind(this);
        this.handleGenerateOtp = this.handleGenerateOtp.bind(this);
        this.tick = this.tick.bind(this);
        this.startCountDown = this.startCountDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ResendPasswordCode = this.ResendPasswordCode.bind(this);
        this.handleKeyDownNP = this.handleKeyDownNP.bind(this);
        this.handleKeyDownCP = this.handleKeyDownCP.bind(this);
        this.handleKeyDownOtp = this.handleKeyDownOtp.bind(this);
        this.handleKeyDownUN = this.handleKeyDownUN.bind(this);
    }
    handleChange(event) {
        this.setState({
            minutes: event.target.value
        })
    }
    componentWillMount() {
        let currentComponent = this;
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {

            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
            }
        });
        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }
        });
        let windowlocation = window.location.href;
        let decryptedParamString = "";
        let queryString = "";
        let source = "";

        const urlParts = windowlocation.split('?');
        if (urlParts.length > 1) {
            decryptedParamString = Api.decryptData(urlParts[1]);
        }

        let paramSplit = decryptedParamString.split('&Redirection=');
        if (decryptedParamString.includes("&Redirection=")) {

            source = 1;
            queryString = paramSplit[1];
            if (!isNullOrUndefined(paramSplit[1]) && paramSplit[1].trim().length > 0) {
                fetch('api/Login/DecryptUrlString', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "UrlString": paramSplit[1] })
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                    if (result.statusMessage === "Success") {
                        currentComponent.setState({
                            Source: result.source, OrderFacilityId: result.orderFacilityId, EncryptedUrlParams: queryString
                        });
                    }
                }).catch(function (error) {
                    this.setState({ Loading: false });
                    if (error === "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });
            }
        }
        else {
            source = 0;
            currentComponent.setState({
                Source: '0'
            })
        }

        if (decryptedParamString.includes("Id=")) {
            if (source === "1") {

                decryptedParamString = paramSplit[0];
            }
            let ordersplit = decryptedParamString.split("Id=");
            let getorderid = ordersplit[1];
            fetch('api/Login/GetDetails', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "apstool": getorderid })
            }).then(function (response) {
                if (response.status === 401) {
                    currentComponent.props.history.push('/');
                }
                else { return response.json(); }
            }).then(function (result) {

                if (result.statusMessage === "Success") {
                    let passwordcount = localStorage.getItem("RememberPasswordCount");

                    currentComponent.setState({ UserName: result.userId, Companycode: result.compCode, Token: result.token, passwordCount: passwordcount });
                    currentComponent.setState({ passwordCount: result.rememberPasswordCount });
                }
                else {
                    localStorage.setItem("Message", result.statusMessage);
                    localStorage.setItem("Title", "Change Password");
                    currentComponent.props.history.push('/error');
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ Loading: false });
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ loading: false });
            });
        }
    }
    handleUNChange(e) {
        if (e.target.value.length !== 0) {
            if (this.checkUN(e.target.value.trim())) {
                this.setState({ UserName: e.target.value, puserName: false });
            }
        }
        else {
            this.setState({ UserName: e.target.value, puserName: false });
        }
    }
    handleNPSChange(e) {
        if (e.target.value.length !== 0) {
            if (e.target.value.length <= 15) {
                if (this.checkPassChars(e.target.value.trim())) {
                    this.setState({ NPassword: e.target.value.trim(), ppassword: false, poPassword: false });
                }
            }
            else {
                this.toasterredfun2("Password should not be more than 15 characters");
                this.setState({ NPassword: "" });
            }
        }
        else {
            this.setState({ NPassword: e.target.value, ppassword: false, poPassword: false });
        }
    }
    handleCPSChange(e) {
        if (e.target.value.length !== 0) {
            if (e.target.value.length <= 15) {
                if (this.checkPassChars(e.target.value.trim())) {
                    this.setState({ CPassword: e.target.value.trim(), pcPassword: false });
                }
            }
            else {
                this.toasterredfun2("Password should not be more than 15 characters");
                this.setState({ CPassword: "" });
            }
        }
        else {
            this.setState({ CPassword: e.target.value, pcPassword: false });
        }
    }
    handleKeyDownCN(e) {
        this.setState({ companycodeerror: false });
        if (/^[a-z0-9]+$/i.test(e.key)) {
            let key = e.which || e.key;
            this.setState({ cnaplphanumericerror: false });
            if (key === 13 || key === "13") {
                if (this.state.Username.length !== 0 && this.state.Companycode.length !== 0) {
                    this.Signin();
                }
            }
        } else {
            this.setState({ cnaplphanumericerror: true });
        }
    }
    handleCNChange = (event) => {
        if (!this.state.cnaplphanumericerror) {
            this.setState({ companycodeerror: false, Companycode: event.target.value });
        }
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e.toString() });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    handleSubmit(e) {
        e.preventDefault();
        if (this.state.popupShow) {
            this.setState({ popupShow: false });
        }
        else {
            let currentComponent = this;
            let isValid = true, Companycode = this.state.Companycode;
            if (currentComponent.state.UserName.length === 0 && currentComponent.state.UserName.length < 8) {
                currentComponent.setState({ puserName: true });
                isValid = false;
            }
            if (Companycode == null || Companycode.length === 0) {
                this.setState({ companycodeerror: true });
                isValid = false;
            }
            if (Companycode != null && Companycode.length < 1 && isValid) {
                this.setState({ cnminlengtherror: true });
                isValid = false;
            }
            if (currentComponent.state.NPassword.length === 0 && currentComponent.state.NPassword.length < 8) {
                currentComponent.setState({ ppassword: true });
                isValid = false;
            }
            else if (!currentComponent.checkPass(currentComponent.state.NPassword)) {
                currentComponent.toasterredfun2("Password does not meet the required criteria");
                currentComponent.setState({ ppassword: true });
                isValid = false;
            }
            if (currentComponent.state.CPassword.length === 0 && currentComponent.state.CPassword.length < 8) {
                currentComponent.setState({ pcPassword: true });
                isValid = false;
            }
            else if (currentComponent.state.NPassword !== currentComponent.state.CPassword) {
                currentComponent.setState({ pcPassword: true });
                currentComponent.toasterredfun2("New Password and Confirm Password not matches.");
                isValid = false;
            }
            else if (currentComponent.state.OPassword === currentComponent.state.NPassword) {
                currentComponent.setState({ ppassword: true });
                currentComponent.toasterredfun2("Current Password and New Password are same. Try with another password.");
                isValid = false;
            }

            if (currentComponent.state.OTP.length === 0) {
                currentComponent.setState({ pcOTP: true });
                isValid = false;
            }
            if (isValid) {
                currentComponent.setState({ loading: true });
                fetch('api/Login/ForgotPassword', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Companycode': Companycode
                    },
                    body: JSON.stringify({ "UserName": Api.encryptData(currentComponent.state.UserName), "NewPassword": Api.encryptData(currentComponent.state.NPassword), "Token": currentComponent.state.Token, "Otp": Api.encryptData(currentComponent.state.OTP), "OtpId": currentComponent.state.OtpId })
                }).then(function (response) {
                    if (response.status === 401) {
                        currentComponent.props.history.push('/');
                    }
                    else { return response.json(); }
                }).then(function (result) {
                    if (result.statusMessage === "Success") {
                        currentComponent.toasterfun2("Password has been changed successfully.");
                        let loginSource = currentComponent.state.Source;
                        if (loginSource === "1") {
                            let queryString = currentComponent.state.EncryptedUrlParams;
                            setTimeout(
                                function () {
                                    currentComponent.props.history.push("/?" + queryString);
                                }, 3000);
                        }
                        else {
                            setTimeout(
                                function () {
                                    currentComponent.props.history.push('/');
                                }, 3000);
                        }
                    }
                    else if (result.statusCode === 102) {
                        currentComponent.setState({ loading: false, pcOTP: true })
                        currentComponent.toasterredfun2(result.statusMessage);
                    } else if (result.statusCode === 405) {
                        currentComponent.toasterredfun2(result.statusMessage);
                        setTimeout(
                            function () {
                                currentComponent.props.history.push('/');
                            }, 5000);
                    }
                    else if (result == null || result.status === 429) {
                        currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                    }
                    else {
                        currentComponent.setState({ loading: false });
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                }).catch(function (error) {
                    currentComponent.setState({ Loading: false });
                    if (error === "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    let apierror = error.toString()
                    if (apierror.includes("API calls")) {
                        currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");

                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                    currentComponent.setState({ loading: false });
                });
            }
        }
    }
    checkUN(e) {
        return !(e.length > 1 && e.split('.').length > 2) && /^[a-zA-Z0-9@.]*$/g.test(e);
    }
    checkPass(e) {

        let re = /^(?!.*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])[^\s]{8,15}$/;
        return re.test(e);
    }
    checkPassChars(e) {
        let currentComponent = this;
        let response = false;
        if (/^[A-Za-z0-9@$!%*#?&]*$/g.test(e)) {
            response = true;
        }
        else {
            currentComponent.setState({ popupShow: true });
            currentComponent.setState({ NPassword: "" });
            currentComponent.setState({ CPassword: "" });
        }
        return response;
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
                this.props.history.push('/');
            }, 5000);
    }
    checkRepeat(e) {
        if (e.length > 2) {
            if (/(.)\1{2}$/.test(e)) {
                return false;
            }
        }
        return true;
    }
    closePopup(e) {
        let currentComponent = this;
        currentComponent.setState({ popupShow: false });
    }
    handleOTPChange(e) {
        this.setState({ OTP: e.target.value.trim() });
        if (e.target.value != null && e.target.value.trim().length > 0) { this.setState({ pcOTP: false }) }
    }
    startCountDown() {

        this.intervalHandle = setInterval(this.tick, 1000);
        let time = this.state.minutes;
        this.secondsRemaining = time * 60;
    }
    tick() {
        let min = Math.floor(this.secondsRemaining / 60);
        let sec = this.secondsRemaining - (min * 60);
        this.setState({
            minutes: min,
            seconds: sec
        })
        if (sec < 10) {
            this.setState(prevState => ({
                seconds: "0" + prevState.seconds,
            }));
        }
        if (min < 10) {
            this.setState({
                value: "0" + min,
            })
        }
        if (min === 0 && sec === 0) {
            clearInterval(this.intervalHandle);
            this.setState({ MFAResendError: false, MFAEnabledError: false });
        }
        this.secondsRemaining--
    }
    componentWillUnmount() {
        clearInterval(this.intervalHandle);
    }
    ResendPasswordCode(e) {

        e.preventDefault();
        let currentComponent = this;
        let username = currentComponent.state.UserName;
        let Companycode = currentComponent.state.Companycode;
        clearInterval(currentComponent.intervalHandle);
        this.setState({ loading: true });
        fetch('api/login/ResendPasswordCode', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Companycode': Companycode,
            },
            body: JSON.stringify({
                "UserName": username,
            })
        }).then(function (response) {
            if (!response.ok) {
                return response;
            }
            return response.json();
        }).then(function (resul) {
            if (resul.statusMessage === "Success") {
                currentComponent.setState({
                    OtpId: resul.otpId, loading: false, otpResendError: true, pcOTP: false, emailsentmsg: true, Otpsendmsg: "Security code has been resent to your registered email address."
                });
                currentComponent.setState({
                    minutes: currentComponent.state.Mseconds, seconds: 0, secondsRemaining: 0
                });
                currentComponent.startCountDown();
            } else {
                currentComponent.setState({ loading: false, emailsentmsg: false });
                currentComponent.toasterredfun2(resul.statusMessage);
            }
        }).catch(function (error) {
            currentComponent.setState({ otpResendError: false, pcOTP: false, loading: false, emailsentmsg: false })
        });

    }

    handleGenerateOtp(e) {
        e.preventDefault();
        this.GenerateOTP();
    }
    GenerateOTP(e) {
        this.setState({ pcPassword: false });
        let currentComponent = this;
        let Companycode = currentComponent.state.Companycode;
        let username = currentComponent.state.UserName;
        let isValid = true;
        if (currentComponent.state.UserName.length === 0 && currentComponent.state.UserName.length < 8) {
            currentComponent.setState({ puserName: true });
            isValid = false;
        }
        if (Companycode == null || Companycode.length === 0) {
            this.setState({ companycodeerror: true });
            isValid = false;
        }
        if (Companycode != null && Companycode.length < 1 && isValid) {
            this.setState({ cnminlengtherror: true });
            isValid = false;
        }
        if (currentComponent.state.NPassword.length === 0 && currentComponent.state.NPassword.length < 8) {
            currentComponent.setState({ ppassword: true });
            isValid = false;
        }
        else if (!currentComponent.checkPass(currentComponent.state.NPassword)) {
            currentComponent.toasterredfun2("Password does not meet the required criteria");
            currentComponent.setState({ ppassword: true });
            isValid = false;
        }
        if (currentComponent.state.CPassword.length === 0 && currentComponent.state.CPassword.length < 8) {
            currentComponent.setState({ pcPassword: true });
            isValid = false;
        }
        else if (currentComponent.state.NPassword !== currentComponent.state.CPassword) {
            currentComponent.setState({ pcPassword: true });
            currentComponent.toasterredfun2("New Password and Confirm Password not matches.");
            isValid = false;
        }
        else if (currentComponent.state.OPassword === currentComponent.state.NPassword) {

            currentComponent.setState({ ppassword: true });
            currentComponent.toasterredfun2("Current Password and New Password are same. Try with another password.");
            isValid = false;
        }
        if (isValid) {
            this.setState({ loading: true });
            fetch('api/login/GenerateMFA', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Companycode': Companycode,
                },
                body: JSON.stringify({
                    "UserName": username,
                })
            }).then(function (response) {
                if (!response.ok) {
                    return response;
                }
                return response.json();
            }).then(function (resul) {

                currentComponent.setState({ loading: false });
                if (resul.statusMessage === "Success") {
                    currentComponent.setState({ OtpInput: true, showSubmitButton: true, emailsentmsg: true, Otpsendmsg: "Security code has been sent to your registered email address." });
                    currentComponent.setState({
                        OtpId: resul.otpId, minutes: resul.tokenExpiryMin, Mseconds: resul.tokenExpiryMin, loading: false, isEditpassword: true
                    });
                    clearInterval(currentComponent.intervalHandle);
                    currentComponent.state = {
                        minutes: currentComponent.state.Mseconds, seconds: 0, secondsRemaining: 0
                    };
                    currentComponent.startCountDown();
                }
                else {
                    currentComponent.toasterredfun2(resul.statusMessage);
                    currentComponent.setState({ OtpId: resul.otpId, loading: false, emailsentmsg: false });
                }
            }).catch(function (error) {
                currentComponent.setState({ otpResendError: false, pcOTP: false, loading: false })
            });
        }
    }
    handleKeyDownUN(event) {
        this.setState({ puserName: false });
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            if (this.state.UserName.length !== 0) {
                if (!this.state.showSubmitButton) {
                    this.handleGenerateOtp(event);
                } else {
                    this.handleSubmit(event);
                }
            }
            else {
                this.setState({ puserName: true });
                event.preventDefault();
            }
        }
    }

    handleKeyDownNP(event) {
        this.setState({ ppassword: false });
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            if (this.state.NPassword.length !== 0) {
                if (!this.state.showSubmitButton) {
                    this.handleGenerateOtp(event);
                } else {
                    this.handleSubmit(event);
                }
            }
            else {
                this.setState({ ppassword: true });
                event.preventDefault();
            }
        }
    }
    handleKeyDownCP(event) {
        this.setState({ pcPassword: false });
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            if (this.state.CPassword.length !== 0) {
                if (!this.state.showSubmitButton) {
                    this.handleGenerateOtp(event);
                } else {
                    this.handleSubmit(event);
                }
            } else {
                event.preventDefault();
                this.setState({ pcPassword: true });
            }
        }
    }
    handleKeyDownOtp(event) {
        this.setState({ pcOTP: false });
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            if (this.state.OTP.length !== 0) {
                if (!this.state.showSubmitButton) {
                    this.handleGenerateOtp(event);
                } else {
                    this.handleSubmit(event);
                }
            } else {
                event.preventDefault();
                this.setState({ pcOTP: true });
            }
        }
    }
    render() {
        let landing = '/img/ems-logo.gif';
        return (
            <Container fluid>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="Loading" /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className="container-div">
                    <div className="con-div-pso">
                        <div className="con-div-xd lefeside-panel">
                        </div>
                    </div> H
                    <div className="con-div-xm">
                        <div className="con-div-x">
                            <div className="login-page">
                                <div className="logo">
                                    <img src="img/eSubmission-logo.png" alt="Logo" />
                                    <h2>eNoah Online Portal </h2>
                                </div>
                                <div className="form">
                                    <form className="login-form">
                                        <h5>Change Password</h5>
                                        <label>User Name</label>
                                        <input type="text" className={"form-control " + (this.state.puserName ? "redborder" : "blackborder")} placeholder="Username" onKeyDown={this.handleKeyDownUN} data-nofill="true" readOnly={(this.state.isReset ? "readOnly" : "")} id="un" autoComplete="false" maxLength={250} onChange={this.handleUNChange} value={this.state.UserName} onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off');
                                        }} />
                                        <label className="small hide">Company Code</label>
                                        <input type="text" className={"form-control hide " + (this.state.ppassword ? "redborder" : "blackborder")} placeholder="Company Code" onKeyDown={this.handleKeyDownCN} maxLength={6} value={this.state.Companycode} onChange={this.handleCNChange} />
                                        <span className={"error " + (this.state.cnalphanumericerror ? "show" : "hide")}>Company code should only be in alphabets and numerics.</span>
                                        <span className={"error " + (this.state.cnminlengtherror ? "show" : "hide")}>Company code should contain min one characters.</span>                                        
                                        <label>New Password</label>
                                        <input type="password" className={"form-control " + (this.state.ppassword ? "redborder" : "blackborder")} autoComplete="new-password" onKeyDown={this.handleKeyDownNP} data-nofill="true" placeholder="Password" maxLength={16} onChange={this.handleNPSChange} id="ps" value={this.state.NPassword} onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off');
                                        }} />
                                        <small style={{ lineHeight: 1.2 }} className="form-text text-muted text-left">
                                            <ul className="password-bullets">
                                                <li>
                                                    Be between 8-15 characters long
                                                </li>
                                                <li>Contain at least one upper case character</li>
                                                <li>Contain at least one lower case character</li>
                                                <li>Contain at least one number</li>
                                                <li>Contain atleast one special character-    !@#$%?&*</li>
                                                <li>Password cannot be same as your last {this.state.passwordCount} {" "}  passwords</li>
                                            </ul>
                                        </small>

                                        <label>Confirm Password</label>
                                        <input type="password" className={"form-control " + (this.state.pcPassword ? "redborder" : "blackborder")} placeholder="Confirm Password" onKeyDown={this.handleKeyDownCP} data-nofill="true" autoComplete="new-password" maxLength={16} onChange={this.handleCPSChange} id="cps" value={this.state.CPassword} onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off');
                                        }} />
                                        <div className={" " + (this.state.OtpInput ? "show" : "hide")}>
                                            <label>Security Code</label>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <input type="password" className={"form-control m-auto " + (this.state.pcOTP ? "redborder" : "blackborder")} placeholder="Security Code" onKeyDown={this.handleKeyDownOtp} data-nofill="true" autoComplete="new-password" maxLength={100} onChange={this.handleOTPChange} id="otp" value={this.state.OTP} onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off');
                                                    }} />
                                                    <div>
                                                        {this.state.minutes <= 0 && this.state.seconds <= 0 ? (<div className="error">Security Code has expired.</div>) : (<div className="expireTxt mr-2">Security Code expires in {this.state.minutes} : {this.state.seconds} </div>)}
                                                    </div>
                                                </div>

                                                <div className=" col-sm-4 small mdb-8 ns-2">
                                                    <button className="m-auto btn btn-blue-btnnm btn-type pull-mid mb-1 mr-1 btn-size-3" onClick={this.ResendPasswordCode} >
                                                        Resend Code
                                                    </button>
                                                </div>

                                            </div>

                                            <div className={" " + (this.state.showSubmitButton ? "show" : "hide")}>
                                                <button className="onLogin" onClick={this.handleSubmit}>Submit</button>
                                            </div>

                                        </div>
                                        <div className={" " + (this.state.showSubmitButton ? "hide" : "show")}>
                                            <button className=" " onClick={this.handleGenerateOtp}>Submit</button>
                                        </div>
                                        <span className={"text-success " + (this.state.emailsentmsg ? "show" : "false")}>{this.state.Otpsendmsg}</span>
                                        <span className="forgot-div-x hide"><NavLink to="/">Login</NavLink> </span>
                                    </form>
                                </div>
                            </div>
                            <div className="footer-text text-center mb-2">Build: <span className="text-dark mr-2">{this.state.Date + ": v" + this.state.Version}</span> Environment: <span className="text-dark">{this.state.Env}</span></div>
                            <div className="footer-text text-center mb-2">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                            <div className="footer-text text-center">
                                <a href={this.state.PrivacyPolicyURL} rel="noopener noreferrer" target="_blank"> Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"removeAlert-divbrowser " + (this.state.popupShow ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3">
                            The following special characters are allowed !@#?$%&*
                        </p>
                        {/* <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.closePopup}>
                                Ok
                            </div>
                        </div>*/}
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" aria-hidden="true" onClick={this.closePopup} onKeyDown={(e) => {
                                if (e.key == 13) {
                                    this.closePopup();
                                }
                            }}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

