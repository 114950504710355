import React, { Component } from 'react';
import { NavLink, } from 'react-router-dom';
import $ from 'jquery';
import Api from '../serviceCall';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { isNullOrUndefined } from 'util';


export class Login extends Component {
    static displayName = Login.name;
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            seconds: '0', minutes: '1', Mseconds: '0',
            isSupporttedBrowser: true, isLatestSupporttedBrowser: false,
            Username: '', Password: '', usererror: false, passerror: false, sererror: false, companycodeerror: false, cnminlengtherror: false, cnaplphanumericerror: false, loading: false, isChecked: false, UserType: "", PlaceOrder: false, ViewStatus: false, ViewAuth: false, emailEnableErr: false, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: false,
            toasterredCla: false, startversion: "", endversion: "", browsername: "", operatingsSystem: "", toaster: "", Version: "", Env: "", Date: "", Companycode: "", CopyRightYear: new Date().getFullYear(),
            popupShow: false, isHigherVersion: false, browserversion: "", pspopupShow: false, Unauthorizedaccess: false, MFATokenPopup: false, MFAToken: "", Userid: "", User: [], MFAEnabledError: false, MFAErrorMsg: "", OtpId: "", ClientIpAddress: "", MaskedEmail: "", StatusToken: false, MFAResendError: false, DisableEnterKey: true,
            PasswordExpired: false, IsLocked: false, HideForgotpasswordlink: false, HideLoginButton: false, announcementData: "", HideForgotpasswordlinkDBdown: true, PrivacyPolicyURL: "",
            AcceptLoginPopup: false, SessionTimeoutPopup: false, TimeoutPopupFromPlaceOrder: false, GenericErrMessage: false, ShowChangePasswordScreen: false, LoginToken: "", LoginTokenId: "", Token: "",
            Source: "", OrderFacilityId: "", EncryptedUrlParams: "", SourceEmail: false


        };
        this.handleChange = this.handleChange.bind(this);
        this.startCountDown = this.startCountDown.bind(this);
        this.tick = this.tick.bind(this);
        this.signInSubmit = this.signInSubmit.bind(this);
        this.handleUNChange = this.handleUNChange.bind(this);
        this.handlePSChange = this.handlePSChange.bind(this);
        this.handleCNChange = this.handleCNChange.bind(this);
        this.handleKeyDownUN = this.handleKeyDownUN.bind(this);
        this.handleKeyDownPass = this.handleKeyDownPass.bind(this);
        this.handleKeyDownCN = this.handleKeyDownCN.bind(this);
        this.GetBrowserVersion = this.GetBrowserVersion.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.psclosePopup = this.psclosePopup.bind(this);
        this.verifyMFATokenPopup = this.verifyMFATokenPopup.bind(this);
        this.handleMFATokenChange = this.handleMFATokenChange.bind(this);
        this.ResendSecurityCode = this.ResendSecurityCode.bind(this);
        this.forgotpasswordDBdown = this.forgotpasswordDBdown.bind(this);
        this.handleSessionTimeout = this.handleSessionTimeout.bind(this);
        this.readTextFile = this.readTextFile.bind(this);
    }
    handleSessionTimeout() {
        this.setState({ SessionTimeoutPopup: false });
        this.setState({ TimeoutPopupFromPlaceOrder: false });
    }
    handleChange(event) {
        this.setState({
            minutes: event.target.value
        })
    }
    tick() {
        let min = Math.floor(this.secondsRemaining / 60);
        let sec = this.secondsRemaining - (min * 60);
        this.setState({
            minutes: min,
            seconds: sec
        })
        if (sec < 10) {
            this.setState(prevState => ({
                seconds: "0" + prevState.seconds,
            }));
        }
        if (min < 10) {
            this.setState({
                value: "0" + min,
            })
        }
        if (min === 0 && sec === 0) {
            clearInterval(this.intervalHandle);
            this.setState({ MFAResendError: false, MFAEnabledError: false });
        }
        this.secondsRemaining--
    }
    startCountDown() {
        this.intervalHandle = setInterval(this.tick, 1000);
        let time = this.state.minutes;
        this.secondsRemaining = time * 60;
    }
    componentWillUnmount() {
        clearInterval(this.intervalHandle);
    }

    componentWillMount() {
        let windowlocation = window.location.href;
        const urlParts = windowlocation.split('?');
        let queryString = "";
        let SessionTimeOut = localStorage.getItem('IsTimeOut');
        let SessionTimeOutFromPlaceOrder = localStorage.getItem('IsTimeOutFromPlaceOrder');
        if (SessionTimeOut === 'true') {
            this.setState({ SessionTimeoutPopup: true });
        }
        if (SessionTimeOutFromPlaceOrder === 'true') {
            this.setState({ TimeoutPopupFromPlaceOrder: true });
        }
        this.GetBrowserVersion(browserName);

        localStorage.clear();
        let currentComponent = this;
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
                currentComponent.setState({ HideForgotpasswordlink: true, HideForgotpasswordlinkDBdown: false });
            }
        });

        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }
            else {
                currentComponent.setState({ PrivacyPolicyURL: "https://enoahisolution.com/privacy-policy/" });
            }
        });

        Api.Get('api/login/GetAnnouncementTxtFilePath', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                if (response.announcementTxtFilePath === "FNF") {
                    currentComponent.setState({ announcementData: "" });
                }
                else {
                    currentComponent.readTextFile(response.announcementTxtFilePath);
                }

            }
            else {
                currentComponent.readTextFile("Application_EOP_IT_ UAT/AnnouncementTxt/announcement.txt");
            }
        });

        if (urlParts.length > 1) {
            queryString = urlParts[1];
            if (!isNullOrUndefined(queryString) && queryString.trim().length > 0) {
                fetch('api/Login/DecryptUrlString', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "UrlString": queryString })
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                    if (result.statusMessage === "Success") {
                        currentComponent.setState({
                            Source: result.source, OrderFacilityId: result.orderFacilityId, EncryptedUrlParams: queryString, SourceEmail: true
                        })
                    }
                }).catch(function (error) {
                    this.setState({ Loading: false });
                    if (error === "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });
            }
        }
        else {
            currentComponent.setState({
                Source: '0'
            })
        }
    }

    GetBrowserVersion(browserName) {
        let token = this.state.Token;
        if (token == null || token.length == 0) {
            this.props.history.push('/');
        }
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/login/GetBrowserVersion', { "BrowserName": browserName }, currentComponent).then(function (result) {
            if (result.checkBrowserCompatibility) {
                let currentBrowser = browserName === result.browserName;
                let latestBrowser = browserName === result.browserName && (browserVersion >= result.startVersion && browserVersion <= result.endVersion);
                if (!latestBrowser) {
                    if (browserVersion > result.endVersion) {
                        latestBrowser = true;
                    }
                    else {
                        latestBrowser = false;
                    }
                }
                currentComponent.setState({ browserName: result.browserName });
                if (!currentBrowser) {
                    currentComponent.setState({ isSupporttedBrowser: false });

                }
                else {
                    currentComponent.setState({ isSupporttedBrowser: true });
                    if (!latestBrowser) {
                        currentComponent.setState({ isLatestSupporttedBrowser: false });
                        currentComponent.setState({ isSupporttedBrowser: false });
                    }
                    else {
                        currentComponent.setState({ isLatestSupporttedBrowser: true });
                        currentComponent.setState({ isSupporttedBrowser: true });
                        if (browserVersion > result.endVersion) {
                            currentComponent.setState({ isHigherVersion: true });
                            currentComponent.setState({ popupShow: true });
                        }
                        else {
                            currentComponent.setState({ isHigherVersion: false });
                        }
                    }
                }
                currentComponent.setState({ browsername: result.browsername, browserversion: browserVersion, startversion: result.startversion, endversion: result.endversion, operatingsSystem: result.operatingsSystem, loading: false });
            }
            else {
                currentComponent.setState({ isSupporttedBrowser: true, loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

    }

    handleUNChange(e) {
        if (e.target.value.length !== 0) {
            if (this.checkUN(e.target.value.trim())) {
                this.setState({ sererror: false, GenericErrMessage: false, Username: e.target.value.trim() });

            }
        }
        else {
            this.setState({ sererror: false, GenericErrMessage: false, Username: e.target.value });

        }
    }
    checkUN(e) {

        if (e.length > 1) {
            let split = e.split('.');
            if (split.length > 2) {
                return false;
            }
        }
        return /^[a-zA-Z0-9@.]*$/g.test(e);
    }
    handlePSChange(e) {
        if (/^[A-Za-z0-9@$!%*#?&]*$/g.test(e.target.value.trim())) {
            this.setState({ sererror: false, GenericErrMessage: false, Password: e.target.value.trim() });
            return true;
        }
        else {
            this.setState({ pspopupShow: true });
            this.setState({ Password: "" });
            return false;
        }
    }
    handleCNChange = (event) => {
        if (!this.state.cnaplphanumericerror) {
            this.setState({ companycodeerror: false, GenericErrMessage: false, Companycode: event.target.value.trim() });
        }
    }
    handleKeyDownUN(event) {
        this.setState({ usererror: false, GenericErrMessage: false });
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            if (this.state.Username.length !== 0 && this.state.Password.length !== 0 && this.state.Companycode.length !== 0) {
                event.preventDefault();
                if (this.state.DisableEnterKey) {
                    this.setState({ DisableEnterKey: false, loading: true })
                    this.Signin();
                }
            }
        }
    }
    handleKeyDownPass(e) {
        this.setState({ passerror: false, GenericErrMessage: false });
        let key = e.which || e.key;
        if (key === 13 || key === "13") {
            if (this.state.Username.length != 0 && this.state.Password.length != 0 && this.state.Companycode.length != 0) {
                e.preventDefault();
                if (this.state.DisableEnterKey) {
                    this.setState({ DisableEnterKey: false, loading: true })
                    this.Signin();
                }
            }
        }
    }
    handleKeyDownCN(e) {
        this.setState({ companycodeerror: false, GenericErrMessage: false });

        if (/^[a-z0-9]+$/i.test(e.key)) {
            let key = e.which || e.key;
            this.setState({ cnaplphanumericerror: false });
            if (key == 13) {
                if (this.state.Username.length != 0 && this.state.Password.length != 0 && this.state.Companycode.length != 0) {
                    e.preventDefault();
                    if (this.state.DisableEnterKey) {
                        this.setState({ DisableEnterKey: false, loading: true })
                        this.Signin();
                    }
                }
            }
        } else {
            this.setState({ cnaplphanumericerror: true });
        }
    }
    closePopup(e) {
        this.setState({ popupShow: false });
    }
    ResendSecurityCode(e) {
        let currentComponent = this;
        let antiToken = Cookies.get('XSRF-TOKEN');
        let Companycode = currentComponent.state.Companycode;
        clearInterval(currentComponent.intervalHandle);
        currentComponent.setState({ loading: true })
        fetch('api/login/ResendMFASecurityCode', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Companycode': Companycode,
                'X-XSRF-Token': antiToken
            },
            body: JSON.stringify({
                "UserId": currentComponent.state.Userid,
            })
        }).then(function (response) {
            return response.json();
        }).then(function (resul) {
            if (resul.statusMessage !== "Success") {
                currentComponent.setState({ MFAEnabledError: true, MFAResendError: false, StatusToken: false, MFAErrorMsg: resul.statusMessage, loading: false })
            }
            else if (resul.statusMessage === "Success") {
                currentComponent.setState({
                    OtpId: resul.otpId, MaskedEmail: resul.maskedEmailAddress, MFAEnabledError: false, MFAErrorMsg: "", StatusToken: true, MFAResendError: true, loading: false
                });
                currentComponent.setState({
                    minutes: currentComponent.state.Mseconds, seconds: 0, secondsRemaining: 0
                });
                currentComponent.startCountDown();
            }
        }).catch(function (error) {
            currentComponent.setState({ MFAEnabledError: true, MFAErrorMsg: error, MFAResendError: false, loading: false })
        });
    }

    verifyMFATokenPopup(e) {
        let currentComponent = this;
        let valid = true;
        let antiToken = Cookies.get('XSRF-TOKEN');
        let Companycode = currentComponent.state.Companycode;
        if (this.state.MFAToken == null || this.state.MFAToken.length === 0) {
            valid = false;
            currentComponent.setState({ MFAEnabledError: true, StatusToken: false, MFAErrorMsg: "Please enter the one-time security code.", MFAResendError: false })
        }
        else {
            currentComponent.setState({ MFAEnabledError: false, StatusToken: false, MFAErrorMsg: "", MFAResendError: false })
        }
        if (valid) {
            currentComponent.setState({ loading: true });
            fetch('api/login/CheckMFAToken', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Companycode': Companycode,
                    'X-XSRF-Token': antiToken
                },
                body: JSON.stringify({
                    "UserId": currentComponent.state.Userid,
                    "Token": Api.encryptData(currentComponent.state.MFAToken),
                    "OtpId": currentComponent.state.OtpId,
                    "ClientIpAddress": Api.encryptData(currentComponent.state.ClientIpAddress),
                })
            }).then(function (response) {
                return response.json();
            }).then(function (resul) {

                if (resul.statusMessage !== "Success") {
                    if (resul.statusCode === 122) {
                        currentComponent.setState({ MFAEnabledError: false, loading: false, MFATokenPopup: false })
                        currentComponent.toasterredfun2(resul.statusMessage);
                        window.location.reload(false);
                    }
                    else {
                        currentComponent.setState({ MFAEnabledError: true, MFAErrorMsg: resul.statusMessage, loading: false })
                    }
                }
                else if (resul.statusMessage === "Success") {
                    currentComponent.setState({
                        MFAEnabledError: false, loading: false, LoginToken: resul.lToken, LoginTokenId: resul.lTokenId, Token: resul.token
                    });
                    Api.SetLocalStorage(currentComponent.state.User, false, currentComponent.state.Companycode);
                    currentComponent.handleLogTime(true);
                }

            }).catch(function (error) {
                currentComponent.setState({ MFAEnabledError: true, MFAErrorMsg: error, loading: false })
            });
        }
    }
    handleMFATokenChange(e) {
        this.setState({ MFAToken: e.target.value.trim() });
        if (e.target.value != null && e.target.value.trim().length > 0) { this.setState({ MFAEnabledError: false, MFAErrorMsg: "" }) }
    }

    psclosePopup(e) {
        this.setState({ pspopupShow: false });
    }
    signInSubmit(e) {
        e.preventDefault();
        if (this.state.pspopupShow) {
            this.setState({ pspopupShow: false });
        }
        else {
            this.Signin();

        }
    }
    forgotpasswordDBdown() {
        this.toasterredfun2("ERROR-Site unavailable, please check the announcement section for more details.");
    }
    Signin() {
        let UN = this.state.Username, Pswd = this.state.Password, valid = true, Companycode = this.state.Companycode;
        let currentComponent = this;
        if (UN == null || UN.trim().length === 0) {
            this.setState({ usererror: true });
            valid = false;
        }
        if (Pswd == null || Pswd.trim().length === 0) {
            this.setState({ passerror: true });
            valid = false;
        }
        if (Companycode == null || Companycode.trim().length === 0) {
            this.setState({ companycodeerror: true });
            valid = false;
        }
        if (Companycode != null && Companycode.length < 1 && valid) {
            this.setState({ cnminlengtherror: true });
            valid = false;
        }
        this.setState({ loading: valid, DisableEnterKey: !valid });
        if (valid) {
            let antiToken = Cookies.get('XSRF-TOKEN');
            fetch('api/login/Checklogin', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Companycode": Companycode,
                    "X-XSRF-Token": antiToken
                },
                body: JSON.stringify({
                    "UserName": Api.encryptData(UN),
                    "Password": Api.encryptData(Pswd),
                    "CompanyCode": Companycode,
                    "BrowserType": deviceDetect().browserName,
                    "BrowserVersion": deviceDetect().browserFullVersion
                })
            }).then(function (response) {
                if (!response.ok) {
                    return response;
                }
                return response.json();
            }).then(function (resul) {
                currentComponent.setState({ loading: false });

                if (resul.status === 503) {
                    currentComponent.setState({ DisableEnterKey: true });
                    currentComponent.toasterredfun2("ERROR-Site unavailable, please check the announcement section for more details.");

                }
                else if (resul.statusMessage !== "Success") {
                    currentComponent.setState({ DisableEnterKey: true });
                    if (resul.statusCode === 454) {
                        currentComponent.setState({ loading: false, GenericErrMessage: true, sererror: false, emailEnableErr: false, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: false, Unauthorizedaccess: false, PasswordExpired: false, IsLocked: false });
                    }
                    else if (resul.statusCode === 411) {
                        currentComponent.setState({ loading: false, GenericErrMessage: false, sererror: false, emailEnableErr: true, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: false, Unauthorizedaccess: false, PasswordExpired: false, IsLocked: false });
                    }
                    else if (resul.statusCode === 133) {
                        currentComponent.setState({ loading: false, GenericErrMessage: true, sererror: false, emailEnableErr: false, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: false, Unauthorizedaccess: false, PasswordExpired: false, IsLocked: false });
                        currentComponent.setState({ HideLoginButton: true })
                    } else if (resul.statusCode === 132) {
                        currentComponent.setState({ loading: false, GenericErrMessage: true, sererror: false, emailEnableErr: false, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: false, Unauthorizedaccess: false, PasswordExpired: false, IsLocked: false });
                        currentComponent.setState({ HideForgotpasswordlink: true, HideLoginButton: true })
                    }
                    else if (resul.statusCode === 401) {
                        currentComponent.props.history.push('/LoginErrorSSO');
                    }
                    else if (resul.statusCode === 4001) {
                        currentComponent.toasterredfun2(resul.statusMessage);
                    }
                    else if (resul.forgotPasswordScreen) {
                        let paramString = "";
                        currentComponent.setState({ GenericErrMessage: true });
                        let loginSource = currentComponent.state.Source;
                        if (loginSource === "1") {
                            paramString = Api.encryptData(resul.forgotPasswordLink + "&Redirection=" + currentComponent.state.EncryptedUrlParams);
                            setTimeout(() => currentComponent.props.history.push(`/forgotPassword?${paramString}`), 3000);
                        }
                        else {
                            paramString = Api.encryptData(resul.forgotPasswordLink);
                            setTimeout(() => currentComponent.props.history.push(`/forgotPassword?${paramString}`), 3000);
                        }
                    }
                    else if (resul == null || resul.status === 429) {
                        currentComponent.setState({ loading: false, sererror: false, GenericErrMessage: false, emailEnableErr: false, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: false, Unauthorizedaccess: false, PasswordExpired: false, IsLocked: false });
                        currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                    }
                    else {
                        currentComponent.setState({ loading: false, sererror: false, GenericErrMessage: false, emailEnableErr: false, consecutivetriedErr: false, notFoundErr: false, somethingwentErr: true, Unauthorizedaccess: false, PasswordExpired: false, IsLocked: false });
                    }
                }

                else {
                    currentComponent.setState({ loading: false, UserType: resul.userType, PlaceOrder: resul.placeOrder, ViewStatus: resul.viewStatus, ViewAuth: resul.viewAuth });
                    currentComponent.setState({ loading: false, UserType: "User", PlaceOrder: false, ViewStatus: false, ViewAuth: false, ShowChangePasswordScreen: resul.changePasswordScreen, LoginToken: resul.lToken, LoginTokenId: resul.lTokenId });
                    localStorage.setItem("PasswordCount", resul.rememberPasswordCount);
                    localStorage.setItem("ExpiryCount", resul.expiryCount);

                    if (Api.decryptData(resul.isValidMFAToken) === "false") {
                        Api.SetLocalStorage(resul, false, Companycode);
                        currentComponent.setState({ AcceptLoginPopup: true, Userid: resul.userId, Token: resul.token })
                    }
                    else {
                        currentComponent.setState({
                            MFATokenPopup: true, Userid: resul.userId, User: resul, minutes: resul.tokenExpiryMin, Mseconds: resul.tokenExpiryMin, OtpId: resul.otpId, ClientIpAddress: resul.clientIpAddress,
                            MaskedEmail: resul.maskedEmailAddress, StatusToken: true
                        });
                        clearInterval(currentComponent.intervalHandle);
                        currentComponent.state = {
                            minutes: currentComponent.state.Mseconds, seconds: 0, secondsRemaining: 0
                        };
                        currentComponent.startCountDown();
                    }
                }
            
            }).catch (function (error) {
            currentComponent.setState({ loading: false, sererror: false, GenericErrMessage: true, DisableEnterKey: true });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

}
onChangeCheckbox = event => {
    this.setState({
        isChecked: event.target.checked
    })
}
toasterredfun2(e) {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.setState({ toasterredCla: true });
    this.setState({ toaster: e });
    setTimeout(
        function () {
            this.setState({ toasterredCla: false });
        }.bind(this), 5000);
}
componentDidMount() {
    if (localStorage.checkbox && localStorage.Username !== "") {
        this.setState({
            isChecked: true,
            Username: localStorage.username,
            Password: localStorage.password
        })
    }
}

    readTextFile = file => {
        if (file === "" || file == null) {

            this.setState({ announcementData: "" });

        }
        else {
            let ext = file.toString().split('.').pop();
            let allText = "";
            let emptyString = "";
            let finalTrimmedString = "";

            if (ext === "txt") {
                let rawFile = new XMLHttpRequest();
                rawFile.open("GET", file, false);
                rawFile.onreadystatechange = () => {
                    if (rawFile.readyState === 4) {
                        if (rawFile.status === 200 || rawFile.status === 0) {
                            allText = rawFile.responseText;
                            if (allText.includes("<!DOCTYPE html>") || allText.includes("<!doctype html>")) {

                                this.setState({ announcementData: emptyString });

                            }
                            else if (allText.length > 500) {
                                let TrimmedString = allText.substring(0, 500);
                                let SpaceIndex = TrimmedString.lastIndexOf(' ');
                                let dotIndex = TrimmedString.lastIndexOf('.');

                                if (dotIndex < 495) {
                                    finalTrimmedString = TrimmedString.substring(0, SpaceIndex + 1);

                                }
                                else {
                                    finalTrimmedString = TrimmedString.substring(0, dotIndex + 1);
                                }

                                this.setState({ announcementData: finalTrimmedString });

                            }
                            else {
                                this.setState({ announcementData: allText });
                            }

                        }
                        else {
                            this.setState({ announcementData: allText });
                        }
                    }
                    else {
                        this.setState({ announcementData: allText });
                    }
                };

                rawFile.send(null);
            }
            else {
                this.setState({ announcementData: allText });
            }
        }
    };



handleLogTime(e) {
    let currentComponent = this;
    let acceptlogin = e;
    let Companycode = currentComponent.state.Companycode;
    let antiToken = Cookies.get('XSRF-TOKEN');
    currentComponent.setState({ loading: true });
    fetch('api/login/SaveLoginDetails', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Companycode': Companycode,
            'X-XSRF-Token': antiToken
        },
        body: JSON.stringify({
            "UserId": currentComponent.state.Userid,
            "ClientIpAddress": Api.encryptData(currentComponent.state.ClientIpAddress), "BrowserType": deviceDetect().browserName,
            "BrowserVersion": deviceDetect().browserFullVersion,
            "LoginAccept": acceptlogin,
            "Token": currentComponent.state.LoginToken,
            "TokenId": currentComponent.state.LoginTokenId
        })
    }).then(function (response) {
        return response.json();
    }).then(function (result) {
        if (result.statusMessage === "Success") {
            if (acceptlogin) {
                let loginSource = currentComponent.state.Source;
                if (loginSource === "1") {
                    if (currentComponent.state.ShowChangePasswordScreen) {
                        currentComponent.props.history.push(`/ChangePassword?${currentComponent.state.EncryptedUrlParams}`);
                    }
                    else {
                        let OrdFacId = currentComponent.state.OrderFacilityId;
                        if (!isNullOrUndefined(OrdFacId) && OrdFacId.trim().length > 0) {
                            currentComponent.props.history.push('/case-details?id=' + OrdFacId);
                        }
                        else {
                            currentComponent.props.history.push('/dashboard');
                        }
                    }
                }
                else if (currentComponent.state.ShowChangePasswordScreen) {
                    currentComponent.props.history.push('/ChangePassword');
                }
                else {
                    currentComponent.props.history.push('/dashboard');
                }
            } else {
                localStorage.clear();
                window.location.reload(false);
            }
        }
        else {
            currentComponent.toasterredfun2(result.statusMessage);
        }
        currentComponent.setState({ loading: false });
    }).catch(function (error) {
        currentComponent.setState({ MFAEnabledError: true, MFAErrorMsg: error, loading: false })
    });
}
render() {

    let landing = 'img/ems-logo.gif';
    return (
        <div>

            <div className={"removeAlert-divbrowser " + (this.state.pspopupShow ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x text-center">
                    <img src="img/error.png" alt="Error" />
                    <p className="my-3">
                        The following special characters are allowed !@#?$%&*
                    </p>
                    <div className="text-center-div m-0">
                        <div className="bttn popupButton m-0" onClick={this.psclosePopup} tabIndex={0} onKeyDown={(event) => {
                            if (event.key == 13 || event.keyCode === 13) {
                                this.psclosePopup();
                            }
                        }}>
                            Ok
                        </div>
                    </div>
                </div>
            </div>
            <div className={"removeAlert-divbrowser " + (this.state.SessionTimeoutPopup ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x text-center">
                    <p className={"my-3 "}>
                        Your session has been expired due to inactivity. Please login again to continue.
                    </p>
                    <div className="text-center-div m-0">
                        <div className="bttn popupButton m-0" onClick={this.handleSessionTimeout} tabIndex={0} onKeyDown={(event) => {
                            if (event.key == 13 || event.keyCode === 13) {
                                this.handleSessionTimeout();
                            }
                        }}>
                            Ok
                        </div>
                    </div>
                </div>
            </div>
            <div className={"removeAlert-divbrowser " + (this.state.TimeoutPopupFromPlaceOrder ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x text-center">
                    <p className={"my-3 "}>
                        Your session has been expired due to inactivity. Please login again to continue. <br />
                        <b>Note</b>: Incomplete Order details will be saved and available in the Unsubmitted Orders.
                    </p>
                    <div className="text-center-div m-0">
                        <div className="bttn popupButton m-0" onClick={this.handleSessionTimeout} tabIndex={0} onKeyDown={(event) => {
                            if (event.key == 13 || event.keyCode === 13) { this.handleSessionTimeout(); }
                        }
                        }>
                            Ok
                        </div>
                    </div>
                </div>
            </div>
            <div className={"removeAlert-divbrowser " + (this.state.popupShow ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x text-center">
                    <img src="img/error.png" alt="Error" />
                    <p className="my-3">
                        {/*You are using the higher version of {this.state.browserName + " " + this.state.browserversion} hence this site may not be compatable for this version.*/}
                        You are using a browser version that has not been tested with the eNoah Online Portal. We are in the process of testing the new version, and you will stop receiving this message once the testing is complete. The Portal supports the Chrome, Edge and Internet Explorer 10 browsers.
                    </p>
                    <p className="my-3"> You can continue to use the website, but you may experience issues. Please contact <a href="mailto:ithelpdesk_us@enoahisolution.com.">ITHelpDesk_US@enoahisolution.com</a> if you require any further assistance.
                    </p>
                    <div className="text-center-div m-0">
                        <div className="bttn popupButton m-0" onClick={this.closePopup} tabIndex={0} onKeyDown={(event) => {
                            if (event.key == 13 || event.keyCode === 13) { this.closePopup(); }
                        }
                        }>
                            Ok
                        </div>
                    </div>
                </div>
            </div>
            <div className={"removeAlert-divbrowser " + (this.state.MFATokenPopup ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x d-flex justify-content-between ">
                    <p>
                        This site contains protected personal and health information, and it should only be accessed from a private computer not from a public computer. Since private data may be cached or downloaded to the computer, you should clear your cache and delete all downloaded files after using this site.
                    </p>
                </div>

                <div className="alert-pos-x d-flex justify-content-between ">
                    <p>To securely access the site, a one-time code has been sent to {this.state.MaskedEmail}.</p>

                </div>

                <div className="alert-pos-x d-flex justify-content-between ">
                    <label>Enter the code and press the <i>Accept & Verify</i> button to accept these conditions and enter the site.</label>
                    <div className="">
                        <input className={"form-control m-auto " + (this.state.MFAEnabledError ? "redborder" : "blackborder")}
                            placeholder="Security Code"
                            id="inputMFAToken"
                            type="password"
                            autoComplete="off"
                            value={this.state.MFAToken}
                            onChange={this.handleMFATokenChange}
                        />
                        <div>
                            {this.state.minutes <= 0 && this.state.seconds <= 0 ? (<div className="error">One Time security code has been expired. Click Resend Security Code.</div>) : (<div className="text-dark mr-2">Security Code expires in {this.state.minutes} : {this.state.seconds}</div>)}
                        </div>
                    </div>

                    <div className="small mdb-8 ns-2"> <button className="btn btn-blue-btnnm btn-type pull-mid mt-1 mb-1 mr-1 btn-size-4" onClick={this.verifyMFATokenPopup} >
                        Accept & Verify
                    </button></div>

                </div>
                <div className="alert-pos-x d-flex justify-content-between ">

                </div>
                <hr className="orangeline" />

                <div className="alert-pos-x d-flex justify-content-between">
                    <p>
                        Press the <i>Decline & Logout</i> button to not accept these terms and be returned to the login page.                        </p>
                    <div className="small mdb-8 ns-2"> <button className="btn btn-blue-btnnm btn-type pull-mid mt-1 mb-1 mr-1 btn-size-4" onClick={() => this.handleLogTime(false)} >
                        Decline & Logout
                    </button></div>
                </div>

                <hr className="orangeline" />
                <div className="alert-pos-x d-flex justify-content-between">
                    <div className="mt-2">
                        If you have not received the email after a few minutes, check your spam filter. You can press the <i>Resend Code</i> button to send the email again.
                        If you continue to not receive the email, then email <a href="mailto:ithelpdesk_us@enoahisolution.com.">ITHelpDesk_US@enoahisolution.com</a> for assistance.
                    </div>
                    <div className="small mdb-8 ns-2">
                        <button className="btn btn-blue-btnnm btn-type pull-mid mt-1 mb-1 mr-1 btn-size-4" onClick={this.ResendSecurityCode} >
                            Resend Code
                        </button>
                    </div>
                </div>

                <div className="alert-pos-x text-center mt-2">
                    <div>
                        <span className={"error " + (this.state.MFAEnabledError ? "show" : "hide")}>{this.state.MFAErrorMsg}</span>
                        <span className={"text-success " + (this.state.MFAResendError ? "show" : "hide")}> One-time code has been Resent to {this.state.MaskedEmail}.{this.state.MFAErrorMsg}</span>
                    </div>
                </div>
            </div>

            <div className={"acpt-button removeAlert-divbrowser " + (this.state.AcceptLoginPopup ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x d-flex justify-content-between">
                    <p>
                        This site contains protected personal and health information, and it should only be accessed from a private computer not from a public computer. Since private data may be cached or downloaded to the computer, you should clear your cache and delete all downloaded files after using the site.
                    </p>
                    <div className="small mdb-8 ns-2"> <button className="btn btn-blue-btnnm btn-type pull-mid mt-1 mb-1 mr-1 btn-size-4" onClick={() => this.handleLogTime(true)} >
                        Accept & Continue
                    </button></div>

                </div>

                <hr className="orangeline" />
                <div className="alert-pos-x d-flex justify-content-between">
                    <p>
                        Press the <i>Decline & Logout</i> button to not accept these terms and to be returned to the login page                        </p>
                    <div className="small mdb-8 ns-2"> <button className="btn btn-blue-btnnm btn-type pull-mid mt-1 mb-1 mr-1 btn-size-4" onClick={() => this.handleLogTime(false)} >
                        Decline & Logout
                    </button></div>
                </div>
            </div>

            <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} alt="Loading"  /> </span>
            <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
            {this.state.isSupporttedBrowser ?
                (
                    <div className={"container-fluid"}>                            {/*+ (this.state.isSupporttedBrowser ? "show" : "hide"*/}
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 lefeside-panel1">
                                <div className="inner-announcement-div">
                                    <div className="bg-white p-5 ann position-relative">

                                        {/*<textarea rows="7" cols="75" maxlength={500} disabled>{this.state.announcementData}</textarea>*/}
                                        <div className="text-area">
                                            <h2 className="announcement-word"> Announcement</h2>
                                            <span className="curve-img">
                                                <textarea placeholder=""
                                                    value={this.state.announcementData}
                                                    className={
                                                        "form-control Announcement-textarea"
                                                    }

                                                    maxLength={500}
                                                    rows={6}
                                                    readOnly
                                                >
                                                </textarea>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 bg-white">
                                <div className="login-page">
                                    <div className="logo">
                                        <img src="img/eSubmission-logo.png" alt="Logo" />
                                        <h2>Online Portal </h2>
                                    </div>
                                    <div className="form">

                                        <form className="login-form">
                                            <label>User Name  </label>

                                            <input type="text" placeholder="User" onKeyDown={this.handleKeyDownUN} value={this.state.Username} onChange={this.handleUNChange} />
                                            <span className={"error " + (this.state.usererror ? "show" : "hide")}>Invalid</span>
                                            <label>Password  </label>
                                            <input type="password" placeholder="Password" onKeyDown={this.handleKeyDownPass} maxLength={15} value={this.state.Password} onChange={this.handlePSChange} />
                                            <span className={"error " + (this.state.passerror ? "show" : "hide")}>Invalid</span>
                                            <span className={"error " + (this.state.sererror ? "show" : "hide")}>User name or Password is Invalid </span>
                                            <span className={"error " + (this.state.emailEnableErr ? "show" : "hide")}>Email is not confirmed / User is deleted. Please contact administrator.</span>
                                            <span className={"error " + (this.state.consecutivetriedErr ? "show" : "hide")}>Your account has been locked due to multiple failed login attempts. Please contact your account administrator for assistance or use 'Forgot Your Password' to reset your password.</span>
                                            <span className={"error " + (this.state.notFoundErr ? "show" : "hide")}>Your User name has not found. Please try again later or contact Administrator.</span>
                                            <span className={"error " + (this.state.somethingwentErr ? "show" : "hide")}>Something Went Wrong</span>
                                            <span className={"error " + (this.state.Unauthorizedaccess ? "show" : "hide")}>User not authorized to access the page</span>
                                            <span className={"error " + (this.state.PasswordExpired ? "show" : "hide")}>Your account has been unlocked. Please contact your account administrator for assistance or use 'Forgot Your Password' to reset your password.</span>
                                            <span className={"error " + (this.state.IsLocked ? "show" : "hide")}>Your account has been locked due to inactivity. Please contact your account Administrator for assistance.</span>

                                            <label>Company Code</label>
                                            <input type="text" placeholder="Company Code" onKeyDown={this.handleKeyDownCN} maxLength={20} value={this.state.Companycode.replace(/\s/g, "")} onChange={this.handleCNChange} />
                                            <span className={"error " + (this.state.companycodeerror ? "show" : "hide")}>Enter company code</span>
                                            <span className={"error " + (this.state.cnalphanumericerror ? "show" : "hide")}>Company code should only be in alphabets and numerics.</span>
                                            <span className={"error " + (this.state.cnminlengtherror ? "show" : "hide")}>Company code should contains min 1 characters.</span>
                                            <span className={"error " + (this.state.GenericErrMessage ? "show" : "hide")}>You may have entered the wrong details, or your account may have been locked. Please contact your account Administrator for assistance or use 'Forgot Your Password' to reset your password.</span>

                                            <div className={"loading " + (this.state.HideLoginButton ? "hide" : "show")} >
                                                <button className="onLogin" onClick={this.signInSubmit}>Login</button>

                                            </div>

                                            <span className="message"><input type="checkbox" onChange={this.onChangeCheckbox} />Remember Me</span>


                                            <span className={"forgot-div-x mb-0 pb-0 " + (this.state.HideForgotpasswordlink ? "hide" : "show")}>
                                                <NavLink to={(this.state.SourceEmail ? "/forgot-password?" + this.state.EncryptedUrlParams : "/forgot-password")}>Forgot Your Password </NavLink>
                                            </span>
                                            <span className={"forgot-div-x mb-0 pb-0 " + (this.state.HideForgotpasswordlinkDBdown ? "hide" : "show")}>

                                                <NavLink onClick={this.forgotpasswordDBdown} to="" >Forgot Your Password </NavLink>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                                <div className="footer-text text-center mb-1">
                                    Build: <span className="text-dark mr-2">{(this.state.Date === "N/A" ? "N/A" : (this.state.Date + ": v" + this.state.Version))} </span>
                                    Environment: <span className="text-dark mr-2">{this.state.Env}</span>
                                    {this.state.isHigherVersion ?
                                        (null)
                                        :
                                        (<div className="d-inline-block">Current Browser: <span className="text-dark">{browserName}</span> Ver <span className="text-dark">{browserVersion}</span></div>)}
                                </div>
                                <div className="footer-text text-center mb-1">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                                <div className="footer-text text-center">
                                    <a href={this.state.PrivacyPolicyURL} rel="noopener noreferrer" target="_blank"> Privacy Policy</a>
                                </div>
                            </div>

                        </div>
                    </div>
                ) :
                (
                    <div className={"browser-error text-center mb-2"}> {/*+ (this.state.isSupporttedBrowser ? "hide" : "show"*/}
                        <img src="img/outdated_browser.png" alt="Outdated Browser" /> <br />
                        <h0>You are not able to access the eNoah Online Portal because you are using a browser version which is no longer supported by the site. </h0>
                        {/*<p>Please download/upgrade your browser to a newer version of Chrome/Edge/Firefox/IE.</p>
                            <p>Please upgrade your browser to the newest version of {this.state.browserName ? this.state.browserName : "Chrome/Edge/Firefox/IE."}*/}
                        <p> Please upgrade your browser to the newest version of Chrome, Edge or Internet Explorer 10.
                            <br />Contact your IT department to assist in updating your browser. Please contact <a href="mailto:ithelpdesk_us@enoahisolution.com.">ITHelpDesk_US@enoahisolution.com</a> if you require any other assistance with the Portal.</p>

                        {/*<ol>*/}
                        {/*    <li><a href="https://support.google.com/chrome/answer/95346?hl=en"><span class="text-dark mr-2">Click here</span></a>to download latest Google Chrome browser.</li>*/}
                        {/*    <li><a href="http://www.mozilla.org/en-US/firefox/new/"><span class="text-dark mr-2">Click here</span></a>to download latest Firefox browser.</li>*/}
                        {/*    <li><a href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV"><span class="text-dark mr-2">Click here</span></a>to download latest Microsoft Edge browser.</li>*/}
                        {/*</ol>*/}
                        {/*   <div className="browser-list">
                                {this.state.browserName == "Chrome" ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <a href="https://support.google.com/chrome/answer/95346?hl=en">
                                            <img src="img/chrome.svg" alt="Chrome" />
                                                Google Chrome
                            </a>
                                    </div>
                                ) :
                                    (this.state.browserName == "Firefox" ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <a href="http://www.mozilla.org/en-US/firefox/new/">
                                                <img src="img/firefox.svg" alt="Firefox" />
                                                Firefox
                                </a>
                                        </div>
                                    ) :
                                            (this.state.browserName == "Edge" ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                <a href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV">
                                                    <img src="img/edge.svg" alt="Microsoft Edge" />
                                                Microsoft Edge
                                    </a>
                                            </div>
                                        ) :
                                            (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <a href="https://support.google.com/chrome/answer/95346?hl=en">
                                                        <img src="img/chrome.svg" alt="Chrome" />
                                                Google Chrome
                                            </a>
                                                    <a href="http://www.mozilla.org/en-US/firefox/new/">
                                                        <img src="img/firefox.svg" alt="Firefox" />
                                                Firefox
                                            </a>
                                                    <a href="https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV">
                                                        <img src="img/edge.svg" alt="Microsoft Edge" />
                                                Microsoft Edge
                                            </a>
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>*/}
                        {/* <p>Thanks, eNoah Support Team!</p>
                           <p>Current Browser: <span class="text-danger mr-2">{browserName}</span> Current Version: <span class="text-danger mr-2">{browserVersion}</span></p>*/}

                    </div >
                )
            }
        </div >
    );
}
}
